import { KeyFunctionObjectMapper, KeyValueObjectMapper } from './base.types';

export class ValidatorResult {
  constructor({ hasError, errors }: ValidatorResult = { hasError: false, errors: null }) {
    this.hasError = hasError;
    this.errors = errors;
  }

  public hasError?: boolean;

  public errors?: string[] | null;
}

export type ValidationResultsObject = {
  [key: string]: ValidatorResult | undefined;
};

export type ValidationObject<Obj> = KeyValueObjectMapper<Obj>;

export type ValidatorStrategy<Strategy> = KeyFunctionObjectMapper<Strategy, any, ValidatorResult>;

export type ValidateObjectFn<Obj, Strategy> = (
  object: ValidationObject<Obj>,
  strategy: ValidatorStrategy<Strategy>,
) => ValidationResultsObject;
