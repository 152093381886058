import { targetResearchFormStrategy } from '../domain/target-research-form.strategy';
import { FormFieldValueType } from '../components/target-research-form.types';
import { ITargetResearchFormInputProps } from '../components/target-research-form-input/target-research-form-input.types';
import {
  ICompanyTargetGroupDataForResearch,
  UpdateCompanyForResearchBody,
} from '../../../entities/company-target-group/domain/company-target-group.types';
import { ValidationResultsObject } from '../../../shared/domain/types/validation.types';

export function formCurrentTargetResearchFormState(
  targetResearchFormData: ICompanyTargetGroupDataForResearch,
): UpdateCompanyForResearchBody {
  const targetResearchFormState = new UpdateCompanyForResearchBody(targetResearchFormData);
  return targetResearchFormState;
}

export function formInputsConfig<T>(
  strategyObject: typeof targetResearchFormStrategy,
  values: ITargetResearchFormInputProps<T>['values'],
  formValidationErrors: ValidationResultsObject | undefined,
  areInputsDisabled: boolean,
  handleChange: ITargetResearchFormInputProps<T>['handleChange'],
  handleCustomInputChange: (name: string, value: any) => void,
) {
  const targetResearchFormFieldsEntries = Object.entries(strategyObject);
  const inputsConfigArray = targetResearchFormFieldsEntries.map(([, fieldConfig]) => {
    const { inputValueType, ...restFieldConfig } = fieldConfig;
    const strategy = {
      [FormFieldValueType.COMMON_FIELD]: {
        ...restFieldConfig,
        handleChange,
        values,
        errors: formValidationErrors,
        disabled: areInputsDisabled,
      },
      [FormFieldValueType.SPECIFIC_FIELD]: {
        ...restFieldConfig,
        handleChange: handleCustomInputChange,
        values,
        errors: formValidationErrors,
        disabled: areInputsDisabled,
      },
    };
    return strategy[inputValueType];
  });
  return inputsConfigArray;
}
