import { AuthProcess, IAuthFormFieldConfig } from '../types/authentication.types';

export const authFormFieldsByProcesses: {
  [Property in AuthProcess]: {
    [key: string]: IAuthFormFieldConfig;
  };
} = {
  [AuthProcess.LOGIN]: {
    email: {
      id: `1_email_${AuthProcess.LOGIN}`,
      title: 'Email',
      label: 'Email address',
      name: 'email',
      type: 'text',
      process: AuthProcess.LOGIN,
      defaultState: '',
      isPasswordField: false,
      key: `1_email_${AuthProcess.LOGIN}`,
    },
    password: {
      id: `2_password_${AuthProcess.LOGIN}`,
      title: 'Password',
      label: 'Password',
      name: 'password',
      type: 'password',
      process: AuthProcess.LOGIN,
      defaultState: '',
      isPasswordField: true,
      key: `2_password_${AuthProcess.LOGIN}`,
    },
  },
  [AuthProcess.REGISTRATION]: {},
  [AuthProcess.CHANGE_PASSWORD]: {},
  [AuthProcess.CONFIRM_EMAIL]: {},
};
