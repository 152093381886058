import { styled } from '@mui/material/styles';

import BaseTextarea from '../../../../shared/components/textarea/base-textarea/base-textarea.component';

export const TargetResearchFormBaseInput = styled(BaseTextarea)(() => ({
  '&.target-research-form-input': {
    '& .MuiInputBase-root': {
      padding: '10px 0',

      '& textarea': { padding: '0 14px' },
    },
  },
}));
