import React from 'react';

import { formatUrlToGuaranteeClickability } from '../../helpers/base.helpers';

import { ILinkProps } from './link.types';
import { LinkWrapper, LinkText } from './link.styles';

function Link({ target, url, title }: ILinkProps) {
  return (
    <LinkWrapper href={formatUrlToGuaranteeClickability(url) ?? ''} target={target ?? '_blank'} rel="noreferrer">
      <LinkText>{title}</LinkText>
    </LinkWrapper>
  );
}

export default Link;
