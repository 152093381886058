import React from 'react';

import {
  TargetVettingRightSectionHeader,
  SectionHeaderName,
  TargetVettingHeaderControls,
} from './kpi-selector-header.styles';
import SelectClientsWithAutocomplete from '../../../features/select-clients-with-autocomplete/components/select-clients-with-autocomplete.component';

function KPISelectionHeader({ header }: { header: string }) {
  return (
    <TargetVettingRightSectionHeader>
      <SectionHeaderName>{header}</SectionHeaderName>
      <TargetVettingHeaderControls>
        <SelectClientsWithAutocomplete id={'111'} label={'Clients'} name={'Clients'} disabled={false} />
      </TargetVettingHeaderControls>
    </TargetVettingRightSectionHeader>
  );
}

export default KPISelectionHeader;
