import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { MuiIconButtonProps } from './base-icon-button.types';

export const muiTooltipStyles = {
  borderRadius: '6px',
  backgroundColor: 'rgba(100, 100, 100, 0.8)',
};

export const muiTooltipArrowStyles = {
  color: 'rgba(100, 100, 100, 0.8)',
};

export const MuiIconButton = styled(IconButton, {
  shouldForwardProp: (props) => props !== 'isCurrentRoute',
})<MuiIconButtonProps>(({ theme }) => ({
  width: '35px',
  height: '35px',

  '> .base-icon-button-loader': {
    color: theme.palette.background.orange,
  },

  '> svg': {
    fill: theme.palette.background.orange,
  },
}));

export const ButtonIcon = styled('img')(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: theme.palette.background.orange,
}));
