import { styled } from '@mui/material/styles';
import Container, { ContainerProps } from '@mui/material/Container';

export const CompanyProfileWrapper = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '50%',
  height: '100%',
  margin: 0,
  padding: '0 2% 0 0',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    padding: '0 2% 0 0',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

const companyProfileInputSize = {
  width: '18rem',
  height: '38px',
  marginTop: '6px',
};

export const CompanyProfileDataContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  maxHeight: '100%',
  overflow: 'auto',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const CompanyProfileDataTGSelectorContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
  '& .update-target-group-on-review > .change-company-target-group-dropdown > .MuiInputBase-root': {
    ...companyProfileInputSize,
    borderRadius: '8px',
    transition: 'border-radius 0.3s ease-in-out',
  },
  marginBottom: '-25px',
}));

export const CompanyProfileDataCommentInputContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
  '& .MuiFormControl-root.MuiTextField-root > .MuiInputBase-root.MuiOutlinedInput-root': {
    minHeight: companyProfileInputSize.height,
  },
  '& .MuiInputBase-root': {
    ...companyProfileInputSize,
  },
}));
