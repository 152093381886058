import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  companiesToReviewCountSelector,
  companyForReviewSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { useIsRouteMatched } from '../../../shared/hooks/use-is-route-matched.hook';
import { Routes } from '../../../shared/domain/constants/routing.constants';

import IndicatorChip from '../../../shared/components/chip/indicator-chip/indicator-chip.component';
import { countCompaniesToReview } from '../../../entities/company-target-group/store/company-target-group.slice';

function CountCompaniesToReview() {
  const dispatch = useAppDispatch();

  const { isRouteMatched } = useIsRouteMatched();

  const { clientId } = useParams();

  const currentCompanyForReview = useAppSelector(companyForReviewSelector);
  const { isMarkedAsValidated } = currentCompanyForReview.markAsValidated;
  const { isMarkedAsRejected } = currentCompanyForReview.markAsRejected;

  const count = useAppSelector(companiesToReviewCountSelector);

  const isDimmed = !isRouteMatched(Routes.REVIEW_EMAILS);

  useEffect(() => {
    dispatch(countCompaniesToReview({ clientId }));
  }, []);

  useEffect(() => {
    if (isMarkedAsRejected || isMarkedAsValidated) dispatch(countCompaniesToReview({ clientId }));
  }, [dispatch, isMarkedAsRejected, isMarkedAsValidated]);

  return <IndicatorChip label={count} isDimmed={isDimmed} />;
}

export default CountCompaniesToReview;
