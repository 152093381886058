import Typography, { TypographyProps } from '@mui/material/Typography';
import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const CompanyTrackingEventsSectionContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '75%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.blueTranslucent,
  color: theme.palette.common.white,

  [theme.breakpoints.up('xs')]: {
    padding: '2.5% 3.5% 0%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const CompanyTrackingEventsSectionHeader = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const SectionHeaderName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1.5rem',
  lineHeight: '34px',
  fontWeight: 600,
  color: theme.palette.common.white,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.75rem',
  },
}));

export const NewsEventsContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '93%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
