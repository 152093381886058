import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const IndicatorTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  color: theme.palette.common.white,
  marginBottom: '15px',
}));

export const IndicatorValue = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1.3rem',
  fontWeight: 600,
  color: theme.palette.background.orange,
  marginBottom: '15px',

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.5rem',
  },
}));
