export const rowPropertyNames = [
  'name',
  'website',
  'linkedIn',
  'headcount',
  'EBITDA',
  'revenue',
  'acquired',
  'acquirer',
  'backedByPE',
  'PEbacker',
  'backedByVC',
  'VCbacker',
  'madeAcquisitions',
  'acquiredCompanies',
  'countries',
  'comment',
];

export const columnNameToColumnHeaderCellConfig = {
  Company: {
    isSortable: false,
    isCurrentlySorted: false,
    handleClick: () => {},
  },
  Website: {
    isSortable: false,
    isCurrentlySorted: false,
    handleClick: () => {},
  },
  'Company LinkedIn': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  Headcount: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  EBITDA: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  Revenue: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  Acquired: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  Acquirer: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  'Backed by PE': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  'PE Backer': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  'Backed by VC': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  'VC Backer': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  'Made Acquisitions': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  'Acquired Companies': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  Countries: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  Comment: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
};
