import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { TestPromptContainer } from './test-prompt.styles';
import { promptExampleCompanies } from '../../../entities/target-group/store/target-group.slice';
import { targetGroupThunksSelector } from '../../../entities/target-group/store/target-group.selectors';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
import BaseAlerts from '../../../shared/components/alert/base-alerts/base-alerts.component';

function TestPrompt({ targetGroupId }: { targetGroupId: string }) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { isLoading, isError, isSuccess, error } = useAppSelector(targetGroupThunksSelector).promptExampleCompanies;

  function handleButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsOpen(true);
  }

  async function handleAction() {
    dispatch(promptExampleCompanies(targetGroupId));
    setIsOpen(false);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleCancel() {
    setIsOpen(false);
  }

  const promptModalConfig = {
    isInfo: isOpen,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Are you sure to run prompt?',
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <TestPromptContainer>
        <TransparentRectButtonWithOrangeBorder
          title="Test prompt"
          handleClick={handleButtonClick}
          className={`marked-as-not-a-fit not-marked`}
          isLoading={isLoading}
        />
      </TestPromptContainer>
      <BaseAlerts message={(error as any).message} isUiVisible={isError} type="error" delay={3000} />
      <BaseAlerts
        message={`Success: you can check the prompt "outcome" button`}
        isUiVisible={isSuccess}
        type="success"
        delay={3000}
      />
    </FeedbackUiDecorator>
  );
}

export default TestPrompt;
