import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const ChangeCompanyTargetGroupContainer = styled(Container, {
  shouldForwardProp: (props) => props !== 'hasError',
})<ContainerProps & { hasError: boolean }>(({ theme, hasError }) => ({
  '& .change-company-target-group-dropdown': {
    '> .MuiInputBase-root': {
      color: theme.palette.background.black,
      fontWeight: 500,
      textAlign: 'left',
      border: hasError ? `1.5px solid ${theme.palette.text.error}` : 'none',
    },
  },
  '& .autocomplete-validation-helper': {
    margin: 0,
    marginTop: '-23px',
    paddingInlineStart: '20px',

    li: {
      p: {
        fontSize: '0.65rem',
        color: theme.palette.text.error,

        [theme.breakpoints.up('xl')]: {
          fontSize: '0.8rem',
        },
      },
    },
  },
}));
