/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Stack from '@mui/material/Stack';

import { relevantCompaniesNewsEventsSelector } from '../../entities/company-target-group/store/company-target-group.selectors';
import { useAppDispatch, useAppSelector } from '../../app/store/utils/redux.hooks';
import { usePopper } from '../../shared/hooks/use-popper.hook';
import { updateRelevantCompanies } from '../../entities/company-target-group/store/company-target-group.slice';
import {
  AddRelevantCompanyContainer,
  ConfigureRelevantCompaniesWrapper,
  RelevantCompanyBox,
  RelevantCompanyContainer,
  RelevantCompanyDomain,
} from './configure-relevant-companies-tracking.styles';
import { InputChangeEvent } from '../../shared/domain/types/html-elements.types';

import BaseInput from '../../shared/components/input/base-input/base-input.component';
import BasePopper from '../../shared/components/popper/base-popper/base-popper.component';
import TransparentSquareIconButton from '../../shared/components/button/transparent-square-icon-button/transparent-square-icon-button.component';

function ConfigureRelevantCompaniesTracking() {
  const relevantCompaniesNewsData = useAppSelector(relevantCompaniesNewsEventsSelector).data.item;
  const relevantCompanies = relevantCompaniesNewsData?.relevantCompanies;
  const companyTargetGroupId = relevantCompaniesNewsData?.id;

  const [newRelevantCompany, setNewRelevantCompany] = useState<string>('');
  const [relevantCompaniesList, setRelevantCompaniesList] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  const { isOpen, handleButtonClick, handleClickAway } = usePopper();

  function handleInputChange(event: InputChangeEvent): void {
    const { value } = event.target;
    setNewRelevantCompany(value);
  }

  function handleRelevantCompanyAdd() {
    if (!newRelevantCompany) return;
    setRelevantCompaniesList((prevRelevantCompaniesList) => [newRelevantCompany, ...prevRelevantCompaniesList]);
    setNewRelevantCompany('');
  }

  function handleRelevantCompanyDelete(domainToDelete: string) {
    setRelevantCompaniesList((prevRelevantCompaniesList) =>
      prevRelevantCompaniesList.filter((domain) => domain !== domainToDelete),
    );
  }

  function handlePopperButtonClick() {
    handleButtonClick();
    if (isOpen) {
      dispatch(updateRelevantCompanies({ companyTargetGroupId, companyDomains: relevantCompaniesList }));
    }
  }

  useEffect(() => {
    if (relevantCompanies) {
      setRelevantCompaniesList([...relevantCompanies]);
    }
  }, [relevantCompanies]);

  return (
    <BasePopper
      buttonTitle={isOpen ? 'Save' : 'Track'}
      isOpen={isOpen}
      handleButtonClick={handlePopperButtonClick}
      handleClickAway={handleClickAway}
      className="configure-relevant-companies-popper"
    >
      <ConfigureRelevantCompaniesWrapper>
        <AddRelevantCompanyContainer>
          <BaseInput
            id={`${newRelevantCompany}`}
            title="Enter a company domain"
            label={newRelevantCompany}
            name="newRelevantCompany"
            isLabelShown={false}
            isPasswordField={false}
            isPlaceholderShown
            handleChange={handleInputChange}
            values={newRelevantCompany ? { newRelevantCompany } : { newRelevantCompany: '' }}
            className="add-relevant-company-input"
            type="text"
            autoFocus
          />
          <TransparentSquareIconButton
            handleClick={handleRelevantCompanyAdd}
            className="add-relevant-company-button"
            disabled={!newRelevantCompany}
          >
            <AddIcon />
          </TransparentSquareIconButton>
        </AddRelevantCompanyContainer>
        <Stack width="100%" direction="column" paddingTop="70px">
          {relevantCompaniesList?.map((domain, index) => (
            <RelevantCompanyContainer key={`${index}_${domain}`}>
              <RelevantCompanyBox>
                <RelevantCompanyDomain>{domain}</RelevantCompanyDomain>
              </RelevantCompanyBox>
              <TransparentSquareIconButton
                handleClick={() => handleRelevantCompanyDelete(domain)}
                className="delete-relevant-company-button"
              >
                <DeleteOutlineIcon />
              </TransparentSquareIconButton>
            </RelevantCompanyContainer>
          ))}
        </Stack>
      </ConfigureRelevantCompaniesWrapper>
    </BasePopper>
  );
}

export default ConfigureRelevantCompaniesTracking;
