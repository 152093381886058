import React, { PropsWithChildren } from 'react';

import BaseInput from '../base-input/base-input.component';
import { InputInfoUiType } from '../base-input/base-input.types';
import { IInputWithLabelTooltipProps } from './input-with-label-tooltip.types';

function InputWithLabelTooltip<T extends { [key: string]: any }>({
  id,
  title,
  label,
  name,
  type,
  handleChange,
  values,
  errors,
  isPasswordField,
  className,
}: PropsWithChildren<IInputWithLabelTooltipProps<T>>) {
  return (
    <BaseInput
      id={id}
      className={className}
      title={title || ''}
      label={label}
      name={name}
      type={type}
      handleChange={handleChange}
      values={values}
      errors={errors}
      isPasswordField={isPasswordField}
      styleSchema={{}}
      isPlaceholderShown={false}
      infoUiType={InputInfoUiType.TOOLTIP}
      isLabelShown
    />
  );
}

export default InputWithLabelTooltip;
