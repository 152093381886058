import React from 'react';

import { AuthBaseButton } from './auth-button.styles';
import { IAuthButtonProps } from './auth-button.types';

function AuthButton({ title, handleClick, disabled, isLoading }: IAuthButtonProps) {
  return (
    <AuthBaseButton
      className="auth-button"
      title={title}
      handleClick={handleClick}
      disabled={disabled}
      isLoading={isLoading}
    />
  );
}

export default AuthButton;
