import { BaseValidationRules } from './base-validation-rules.constants';

export class ValidatorCheckHelpers {
  public static checkIsString(value: any): boolean {
    return typeof value === 'string';
  }

  public static checkIsNotEmpty(value: string | any[]): boolean | undefined {
    if (typeof value === 'string') return Boolean(value.toString()?.trim());
    if (Array.isArray(value)) return Boolean(value.length > 0);
  }

  public static checkIsEmail(value: string): boolean {
    return BaseValidationRules.EMAIL_REGEXP.test(value);
  }

  public static checkPatternMatch(value: any, pattern: any): boolean {
    return value?.match(pattern);
  }

  public static checkStringLength(value: string, minLength: number, maxLength: number): boolean {
    return value.length >= minLength && value.length <= maxLength;
  }

  public static checkStringMinLength(value: string, length: number): boolean {
    return value.length >= length;
  }

  public static checkStringMaxLength(value: string, length: number): boolean {
    return value.length <= length;
  }
}
