import { IClient, UserRole } from '../../../entities/user/domain/user.types';
import { PipelineStageName } from '../components/pipeline-navigation/pipeline-navigation.types';

const stageSkippingConfig = {
  [UserRole.CLIENT]: [PipelineStageName.GTM_PLAN, PipelineStageName.TARGET_UPLOAD, PipelineStageName.TARGET_RESEARCH],
  [UserRole.EMPLOYEE]: [],
};

export function getSkippedStages(currentClient: IClient, role: UserRole): PipelineStageName[] {
  const skippedStages = currentClient?.pipelineConfig?.skipStages || [];
  const roleSkippedStages = stageSkippingConfig[role] || [];

  return [...skippedStages, ...roleSkippedStages];
}

export function isStageAllowed(stage: string, skippedStages: PipelineStageName[]): boolean {
  return !skippedStages.includes(stage as PipelineStageName);
}
