interface IFieldsFlags {
  hasEBITDA?: boolean;
  hasAquirer?: boolean;
  hasBEBacker?: boolean;
  hasVCBacker?: boolean;
  hasAcquisitions?: boolean;
  hasHeadcount?: boolean;
}

export const rowPropertyNames = ({
  hasEBITDA,
  hasAquirer,
  hasBEBacker,
  hasVCBacker,
  hasAcquisitions,
  hasHeadcount,
}: IFieldsFlags = {}) => [
  'companyName',
  'targetGroupName',
  ...(hasEBITDA ? ['revenue', 'EBITDA'] : []),
  ...(hasAquirer ? ['acquired', 'acquirer'] : []),
  ...(hasBEBacker ? ['backedByPE', 'PEbacker'] : []),
  ...(hasVCBacker ? ['backedByVC', 'VCbacker'] : []),
  ...(hasAcquisitions ? ['madeAcquisitions', 'acquiredCompanies'] : []),
  ...(hasHeadcount ? ['headcount'] : []),
  'countries',
  'rejectionStatus',
  'comment',
];

export const columnNameToColumnHeaderCellConfig = ({
  hasEBITDA,
  hasAquirer,
  hasBEBacker,
  hasVCBacker,
  hasAcquisitions,
  hasHeadcount,
}: IFieldsFlags = {}) => ({
  Company: {
    isSortable: false,
    isCurrentlySorted: false,
    handleClick: () => {},
  },
  'Target Group': {
    isSortable: false,
    isCurrentlySorted: false,
    handleClick: () => {},
  },
  ...(hasEBITDA
    ? {
        Revenue: {
          isSortable: false,
          isCurrentlySorted: false,
          handleClick: () => {},
        },
        EBITDA: {
          isSortable: false,
          isCurrentlySorted: false,
          handleClick: () => {},
        },
      }
    : {}),
  ...(hasAquirer
    ? {
        Acquired: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
        Acquirer: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
      }
    : {}),
  ...(hasBEBacker
    ? {
        'Backed by PE': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
        'PE Backer': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
      }
    : {}),
  ...(hasVCBacker
    ? {
        'Backed by VC': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
        'VC Backer': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
      }
    : {}),
  ...(hasAcquisitions
    ? {
        'Made Acquisitions': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
        'Acquired Companies': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
      }
    : {}),
  ...(hasHeadcount
    ? {
        Headcount: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
      }
    : {}),
  Country: {
    isSortable: false,
    isCurrentlySorted: false,
    handleClick: () => {},
  },
  "Don't Contact": { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  Comment: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
});
