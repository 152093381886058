import React from 'react';
import { Outlet } from 'react-router-dom';

import { BaseLayoutContainer } from './base-layout.styles';

function BaseLayout() {
  return (
    <BaseLayoutContainer className="base-layout-container">
      <Outlet />
    </BaseLayoutContainer>
  );
}

export default BaseLayout;
