export enum CompanyProfileDataField {
  LEAD_OWNER = 'Lead Owner',
  NAME = 'Person Name',
  JOB_TITLE = 'Job Title',
  EMAIL = 'Email',
  LINKEDIN_URL = 'LinkedIn URL',
  WEBSITE = 'Website',
  HEADCOUNT = 'Headcount',
  COUNTRIES = 'Country(ies)',
  TARGET_GROUP = 'Target Group',
  COMPANY_NAME = 'Company Name',
  COMPANY_DESCRIPTION = 'Company Description',
  OTHER_INFO = 'Other info',
  EBITDA_MARGIN = 'EBITDA Margin',
  EBITDA = 'EBITDA',
  REVENUE = 'Revenue',
  ACQUIRER = 'Acquirer',
  BE_BACKER = 'PE Backer',
  VC_BACKER = 'VC Backer',
  ACQUIRED_COMPANIES = 'Acquired companies',
  COMMENT = 'Comment',
}
