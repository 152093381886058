import { composeName } from '../../../helpers/base.helpers';

export const cellWithSpecificFormatting: {
  [x: string]: any;
} = {
  person: composeName,
};

export function formatCellData(
  propertyName: string,
  row: {
    [x: string]: any;
  },
) {
  if (propertyName in cellWithSpecificFormatting) return cellWithSpecificFormatting?.[propertyName](row);
  return row[propertyName];
}
