export enum FileRejectionSource {
  REACT_DROPZONE = 'reactDropzone',
  SERVER = 'server',
}

export interface IRejectedFileProps {
  fileName: string;
  error: any;
  errorSource: string;
}
