import {
  CompanyName,
  CompanyNewsHeader,
  CompanyNewsListContainer,
  CompanyNewsWrapper,
  SectionHeaderName,
} from './fetch-company-news-layout.styles';
import { IFetchCompanyNewsLayoutProps } from './fetch-company-news-layout.types';

function FetchCompanyNewsLayout({ companyName, children, listWidth = '50%' }: IFetchCompanyNewsLayoutProps) {
  return (
    <CompanyNewsWrapper width={listWidth}>
      <CompanyNewsHeader>
        <CompanyName>{companyName}</CompanyName>
        <SectionHeaderName>News Events</SectionHeaderName>
      </CompanyNewsHeader>
      <CompanyNewsListContainer>{children}</CompanyNewsListContainer>
    </CompanyNewsWrapper>
  );
}

export default FetchCompanyNewsLayout;
