import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import {
  currentTargetGroupParamsSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import {
  defaultCurrentTargetGroupParams,
  deleteTargetGroup,
  getAllTargetGroupsByClient,
  setCurrentTargetGroupParams,
} from '../../../entities/target-group/store/target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
import { IDeleteTargetGroupProps } from './delete-target-group.types';
import { DeleteTargetGroupButton } from './delete-target-group.styles';

import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';

function DeleteTargetGroup({ targetGroupId }: IDeleteTargetGroupProps) {
  const [isPromptedToDelete, setIsPromptedToDelete] = useState(false);

  const { id: selectedTargetGroupId } = useAppSelector(currentTargetGroupParamsSelector);

  const { isLoading: isTargetGroupDeleting } = useAppSelector(targetGroupThunksSelector).deleteTargetGroup;
  const { isLoading: areTargetGroupsLoading } = useAppSelector(targetGroupThunksSelector).getAllTargetGroupsByClient;

  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  function resetTargetGroupForm() {
    if (selectedTargetGroupId === targetGroupId) {
      dispatch(setCurrentTargetGroupParams(defaultCurrentTargetGroupParams));
    }
  }

  function handleDeleteButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsPromptedToDelete(true);
  }

  async function handleAction() {
    dispatch(deleteTargetGroup(targetGroupId));
    await dispatch(getAllTargetGroupsByClient({ clientId }))
      .unwrap()
      .then(() => {
        resetTargetGroupForm();
        setIsPromptedToDelete(false);
      });
  }

  function handleClose() {
    setIsPromptedToDelete(false);
  }

  function handleCancel() {
    setIsPromptedToDelete(false);
  }

  const promptModalConfig = {
    isInfo: isPromptedToDelete,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Remove this target group?',
    isActionLoading: isTargetGroupDeleting || areTargetGroupsLoading,
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <DeleteTargetGroupButton onClick={handleDeleteButtonClick}>
        <DeleteOutlinedIcon />
      </DeleteTargetGroupButton>
    </FeedbackUiDecorator>
  );
}

export default DeleteTargetGroup;
