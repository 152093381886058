import { PipelineStageName } from '../../../modules/navigation/components/pipeline-navigation/pipeline-navigation.types';
import { BaseListServerResponse } from '../../../shared/domain/types/base.types';

export enum UserRole {
  EMPLOYEE = 'employee',
  CLIENT = 'client',
}

export interface IClient {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  pipelineConfig: { skipStages?: PipelineStageName[] };
}

export interface IGetAllClientsItemDto {
  id: string;
  createdAt: string;
  user: { id: string; name: string; surname: string };
  companyName?: string;
}

export interface IEmployee {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
}

export type UserRoles = IClient | IEmployee;

export interface IThunkStatuses {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  isStarted: boolean;
  isFinished: boolean;
}

export interface IUserThunks {
  getAllClients: IThunkStatuses;
  getCurrentClient: IThunkStatuses;
}

export interface IUser {
  id: string;
  name: string;
  surname: string;
  email: string;
  role: UserRole | null;
  roleInfo: UserRoles | null;
  createdAt: string;
  updatedAt: string;
}

export type UserState = {
  user: IUser;
  clients: IGetAllClientsItemDto[];
  selectedClients: any[];
  thunks: IUserThunks;
  currentClient?: IClient;
};

export interface IClientsListResponse extends BaseListServerResponse<IGetAllClientsItemDto> {}
