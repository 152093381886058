import { styled } from '@mui/material/styles';
import Container, { ContainerProps } from '@mui/material/Container';

export const ReviewEmailsRightSectionFormContainer = styled(Container, {
  shouldForwardProp: (props) => props !== 'isCompanyChosen',
})<ContainerProps & { isCompanyChosen: boolean }>(({ isCompanyChosen, theme }) => ({
  width: '100%',
  overflow: 'auto',
  height: '100%',
  padding: 0,
  paddingBottom: isCompanyChosen ? 0 : '32px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  '> .review-emails-form-wrapper': {
    width: '100%',
  },

  [theme.breakpoints.up('xs')]: {
    padding: 0,
    paddingBottom: isCompanyChosen ? 0 : '32px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
