import React from 'react';
import { Stack } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { downloadDataPerClient } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch } from '../../../app/store/utils/redux.hooks';

import TransparentSquareIconButton from '../../../shared/components/button/transparent-square-icon-button/transparent-square-icon-button.component';

function DownloadClientData({ clientId }: { clientId: string }) {
  const dispatch = useAppDispatch();

  function downloadFileInCsvFormat() {
    dispatch(downloadDataPerClient({ clientId }));
  }

  return (
    <Stack>
      <TransparentSquareIconButton handleClick={downloadFileInCsvFormat}>
        <FileDownloadOutlinedIcon style={{ strokeWidth: 1 }} />
      </TransparentSquareIconButton>
    </Stack>
  );
}

export default DownloadClientData;
