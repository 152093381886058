/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';

import { infoFeedbackUiPropsWithDefaults } from './info-feedback-ui-kit.constants';
import {
  IBaseModalProps,
  InfoOrErrorFeedbackUiPropsUnion,
  FeedbackUiType,
  InfoOrErrorFeedbackUiType,
  IBaseAlertProps,
} from '../feedback-ui.types';

import BaseAlerts from '../../alert/base-alerts/base-alerts.component';
import BaseModal from '../../modal/base-modal/base-modal.component';

export const infoFeedbackUiKit: {
  [Property in InfoOrErrorFeedbackUiType]: (props: InfoOrErrorFeedbackUiPropsUnion) => ReactNode;
} = {
  [FeedbackUiType.MODAL]: (props: InfoOrErrorFeedbackUiPropsUnion) => {
    const propsWithDefaults = infoFeedbackUiPropsWithDefaults[FeedbackUiType.MODAL](props);
    return <BaseModal {...(propsWithDefaults as IBaseModalProps)} />;
  },
  [FeedbackUiType.SNACKBAR]: (props: InfoOrErrorFeedbackUiPropsUnion) => null,
  [FeedbackUiType.ALERT]: (props: InfoOrErrorFeedbackUiPropsUnion) => {
    const propsWithDefaults = infoFeedbackUiPropsWithDefaults[FeedbackUiType.ALERT](props);
    return <BaseAlerts {...(propsWithDefaults as IBaseAlertProps)} />;
  },
};
