import React, { useEffect } from 'react';

import { clientsSelector, userThunksSelector } from '../../../entities/user/store/user.selectors';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { FetchClientsListContainer } from './fetch-all-clients-list.styles';

import ClientCard from './client-card/client-card.component';
import SelectEntity from '../../select-entity/components/select-entity.component';
import { getAllClients } from '../../../entities/user/store/user.slice';
import { formSelectCardProps } from '../helpers/fetch-all-clients-list.helpers';

function FetchAllClientsList() {
  const dispatch = useAppDispatch();

  const clients = useAppSelector(clientsSelector);
  const clientsForSelect = formSelectCardProps(clients);
  const { isLoading: areClientsLoading } = useAppSelector(userThunksSelector).getAllClients;

  useEffect(() => {
    dispatch(getAllClients());
  }, []);

  return (
    <FetchClientsListContainer>
      <SelectEntity
        entityData={clientsForSelect ?? []}
        isLoading={areClientsLoading}
        className="select-client"
        entityCard={ClientCard}
      />
    </FetchClientsListContainer>
  );
}

export default FetchAllClientsList;
