import { styled } from '@mui/material/styles';
import Container, { ContainerProps } from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const NoTargetGroupChosenScreenContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  margin: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 3%',
  textAlign: 'center',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const NoTargetGroupChosenMessage = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: 600,
  color: theme.palette.text.orange,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));
