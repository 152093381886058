/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { companyTargetGroupThunksSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { propagate } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { RejectLeadContainer } from './reject-lead.styles';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import BaseAlerts from '../../../shared/components/alert/base-alerts/base-alerts.component';

function Propagate() {
  const [isPromptedToReject, setIsPromptedToReject] = useState(false);
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const { isLoading, isError, error } = useAppSelector(companyTargetGroupThunksSelector).propagate;

  async function handleButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsPromptedToReject(true);
  }

  async function handleAction() {
    await dispatch(propagate({ clientId, targetGroupId }));
    setIsPromptedToReject(false);
  }

  function handleClose() {
    setIsPromptedToReject(false);
  }

  function handleCancel() {
    setIsPromptedToReject(false);
  }

  const promptModalConfig = {
    isInfo: isPromptedToReject,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Are you sure to propagate?',
    isActionLoading: isLoading,
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <RejectLeadContainer>
        <TransparentRectButtonWithOrangeBorder
          title={'Propagate Changes'}
          handleClick={handleButtonClick}
          className={`not-rejected`}
          isLoading={isLoading}
        />
      </RejectLeadContainer>
      <BaseAlerts
        message={`Unable to propagate: ${error?.message || 'internal server error'}`}
        isUiVisible={isError && !isPromptedToReject}
        type="error"
        delay={3000}
      />
    </FeedbackUiDecorator>
  );
}

export default Propagate;
