import React from 'react';

import SingleSectionScreenLayout from '../../app/layouts/single-sections-screen/single-sections-screen.component';
import KPISelectionHeader from '../../modules/kpi-selection-header/components/kpi-selector-header.component';
import KPIWeeklyTable from '../../modules/kpi-weekly-table/components/kpi-weekly-table.component';

function KPIWeekly() {
  const SECTION_HEADER = 'Weekly KPIs';

  return (
    <SingleSectionScreenLayout>
      <KPISelectionHeader header={SECTION_HEADER} />
      <KPIWeeklyTable />
    </SingleSectionScreenLayout>
  );
}

export default KPIWeekly;
