import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const CompanyTrackingSelectContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '25%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.blueTranslucent,
  color: theme.palette.common.white,
  overflow: 'auto',
  position: 'relative',

  '> .company-tracking-target-groups-select': {
    width: '100%',
    position: 'sticky',
    top: 0,
    left: 0,

    [theme.breakpoints.up('xs')]: {
      maxWidth: '100%',
    },
  },

  [theme.breakpoints.up('xs')]: {
    padding: '1.2%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },

  // [theme.breakpoints.up('xl')]: {
  //   width: '20%',
  // },
}));
