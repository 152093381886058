import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import { PercentOutlined } from '@mui/icons-material';
import { resetCompanyState } from '../../../../entities/company/store/company.slice';
import { resetCompanyTargetGroupState } from '../../../../entities/company-target-group/store/company-target-group.slice';
import { resetTargetGroupState } from '../../../../entities/target-group/store/target-group.slice';
import { useIsRouteMatched } from '../../../../shared/hooks/use-is-route-matched.hook';
import { useAppDispatch, useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { userSelector } from '../../../../entities/user/store/user.selectors';
import { DashboardNavigationTooltip } from './dashboard-navigation.constants';
import { NavigationPaths, Routes } from '../../../../shared/domain/constants/routing.constants';
import { UserRole } from '../../../../entities/user/domain/user.types';

import Logout from '../../../../features/authentication/components/logout/logout.component';
import TransparentSquareIconButton from '../../../../shared/components/button/transparent-square-icon-button/transparent-square-icon-button.component';

function DashboardNavigation() {
  const { isRouteMatched } = useIsRouteMatched();

  const navigate = useNavigate();

  const { clientId } = useParams();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const { role } = useAppSelector(userSelector);

  function checkIsDashboardNavigationAllowed(currentRoute: string) {
    const forbiddenForRoutes = [NavigationPaths.CLIENTS];
    const isAllowed = !forbiddenForRoutes.includes(currentRoute);
    return isAllowed;
  }

  function isAnalyticsRelatedRoute(): boolean {
    const routes = [Routes.ANALYTICS_AND_REPORTING, Routes.COMPANY_TRACKING, Routes.MARKET_TRACKING];
    return routes.some(isRouteMatched);
  }

  function isKPIsRelatedRoute(): boolean {
    const routes = [Routes.SELECTION, Routes.WEEKLY_KPIS, Routes.VISIBILITY_KPIS];
    return routes.some(isRouteMatched);
  }

  return (
    <Stack direction={{ xs: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
      {checkIsDashboardNavigationAllowed(location.pathname) ? (
        <Stack direction={{ xs: 'row' }} spacing={{ xs: 0.3, sm: 0.7, md: 1.3 }}>
          {role === UserRole.EMPLOYEE ? (
            <TransparentSquareIconButton
              handleClick={() => {
                navigate(NavigationPaths.CLIENTS);
                dispatch(resetTargetGroupState());
                dispatch(resetCompanyState());
                dispatch(resetCompanyTargetGroupState());
              }}
              tooltipConfig={{ title: DashboardNavigationTooltip.CLIENTS_LIST }}
            >
              <ListAltOutlinedIcon />
            </TransparentSquareIconButton>
          ) : null}
          {role === UserRole.EMPLOYEE ? (
            <TransparentSquareIconButton
              handleClick={() => {
                navigate(NavigationPaths.SELECTION(clientId));
              }}
              tooltipConfig={{ title: DashboardNavigationTooltip.KPI }}
              isCurrentRoute={isKPIsRelatedRoute()}
            >
              <PercentOutlined />
            </TransparentSquareIconButton>
          ) : null}
          <TransparentSquareIconButton
            handleClick={() => navigate(NavigationPaths.TARGET_DEFINITION(clientId))}
            isCurrentRoute={isRouteMatched(Routes.PIPELINE)}
            tooltipConfig={{ title: DashboardNavigationTooltip.CAMPAIGN_CREATION_FUNNEL }}
          >
            <ModeEditOutlinedIcon />
          </TransparentSquareIconButton>
          <TransparentSquareIconButton
            handleClick={() => navigate(NavigationPaths.ANALYTICS_AND_REPORTING(clientId))}
            isCurrentRoute={isAnalyticsRelatedRoute()}
            tooltipConfig={{ title: DashboardNavigationTooltip.REPORTING }}
          >
            <EqualizerRoundedIcon />
          </TransparentSquareIconButton>
        </Stack>
      ) : null}
      <Logout />
    </Stack>
  );
}

export default DashboardNavigation;
