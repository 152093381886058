import React from 'react';
import { MarkAsResearchedContainer } from './save-research-changes.styles';
import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';

interface ISaveProps {
  isLoading: boolean;
  handleClick: () => void;
}
function SaveResearchChanges({ isLoading, handleClick }: ISaveProps) {
  return (
    <MarkAsResearchedContainer>
      <TransparentRectButtonWithOrangeBorder
        title={'Save Changes'}
        handleClick={handleClick}
        className={'marked-as-researched not-researched'}
        isLoading={isLoading}
      />
    </MarkAsResearchedContainer>
  );
}

export default SaveResearchChanges;
