import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { muiThemeOptions } from '../../styles/global/mui/mui-theme-options';
import { IMuiThemeDecoratorPropsTypes } from './mui-theme.types';

export function MuiThemeDecorator({ children }: IMuiThemeDecoratorPropsTypes) {
  const theme = createTheme(muiThemeOptions);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
