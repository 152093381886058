import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';

export const TestPromptContainer = styled(Stack)<StackProps>(({ theme }) => ({
  '> .marked-as-not-a-fit': {
    width: '150px',
    minWidth: '100px',
    height: '40px',

    '& .MuiCircularProgress-root': { color: theme.palette.background.orange },

    '&.not-a-fit': {
      backgroundColor: theme.palette.background.orange,
      color: theme.palette.background.black,
    },
  },

  [theme.breakpoints.up('xs')]: {
    padding: '20px 0',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
