import React, { Fragment } from 'react';

import {
  currentCompanyForResearchParamsSelector,
  companyForResearchSelector,
  companyTargetGroupThunksSelector,
} from '../../../../entities/company-target-group/store/company-target-group.selectors';
import { useAppDispatch, useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { PipelineStageName } from '../../../navigation/components/pipeline-navigation/pipeline-navigation.types';

import MarkAsNotAFit from '../../../../features/mark-as-not-a-fit/components/mark-as-not-a-fit.component';
import MarkAsResearched from '../../../../features/mark-as-researched/components/mark-as-researched.component';
import RightSectionHeader from '../../../../app/layouts/layout-components/right-section-header/right-section-header.component';
import { formCurrentTargetResearchFormState } from '../../../target-research-form/helpers/target-research-form.helpers';
import { updateCompanyForResearch } from '../../../../entities/company-target-group/store/company-target-group.slice';
import SaveResearchChanges from '../../../../features/save-research-changes/components/save-research-changes.component';
import BaseAlerts from '../../../../shared/components/alert/base-alerts/base-alerts.component';
import MarkAsPaused from '../../../../features/mark-as-paused/components/mark-as-paused.component';
import { PipelineStage } from '../../../../entities/company-target-group/domain/company-target-group.types';

const SECTION_HEADER = PipelineStageName.TARGET_RESEARCH;

function TargetResearchHeader() {
  const dispatch = useAppDispatch();
  const currentCompanyForResearch = useAppSelector(currentCompanyForResearchParamsSelector);
  const {
    isLoading: isSavingChanges,
    isSuccess,
    // isError,
  } = useAppSelector(companyTargetGroupThunksSelector).updateCompanyForResearch;
  const findEmailThunks = useAppSelector(companyTargetGroupThunksSelector).findLeadEmail;
  const isError = findEmailThunks.some((thunk) => thunk.isError);
  const error = findEmailThunks.find((thunk) => thunk.isError && thunk.error)?.error;
  const { id: currentCompanyTargetGroupId, name: companyName } = currentCompanyForResearch;
  const isCompanyForResearchChosen = Boolean(currentCompanyTargetGroupId);
  const companyForResearchState = useAppSelector(companyForResearchSelector);
  const { isResearched, isNotAFit, id: companyTargetGroupId, companyId, pipelineStage } = companyForResearchState.item;

  const currentTargetResearchFormState = formCurrentTargetResearchFormState(companyForResearchState.item);

  return (
    <RightSectionHeader
      isListEntityChosen={isCompanyForResearchChosen}
      headerName={SECTION_HEADER}
      listEntityName={companyName}
      hasControls
      controls={
        <Fragment>
          <SaveResearchChanges
            handleClick={() =>
              dispatch(
                updateCompanyForResearch({
                  companyTargetGroupId,
                  companyId,
                  leadId: 'none',
                  body: currentTargetResearchFormState,
                }),
              )
            }
            isLoading={isSavingChanges}
          />
          <MarkAsPaused
            companyTargetGroupId={currentCompanyTargetGroupId}
            isMarkedAsPaused={pipelineStage === PipelineStage.RESEARCH_PAUSED}
            onStage="research"
          />
          <MarkAsNotAFit companyTargetGroupId={currentCompanyTargetGroupId} isMarkedAsNotAFit={isNotAFit} />
          <MarkAsResearched companyTargetGroupId={currentCompanyTargetGroupId} isMarkedAsResearched={isResearched} />
          <BaseAlerts message={error} isUiVisible={isError} type="error" delay={3000} />
          <BaseAlerts message={'Changes saved'} isUiVisible={isSuccess} type="success" delay={1000} />
        </Fragment>
      }
    />
  );
}

export default TargetResearchHeader;
