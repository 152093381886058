import { styled } from '@mui/material/styles';
import Container, { ContainerProps } from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const NoRelevantCompaniesScreenContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  margin: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const NoRelevantCompaniesMessage = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: 500,
  color: theme.palette.text.orange,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
    lineHeight: '1.625rem',
  },
}));
