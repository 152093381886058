import React from 'react';

import TargetResearchLeftSection from '../../modules/target-research-left-section/components/target-research-left-section.component';
import TargetResearchRightSection from '../../modules/target-research-right-section/components/target-research-right-section.component';
import TwoSectionsScreenLayout from '../../app/layouts/two-sections-screen/two-sections-screen-layout.component';

function TargetResearch() {
  return (
    <TwoSectionsScreenLayout
      leftSectionComponent={TargetResearchLeftSection}
      rightSectionComponent={TargetResearchRightSection}
    />
  );
}

export default TargetResearch;
