export const rowPropertyNames = ['companyName', 'eventTitle', 'eventArticlePublishedAt'];

// export const columnNameToColumnHeaderCellConfig = {
//   'Company Name': {
//     isSortable: false,
//     isCurrentlySorted: false,
//     handleClick: () => {},
//   },
//   'Article Title': {
//     isSortable: false,
//     isCurrentlySorted: false,
//     handleClick: () => {},
//   },
//   Date: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
// };
