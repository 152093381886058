import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ImportCompaniesDropzoneContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  borderRadius: '16px',
  border: theme.palette.border.dropzone,
  backgroundColor: theme.palette.background.blueTranslucent,
  color: 'black',
  fontWeight: 'bold',
  fontSize: '1.4rem',
  outline: 'none',
  transition: 'border 0.24s ease-in-out',

  '& .browse-file-button': {
    height: '40px',
    minWidth: 'auto',
    width: 'auto',
    color: theme.palette.text.orange,
    textDecoration: 'underline',
    padding: 0,
    fontFamily: '"Mulish", sans-serif',
    fontSize: '0.8rem',
    fontWeight: 700,
    lineHeight: '1.5rem',

    '&:hover': {
      backgroundColor: 'none',
      color: theme.palette.text.orange,
      textDecoration: 'underline',
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const FileInput = styled('input')(() => ({}));

export const UploadIcon = styled('img')(() => ({
  width: '15%',
  height: '14%',
}));

export const UploadText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Mulish", sans-serif',
  fontSize: '0.8rem',
  fontWeight: 700,
  lineHeight: '1.5rem',
  color: theme.palette.common.white,
  marginRight: '3px',

  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem',
  },
}));

export const UploadFormats = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.mediumGrey,
  fontFamily: '"Mulish", sans-serif',
  fontSize: '0.6525rem',
  fontWeight: 400,
  lineHeight: '1.125rem',

  [theme.breakpoints.up('lg')]: {
    fontSize: '0.75rem',
  },
}));
