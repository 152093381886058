import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const AuthenticationLayoutContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  '&.authentication-layout-container': {
    padding: '25px',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const AuthenticationBackground = styled(Container)<ContainerProps>(({ theme }) => ({
  backgroundImage: 'url(/auth-background.png)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom',
  backgroundSize: 'cover',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    backgroundSize: 'contain',
  },
}));
