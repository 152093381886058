import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const FetchTargetGroupsListContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: 'calc(100% - 80px)',
  overflow: 'auto',
  paddingBottom: '10px',

  '> .select-target-group': {
    paddingTop: 0,
  },

  [theme.breakpoints.up('xs')]: {
    paddingBottom: '10px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    height: 'calc(100% - 120px)',
  },
}));
