import React from 'react';

import { IBaseButtonProps } from './base-button.types';
import { MuiButton } from './base-button.styles';

import BaseButtonLoader from '../../loader/base-button-loader/base-button-loader.component';

function BaseButton({
  title,
  handleClick,
  disabled,
  className,
  isLoading,
  isCurrentRoute,
  children,
  disableRipple,
}: IBaseButtonProps) {
  return (
    <MuiButton
      onClick={handleClick}
      disabled={disabled || isLoading || false}
      className={className}
      isCurrentRoute={isCurrentRoute}
      disableRipple={disableRipple ?? false}
    >
      {isLoading ? <BaseButtonLoader status={isLoading} className="base-button-loader" size={20} /> : title ?? children}
    </MuiButton>
  );
}

export default BaseButton;
