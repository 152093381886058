/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';

import {
  // areCompanyTargetGroupsStatusesUpdatedSelector,
  companyTargetGroupsAnalyticsSortingSelector,
  companyTargetGroupsForAnalyticsSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import {
  getCompanyTargetGroupsForAnalytics,
  resetCompanyTargetGroupForAnalyticsData,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { formColumnNameToColumnHeaderCellConfig } from '../helpers/analytics-table-sorting.helpers';
import { rowPropertyNames } from '../domain/company-target-group-analytics-table.constants';
// import { useInfiniteScroll } from '../../../shared/hooks/use-infinite-scroll.hook';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import Link from '../../../shared/components/link/link.component';
import SelectCompanyStatus from '../../../features/select-company-status/components/select-company-status.component';
import Table from '../../../shared/components/table/table.component';

export const specificTableCellFormatsConfig = {
  status: ({ id, status }: any) => <SelectCompanyStatus companyTargetGroupId={id} status={status ?? []} />,
  website: ({ id, website }: any) => <Link key={id} url={website} title={website} />,
  linkedIn: ({ id, linkedIn }: any) => <Link key={id} url={linkedIn} title={linkedIn} />,
};

function CompanyTargetGroupAnalyticsTable() {
  const { clientId } = useParams();

  const dispatch = useAppDispatch();

  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const { data: companyTargetGroupsForAnalytics } = useAppSelector(companyTargetGroupsForAnalyticsSelector);
  // const isStatusUpdated = useAppSelector(areCompanyTargetGroupsStatusesUpdatedSelector);
  const { isSorted, sortBy } = useAppSelector(companyTargetGroupsAnalyticsSortingSelector);

  const prevTargetGroupId = useRef(targetGroupId);
  const prevSortBy = useRef(sortBy);

  const columnNameToColumnHeaderCellConfig = formColumnNameToColumnHeaderCellConfig(
    isSorted,
    sortBy!,
    prevSortBy,
    dispatch,
  );

  // const { observedElementRef, parentElementRef, skip, take, setSkip } = useInfiniteScroll<
  //   HTMLDivElement,
  //   HTMLTableSectionElement
  // >([targetGroupId, sortBy]);

  useEffect(() => {
    // setSkip(-50);
    prevTargetGroupId.current = targetGroupId;
    prevSortBy.current = sortBy;
  }, [targetGroupId, sortBy]);

  useEffect(() => {
    async function getCompanies() {
      await dispatch(getCompanyTargetGroupsForAnalytics({ clientId, targetGroupId, skip: 0, take: 10000, sortBy }));
    }
    getCompanies();
  }, [clientId, targetGroupId, sortBy, dispatch]); // removed "skip" and "isStatusUpdated, prevTargetGroupId, prevSortBy => targetGroupId, sortBy

  useEffect(() => {
    dispatch(resetCompanyTargetGroupForAnalyticsData());
  }, [dispatch, targetGroupId, sortBy]);

  return (
    <Stack
      direction="column"
      width="100%"
      height="100%"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      sx={{ background: 'white', borderRadius: '10px' }}
    >
      <Table
        rowPropertyNames={rowPropertyNames}
        rows={companyTargetGroupsForAnalytics?.items ?? []}
        specificTableCellFormatsConfig={specificTableCellFormatsConfig}
        // scrollableRootRef={parentElementRef}
        // observedElementRef={observedElementRef}
        columnNameToColumnHeaderCellConfig={columnNameToColumnHeaderCellConfig}
      />
    </Stack>
  );
}

export default CompanyTargetGroupAnalyticsTable;
