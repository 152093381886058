import React from 'react';

import { NoNewsFetchedMessage, NoNewsFetchedScreenContainer } from './no-news-fetched-screen.styles';
import { INoNewsFetchedScreenProps } from './no-news-fetched-screen.types';

function NoNewsFetchedScreen({ message }: INoNewsFetchedScreenProps) {
  return (
    <NoNewsFetchedScreenContainer>
      <NoNewsFetchedMessage>{message}</NoNewsFetchedMessage>
    </NoNewsFetchedScreenContainer>
  );
}

export default NoNewsFetchedScreen;
