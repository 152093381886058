import Chip, { ChipProps } from '@mui/material/Chip';
import Container, { ContainerProps } from '@mui/material/Container';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const AcceptedFileContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  minHeight: '36px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'relative',
  backgroundColor: theme.palette.background.lightBlue,
  borderRadius: '10px',
  border: '0.5px solid #0F1B39',
  marginTop: '10px',

  [theme.breakpoints.up('xs')]: {
    padding: '10px',
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const FileName = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: '"Mulish", sans-serif',
  fontSize: '0.6525rem',
  fontWeight: 400,
  lineHeight: '1.125rem',
  marginRight: '10px',

  [theme.breakpoints.up('lg')]: {
    fontSize: '0.75rem',
  },
}));

export const FileProgress = styled(LinearProgress)<LinearProgressProps>(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: '0.5%',
  width: '99%',
  borderRadius: '10px',
  '&.MuiLinearProgress-root': { backgroundColor: 'transparent' },
  '& .MuiLinearProgress-bar': {
    borderRadius: '10px',
    backgroundColor: 'transparent',
    '&.MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.background.orange,
    },
  },
}));

export const SuccessLabel = styled(Chip, {
  shouldForwardProp: (props) => props !== 'isVisible',
})<ChipProps & { isVisible: boolean }>(({ isVisible, theme }) => ({
  backgroundColor: '#B4FFC5',
  color: 'rgba(1, 150, 24, 0.80)',
  fontFamily: '"Mulish", sans-serif',
  fontSize: '0.6525rem',
  borderRadius: '10px',
  height: '17px',
  opacity: isVisible ? 1 : 0,
  transition: 'opacity 0.3s ease-in',

  [theme.breakpoints.up('lg')]: {
    fontSize: '0.75rem',
  },
}));
