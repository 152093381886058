import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const UploadedFilesListContainer = styled(Container)<ContainerProps>(() => ({
  width: '60%',
  marginLeft: 0,
  // position: 'inherit',
  // height: 'calc(100% - 80px)',
  // overflow: 'auto',
  // paddingBottom: '10px',
}));
