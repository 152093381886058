/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { Snackbar } from '@mui/material';

import { MuiAlert } from './base-alert.styles';

function BaseAlert({
  isUiVisible,
  message,
  handleClose,
  type,
  delay,
}: {
  message: string;
  isUiVisible: boolean;
  handleClose: any;
  type?: 'error' | 'success';
  delay?: number;
}) {
  const [isAlertVisible, setIsAlertVisible] = useState<boolean | undefined>(false);

  function handleCloseClick(event: any, reason?: string) {
    if (reason === 'clickaway') return;
    setIsAlertVisible(false);
    if (handleClose && typeof handleClose === 'function') handleClose(event);
  }

  useEffect(() => {
    if (isUiVisible) setIsAlertVisible(true);
  }, [isUiVisible]);

  return (
    <Snackbar
      open={isAlertVisible}
      autoHideDuration={delay || 6000}
      onClose={handleCloseClick}
      sx={{ position: 'static' }}
    >
      <MuiAlert severity={type || 'error'} onClose={handleCloseClick}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

export default BaseAlert;
