/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';

import { statusFeedbackUiPropsWithDefaults } from './status-feedback-ui-kit.constants';
import {
  StatusFeedbackUiPropsUnion,
  FeedbackUiType,
  StatusFeedbackUiType,
  IBaseLoaderProps,
} from '../feedback-ui.types';
import { BaseLoader } from '../../loader/base-loader/base-loader.component';

export const statusFeedbackUiKit: {
  [Property in StatusFeedbackUiType]: (props: StatusFeedbackUiPropsUnion) => ReactNode;
} = {
  [FeedbackUiType.LOADER]: (props: StatusFeedbackUiPropsUnion) => {
    const propsWithDefaults = statusFeedbackUiPropsWithDefaults[FeedbackUiType.LOADER](props);
    return <BaseLoader {...(propsWithDefaults as IBaseLoaderProps)} />;
  },
  [FeedbackUiType.SKELETON]: (props: StatusFeedbackUiPropsUnion) => null,
  [FeedbackUiType.PROGRESS]: (props: StatusFeedbackUiPropsUnion) => null,
};
