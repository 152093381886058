import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export const Input = styled(TextField)<TextFieldProps>(({ theme }) => ({
  marginBottom: '22px',

  '& .MuiInputLabel-root': {
    color: theme.palette.common.white,
    fontSize: '0.8rem',

    '&.Mui-focused': {
      color: theme.palette.common.white,
    },

    '&.Mui-error': {
      color: theme.palette.common.white,
    },

    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
    },
  },

  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    backgroundColor: theme.palette.common.white,
    padding: '4px',
    color: theme.palette.text.primary,
    border: theme.palette.border.input,
    borderRadius: '10px',
    minHeight: '46px',
    gap: '4px',

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      top: 0,
    },
  },

  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0 10px',
    minWidth: '100px',

    [theme.breakpoints.down(1600)]: {
      padding: '0 10px',
    },
  },

  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.darkGrey,
    borderRadius: '10px',
    boxSizing: 'border-box',
    minWidth: '100px',
    height: '36px',
    fontSize: '0.8rem',
    fontWeight: 500,
    padding: '0 1% 0',

    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
    },
  },

  '&.Mui-error': {
    color: '#ea4c89',
  },
}));
