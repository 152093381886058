import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const SelectClientsWithDropdownContainer = styled(
  Container,
  {},
)<ContainerProps>(() => ({
  // maxWidth: '20rem',
  marginTop: '1rem',
  marginBottom: '1rem',

  '> div > .some-class': {
    maxWidth: '45rem',
    minWidth: '25rem',
    // backgroundColor: '#e2e2e2',
    // height: '10rem',

    '& .MuiButtonBase-root.MuiChip-root': {
      // fontSize: '0.73rem',
      // height: '2rem',
      margin: '0.06rem',
      // backgroundColor: 'red',
    },
  },
}));
