import { useState } from 'react';

export function usePopper() {
  const [isOpen, setIsOpen] = useState(false);

  function handleButtonClick() {
    setIsOpen((prev) => !prev);
  }

  function handleClickAway() {
    setIsOpen(false);
  }

  return { isOpen, setIsOpen, handleButtonClick, handleClickAway };
}
