/* eslint-disable react/no-array-index-key */
import React, { SyntheticEvent } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import { Input } from './input-with-multiple-chips.styles';
import { IInputWithMultipleChipsProps } from './input-with-multiple-chips.types';

import BaseChip from '../../chip/base-chip/base-chip.component';

function InputWithMultipleChips({
  id,
  className,
  label,
  name,
  handleChange,
  values,
  isClickableLink,
  disabled,
}: IInputWithMultipleChipsProps) {
  function handleChipAdd(event: SyntheticEvent<Element, Event>, newChips: readonly string[]) {
    handleChange(name, newChips as string[]);
  }

  function handleChipDelete(chipToDelete: string) {
    const chipsAfterDelete = values[name].filter((chip: string) => chip !== chipToDelete);
    handleChange(name, chipsAfterDelete);
  }

  return (
    <Autocomplete
      className={className}
      multiple
      disabled={disabled}
      freeSolo
      value={values[name] ?? []}
      onChange={handleChipAdd}
      options={[]}
      clearIcon={null}
      sx={{ width: '100%' }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <BaseChip
            isClickableLink={isClickableLink}
            label={option}
            {...getTagProps({ index })}
            onDeleteCallback={handleChipDelete}
          />
        ))
      }
      renderInput={(params) => (
        <Input {...params} variant="outlined" id={id} label={label} placeholder="Type and press enter" />
      )}
    />
  );
}

export default InputWithMultipleChips;
