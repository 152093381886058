import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';

export const NewsEventsLeftSectionContainer = styled(Stack)<StackProps>(() => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',

  '> .fetch-target-groups-list-container': {
    height: '100%',
    overflow: 'auto',
    marginBottom: '10px',
  },
}));
