import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import { IBaseIconButtonProps } from './base-icon-button.types';
import { MuiIconButton, muiTooltipArrowStyles, muiTooltipStyles } from './base-icon-button.styles';
import { TooltipPlacement } from '../../tooltip/base-tooltip.types';

function BaseIconButton({
  handleClick,
  disabled,
  className,
  isCurrentRoute,
  children,
  disableRipple,
  tooltipConfig,
}: IBaseIconButtonProps) {
  return (
    <Tooltip
      {...tooltipConfig}
      title={tooltipConfig?.title}
      placement={tooltipConfig?.placement ?? TooltipPlacement.TOP}
      arrow={tooltipConfig?.arrow ?? true}
      componentsProps={{
        tooltip: { sx: tooltipConfig?.componentsProps?.tooltip ?? muiTooltipStyles },
        arrow: { sx: tooltipConfig?.componentsProps?.arrow ?? muiTooltipArrowStyles },
      }}
    >
      <MuiIconButton
        onClick={handleClick}
        disabled={disabled || false}
        className={className}
        isCurrentRoute={isCurrentRoute}
        disableRipple={disableRipple ?? false}
      >
        {children}
      </MuiIconButton>
    </Tooltip>
  );
}

export default BaseIconButton;
