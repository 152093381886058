import React, { useEffect } from 'react';

import TargetDefinitionLeftSection from '../../modules/target-definition-left-section/components/target-definition-left-section.component';
import TwoSectionsScreenLayout from '../../app/layouts/two-sections-screen/two-sections-screen-layout.component';
import TargetDefinitionRightSection from '../../modules/target-definition-right-section/components/target-definition-right-section.component';
import { useAppDispatch, useAppSelector } from '../../app/store/utils/redux.hooks';
import { targetGroupThunksSelector } from '../../entities/target-group/store/target-group.selectors';
import { resetDeleteTargetGroupThunk } from '../../entities/target-group/store/target-group.slice';
import { FeedbackUiType } from '../../shared/components/feedback-ui/feedback-ui.types';
import FeedbackUiDecorator from '../../shared/decorators/feedback-ui/feedback-ui.decorator';

function TargetDefinition() {
  const dispatch = useAppDispatch();

  const { isError, error } = useAppSelector(targetGroupThunksSelector).deleteTargetGroup;

  useEffect(() => {
    dispatch(resetDeleteTargetGroupThunk());
  }, []);

  const errorConfig = {
    isError,
    uiType: FeedbackUiType.ALERT,
    message: (error as any).message,
    handleClose: () => {
      // dispatch(resetDeleteTargetGroupThunk());
    },
  };

  return (
    <FeedbackUiDecorator errorConfig={errorConfig} isChildrenVisible>
      <TwoSectionsScreenLayout
        leftSectionComponent={TargetDefinitionLeftSection}
        rightSectionComponent={TargetDefinitionRightSection}
      />
    </FeedbackUiDecorator>
  );
}

export default TargetDefinition;
