import React from 'react';

import {
  getTargetGroupById,
  setCurrentTargetGroupParams,
} from '../../../../entities/target-group/store/target-group.slice';
import { useAppDispatch } from '../../../../app/store/utils/redux.hooks';
import { ITargetGroupCardWithControlsProps } from './target-group-card-with-controls.types';

import EntityCardWithActions from '../../../../shared/components/card/entity-card-with-actions/entity-card-with-actions.component';
import DeleteTargetGroup from '../../../delete-target-group/components/delete-target-group.component';

function TargetGroupCardWithControls({
  isActiveEntity,
  targetGroupId,
  targetGroupName,
}: ITargetGroupCardWithControlsProps) {
  const dispatch = useAppDispatch();

  function handleTargetGroupEntityClick() {
    dispatch(setCurrentTargetGroupParams({ id: targetGroupId, name: targetGroupName }));
    dispatch(getTargetGroupById(targetGroupId));
  }

  return (
    <EntityCardWithActions
      title={targetGroupName}
      handleClick={handleTargetGroupEntityClick}
      isActiveEntity={isActiveEntity}
    >
      <DeleteTargetGroup targetGroupId={targetGroupId} />
    </EntityCardWithActions>
  );
}

export default React.memo(TargetGroupCardWithControls);
