import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { EnrichExamplesContainer } from './enrich-examples.styles';
import { enrichExampleCompanies } from '../../../entities/target-group/store/target-group.slice';
import { targetGroupThunksSelector } from '../../../entities/target-group/store/target-group.selectors';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
import BaseAlerts from '../../../shared/components/alert/base-alerts/base-alerts.component';

function EnrichExamples({ targetGroupId }: { targetGroupId: string }) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { isLoading, isError, isSuccess, error } = useAppSelector(targetGroupThunksSelector).enrichExampleCompanies;

  function handleButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsOpen(true);
  }

  async function handleAction() {
    dispatch(enrichExampleCompanies(targetGroupId));
    setIsOpen(false);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleCancel() {
    setIsOpen(false);
  }

  const enrichModalConfig = {
    isInfo: isOpen,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Are you sure to enrich? It overrides existing values in "keywords" and "industryFilters".',
  };

  return (
    <FeedbackUiDecorator infoConfig={enrichModalConfig} isChildrenVisible>
      <EnrichExamplesContainer>
        <TransparentRectButtonWithOrangeBorder
          title="Enrich examples"
          handleClick={handleButtonClick}
          className={`marked-as-not-a-fit not-marked`}
          isLoading={isLoading}
        />
      </EnrichExamplesContainer>
      <BaseAlerts message={(error as any).message} isUiVisible={isError} type="error" delay={3000} />
      <BaseAlerts
        message={`Success: "keywords" and "industryFilters" fields are overridden`}
        isUiVisible={isSuccess && !isLoading}
        type="success"
        delay={3000}
      />
    </FeedbackUiDecorator>
  );
}

export default EnrichExamples;
