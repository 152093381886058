/* eslint-disable func-names */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';

import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import { currentCompanySelector } from '../../../entities/company/store/company.selectors';
import { clientsCompaniesListByTargetGroupSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { formCompaniesEntityData } from '../helpers/company-tracking-select-section.helpers';
import {
  getClientCompaniesListByTargetGroupId,
  resetClientsCompaniesByTargetGroupData,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { resetCurrentCompany, setCurrentCompany } from '../../../entities/company/store/company.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { useInfiniteScroll } from '../../../shared/hooks/use-infinite-scroll.hook';
import { CompanyTrackingSelectContainer } from './company-tracking-select-section.styles';

import EntityCard from '../../../shared/components/card/entity-card/entity-card.component';
import SelectEntity from '../../../features/select-entity/components/select-entity.component';
import SelectTargetGroupWithDropdown from '../../../features/select-target-group-with-dropdown/components/select-target-group-with-dropdown.component';

function CompanyTrackingSelectSection() {
  const { clientId } = useParams();

  const dispatch = useAppDispatch();

  const { data: companiesData, isLoading: isCompanyListLoading } = useAppSelector(
    clientsCompaniesListByTargetGroupSelector,
  );

  const { id: currentCompanyId } = useAppSelector(currentCompanySelector);

  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);

  const { observedElementRef, parentElementRef, skip, take, setSkip } = useInfiniteScroll<
    HTMLDivElement,
    HTMLDivElement
  >();

  function handleCompanyEntityClick(companyId: string, companyName: string) {
    return function () {
      dispatch(setCurrentCompany({ id: companyId, name: companyName }));
    };
  }

  const companiesEntityData = formCompaniesEntityData(companiesData?.items, currentCompanyId, handleCompanyEntityClick);

  useEffect(() => {
    if (skip >= 0) dispatch(getClientCompaniesListByTargetGroupId({ clientId, targetGroupId, skip, take }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, targetGroupId, skip, dispatch]);

  useEffect(() => {
    dispatch(resetClientsCompaniesByTargetGroupData());
    dispatch(resetCurrentCompany());
  }, [dispatch, targetGroupId]);

  useEffect(() => {
    setSkip(-50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetGroupId]);

  return (
    <CompanyTrackingSelectContainer ref={parentElementRef}>
      <SelectTargetGroupWithDropdown className="company-tracking-target-groups-select" />
      <SelectEntity entityData={companiesEntityData ?? []} isLoading={isCompanyListLoading} entityCard={EntityCard} />
      <Stack className="observed-element" ref={observedElementRef} height="1px" />
    </CompanyTrackingSelectContainer>
  );
}

export default CompanyTrackingSelectSection;
