import { check } from '../../../shared/libs/validator/check.factory';
import { ValidatorResult } from '../../../shared/domain/types/validation.types';
import { ICustomObjectReturnType } from '../../../shared/libs/validator/validator.types';

const reservedVariables = ['firstName', 'lastName', 'companyName'];

function removeCurlyBraces(providedVariables: string[]) {
  const providedVariablesWithoutCurlyBraces = providedVariables?.map((variable) => {
    const varWithoutCurlyBraces = variable.slice(2, -2).trim();
    return varWithoutCurlyBraces;
  });
  return providedVariablesWithoutCurlyBraces;
}

function formReservedWordsErrorMessage(variablesWithCurlyBraces: string[]) {
  const providedVariablesWithoutCurlyBraces = removeCurlyBraces(variablesWithCurlyBraces);
  return `The allowed variables are ${reservedVariables
    .map((variable) => `'${variable}'`)
    .join(', ')}, but you've provided ${providedVariablesWithoutCurlyBraces
    .map((variable) => `'${variable}'`)
    .join(', ')}.`;
}

function checkReservedVariables(text: string): ICustomObjectReturnType {
  const regex = /{{(.*?)}}/g;
  const matches = text.match(regex);
  let errorMessage: string = '';
  if (matches) errorMessage = formReservedWordsErrorMessage(matches);
  if (!matches) return { booleanResult: true };
  // eslint-disable-next-line no-restricted-syntax
  for (const match of matches) {
    const variable = match.slice(2, -2).trim();
    if (!reservedVariables.includes(variable)) return { booleanResult: false, errorMessage };
  }
  return { booleanResult: true };
}

export const emailEditorValidationStrategy: { [key: string]: (param: any) => ValidatorResult } = {
  subject: (subject: string) =>
    check('subject', subject).isString().customFnWithObjectReturnType(checkReservedVariables).validate(),
  body: (body: string) =>
    check('body', body).isString().customFnWithObjectReturnType(checkReservedVariables).validate(),
};
