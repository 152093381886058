import React from 'react';
import { useParams } from 'react-router-dom';

import {
  createTargetGroup,
  getAllTargetGroupsByClient,
  setCurrentTargetGroupParams,
} from '../../../entities/target-group/store/target-group.slice';
import { defaultCreateTargetGroupBody } from '../../../entities/target-group/domain/target-group.constants';
import { targetGroupThunksSelector } from '../../../entities/target-group/store/target-group.selectors';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { CreateTargetGroupWrapper } from './create-target-group.styles';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';

const BUTTON_TITLE = 'Add a new target group';

function CreateTargetGroup() {
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const { isLoading: isTargetGroupCreating } = useAppSelector(targetGroupThunksSelector).createTargetGroup;
  const { isLoading: areTargetGroupsLoading } = useAppSelector(targetGroupThunksSelector).getAllTargetGroupsByClient;

  async function handleCreateTargetGroup() {
    await dispatch(createTargetGroup({ ...defaultCreateTargetGroupBody, clientId }))
      .unwrap()
      .then((response) => {
        dispatch(setCurrentTargetGroupParams({ id: response.id, name: response.name }));
      });
    await dispatch(getAllTargetGroupsByClient({ clientId })).unwrap();
  }

  return (
    <CreateTargetGroupWrapper>
      <TransparentRectButtonWithOrangeBorder
        className="create-target-group-button"
        title={BUTTON_TITLE}
        handleClick={handleCreateTargetGroup}
        isLoading={isTargetGroupCreating || areTargetGroupsLoading}
      />
    </CreateTargetGroupWrapper>
  );
}

export default CreateTargetGroup;
