import { check } from '../../../../shared/libs/validator/check.factory';
import { ValidatorResult } from '../../../../shared/domain/types/validation.types';
import { ValidationRules } from '../constants/validation-rules.constants';

export const authFormValidationStrategy: { [key: string]: (param: any) => ValidatorResult } = {
  email: (email: string) => check('email', email).isString().notEmpty().isEmail().validate(),
  password: (password: string) =>
    check('password', password)
      .isString()
      .notEmpty()
      .length(ValidationRules.PASSWORD_LENGTH_MIN, ValidationRules.PASSWORD_LENGTH_MAX)
      .validate(),
};
