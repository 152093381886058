import { styled } from '@mui/material/styles';

import BaseTextarea from '../../../../shared/components/textarea/base-textarea/base-textarea.component';
import BaseInput from '../../../../shared/components/input/base-input/base-input.component';

export const TargetDefinitionFormBaseInput = styled(BaseTextarea)(() => ({
  '&.target-definition-form-input': {
    '& .MuiInputBase-root': {
      padding: '10px 0',

      '& textarea': { padding: '0 14px' },
    },
  },
}));

export const TargetDefinitionInput = styled(BaseInput)(() => ({
  '&.target-definition-form-input': {
    '& .MuiInputBase-root': {
      padding: '10px 0',

      '& .MuiInputBase-input': {
        paddingLeft: '14px',
      },
    },
  },
}));
