import { styled } from '@mui/material/styles';

import BaseButton from '../../../../shared/components/button/base-button/base-button.component';

export const AuthBaseButton = styled(BaseButton)(() => ({
  '&.auth-button': {
    width: '100%',
    fontWeight: 600,

    '@media (min-width: 1536px)': {
      fontSize: '1.125rem',
    },
  },
}));
