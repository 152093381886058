import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import { downloadCompanyTargetGroupsAnalytics } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import TransparentSquareIconButton from '../../../shared/components/button/transparent-square-icon-button/transparent-square-icon-button.component';

function DownloadFile() {
  const { clientId } = useParams();

  const dispatch = useAppDispatch();

  const currentTargetGroup = useAppSelector(currentTargetGroupParamsSelector);
  const { id: targetGroupId } = currentTargetGroup;

  function downloadFileInCsvFormat() {
    dispatch(downloadCompanyTargetGroupsAnalytics({ clientId, targetGroupId }));
  }

  return (
    <Stack>
      <TransparentSquareIconButton handleClick={downloadFileInCsvFormat}>
        <FileDownloadOutlinedIcon style={{ strokeWidth: 1 }} />
      </TransparentSquareIconButton>
    </Stack>
  );
}

export default DownloadFile;
