import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { runClassification } from '../../../entities/target-group/store/target-group.slice';
import {
  targetGroupStateSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
import BaseAlerts from '../../../shared/components/alert/base-alerts/base-alerts.component';
import { RunSearchesContainer } from './run-searches.styles';

function RunClassificationForTG({
  targetGroupId,
  targetGroupName,
}: {
  targetGroupId: string;
  targetGroupName: string;
}) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { isLoading, isError, isSuccess, error } = useAppSelector(targetGroupThunksSelector).runClassification;

  const { classificationTriggeredForTGs } = useAppSelector(targetGroupStateSelector);

  const isLoadingForTG = isLoading && classificationTriggeredForTGs.has(targetGroupId);

  function handleButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsOpen(true);
  }

  async function handleAction() {
    dispatch(runClassification(targetGroupId));
    setIsOpen(false);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleCancel() {
    setIsOpen(false);
  }

  const runClassificationModalConfig = {
    isInfo: isOpen,
    handleAction,
    handleClose,
    handleCancel,
    message: `Are you sure to run classification for ${targetGroupName}?`,
  };

  return (
    <FeedbackUiDecorator infoConfig={runClassificationModalConfig} isChildrenVisible>
      <RunSearchesContainer>
        <TransparentRectButtonWithOrangeBorder
          title="Run"
          handleClick={handleButtonClick}
          className={`marked-as-not-a-fit not-marked`}
          isLoading={isLoadingForTG}
        />
      </RunSearchesContainer>
      <BaseAlerts message={(error as any).message} isUiVisible={isError} type="error" delay={3000} />
      <BaseAlerts
        message={`Success: the classification is completed!`}
        isUiVisible={isSuccess && !isLoadingForTG}
        type="success"
        delay={3000}
      />
    </FeedbackUiDecorator>
  );
}

export default RunClassificationForTG;
