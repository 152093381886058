import { FormControlProps, InputAdornmentProps, InputBaseProps, InputLabelProps, SvgIcon } from '@mui/material';

import { InputChangeEvent } from '../../../domain/types/html-elements.types';
import { TooltipPlacement, ITooltipStyleSchema } from '../../tooltip/base-tooltip.types';
import { ValidationResultsObject } from '../../../domain/types/validation.types';

export type MUIIcon = typeof SvgIcon;

export interface IBaseInputStyleSchema {
  tooltipStyleSchema?: {
    styleSchema?: ITooltipStyleSchema | undefined;
    transition?: any;
    placement?: TooltipPlacement;
  };
}

export enum InputInfoUiType {
  TOOLTIP = 'tooltip',
  FORM_HELPER = 'form-helper',
}

export interface IBaseInputProps<T> {
  id: string;
  className?: string;
  title?: string;
  label?: string;
  name: string;
  type: string;
  handleChange: (event: InputChangeEvent) => void;
  values: T;
  errors?: ValidationResultsObject;
  styleSchema?: IBaseInputStyleSchema;
  isLabelShown?: boolean;
  isPlaceholderShown?: boolean;
  infoUiType?: InputInfoUiType;
  isPasswordField?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}

export type FormControlContainerProps = FormControlProps & {};

export type MuiInputProps = InputBaseProps & {
  isPasswordField: boolean;
};

export type InputIconProps = InputAdornmentProps & {};

export type LabelProps = InputLabelProps & {};

// import { FormControlProps, InputAdornmentProps, InputBaseProps, InputLabelProps, SvgIcon } from '@mui/material';

// import { InputChangeEvent } from '../../../domain/types/html-elements.types';
// import { TooltipPlacement, ITooltipStyleSchema } from '../../tooltip/base-tooltip.types';
// import { ValidationResultsObject } from '../../../domain/types/validation.types';

// export type MUIIcon = typeof SvgIcon;

// export interface IBaseInputStyleSchema {
//   tooltipStyleSchema?: {
//     styleSchema?: ITooltipStyleSchema | undefined;
//     transition?: any;
//     placement?: TooltipPlacement;
//   };
// }

// export enum InputInfoUiType {
//   TOOLTIP = 'tooltip',
//   FORM_HELPER = 'form-helper',
// }

// export interface IBaseInputProps<T> {
//   id: string;
//   className?: string;
//   title: string;
//   label: string;
//   name: string;
//   type: string;
//   handleChange: (event: InputChangeEvent) => void;
//   values: T;
//   errors?: ValidationResultsObject;
//   styleSchema?: IBaseInputStyleSchema;
//   isLabelShown?: boolean;
//   isPlaceholderShown?: boolean;
//   infoUiType?: InputInfoUiType;
//   isPasswordField?: boolean;
//   autoFocus?: boolean;
//   disabled?: boolean;
// }

// export type FormControlContainerProps = FormControlProps & {};

// export type MuiInputProps = InputBaseProps & {
//   isPasswordField: boolean;
// };

// export type InputIconProps = InputAdornmentProps & {};

// export type LabelProps = InputLabelProps & {};
