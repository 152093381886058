import { authFormFieldsByProcesses } from '../domain/constants/auth-form-fields-by-processes.constants';
import { AuthProcess, AuthFieldsEntries } from '../domain/types/authentication.types';
import { IBaseInputProps } from '../../../shared/components/input/base-input/base-input.types';

export function formDefaultAuthFormState(process: AuthProcess) {
  const processFormFieldsObject = { ...authFormFieldsByProcesses[process] };
  const processFormFieldsEntries = Object.entries(processFormFieldsObject);
  const mapCallback = ([fieldName, fieldConfig]: AuthFieldsEntries) => [fieldName, fieldConfig.defaultState];
  const fieldsWithDefaultStateEntries = processFormFieldsEntries.map(mapCallback);
  const defaultAuthFormState = Object.fromEntries(fieldsWithDefaultStateEntries);
  return defaultAuthFormState;
}

export function formInputsConfig<T>(
  process: AuthProcess,
  values: IBaseInputProps<T>['values'],
  errors: IBaseInputProps<T>['errors'],
  handleChange: IBaseInputProps<T>['handleChange'],
) {
  const inputsConfigObject = { ...authFormFieldsByProcesses[process] };
  const inputsConfigObjectEntries = Object.entries(inputsConfigObject);
  const inputsConfigArray = inputsConfigObjectEntries.map(([, fieldConfig]) => {
    return { ...fieldConfig, handleChange, values, errors };
  });
  return inputsConfigArray;
}
