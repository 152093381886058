import Container, { ContainerProps } from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const ClientsListHeaderContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '85px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const SectionHeaderName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1.5rem',
  lineHeight: '34px',
  fontWeight: 600,
  color: theme.palette.common.white,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.75rem',
  },
}));
