import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { runSeaches } from '../../../entities/target-group/store/target-group.slice';
import { targetGroupThunksSelector } from '../../../entities/target-group/store/target-group.selectors';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
import BaseAlerts from '../../../shared/components/alert/base-alerts/base-alerts.component';
import { RunSearchesContainer } from './run-searches.styles';

function RunSearches({ targetGroupId }: { targetGroupId: string }) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { isLoading, isError, isSuccess, error } = useAppSelector(targetGroupThunksSelector).runSeaches;

  function handleButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsOpen(true);
  }

  async function handleAction() {
    dispatch(runSeaches(targetGroupId));
    setIsOpen(false);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleCancel() {
    setIsOpen(false);
  }

  const runSearchModalConfig = {
    isInfo: isOpen,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Are you sure to run searches? This action will load companies for classification.',
  };

  return (
    <FeedbackUiDecorator infoConfig={runSearchModalConfig} isChildrenVisible>
      <RunSearchesContainer>
        <TransparentRectButtonWithOrangeBorder
          title="Run searches"
          handleClick={handleButtonClick}
          className={`marked-as-not-a-fit not-marked`}
          isLoading={isLoading}
        />
      </RunSearchesContainer>
      <BaseAlerts message={(error as any).message} isUiVisible={isError} type="error" delay={3000} />
      <BaseAlerts
        message={`Success: the searches have been executed`}
        isUiVisible={isSuccess && !isLoading}
        type="success"
        delay={3000}
      />
    </FeedbackUiDecorator>
  );
}

export default RunSearches;
