import Container, { ContainerProps } from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const AnalyticsAndReportingWrapper = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 167px)',

  [theme.breakpoints.up('xs')]: {
    paddingBottom: '1.2%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const AnalyticsAndReportingContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.blueTranslucent,
  color: theme.palette.common.white,

  [theme.breakpoints.up('xs')]: {
    padding: '2% 4% 1.2%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const AnalyticsAndReportingHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
  textWrap: 'nowrap',
  marginRight: '25px',
  fontSize: '1.5rem',
  fontWeight: 600,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.75rem',
  },
}));
