/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, AsyncThunk } from '@reduxjs/toolkit';

type ThunkCase = 'pending' | 'fulfilled' | 'rejected';

export function generateBaseThunkReducers<State>(
  builder: ActionReducerMapBuilder<State>,
  thunk: AsyncThunk<any, any, any>,
  thunkState: string,
  payloadState?: string,
  excludeCases?: ThunkCase[],
) {
  const casesStrategy = new Map<ThunkCase, (state: any, action: any) => void>([
    [
      'pending',
      (state: any) => {
        state.thunks[thunkState].isLoading = true;
        state.thunks[thunkState].isSuccess = false;
        state.thunks[thunkState].error = {};
        state.thunks[thunkState].isError = false;
      },
    ],
    [
      'fulfilled',
      (state: any, action: any) => {
        if (payloadState) state[payloadState] = action.payload;
        state.thunks[thunkState].isLoading = false;
        state.thunks[thunkState].isSuccess = true;
        state.thunks[thunkState].error = {};
        state.thunks[thunkState].isError = false;
      },
    ],
    [
      'rejected',
      (state: any, action: any) => {
        state.thunks[thunkState].error = action.payload;
        state.thunks[thunkState].isError = true;
        state.thunks[thunkState].isLoading = false;
      },
    ],
  ]);

  casesStrategy.forEach((fn, caseName) => {
    const isCaseEnabled = !excludeCases || excludeCases.length === 0 || !excludeCases.includes(caseName);
    if (isCaseEnabled) builder.addCase((thunk as any)[caseName], fn);
  });
}
