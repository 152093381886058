import React from 'react';

import { ITransparentRectButtonWithOrangeBorderProps } from './transparent-rect-button-with-orange-border.types';
import { TransparentRectBaseButtonWithOrangeBorder } from './transparent-rect-button-with-orange-border.styles';

function TransparentRectButtonWithOrangeBorder({
  title,
  handleClick,
  disabled,
  className,
  isLoading,
}: ITransparentRectButtonWithOrangeBorderProps) {
  return (
    <TransparentRectBaseButtonWithOrangeBorder
      handleClick={handleClick}
      disabled={disabled}
      className={className}
      isLoading={isLoading}
      title={title}
    />
  );
}

export default TransparentRectButtonWithOrangeBorder;
