import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const SelectCompanyStatusContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',

  [theme.breakpoints.up('xs')]: {
    maxWidth: '100%',
    margin: 0,
  },
}));
