/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { Stack, Table as MuiTable } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { ITableProps } from './table.types';
import { MuiTableCell } from './table.styles';

import DataTableRow from './data-table-row/data-table-row.component';

export default function Table({
  rowPropertyNames,
  rows,
  specificTableCellFormatsConfig,
  observedElementRef,
  scrollableRootRef,
  columnNameToColumnHeaderCellConfig,
  optionalData = [],
}: ITableProps) {
  const columnNameToColumnHeaderCellEntries = Object.entries(columnNameToColumnHeaderCellConfig);

  const renderTableRows = rows.map((row, rowIndex) => {
    return (
      <DataTableRow
        row={row}
        rowKey={rowIndex}
        rowPropertyNames={rowPropertyNames}
        specificTableCellFormatsConfig={specificTableCellFormatsConfig}
        optionalData={optionalData}
      />
    );
  });

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: '10px', boxShadow: 'none', height: '100%' }}
      ref={scrollableRootRef}
    >
      <MuiTable sx={{ minWidth: '100%' }} stickyHeader>
        <TableHead>
          <TableRow>
            {columnNameToColumnHeaderCellEntries.map(
              ([columnHeaderName, columnHeaderCellConfig], columnHeaderNameIndex) => (
                <MuiTableCell align="left" key={columnHeaderNameIndex} onClick={columnHeaderCellConfig.handleClick}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span>{columnHeaderName}</span>
                    {columnHeaderCellConfig.isSortable && !columnHeaderCellConfig.isCurrentlySorted ? (
                      <ArrowDropDownIcon />
                    ) : columnHeaderCellConfig.isSortable && columnHeaderCellConfig.isCurrentlySorted ? (
                      <ArrowDropUpIcon />
                    ) : null}
                  </div>
                </MuiTableCell>
              ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>{renderTableRows}</TableBody>
      </MuiTable>
      <Stack className="observed-element" ref={observedElementRef} height="1px" />
    </TableContainer>
  );
}
