/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment, ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { isAuthedSelector } from '../../features/authentication/store/authentication.selectors';
import { useAppSelector } from '../store/utils/redux.hooks';
import { userSelector } from '../../entities/user/store/user.selectors';
import { UserRole } from '../../entities/user/domain/user.types';
import SingleSectionScreenLayout from '../layouts/single-sections-screen-with-message/single-sections-screen-with-message.component';

export enum RejectionFeedbackType {
  REDIRECT = 'redirect',
  REJECTION_SCREEN = 'rejectionScreen',
}

export interface IRequireRoleRoute {
  allowedRoles?: UserRole[];
  children: ReactNode;
  rejectionFeedbackType: RejectionFeedbackType;
  redirectTo?: string;
  rejectionScreenMessage?: string;
}

function RedirectOnRejection(location: any, redirectTo?: string) {
  return <Navigate to={redirectTo ?? '/authentication/login'} state={{ from: location }} replace />;
}

function ShowRejectionScreen(rejectionScreenMessage?: string) {
  return <SingleSectionScreenLayout message={rejectionScreenMessage ?? 'You are not allowed to access this screen'} />;
}

function RequireRoleRoute({
  allowedRoles,
  children,
  rejectionFeedbackType,
  redirectTo,
  rejectionScreenMessage,
}: IRequireRoleRoute) {
  const { role } = useAppSelector(userSelector);
  const isAuthenticated = useAppSelector(isAuthedSelector);

  const location = useLocation();

  return Boolean((allowedRoles || Object.values(UserRole)).find((allowedRole) => allowedRole === role)) &&
    isAuthenticated ? (
    <Fragment>{children}</Fragment>
  ) : rejectionFeedbackType === RejectionFeedbackType.REDIRECT ? (
    RedirectOnRejection(location, redirectTo)
  ) : (
    ShowRejectionScreen(rejectionScreenMessage)
  );
}

export default RequireRoleRoute;
