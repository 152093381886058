import React from 'react';
import { useNavigate } from 'react-router-dom';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';

import { logout } from '../../store/authentication.slice';
import { resetUser } from '../../../../entities/user/store/user.slice';
import { resetCompanyTargetGroupState } from '../../../../entities/company-target-group/store/company-target-group.slice';
import { resetCompanyState } from '../../../../entities/company/store/company.slice';
import { resetTargetGroupState } from '../../../../entities/target-group/store/target-group.slice';
import { useAppDispatch } from '../../../../app/store/utils/redux.hooks';
import { ButtonClickEvent } from '../../../../shared/domain/types/html-elements.types';
import { DashboardNavigationTooltip } from '../../../../modules/navigation/components/dashboard-navigation/dashboard-navigation.constants';
import { NavigationPaths } from '../../../../shared/domain/constants/routing.constants';

import TransparentSquareIconButton from '../../../../shared/components/button/transparent-square-icon-button/transparent-square-icon-button.component';

function Logout() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  //   function handleLogoutError(error: BaseRtkErrorResponse | any) {
  //     handleError({
  //       uiType: FeedbackUiType.ALERT,
  //       message: error?.data?.message,
  //     });
  //   }

  async function submitLogout(event: ButtonClickEvent) {
    event.preventDefault();
    try {
      await dispatch(logout())
        .unwrap()
        .then(() => {
          dispatch(resetUser());
          dispatch(resetTargetGroupState());
          dispatch(resetCompanyState());
          dispatch(resetCompanyTargetGroupState());
          navigate(NavigationPaths.LOGIN);
        });
    } catch (error: any) {
      //   handleLogoutError(error);
    }
  }
  return (
    <TransparentSquareIconButton
      handleClick={submitLogout}
      tooltipConfig={{ title: DashboardNavigationTooltip.LOGOUT }}
    >
      <ExitToAppOutlinedIcon />
    </TransparentSquareIconButton>
  );
}

export default Logout;
