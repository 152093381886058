import React from 'react';

import { RejectedFileContainer, FileName, RejectionLabel } from './rejected-file.styles';
import { FileRejectionSource, IRejectedFileProps } from './rejected-file.types';
import { FileRejectionStrategy } from './rejection-reason.constants';

function RejectedFile({ fileName, error, errorSource }: IRejectedFileProps) {
  const key = errorSource === FileRejectionSource.REACT_DROPZONE ? error.code : error;
  const fileRejectionLabel = FileRejectionStrategy[errorSource](key);

  return (
    <RejectedFileContainer>
      <FileName>{fileName}</FileName>
      <RejectionLabel label={fileRejectionLabel} />
    </RejectedFileContainer>
  );
}

export default RejectedFile;
