import Select, { SelectProps } from '@mui/material/Select';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

export const menuStyles = {
  sx: {
    '& .MuiPaper-root': {
      backgroundColor: '#152040',
      borderRadius: '10px',
      width: '186px',
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      paddingTop: 0,

      '> ul': {
        '> div': {
          '&.dropdown-items-container': {
            position: 'relative',
            width: '100%',
            // '> li': {
            '> .dropdown-items-counter': {
              position: 'absolute',
              // color: 'white',
              right: '10px',
              top: '10px',
              zIndex: 1000,
              // padding: 0,
              // margin: '10px',
            },
          },
          // },
        },
      },
    },
  },
};

export const MuiSelect = styled(Select)<SelectProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  color: theme.palette.background.orange,
  fontSize: '0.8rem',
  fontWeight: 600,
  textAlign: 'center',

  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },

  '& .MuiSelect-icon': {
    fontSize: '1.1rem',
    fontWeight: 500,
    color: theme.palette.background.orange,
    right: '15px',
  },

  [theme.breakpoints.up('xl')]: {
    fontSize: '0.875rem',
  },
}));

export const MuiSelectItem = styled(MenuItem, {
  shouldForwardProp: (props) => props !== 'isCurrentlySelectedItem',
})<MenuItemProps & { isCurrentlySelectedItem: boolean }>(({ isCurrentlySelectedItem, theme }) => ({
  height: '35px',
  fontSize: '0.8rem',
  fontWeight: 600,
  textAlign: 'center',
  backgroundColor: isCurrentlySelectedItem ? theme.palette.background.orange : '#152040',
  color: isCurrentlySelectedItem ? theme.palette.text.primary : '#ffffff',
  justifyContent: 'center',
  transition: theme.transitions.create(['color', 'background-color']),

  '&:focus': {
    backgroundColor: isCurrentlySelectedItem ? theme.palette.background.orange : '#152040',
  },

  '&:hover': {
    backgroundColor: theme.palette.action.hoverSelectItem,
    color: theme.palette.background.orange,
    transition: theme.transitions.create(['color', 'background-color']),
  },

  '> div': {
    '&.dropdown-items-container': {
      position: 'relative',
      width: '100%',
      height: '100%',
      // '> li': {
      '> .dropdown-items-counter': {
        position: 'absolute',
        // color: 'white',
        right: '-10px',
        top: '2px',
        zIndex: 1000,
        // padding: 0,
        // margin: '10px',
      },
    },
    // },
  },

  [theme.breakpoints.up('xl')]: {
    fontSize: '0.875rem',
  },
}));
