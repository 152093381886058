export const rowPropertyNames = () => [
  'clientCompanyName',
  'name',
  'selectionJobToggle',
  'weeklySelectionRate',
  'runSelection',
];

export const columnNameToColumnHeaderCellConfig = () => ({
  Client: {
    isSortable: false,
    isCurrentlySorted: false,
    handleClick: () => {},
  },
  'Target Group': {
    isSortable: false,
    isCurrentlySorted: false,
    handleClick: () => {},
  },
  'Selection Toggle': {
    isSortable: false,
    isCurrentlySorted: false,
    handleClick: () => {},
  },
  'Weekly Rate': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  'Run Selection': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
});
