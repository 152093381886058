import { CircularProgress, Stack } from '@mui/material';

import { muiThemeOptions } from '../../../../app/styles/global/mui/mui-theme-options';
import { IBaseContainerLoaderProps } from '../../feedback-ui/feedback-ui.types';

function BaseContainerLoader({ status: isLoading, title, className, size }: IBaseContainerLoaderProps) {
  return isLoading ? (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
      padding="15px"
      className={className}
    >
      <CircularProgress size={size || 50} sx={{ color: muiThemeOptions.palette.background.orange }} />
      <span style={{ color: 'black', fontSize: 18 }}>{title || ''}</span>
    </Stack>
  ) : null;
}

export default BaseContainerLoader;
