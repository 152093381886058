import {
  CompaniesForReviewFilterCriterion,
  CompaniesForReviewFilterCriterionName,
} from '../../../entities/company-target-group/domain/company-target-group.types';

export const dropdownOptions = [
  {
    id: CompaniesForReviewFilterCriterion.TO_VALIDATE,
    name: CompaniesForReviewFilterCriterionName.TO_VALIDATE,
    values: {
      id: CompaniesForReviewFilterCriterion.TO_VALIDATE,
      name: CompaniesForReviewFilterCriterionName.TO_VALIDATE,
      value: CompaniesForReviewFilterCriterion.TO_VALIDATE,
    },
  },
  {
    id: CompaniesForReviewFilterCriterion.VALIDATED,
    name: CompaniesForReviewFilterCriterionName.VALIDATED,
    values: {
      id: CompaniesForReviewFilterCriterion.VALIDATED,
      name: CompaniesForReviewFilterCriterionName.VALIDATED,
      value: CompaniesForReviewFilterCriterion.VALIDATED,
    },
  },
  {
    id: CompaniesForReviewFilterCriterion.REJECTED,
    name: CompaniesForReviewFilterCriterionName.REJECTED,
    values: {
      id: CompaniesForReviewFilterCriterion.REJECTED,
      name: CompaniesForReviewFilterCriterionName.REJECTED,
      value: CompaniesForReviewFilterCriterion.REJECTED,
    },
  },
  {
    id: CompaniesForReviewFilterCriterion.REVIEW_PAUSED,
    name: CompaniesForReviewFilterCriterionName.REVIEW_PAUSED,
    values: {
      id: CompaniesForReviewFilterCriterion.REVIEW_PAUSED,
      name: CompaniesForReviewFilterCriterionName.REVIEW_PAUSED,
      value: CompaniesForReviewFilterCriterion.REVIEW_PAUSED,
    },
  },
];
