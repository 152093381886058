/* eslint-disable no-unneeded-ternary */
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';

import { DashboardLayoutBar, DashboardLayoutContainer, LogoImage } from './dashboard-layout.styles';
import { IDashboardLayoutProps } from './dashboard-layout.types';

import DashboardNavigation from '../../../modules/navigation/components/dashboard-navigation/dashboard-navigation.component';

function DashboardLayout({ navigationSection, children }: IDashboardLayoutProps) {
  return (
    <DashboardLayoutContainer className="dashboard-layout-container">
      <DashboardLayoutBar>
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom="25px">
          <LogoImage src="/standing-logo.svg" />
          <DashboardNavigation />
        </Stack>
        {navigationSection}
      </DashboardLayoutBar>
      {children ? children : <Outlet />}
    </DashboardLayoutContainer>
  );
}

export default DashboardLayout;
