export interface ISearchCompanyParams {
  organization_num_employees_ranges?: string[];
  organization_locations?: string[];
  q_organization_keyword_tags?: string[];
}

const URL_PARAMS_MAP = new Map<string, keyof ISearchCompanyParams>([
  ['organizationLocations[]', 'organization_locations'],
  ['organizationNumEmployeesRanges[]', 'organization_num_employees_ranges'],
  ['qOrganizationKeywordTags[]', 'q_organization_keyword_tags'],
]);

export function parseApolloSearchParams(search: string): ISearchCompanyParams {
  const queryPart = search.split('?')[1];
  const searchParams = new URLSearchParams(queryPart);
  const parsedParams: ISearchCompanyParams = {};

  searchParams.forEach((value, key) => {
    const apiParamName = URL_PARAMS_MAP.get(key);
    if (apiParamName) {
      if (!parsedParams[apiParamName]) {
        parsedParams[apiParamName] = [];
      }
      parsedParams[apiParamName]?.push(value);
    }
  });

  return parsedParams;
}
