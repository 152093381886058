import SelectEntity from '../../../select-entity/components/select-entity.component';
import UploadedFileCard from '../uploaded-file-card/uploaded-file-card.component';
import { UploadedFilesListContainer } from './uploaded-files-list.styles';
import { IUploadedFilesListProps } from './uploaded-files-list.types';

function UploadedFilesList({ targetGroupId, fileNames }: IUploadedFilesListProps) {
  const itemsToDisplay = fileNames.map((fileName) => ({ targetGroupId, fileName }));
  const hasNoItems = fileNames.length === 0;

  if (hasNoItems) return;

  return (
    <UploadedFilesListContainer>
      <h4>Uploaded files:</h4>
      <SelectEntity entityData={itemsToDisplay} entityCard={UploadedFileCard} />
    </UploadedFilesListContainer>
  );
}

export default UploadedFilesList;
