import React, { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { IPasswordVisibilitySwitcherProps } from './password-visibility-switcher.types';

function PasswordVisibilitySwitcher({ setPasswordInputType }: IPasswordVisibilitySwitcherProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  function turnPasswordVisibilityOn() {
    setPasswordInputType('text');
    setIsPasswordVisible((visibility) => !visibility);
  }

  function turnPasswordVisibilityOff() {
    setPasswordInputType('password');
    setIsPasswordVisible((visibility) => !visibility);
  }

  return isPasswordVisible ? (
    <VisibilityOutlinedIcon onClick={turnPasswordVisibilityOff} />
  ) : (
    <VisibilityOffOutlinedIcon onClick={turnPasswordVisibilityOn} />
  );
}

export default PasswordVisibilitySwitcher;
