/* eslint-disable react-hooks/rules-of-hooks, no-restricted-syntax */
import React from 'react';
import { useParams } from 'react-router-dom';

import { companyTargetGroupThunksSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { IMarkAsPausedProps } from './mark-as-paused.types';
import {
  getCompanyForReview,
  markCompanyAsPaused,
  getCompanyForResearch,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { MarkAsPausedContainer } from './mark-as-paused.styles';

function MarkAsPaused({ companyTargetGroupId, isMarkedAsPaused, onStage }: IMarkAsPausedProps) {
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const { isLoading } = useAppSelector(companyTargetGroupThunksSelector).markCompanyAsPaused;

  const resetPausedCompanyAction = new Map<IMarkAsPausedProps['onStage'], any>([
    ['review', () => getCompanyForReview({ clientId, companyTargetGroupId })],
    ['research', () => getCompanyForResearch({ clientId, companyTargetGroupId })],
  ]);

  async function handleButtonClick() {
    const resetAction = resetPausedCompanyAction.get(onStage);
    if (!resetAction) return;

    await dispatch(markCompanyAsPaused({ companyTargetGroupId, isPaused: !isMarkedAsPaused, onStage }));
    await dispatch(resetAction());
  }

  return (
    <MarkAsPausedContainer>
      <TransparentRectButtonWithOrangeBorder
        title={isMarkedAsPaused ? 'Unpause' : 'Pause'}
        handleClick={handleButtonClick}
        className={`marked-as-paused ${isMarkedAsPaused ? 'paused' : 'not-marked'}`}
        isLoading={isLoading}
      />
    </MarkAsPausedContainer>
  );
}

export default MarkAsPaused;
