import React from 'react';
import { Stack } from '@mui/material';

import { IndicatorTitle, IndicatorValue } from './analytics-indicatior.styles';
import { IAnalyticsIndicator } from './analytics-indicator.types';

function AnalyticsIndicator({ title, value }: IAnalyticsIndicator) {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <IndicatorTitle>{title}</IndicatorTitle>
      <IndicatorValue>{value}</IndicatorValue>
    </Stack>
  );
}

export default AnalyticsIndicator;
