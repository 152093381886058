import { styled } from '@mui/material/styles';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';

export const NewsListItem = styled(ListItem)<ListItemProps>(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  paddingTop: 0,
  paddingLeft: '8px',
  paddingBottom: 0,
}));

export const NewsListItemIcon = styled(ListItemIcon)<ListItemIconProps>(({ theme }) => ({
  minWidth: 0,
  color: theme.palette.common.white,
  marginRight: '10px',
  marginTop: '0.6rem',
  '> svg': { fontSize: '0.5rem' },
}));

export const NewsListItemText = styled(ListItemText)<ListItemTextProps>(({ theme }) => ({
  display: 'inline-block',
  marginTop: 0,
  marginBottom: '8px',

  '& .article-name': {
    color: theme.palette.text.orange,
    textDecoration: 'underline',
    fontSize: '0.8rem',
    lineHeight: '0.8',

    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
    },
  },
  '& .article-date': {
    color: theme.palette.common.white,
    textDecoration: 'none',
    fontSize: '0.8rem',

    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
    },
  },
}));
