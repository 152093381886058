/* eslint-disable react-hooks/rules-of-hooks, no-restricted-syntax */
import React, { useEffect, useRef } from 'react';

import {
  companyForResearchSelector,
  companyTargetGroupThunksSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import { formCurrentTargetResearchFormState, formInputsConfig } from '../helpers/target-research-form.helpers';
import { useAppSelector, useAppDispatch } from '../../../app/store/utils/redux.hooks';
import {
  findLeadEmail,
  setResearchFormValidationErrors,
  updateCompanyForResearch,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { useDebounce } from '../../../shared/hooks/use-debounce.hook';
import { useForm } from '../../../shared/hooks/use-form.hook';

import BaseFormWrapper from '../../../shared/components/form/base-form-wrapper/base-form-wrapper.component';
import MapperWithVariableComponents from '../../../shared/components/mapper/mapper-with-variable-components/mapper-with-variable-components.component';
import CreateLead from '../../../features/create-lead/components/create-lead.component';
import {
  targetResearchFormStrategy,
  leadFormStrategy,
  generatePlaceholdersFormStrategy,
} from '../domain/target-research-form.strategy';
import DeleteLead from '../../../features/delete-lead/components/delete-lead.component';
import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { LeadActionsContainer } from './target-research-form.styles';
import { ILead } from '../../../entities/company-target-group/domain/company-target-group.types';

const DEBOUNCE_DELAY = 500;

function LeadForm({ i, lead }: { i: number; lead: ILead }) {
  const dispatch = useAppDispatch();
  const isFirstRender = useRef(true);
  const companyForResearch = useAppSelector(companyForResearchSelector);
  const findEmailThunk = useAppSelector(companyTargetGroupThunksSelector).findLeadEmail[i];

  const researchFormValidationErrors = companyForResearch.formValidationErrors as any;
  const { id: companyTargetGroupId, companyId } = companyForResearch.item;
  const currentTargetResearchFormState = formCurrentTargetResearchFormState(companyForResearch.item);
  const areInputsDisabled =
    Boolean(companyForResearch.item.isResearched) || Boolean(companyForResearch.item.isWaitingForEmailValidation);
  const { resetFormState: resetLeadForm, ...leadForm } = useForm(lead);
  const leadsInputsConfigs = formInputsConfig(
    leadFormStrategy as any,
    leadForm.formValues,
    researchFormValidationErrors?.leads?.[lead?.id],
    areInputsDisabled,
    leadForm.handleInputChange,
    leadForm.handleCustomInputChange,
  );
  const debouncedLeadValues = useDebounce(leadForm.formValues, DEBOUNCE_DELAY);
  useEffect(() => {
    isFirstRender.current = true;
    resetLeadForm();
  }, [companyTargetGroupId]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const body = JSON.parse(JSON.stringify(currentTargetResearchFormState));
    const index = body.leads.findIndex((e: any) => debouncedLeadValues.id === e.id);
    body.leads[index] = debouncedLeadValues;
    dispatch(
      updateCompanyForResearch({
        companyTargetGroupId,
        companyId,
        leadId: debouncedLeadValues?.id,
        body,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLeadValues, dispatch]);

  const handleFindEmailClick = async () => {
    dispatch(
      findLeadEmail({
        companyId,
        leadId: lead.id,
      }),
    )
      .unwrap()
      .then((leads) => resetLeadForm(leads[i]))
      .catch(console.error);
  };

  const isFindEmailDisabled = !leadForm.formValues.firstName || !leadForm.formValues.lastName;

  return (
    <>
      <div style={{ display: 'flex', width: '100%' }}>
        <h3>
          {`Person ${i + 1}`}
          <LeadActionsContainer>
            <TransparentRectButtonWithOrangeBorder
              title="Find email"
              handleClick={handleFindEmailClick}
              disabled={isFindEmailDisabled}
              className={`form-button`}
              isLoading={findEmailThunk?.isLoading}
            />
            {!areInputsDisabled ? <DeleteLead leadId={lead?.id} /> : null}
          </LeadActionsContainer>
        </h3>
      </div>
      <MapperWithVariableComponents config={leadsInputsConfigs} componentsPerRow={2} />
    </>
  );
}

function LeadsForm({ leads }: { leads: any[] }) {
  const result = [];
  for (let i = 0; i < leads.length; i += 1) {
    result.push(<LeadForm lead={leads[i]} i={i} key={leads[i].id} />);
  }

  return result;
}

function TargetResearchForm() {
  const isFirstRender = useRef(true);

  const dispatch = useAppDispatch();

  const companyForResearch = useAppSelector(companyForResearchSelector);
  const currentTargetResearchFormState = formCurrentTargetResearchFormState(companyForResearch.item);

  const researchFormValidationErrors = companyForResearch.formValidationErrors;
  const { id: companyTargetGroupId, companyId } = companyForResearch.item;
  const areInputsDisabled =
    Boolean(companyForResearch.item.isResearched) || Boolean(companyForResearch.item.isWaitingForEmailValidation);

  const useFormResult = useForm(currentTargetResearchFormState);
  const { formValues, handleInputChange, handleCustomInputChange, resetFormState } = useFormResult;

  const inputsSetConfig = formInputsConfig<typeof formValues>(
    { ...targetResearchFormStrategy, ...generatePlaceholdersFormStrategy(companyForResearch.item.placeholders) },
    formValues,
    researchFormValidationErrors,
    areInputsDisabled,
    handleInputChange,
    handleCustomInputChange,
  );

  const debouncedValues = useDebounce(formValues, DEBOUNCE_DELAY);

  useEffect(() => {
    isFirstRender.current = true;
    resetFormState();
    dispatch(setResearchFormValidationErrors(researchFormValidationErrors));
  }, [companyTargetGroupId]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    dispatch(
      updateCompanyForResearch({
        companyTargetGroupId,
        companyId,
        leadId: 'none', // ignore leads
        body: debouncedValues,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues, dispatch]);
  return (
    <BaseFormWrapper isHeaderShown={false} className="target-research-form-wrapper">
      <div style={{ display: 'flex', width: '100%' }}>
        <h3>Company</h3>
      </div>
      <MapperWithVariableComponents config={inputsSetConfig} componentsPerRow={2} />
      <LeadsForm leads={companyForResearch.item?.leads ?? []} />
      <div style={{ display: 'flex', width: '100%', paddingTop: '20px' }}>
        {!areInputsDisabled ? <CreateLead companyId={companyId} /> : null}
      </div>
    </BaseFormWrapper>
  );
}

export default TargetResearchForm;
