import { defaultCampaignOwner, defaultEmail } from '../store/target-group.slice';

export const defaultCreateTargetGroupBody = {
  name: 'New target group',
  clientId: '',
  description: '',
  emailSequences: [defaultEmail, defaultEmail],
  headcount: '',
  revenue: '',
  regions: '',
  keywords: [],
  exampleCompanies: '',
  relevantPastDeals: '',
  relevantAcquirers: '',
  otherRelevantInformation: '',
  campaignOwner: defaultCampaignOwner,
};
