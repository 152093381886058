import React from 'react';
import { useParams } from 'react-router-dom';

import {
  getCompanyForReview,
  setCurrentCompanyForReviewParams,
  setIsFirstCompanyForReviewLoad,
} from '../../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch } from '../../../../app/store/utils/redux.hooks';
import { ICompanyForReviewCardProps } from './company-for-review-card.types';

import EntityCard from '../../../../shared/components/card/entity-card/entity-card.component';

function CompanyForReviewCard({ isActiveEntity, companyTargetGroupId, companyName }: ICompanyForReviewCardProps) {
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  async function handleCompanyForReviewEntityClick() {
    dispatch(setCurrentCompanyForReviewParams({ id: companyTargetGroupId, name: companyName }));
    dispatch(setIsFirstCompanyForReviewLoad(true));
    await dispatch(getCompanyForReview({ clientId, companyTargetGroupId }))
      .unwrap()
      .finally(() => dispatch(setIsFirstCompanyForReviewLoad(false)));
  }

  return (
    <EntityCard title={companyName} handleClick={handleCompanyForReviewEntityClick} isActiveEntity={isActiveEntity} />
  );
}

export default React.memo(CompanyForReviewCard);
