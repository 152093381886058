import React from 'react';
import { useParams } from 'react-router-dom';

import {
  getCompanyForResearch,
  setCurrentCompanyForResearchParams,
  setIsFirstCompanyLoad,
} from '../../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch } from '../../../../app/store/utils/redux.hooks';
import { ICompanyForResearchCardProps } from './company-for-research-card.types';

import EntityCard from '../../../../shared/components/card/entity-card/entity-card.component';

function CompanyForResearchCard({ isActiveEntity, companyTargetGroupId, companyName }: ICompanyForResearchCardProps) {
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  async function handleCompanyForResearchEntityClick() {
    dispatch(setCurrentCompanyForResearchParams({ id: companyTargetGroupId, name: companyName }));
    dispatch(setIsFirstCompanyLoad(true));
    await dispatch(getCompanyForResearch({ clientId, companyTargetGroupId }))
      .unwrap()
      .finally(() => dispatch(setIsFirstCompanyLoad(false)));
  }

  return (
    <EntityCard title={companyName} handleClick={handleCompanyForResearchEntityClick} isActiveEntity={isActiveEntity} />
  );
}

export default React.memo(CompanyForResearchCard);
