import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IClientCardProps } from './client-card.types';
import { NavigationPaths } from '../../../../shared/domain/constants/routing.constants';

import EntityCard from '../../../../shared/components/card/entity-card/entity-card.component';
import DownloadClientData from '../../../download-client-data/components/download-file.component';

function ClientCard({ clientId, clientName }: IClientCardProps) {
  const navigate = useNavigate();

  async function handleClientEntityClick() {
    navigate(NavigationPaths.TARGET_DEFINITION(clientId));
  }

  return (
    <EntityCard
      title={clientName}
      handleClick={handleClientEntityClick}
      className="client-card"
      buttonComponent={<DownloadClientData clientId={clientId} />}
    />
  );
}

export default React.memo(ClientCard);
