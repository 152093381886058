import React from 'react';

import TargetUploadLeftSection from '../../modules/target-upload-left-section/components/target-upload-left-section.component';
import TargetUploadRightSection from '../../modules/target-upload-right-section/components/target-upload-right-section.component';
import TwoSectionsScreenLayout from '../../app/layouts/two-sections-screen/two-sections-screen-layout.component';

function TargetUpload() {
  return (
    <TwoSectionsScreenLayout
      leftSectionComponent={TargetUploadLeftSection}
      rightSectionComponent={TargetUploadRightSection}
    />
  );
}

export default TargetUpload;
