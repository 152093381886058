import { ICompaniesForReviewListResponse } from '../../../entities/company-target-group/domain/company-target-group.types';
import { ICompanyForReviewCardProps } from '../components/company-for-review-card/company-for-review-card.types';

export function formCompaniesForReviewEntityData(
  companiesForReviewFromServer: ICompaniesForReviewListResponse['items'],
  currentCompanyId: string,
): (ICompanyForReviewCardProps & { key: string })[] {
  return companiesForReviewFromServer?.map(({ id: companyTargetGroupId, companyName }) => {
    return {
      isActiveEntity: currentCompanyId === companyTargetGroupId,
      companyTargetGroupId,
      companyName,
      key: `${companyTargetGroupId}_${companyName}`,
    };
  });
}
