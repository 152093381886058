/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import { MuiChip } from './status-chip.styles';
import { IStatusChipProps } from './status-chip.types';

function StatusChip({ label, onDeleteCallback, className }: IStatusChipProps) {
  function handleDelete() {
    onDeleteCallback && onDeleteCallback(label);
  }

  return (
    <MuiChip
      label={label}
      onDelete={handleDelete}
      deleteIcon={<ClearIcon />}
      onMouseDown={(e: any) => {
        e.stopPropagation();
      }}
      className={className}
      currentLabel={label}
    />
  );
}

export default StatusChip;
