import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';

import { InputInfoHelperProps } from './validation-errors-helper.types';

export const InputInfoHelper = styled(FormHelperText)<InputInfoHelperProps>(({ theme }) => ({
  margin: '0',

  '&.Mui-error': {
    color: theme.palette.text.error,
  },
}));
