import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

import { SolidOrangeRectTabBaseButtonProps } from './solid-orange-rect-tab-button.types';

import BaseButton from '../base-button/base-button.component';

export const SolidOrangeRectTabBaseButton = styled(BaseButton, {
  shouldForwardProp: (props) => props !== 'isCurrentRoute',
})<SolidOrangeRectTabBaseButtonProps>(({ isCurrentRoute, theme }) => ({
  transition: theme.transitions.create('background-color'),
  minWidth: '180px',
  height: '65px',
  padding: '30px 14px',
  border: 'none',
  fontWeight: 600,
  backgroundColor: isCurrentRoute ? theme.palette.background.orange : theme.palette.background.orangeDim,
  color: isCurrentRoute ? theme.palette.text.primary : theme.palette.text.primaryDim,

  '&:hover': {
    transition: theme.transitions.create('background-color'),
  },

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.1875rem',
    minWidth: '200px',
    height: '70px',
  },
}));

export const UpperLine = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: '#252525',
  fontSize: '0.55rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  marginRight: '8px',
  '&:hover': {},

  [theme.breakpoints.up('xl')]: {
    fontSize: '0.625rem',
  },
}));

export const BottomLine = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: '#01071A',
  fontSize: '1rem',
  fontWeight: 600,
  '&:hover': {},

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.1875rem',
  },
}));
