import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const InputsContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
