import Chip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { muiThemeOptions } from '../../../../app/styles/global/mui/mui-theme-options';
import { CompanyTargetGroupStatus } from '../../../../entities/company-target-group/domain/company-target-group.types';

const MuiChipStylesConfig: { [key in string]: { backgroundColor: string; color: string } } = {
  [CompanyTargetGroupStatus.ACTIVE]: {
    backgroundColor: muiThemeOptions.palette.background.chip.active,
    color: muiThemeOptions.palette.text.chip.active,
  },
  [CompanyTargetGroupStatus.KEEP_IN_TOUCH]: {
    backgroundColor: muiThemeOptions.palette.background.chip.keepInTouch,
    color: muiThemeOptions.palette.text.chip.keepInTouch,
  },
  [CompanyTargetGroupStatus.MET_WITH]: {
    backgroundColor: muiThemeOptions.palette.background.chip.metWith,
    color: muiThemeOptions.palette.text.chip.metWith,
  },
  [CompanyTargetGroupStatus.POSITIVE_RESPONSE]: {
    backgroundColor: muiThemeOptions.palette.background.chip.positiveResponse,
    color: muiThemeOptions.palette.text.chip.positiveResponse,
  },
  [CompanyTargetGroupStatus.NEGATIVE_RESPONSE]: {
    backgroundColor: muiThemeOptions.palette.background.chip.negativeResponse,
    color: muiThemeOptions.palette.text.chip.negativeResponse,
  },
};

export const MuiChip = styled(Chip, { shouldForwardProp: (props) => props !== 'currentLabel' })<
  ChipProps & { currentLabel: string }
>(({ currentLabel }) => ({
  backgroundColor: MuiChipStylesConfig[currentLabel].backgroundColor,
  color: MuiChipStylesConfig[currentLabel].color,
  height: '22px',
  fontSize: '0.78rem',
  fontWeight: 500,

  '& .MuiChip-deleteIcon': {
    fontSize: '0.8rem',
    color: MuiChipStylesConfig[currentLabel].color,
  },
}));