import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const CompanyTrackingWrapper = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100vh',
  overflow: 'hidden',

  [theme.breakpoints.up('xs')]: {
    paddingBottom: '1.2%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
