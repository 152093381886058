import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';

import {
  currentTargetGroupParamsSelector,
  getCompanyCountFilter,
  targetGroupsListSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import {
  getAllTargetGroupsByClient,
  setCurrentTargetGroupParams,
} from '../../../entities/target-group/store/target-group.slice';
import { resetCompanyTargetGroupAnalyticsSorting } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { ISelectTargetGroupWithDropdownProps } from './select-target-group-with-dropdown.types';
import { SelectTargetGroupWithDropdownContainer } from './select-target-group-with-dropdown.styles';

import BaseDropdown from '../../../shared/components/dropdown/base-dropdown/base-dropdown.component';

function SelectTargetGroupWithDropdown({ className, showCounts, isVetting }: ISelectTargetGroupWithDropdownProps) {
  const defaultTargetGroupName = 'All target groups';
  const defaultTargetGroupId = '';

  const dispatch = useAppDispatch();

  const { name: currentTargetGroupName } = useAppSelector(currentTargetGroupParamsSelector);

  const targetGroupsList = useAppSelector(targetGroupsListSelector);

  const dropdownOptions = (targetGroupsList?.items || []).map((targetGroup) => {
    return {
      id: targetGroup.id,
      name: targetGroup.name,
      values: { id: targetGroup.id, name: targetGroup.name },
      chip: targetGroup.countCompanies,
    };
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { clientId } = useParams();

  function handleOptionChange(event: SelectChangeEvent<unknown>) {
    const chosenTargetGroup = event.target.value as string;
    const { name, id } = JSON.parse(chosenTargetGroup);
    dispatch(setCurrentTargetGroupParams({ id, name }));
    dispatch(resetCompanyTargetGroupAnalyticsSorting());
  }
  const filter = useAppSelector(getCompanyCountFilter);
  const prevFilter = useRef(filter);

  const getAndSetTargetGroupsByClient = useCallback(async () => {
    if (showCounts && isVetting) {
      dispatch(getAllTargetGroupsByClient({ clientId, filter: 'vetting' }));
      return;
    }
    if (showCounts) {
      if (!filter || filter === prevFilter.current) return;
      dispatch(getAllTargetGroupsByClient({ clientId, filter }));
      prevFilter.current = filter;
    } else {
      dispatch(getAllTargetGroupsByClient({ clientId }));
      prevFilter.current = undefined;
    }
  }, [clientId, filter]);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    getAndSetTargetGroupsByClient();
  }, [getAndSetTargetGroupsByClient]);

  return (
    <SelectTargetGroupWithDropdownContainer isMenuOpen={isMenuOpen} className={className}>
      <BaseDropdown
        selectedOptionName={currentTargetGroupName}
        defaultValue={{ name: defaultTargetGroupName, id: defaultTargetGroupId }}
        options={dropdownOptions || []}
        handleOptionChange={handleOptionChange}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        showChip={showCounts}
      />
    </SelectTargetGroupWithDropdownContainer>
  );
}

export default SelectTargetGroupWithDropdown;
