import { ICompaniesForResearchListResponse } from '../../../entities/company-target-group/domain/company-target-group.types';
import { ICompanyForResearchCardProps } from '../components/company-for-research-card/company-for-research-card.types';

export function formCompaniesForResearchEntityData(
  companiesForResearchFromServer: ICompaniesForResearchListResponse['items'],
  currentCompanyId: string,
): (ICompanyForResearchCardProps & { key: string })[] {
  return companiesForResearchFromServer?.map(({ id: companyTargetGroupId, companyName }) => {
    return {
      isActiveEntity: currentCompanyId === companyTargetGroupId,
      companyTargetGroupId,
      companyName,
      key: `${companyTargetGroupId}_${companyName}`,
    };
  });
}
