import { CreateLeadWrapper } from './create-lead.styles';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { useAppDispatch } from '../../../app/store/utils/redux.hooks';
import { createNewLead } from '../../../entities/company-target-group/store/company-target-group.slice';

const BUTTON_TITLE = 'Add a new person';

function CreateLead({ companyId }: { companyId: string }) {
  const dispatch = useAppDispatch();

  async function handleCreateSequence() {
    dispatch(createNewLead({ companyId }));
  }

  return (
    <CreateLeadWrapper>
      <TransparentRectButtonWithOrangeBorder
        className="create-target-group-button"
        title={BUTTON_TITLE}
        handleClick={handleCreateSequence}
      />
    </CreateLeadWrapper>
  );
}

export default CreateLead;
