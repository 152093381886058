import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import { Login } from '../../pages/authentication/login/login.component';
import { UserRole } from '../../entities/user/domain/user.types';

import AnalyticsAndReporting from '../../pages/analytics-and-reporting/analytics-and-reporting.component';
import AuthenticationLayout from '../layouts/authentication/authentication-layout.component';
import BaseLayout from '../layouts/base/base-layout.component';
import CompanyTracking from '../../pages/company-tracking/company-tracking.component';
import DashboardLayout from '../layouts/dashboard/dashboard-layout.component';
import GtmPlan from '../../pages/gtm-plan/gtm-plan.component';
import PipelineNavigation from '../../modules/navigation/components/pipeline-navigation/pipeline-navigation.component';
import ReportingNavigation from '../../modules/navigation/components/reporting-navigation/reporting-navigation.component';
import RequireAuthRoute from './require-auth-route.component';
import RequireRoleRoute, { RejectionFeedbackType } from './require-role-route.component';
import TargetDefinition from '../../pages/target-definition/target-definition.component';
import TargetUpload from '../../pages/target-upload/target-upload.component';
import TargetVetting from '../../pages/target-vetting/target-vetting.component';
import TargetResearch from '../../pages/target-research/target-research.component';
import Clients from '../../pages/clients/clients.component';
import ReviewEmails from '../../pages/review-emails/review-emails.component';
import NewsEvents from '../../pages/news-events/news-events.component';
import KpisNavigation from '../../modules/navigation/components/kpis-navigation/kpis-navigation.component';
import KPISelection from '../../pages/kpi-selection/kpi-selection.component';
import KPIWeekly from '../../pages/kpi-weekly/kpi-weekly.component';
import KPIVisibility from '../../pages/kpi-visibility/kpi-visibility.component';
import RequirePipelineStageAccessRoute from './require-pipeline-stage-access-route.component';
import { PipelineStageName } from '../../modules/navigation/components/pipeline-navigation/pipeline-navigation.types';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '', // Empty string for the default route
        element: <Navigate to="authentication/login" />, // Redirect to "/authentication/login"
      },
      {
        path: 'authentication',
        element: <AuthenticationLayout />,
        children: [
          {
            path: 'login',
            element: <Login />,
          },
        ],
      },
      {
        path: 'dashboard',
        element: (
          <RequireAuthRoute>
            <Outlet />
          </RequireAuthRoute>
        ),
        children: [
          {
            path: 'clients',
            element: (
              <RequireRoleRoute
                allowedRoles={[UserRole.EMPLOYEE]}
                rejectionFeedbackType={RejectionFeedbackType.REJECTION_SCREEN}
              >
                <DashboardLayout>
                  <Clients />
                </DashboardLayout>
              </RequireRoleRoute>
            ),
          },
          {
            path: 'pipeline/:clientId',
            element: <DashboardLayout navigationSection={<PipelineNavigation />} />,
            children: [
              {
                path: 'target-definition',
                element: <TargetDefinition />,
              },
              {
                path: 'gtm-plan',
                element: (
                  <RequireRoleRoute
                    allowedRoles={[UserRole.EMPLOYEE]}
                    rejectionFeedbackType={RejectionFeedbackType.REJECTION_SCREEN}
                  >
                    <GtmPlan />
                  </RequireRoleRoute>
                ),
              },
              {
                path: 'target-upload',
                element: (
                  <RequireRoleRoute
                    allowedRoles={[UserRole.EMPLOYEE]}
                    rejectionFeedbackType={RejectionFeedbackType.REJECTION_SCREEN}
                  >
                    <TargetUpload />
                  </RequireRoleRoute>
                ),
              },
              {
                path: 'target-vetting',
                element: (
                  <RequirePipelineStageAccessRoute stageName={PipelineStageName.TARGET_VETTING}>
                    <TargetVetting />
                  </RequirePipelineStageAccessRoute>
                ),
              },
              {
                path: 'target-research',
                element: (
                  <RequireRoleRoute
                    allowedRoles={[UserRole.EMPLOYEE]}
                    rejectionFeedbackType={RejectionFeedbackType.REJECTION_SCREEN}
                  >
                    <TargetResearch />
                  </RequireRoleRoute>
                ),
              },
              {
                path: 'review-emails',
                element: <ReviewEmails />,
              },
            ],
          },
          {
            path: 'reporting/:clientId',
            element: <DashboardLayout navigationSection={<ReportingNavigation />} />,
            children: [
              {
                path: 'analytics-and-reporting',
                element: <AnalyticsAndReporting />,
              },
              {
                path: 'company-tracking',
                element: <CompanyTracking />,
              },
              {
                path: 'market-tracking',
                element: <NewsEvents />,
              },
            ],
          },
          {
            path: 'kpis/:clientId',
            element: (
              <RequireRoleRoute
                allowedRoles={[UserRole.EMPLOYEE]}
                rejectionFeedbackType={RejectionFeedbackType.REJECTION_SCREEN}
              >
                <DashboardLayout navigationSection={<KpisNavigation />} />,
              </RequireRoleRoute>
            ),
            children: [
              {
                path: 'selection',
                element: <KPISelection />,
              },
              {
                path: 'weekly',
                element: <KPIWeekly />,
              },
              {
                path: 'visibility',
                element: <KPIVisibility />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
