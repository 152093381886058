import React, { PropsWithChildren } from 'react';

import { ITargetResearchFormInputProps } from './target-research-form-input.types';
import { TargetResearchFormBaseInput } from './target-research-form-input.styles';

function TargetResearchFormInput<T extends { [key: string]: any }>({
  id,
  label,
  name,
  values,
  errors,
  disabled,
  handleChange,
}: PropsWithChildren<ITargetResearchFormInputProps<T>>) {
  return (
    <TargetResearchFormBaseInput
      className="target-research-form-input"
      id={id}
      label={label}
      name={name}
      handleChange={handleChange}
      values={values}
      errors={errors}
      disabled={disabled}
    />
  );
}

export default TargetResearchFormInput;
