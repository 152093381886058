import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const SelectSequenceLeadWithDropdownContainer = styled(Container, {
  shouldForwardProp: (props) => props !== 'isMenuOpen',
})<ContainerProps & { isMenuOpen: boolean }>(({ isMenuOpen, theme }) => ({
  width: '72%',
  height: '40px',
  marginTop: '5px',
  borderRadius: '10px',
  background: '#152040',
  borderBottomRightRadius: isMenuOpen ? '0' : '10px',
  borderBottomLeftRadius: isMenuOpen ? '0' : '10px',
  transition: 'border-radius 0.3s ease-in-out',

  [theme.breakpoints.up('xs')]: {
    margin: 0,
    marginTop: '8px',
  },
}));
