import React, { useState } from 'react';

import { SelectChangeEvent } from '@mui/material';

import { ChangeCompanyTargetGroupContainer } from './change-company-target-group.styles';
import { IChangeCompanyTargetGroupProps } from './change-company-target-group.types';

import BaseDropdownLight from '../../shared/components/dropdown/base-dropdown-light/base-dropdown-light.component';
import ValidationErrorsHelper from '../../shared/components/helper/validation-errors-helper/validation-errors-helper.component';

function CheckboxSelectorInput({
  className,
  label,
  name,
  values,
  disabled,
  errors,
  handleChange,
}: IChangeCompanyTargetGroupProps & { errors: any }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const optionsList = [
    { id: '1', name: 'Yes' },
    { id: '2', name: 'No' },
  ];

  const currentSelectedOption = values[name]?.name || values[name] || '';
  const dropdownOptions = (optionsList || []).map((option) => {
    const optionId = option.id;
    const optionName = option.name;
    return { id: optionId, name: optionName, values: optionName } as any;
  });

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  function handleOptionChange(event: SelectChangeEvent<unknown>) {
    const chosenOption = event?.target?.value as string;
    const parsedOption = JSON.parse(chosenOption);
    handleChange(name, parsedOption);
  }

  return (
    <ChangeCompanyTargetGroupContainer className={className} hasError={Boolean(errors?.[name])}>
      <BaseDropdownLight
        selectedOption={currentSelectedOption}
        options={dropdownOptions || []}
        handleOptionChange={handleOptionChange}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        label={label}
        className="change-company-target-group-dropdown"
        isMenuOpen={isMenuOpen}
        disabled={disabled}
      />
      {errors?.[name] && (
        <ValidationErrorsHelper errors={errors} name={name} id={'1'} className="autocomplete-validation-helper" />
      )}
    </ChangeCompanyTargetGroupContainer>
  );
}

export default CheckboxSelectorInput;
