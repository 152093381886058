import React from 'react';
import Stack from '@mui/material/Stack/Stack';

import { ITwoSectionsScreenLayoutProps } from './two-sections-screen-layout.types';
import {
  LeftSectionContainer,
  RightSectionContainer,
  TwoSectionsScreenLayoutContainer,
} from './two-sections-screen-layout.styles';

function TwoSectionsScreenLayout({
  leftSectionComponent: LeftSectionComponent,
  rightSectionComponent: RightSectionComponent,
  customPadding,
}: ITwoSectionsScreenLayoutProps) {
  return (
    <TwoSectionsScreenLayoutContainer>
      <Stack direction="row" height="100%" gap="1.2%">
        <LeftSectionContainer>
          <LeftSectionComponent />
        </LeftSectionContainer>
        <RightSectionContainer customPadding={customPadding}>
          <RightSectionComponent />
        </RightSectionContainer>
      </Stack>
    </TwoSectionsScreenLayoutContainer>
  );
}

export default TwoSectionsScreenLayout;
