import { styled } from '@mui/material/styles';

import { SolidOrangeRectNavBaseButtonProps } from './solid-orange-rect-nav-button.types';

import BaseButton from '../base-button/base-button.component';

export const SolidOrangeRectNavBaseButton = styled(BaseButton, {
  shouldForwardProp: (props) => props !== 'isCurrentRoute',
})<SolidOrangeRectNavBaseButtonProps>(({ isCurrentRoute, theme }) => ({
  transition: theme.transitions.create('background-color'),
  width: '235px',
  height: '52px',
  border: 'none',
  fontWeight: 600,
  backgroundColor: isCurrentRoute ? theme.palette.background.orange : theme.palette.background.orangeDim,
  color: isCurrentRoute ? theme.palette.text.primary : theme.palette.text.primaryDim,

  '&:hover': {
    transition: theme.transitions.create('background-color'),
  },

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.1875rem',
  },
}));
