import React from 'react';

import SingleSectionScreenLayout from '../../app/layouts/single-sections-screen/single-sections-screen.component';
import KPISelectionHeader from '../../modules/kpi-selection-header/components/kpi-selector-header.component';
import KPIVisibilityTable from '../../modules/kpi-visibility-table/components/kpi-visibility-table.component';

function KPIVisibility() {
  const SECTION_HEADER = 'Visibility KPIs';

  return (
    <SingleSectionScreenLayout>
      <KPISelectionHeader header={SECTION_HEADER} />
      <KPIVisibilityTable />
    </SingleSectionScreenLayout>
  );
}

export default KPIVisibility;
