import React from 'react';

import {
  EntityCardWithActionsWrapper,
  EntityCardWithActionsContent,
  EntityCardWithActionsTitle,
  EntityCardActions,
} from './entity-card-with-actions.styles';
import { IEntityCardWithActionsProps } from './entity-card-with-actions.types';

function EntityCardWithActions({
  className,
  title,
  handleClick,
  isActiveEntity,
  children,
}: IEntityCardWithActionsProps) {
  return (
    <EntityCardWithActionsWrapper className={className} onClick={handleClick}>
      <EntityCardWithActionsContent>
        <EntityCardWithActionsTitle isActiveEntity={isActiveEntity}>{title}</EntityCardWithActionsTitle>
      </EntityCardWithActionsContent>
      <EntityCardActions>{children}</EntityCardActions>
    </EntityCardWithActionsWrapper>
  );
}

export default React.memo(EntityCardWithActions);

// import React from 'react';

// import {
//   EntityCardWithActionsWrapper,
//   EntityCardWithActionsContent,
//   EntityCardWithActionsTitle,
//   EntityCardActions,
// } from './entity-card-with-actions.styles';
// import { IEntityCardWithActionsProps } from './entity-card-with-actions.types';

// function EntityCardWithActions({
//   className,
//   title,
//   handleClick,
//   isActiveEntity,
//   actionsComponent: ActionsComponent,
//   actionsComponentProps,
// }: IEntityCardWithActionsProps) {
//   return (
//     <EntityCardWithActionsWrapper className={className} onClick={handleClick}>
//       <EntityCardWithActionsContent>
//         <EntityCardWithActionsTitle isActiveEntity={isActiveEntity}>{title}</EntityCardWithActionsTitle>
//       </EntityCardWithActionsContent>
//       <EntityCardActions>
//         <ActionsComponent {...actionsComponentProps} />
//       </EntityCardActions>
//     </EntityCardWithActionsWrapper>
//   );
// }

// export default React.memo(EntityCardWithActions);
