import React, { PropsWithChildren } from 'react';

import AuthButton from '../auth-button/auth-button.component';
import AuthInput from '../auth-input/auth-input.component';
import { AuthBaseForm } from './auth-form.styles';
import { IAuthFormProps } from './auth-form.types';

function AuthForm<T>({ submitForm, inputsConfig, buttonTitle, isButtonLoading }: PropsWithChildren<IAuthFormProps<T>>) {
  return (
    <AuthBaseForm
      className="auth-form"
      submitForm={submitForm}
      inputComponent={AuthInput}
      inputsConfig={inputsConfig}
      buttonComponent={AuthButton}
      isHeaderShown={false}
      buttonTitle={buttonTitle}
      isButtonLoading={isButtonLoading}
    />
  );
}

export default AuthForm;
