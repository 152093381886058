import React, { Fragment } from 'react';

import FetchCompaniesForReviewList from '../../../features/fetch-companies-for-review-list/components/fetch-companies-for-review-list.component';
import SelectReviewFilterWithDropdown from '../../../features/select-review-filter-with-dropdown/components/select-review-filter-with-dropdown.component';
import SelectTargetGroupWithDropdown from '../../../features/select-target-group-with-dropdown/components/select-target-group-with-dropdown.component';

function ReviewEmailsLeftSection() {
  return (
    <Fragment>
      <div style={{ width: '100%', marginBottom: '5px' }}>
        <SelectReviewFilterWithDropdown />
        <SelectTargetGroupWithDropdown showCounts />
      </div>
      <hr style={{ width: '80%', color: '#01071A', border: 'none', borderTop: '1.5px solid #01071A' }} />
      <FetchCompaniesForReviewList />
    </Fragment>
  );
}

export default ReviewEmailsLeftSection;
