import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import BaseTextarea from '../../../shared/components/textarea/base-textarea/base-textarea.component';

export const InputVettingCommentContainer = styled(Container)<ContainerProps>(() => ({
  backgroundColor: 'transparent',
}));

export const VettingCommentBaseInput = styled(BaseTextarea)(() => ({
  '&.vetting-comment-input': {
    'margin-bottom': 0,
    '& .MuiInputBase-root': {
      padding: '0 0',
      borderRadius: '8px',
      border: '0.5px solid #E0E0E0',
      minWidth: '200px',

      '& textarea': {
        padding: '0 10px',
        fontSize: '0.8rem',
      },
    },
  },
}));
