import React from 'react';
import { Grow } from '@mui/material';

import { MuiTooltip, defaultTooltipStyle, muiTooltipArrowStyles, muiTooltipStyles } from './base-tooltip.styles';
import { IBaseTooltipProps, TooltipPlacement } from './base-tooltip.types';

function BaseTooltip({
  children,
  title,
  transitionComponent: TransitionComponent,
  placement,
  open,
  arrow,
  styleSchema,
  componentsProps,
}: IBaseTooltipProps) {
  return (
    <MuiTooltip
      title={title}
      TransitionComponent={TransitionComponent || Grow}
      placement={placement || TooltipPlacement.BOTTOM}
      open={open || false}
      arrow={arrow || false}
      styleSchema={styleSchema || defaultTooltipStyle}
      componentsProps={{
        tooltip: { sx: componentsProps?.tooltip || muiTooltipStyles },
        arrow: { sx: componentsProps?.arrow || muiTooltipArrowStyles },
        // popper: { sx: componentsProps?.popper || muiTooltipPopperStyles },
      }}
    >
      {children}
    </MuiTooltip>
  );
}

export default BaseTooltip;
