import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { updateTargetGroup } from '../../../entities/target-group/store/target-group.slice';
import {
  currentTargetGroupSelector,
  // targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
// import BaseAlerts from '../../../shared/components/alert/base-alerts/base-alerts.component';
import { ToggleSelectionContainer } from './toggle-selection.styles';

function ToggleSelection({ targetGroupId }: { targetGroupId: string }) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  // const { isLoading, isError, isSuccess, error } = useAppSelector(targetGroupThunksSelector).updateTargetGroup;
  const { selectionJobToggle, ...rest } = useAppSelector(currentTargetGroupSelector);

  function getOppositeToggle() {
    if (selectionJobToggle === 'on') return 'off';
    return 'on';
  }

  function handleButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsOpen(true);
  }

  async function handleAction() {
    dispatch(updateTargetGroup({ targetGroupId, body: { ...rest, selectionJobToggle: getOppositeToggle() } }));
    setIsOpen(false);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleCancel() {
    setIsOpen(false);
  }

  const runSearchModalConfig = {
    isInfo: isOpen,
    handleAction,
    handleClose,
    handleCancel,
    message: `Are you sure to turn ${getOppositeToggle()} the company selection job?`,
  };

  return (
    <FeedbackUiDecorator infoConfig={runSearchModalConfig} isChildrenVisible>
      <ToggleSelectionContainer>
        <TransparentRectButtonWithOrangeBorder
          title={`Turn ${getOppositeToggle()}`}
          handleClick={handleButtonClick}
          className={`marked-as-not-a-fit not-marked`}
          // isLoading={isLoading}
        />
      </ToggleSelectionContainer>
      {/* <BaseAlerts message={(error as any).message} isUiVisible={isError} type="error" delay={3000} />
      <BaseAlerts
        message={`Success: the company selection jon has been turned ${getOppositeToggle()}`}
        isUiVisible={isSuccess && !isLoading}
        type="success"
        delay={3000}
      /> */}
    </FeedbackUiDecorator>
  );
}

export default ToggleSelection;
