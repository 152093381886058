import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';
import Divider, { DividerProps } from '@mui/material/Divider';
import Stack, { StackProps } from '@mui/material/Stack';

export const Domain = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  color: theme.palette.common.white,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.125rem',
  },
}));

export const MuiDivider = styled(Divider)<DividerProps>(() => ({
  borderColor: '#E9EDF1',
}));

export const DomainNewsContainer = styled(Stack)<StackProps>(() => ({
  flexDirection: 'column',
  marginBottom: '20px',
}));

export const NewsListItem = styled(ListItem)<ListItemProps>(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  paddingTop: 0,
  paddingLeft: '8px',
  paddingBottom: 0,
}));

export const NewsListItemIcon = styled(ListItemIcon)<ListItemIconProps>(({ theme }) => ({
  minWidth: 0,
  color: theme.palette.common.white,
  marginRight: '10px',
  marginTop: '0.8rem',
  '> svg': { fontSize: '0.5rem' },
}));

export const NewsListItemText = styled(ListItemText)<ListItemTextProps>(({ theme }) => ({
  display: 'inline-block',
  '& .article-name': {
    color: theme.palette.text.orange,
    fontSize: '0.8rem',
    lineHeight: '0.8',
    textDecoration: 'underline',

    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
    },
  },
  '& .article-date': {
    color: theme.palette.common.white,
    textDecoration: 'none',
    fontSize: '0.8rem',

    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
    },
  },
}));
