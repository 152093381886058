import Chip from '@mui/material/Chip';
import Container, { ContainerProps } from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const RejectedFileContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  minHeight: '36px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.background.lightBlue,
  borderRadius: '10px',
  border: '0.5px solid #960118',
  marginTop: '10px',
  animation: '0.7s rejection-in',
  animationFillMode: 'both',
  animationTimingFunction: 'ease',

  '@keyframes rejection-in': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },

  [theme.breakpoints.up('xs')]: {
    padding: '10px',
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const FileName = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: '"Mulish", sans-serif',
  fontSize: '0.6525rem',
  fontWeight: 400,
  lineHeight: '1.125rem',
  marginRight: '10px',

  [theme.breakpoints.up('lg')]: {
    fontSize: '0.75rem',
  },
}));

export const RejectionLabel = styled(Chip)(({ theme }) => ({
  backgroundColor: '#FFC5B4',
  color: 'rgba(150, 1, 24, 0.80)',
  fontFamily: '"Mulish", sans-serif',
  fontSize: '0.6525rem',
  borderRadius: '10px',
  height: '17px',

  [theme.breakpoints.up('lg')]: {
    fontSize: '0.75rem',
  },
}));
