/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../app/services/axios.service';
import {
  IClientsVisibilityKPIsResponse,
  ICreateTargetGroupBody,
  ICurrentTargetGroupParams,
  ITargetGroup,
  ITargetGroupKPIsResponse,
  ITargetGroupsListResponse,
  IUpdateTargetGroupParams,
  TargetGroupState,
} from '../domain/target-group.types';
import { generateBaseThunkReducers } from '../../../shared/store/base-thunk-reducer';

export const defaultTargetGroupsList = {} as ITargetGroupsListResponse;

export const defaultEmail = { subject: '', body: '' };

export const defaultCurrentTargetGroupParams = {
  id: '',
  name: 'All target groups',
};

export const defaultCampaignOwner = {
  id: 'Select campaign owner',
  name: 'Select campaign owner',
};

export const defaultCurrentTargetGroup = {
  ...defaultCurrentTargetGroupParams,
  clientId: '',
  description: '',
  emailSequences: [defaultEmail, defaultEmail],
  headcount: '',
  revenue: '',
  regions: '',
  keywords: [],
  exampleCompanies: '',
  relevantPastDeals: '',
  relevantAcquirers: '',
  otherRelevantInformation: '',
  campaignOwner: defaultCampaignOwner,
  createdAt: '',
  updatedAt: '',
};

export const defaultCampaigns = [];

const defaultThunkStatus = { isLoading: false, isError: false, isSuccess: false, error: {} };

const defaultTargetGroupState = {
  targetGroupsWeeklyKPIs: {} as ITargetGroupKPIsResponse,
  clientsVisibilityKPIs: {} as IClientsVisibilityKPIsResponse,
  allCLientsTGsList: defaultTargetGroupsList,
  targetGroupsList: defaultTargetGroupsList,
  currentTargetGroupParams: defaultCurrentTargetGroupParams,
  currentTargetGroup: defaultCurrentTargetGroup,
  campaigns: defaultCampaigns,
  companyCountFilter: undefined,
  classificationTriggeredForTGs: new Set<string>(),
  thunks: {
    getClientsVisibilityKPIs: defaultThunkStatus,
    getTargetGroupsWeeklyKPIs: defaultThunkStatus,
    getForAllClientsTargetGroupsList: defaultThunkStatus,
    getAllTargetGroupsByClient: defaultThunkStatus,
    getTargetGroupById: defaultThunkStatus,
    createTargetGroup: defaultThunkStatus,
    updateTargetGroup: defaultThunkStatus,
    deleteTargetGroup: defaultThunkStatus,
    fetchCampaigns: defaultThunkStatus,
    promptExampleCompanies: defaultThunkStatus,
    enrichExampleCompanies: defaultThunkStatus,
    runSeaches: defaultThunkStatus,
    runClassification: defaultThunkStatus,
    deleteUploadedFile: defaultThunkStatus,
  },
};

export const targetGroupState: TargetGroupState = defaultTargetGroupState;

export const deleteUploadedFile = createAsyncThunk(
  'targetGroup/deleteUploadedFile',
  async ({ targetGroupId, sourceUrl }: { targetGroupId: string; sourceUrl: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`raw-companies/source/${targetGroupId}/${sourceUrl}`);
      return data;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getClientsVisibilityKPIs = createAsyncThunk(
  'targetGroup/getClientsVisibilityKPIs',
  async ({ clientIds = [] }: { clientIds?: string[] | undefined }, { rejectWithValue }) => {
    try {
      const endpoint = `/metrics/visibility-kpis?take=500&${clientIds.map((e) => `clientId=${e}`).join('&')}`;
      const { data: visibilityKPIs } = await axios.get(endpoint);
      return visibilityKPIs as IClientsVisibilityKPIsResponse;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getTargetGroupsWeeklyKPIs = createAsyncThunk(
  'targetGroup/getTargetGroupsWeeklyKPIs',
  async ({ clientIds = [] }: { clientIds?: string[] | undefined }, { rejectWithValue }) => {
    try {
      const endpoint = `/metrics/weekly-kpis?take=500&${clientIds.map((e) => `clientId=${e}`).join('&')}`;
      const { data: weeklyKPIs } = await axios.get(endpoint);
      return weeklyKPIs as ITargetGroupKPIsResponse;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getForAllClientsTargetGroupsList = createAsyncThunk(
  'targetGroup/getForAllClientsTargetGroupsList',
  async ({ clientIds = [] }: { clientIds?: string[] | undefined }, { rejectWithValue }) => {
    try {
      const endpoint = `/target-group/for-clients?take=500&${clientIds.map((e) => `clientId=${e}`).join('&')}`;
      const { data: targetGroupsList } = await axios.get(endpoint);
      return targetGroupsList as ITargetGroupsListResponse;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const runSeaches = createAsyncThunk(
  'targetGroup/runSeaches',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/ai-company-selection/scrape-companies`;
      const { data: targetGroup } = await axios.post(endpoint, { targetGroupId });
      return targetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const runClassification = createAsyncThunk(
  'targetGroup/runClassification',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/ai-company-selection/classify-target-group`;
      const { data: targetGroup } = await axios.post(endpoint, { targetGroupId });
      return targetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const enrichExampleCompanies = createAsyncThunk(
  'targetGroup/enrichExampleCompanies',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/ai-company-selection/enrich-example-companies`;
      const { data: targetGroup } = await axios.post(endpoint, { targetGroupId });
      return targetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const promptExampleCompanies = createAsyncThunk(
  'targetGroup/promptExampleCompanies',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/ai-company-selection/prompt-example-companies`;
      const { data: targetGroup } = await axios.post(endpoint, { targetGroupId });
      return targetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getAllTargetGroupsByClient = createAsyncThunk(
  'targetGroup/getAllTargetGroupsByClient',
  async ({ clientId, filter }: { clientId: string | undefined; filter?: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/target-group/all/${clientId}?take=300${filter ? `&criteria=${filter}` : ''}`;
      const { data: targetGroupsList } = await axios.get(endpoint);
      return targetGroupsList as ITargetGroupsListResponse;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getTargetGroupById = createAsyncThunk(
  'targetGroup/getTargetGroupById',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/target-group/${targetGroupId}`;
      const { data: targetGroup } = await axios.get(endpoint);
      return targetGroup.item as ITargetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const createTargetGroup = createAsyncThunk(
  'targetGroup/createTargetGroup',
  async (body: ICreateTargetGroupBody, { rejectWithValue }) => {
    try {
      const endpoint = '/target-group';
      const { data: targetGroup } = await axios.post(endpoint, body);
      return targetGroup.item as ITargetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updateTargetGroup = createAsyncThunk(
  'targetGroup/updateTargetGroup',
  async ({ targetGroupId, body }: IUpdateTargetGroupParams, { rejectWithValue }) => {
    try {
      const endpoint = `/target-group/${targetGroupId}`;
      const { data: targetGroup } = await axios.patch(endpoint, body);
      return targetGroup.item as ITargetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const deleteTargetGroup = createAsyncThunk(
  'targetGroup/deleteTargetGroup',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/target-group/${targetGroupId}`;
      const { data } = await axios.delete(endpoint);
      return data.message;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const fetchCampaigns = createAsyncThunk(
  'targetGroup/fetchCampaigns',
  async (clientId: string | undefined, { rejectWithValue }) => {
    try {
      const endpoint = `client/${clientId}/replyio/campaigns`;
      const { data } = await axios.get(endpoint);
      return data;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

const targetGroupSlice = createSlice({
  name: 'targetGroup',
  initialState: targetGroupState,
  reducers: {
    addNewEmailSequenceInCurrentTargetGroup: (state) => {
      // INFO: I've kept this code in case if we'll need the limit again
      // const isMax = state.currentTargetGroup.emailSequences.length >= 6;
      // if (isMax) return;
      const emptyEmailsPair = [defaultEmail, defaultEmail];
      state.currentTargetGroup.emailSequences.push(...emptyEmailsPair);
    },
    removeNewEmailSequenceInCurrentTargetGroup: (state, action: PayloadAction<number>) => {
      if (state.currentTargetGroup.emailSequences.length <= 2) return;
      if (action.payload <= 1) return;
      if (!state.currentTargetGroup.emailSequences?.length) return;
      if (!state.currentTargetGroup.emailSequences[action.payload]) return;
      state.currentTargetGroup.emailSequences.splice(action.payload, 2);
    },
    resetDeleteTargetGroupThunk: (state) => {
      state.thunks.deleteTargetGroup = { ...defaultThunkStatus, error: {} };
      // state.thunks.deleteTargetGroup = defaultThunkStatus;
    },
    setCompanyCountFilter: (state, action: PayloadAction<{ filter: string | undefined }>) => {
      state.companyCountFilter = action.payload?.filter;
    },
    setCurrentTargetGroupParams: (state, action: PayloadAction<ICurrentTargetGroupParams>) => {
      const currentTargetGroupParams = action.payload;
      state.currentTargetGroupParams = currentTargetGroupParams;
    },
    setCampaignOwner: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const campaignOwner = action.payload;
      state.currentTargetGroup.campaignOwner = campaignOwner;
    },
    resetTargetGroupState: (state) => {
      state.targetGroupsList = defaultTargetGroupState.targetGroupsList;
      state.currentTargetGroupParams = defaultTargetGroupState.currentTargetGroupParams;
      state.currentTargetGroup = defaultTargetGroupState.currentTargetGroup;
      state.campaigns = defaultTargetGroupState.campaigns;
      state.thunks = defaultTargetGroupState.thunks;
    },
  },

  extraReducers: (builder) => {
    generateBaseThunkReducers(builder, deleteUploadedFile, 'deleteUploadedFile', undefined, ['fulfilled']);
    builder.addCase(deleteUploadedFile.fulfilled, (state, action) => {
      const { sourceFiles } = state.currentTargetGroup;
      const isExist = (e: string) => e !== action.meta.arg.sourceUrl;

      if (sourceFiles) state.currentTargetGroup.sourceFiles = sourceFiles.filter(isExist);

      state.thunks.getForAllClientsTargetGroupsList.isLoading = false;
      state.thunks.getForAllClientsTargetGroupsList.isSuccess = true;
      state.thunks.getForAllClientsTargetGroupsList.error = {};
      state.thunks.getForAllClientsTargetGroupsList.isError = false;
    });

    generateBaseThunkReducers(builder, getClientsVisibilityKPIs, 'getClientsVisibilityKPIs', 'clientsVisibilityKPIs');

    generateBaseThunkReducers(
      builder,
      getTargetGroupsWeeklyKPIs,
      'getTargetGroupsWeeklyKPIs',
      'targetGroupsWeeklyKPIs',
    );

    builder.addCase(getForAllClientsTargetGroupsList.pending, (state, action) => {
      state.thunks.getForAllClientsTargetGroupsList.isLoading = true;
      state.thunks.getForAllClientsTargetGroupsList.isSuccess = false;
      state.thunks.getForAllClientsTargetGroupsList.error = {};
      state.thunks.getForAllClientsTargetGroupsList.isError = false;
    });
    builder.addCase(getForAllClientsTargetGroupsList.fulfilled, (state, action) => {
      const targetGroupsList = action.payload;
      state.allCLientsTGsList = targetGroupsList;
      state.thunks.getForAllClientsTargetGroupsList.isLoading = false;
      state.thunks.getForAllClientsTargetGroupsList.isSuccess = true;
      state.thunks.getForAllClientsTargetGroupsList.error = {};
      state.thunks.getForAllClientsTargetGroupsList.isError = false;
    });
    builder.addCase(getForAllClientsTargetGroupsList.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getForAllClientsTargetGroupsList.error = error;
      state.thunks.getForAllClientsTargetGroupsList.isError = true;
      state.thunks.getForAllClientsTargetGroupsList.isLoading = false;
    });

    builder.addCase(getAllTargetGroupsByClient.pending, (state, action) => {
      state.thunks.getAllTargetGroupsByClient.isLoading = true;
      state.thunks.getAllTargetGroupsByClient.isSuccess = false;
      state.thunks.getAllTargetGroupsByClient.error = {};
      state.thunks.getAllTargetGroupsByClient.isError = false;
    });
    builder.addCase(getAllTargetGroupsByClient.fulfilled, (state, action) => {
      const targetGroupsList = action.payload;
      state.targetGroupsList = targetGroupsList;
      state.companyCountFilter = undefined;
      state.thunks.getAllTargetGroupsByClient.isLoading = false;
      state.thunks.getAllTargetGroupsByClient.isSuccess = true;
      state.thunks.getAllTargetGroupsByClient.error = {};
      state.thunks.getAllTargetGroupsByClient.isError = false;
    });
    builder.addCase(getAllTargetGroupsByClient.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getAllTargetGroupsByClient.error = error;
      state.thunks.getAllTargetGroupsByClient.isError = true;
      state.thunks.getAllTargetGroupsByClient.isLoading = false;
    });

    builder.addCase(promptExampleCompanies.pending, (state, action) => {
      state.thunks.promptExampleCompanies.isLoading = true;
      state.thunks.promptExampleCompanies.isSuccess = false;
      state.thunks.promptExampleCompanies.error = {};
      state.thunks.promptExampleCompanies.isError = false;
    });
    builder.addCase(promptExampleCompanies.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.thunks.promptExampleCompanies.isLoading = false;
      state.thunks.promptExampleCompanies.isSuccess = true;
      state.thunks.promptExampleCompanies.error = {};
      state.thunks.promptExampleCompanies.isError = false;
    });
    builder.addCase(promptExampleCompanies.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.promptExampleCompanies.error = error;
      state.thunks.promptExampleCompanies.isError = true;
      state.thunks.promptExampleCompanies.isLoading = false;
    });

    builder.addCase(runSeaches.pending, (state, action) => {
      state.thunks.runSeaches.isLoading = true;
      state.thunks.runSeaches.isSuccess = false;
      state.thunks.runSeaches.error = {};
      state.thunks.runSeaches.isError = false;
    });
    builder.addCase(runSeaches.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.thunks.runSeaches.isLoading = false;
      state.thunks.runSeaches.isSuccess = true;
      state.thunks.runSeaches.error = {};
      state.thunks.runSeaches.isError = false;
    });
    builder.addCase(runSeaches.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.runSeaches.error = error;
      state.thunks.runSeaches.isError = true;
      state.thunks.runSeaches.isLoading = false;
    });

    builder.addCase(runClassification.pending, (state, action) => {
      state.classificationTriggeredForTGs.add(action.meta.arg);
      state.thunks.runClassification.isLoading = true;
      state.thunks.runClassification.isSuccess = false;
      state.thunks.runClassification.error = {};
      state.thunks.runClassification.isError = false;
    });
    builder.addCase(runClassification.fulfilled, (state, action) => {
      state.classificationTriggeredForTGs.delete(action.meta.arg);
      state.thunks.runClassification.isLoading = false;
      state.thunks.runClassification.isSuccess = true;
      state.thunks.runClassification.error = {};
      state.thunks.runClassification.isError = false;
    });
    builder.addCase(runClassification.rejected, (state, action: any) => {
      state.classificationTriggeredForTGs.delete(action.meta.arg);
      const error = action.payload;
      state.thunks.runClassification.error = error;
      state.thunks.runClassification.isError = true;
      state.thunks.runClassification.isLoading = false;
    });

    builder.addCase(enrichExampleCompanies.pending, (state, action) => {
      state.thunks.enrichExampleCompanies.isLoading = true;
      state.thunks.enrichExampleCompanies.isSuccess = false;
      state.thunks.enrichExampleCompanies.error = {};
      state.thunks.enrichExampleCompanies.isError = false;
    });
    builder.addCase(enrichExampleCompanies.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.thunks.enrichExampleCompanies.isLoading = false;
      state.thunks.enrichExampleCompanies.isSuccess = true;
      state.thunks.enrichExampleCompanies.error = {};
      state.thunks.enrichExampleCompanies.isError = false;
    });
    builder.addCase(enrichExampleCompanies.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.enrichExampleCompanies.error = error;
      state.thunks.enrichExampleCompanies.isError = true;
      state.thunks.enrichExampleCompanies.isLoading = false;
    });

    builder.addCase(getTargetGroupById.pending, (state, action) => {
      state.thunks.getTargetGroupById.isLoading = true;
      state.thunks.getTargetGroupById.isSuccess = false;
      state.thunks.getTargetGroupById.error = {};
      state.thunks.getTargetGroupById.isError = false;
    });
    builder.addCase(getTargetGroupById.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.thunks.getTargetGroupById.isLoading = false;
      state.thunks.getTargetGroupById.isSuccess = true;
      state.thunks.getTargetGroupById.error = {};
      state.thunks.getTargetGroupById.isError = false;
    });
    builder.addCase(getTargetGroupById.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getTargetGroupById.error = error;
      state.thunks.getTargetGroupById.isError = true;
      state.thunks.getTargetGroupById.isLoading = false;
    });

    builder.addCase(createTargetGroup.pending, (state, action) => {
      state.thunks.createTargetGroup.isLoading = true;
      state.thunks.createTargetGroup.isSuccess = false;
      state.thunks.createTargetGroup.error = {};
      state.thunks.createTargetGroup.isError = false;
    });
    builder.addCase(createTargetGroup.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.thunks.createTargetGroup.isLoading = false;
      state.thunks.createTargetGroup.isSuccess = true;
      state.thunks.createTargetGroup.error = {};
      state.thunks.createTargetGroup.isError = false;
    });
    builder.addCase(createTargetGroup.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.createTargetGroup.error = error;
      state.thunks.createTargetGroup.isError = true;
      state.thunks.createTargetGroup.isLoading = false;
    });

    builder.addCase(updateTargetGroup.pending, (state, action) => {
      state.thunks.updateTargetGroup.isLoading = true;
      state.thunks.updateTargetGroup.isSuccess = false;
      state.thunks.updateTargetGroup.error = {};
      state.thunks.updateTargetGroup.isError = false;
    });
    builder.addCase(updateTargetGroup.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.currentTargetGroup.emailSequences = [...(currentTargetGroup.emailSequences || [])];
      state.thunks.updateTargetGroup.isLoading = false;
      state.thunks.updateTargetGroup.isSuccess = true;
      state.thunks.updateTargetGroup.error = {};
      state.thunks.updateTargetGroup.isError = false;
    });
    builder.addCase(updateTargetGroup.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.updateTargetGroup.error = error;
      state.thunks.updateTargetGroup.isError = true;
      state.thunks.updateTargetGroup.isLoading = false;
    });

    builder.addCase(deleteTargetGroup.pending, (state, action) => {
      state.thunks.deleteTargetGroup.isLoading = true;
      state.thunks.deleteTargetGroup.isSuccess = false;
      state.thunks.deleteTargetGroup.error = {};
      state.thunks.deleteTargetGroup.isError = false;
    });
    builder.addCase(deleteTargetGroup.fulfilled, (state, action) => {
      state.thunks.deleteTargetGroup.isLoading = false;
      state.thunks.deleteTargetGroup.isSuccess = true;
      state.thunks.deleteTargetGroup.error = {};
      state.thunks.deleteTargetGroup.isError = false;
    });
    builder.addCase(deleteTargetGroup.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.deleteTargetGroup.error = error;
      state.thunks.deleteTargetGroup.isError = true;
      state.thunks.deleteTargetGroup.isLoading = false;
    });

    builder.addCase(fetchCampaigns.pending, (state, action) => {
      state.thunks.fetchCampaigns.isLoading = true;
      state.thunks.fetchCampaigns.isSuccess = false;
      state.thunks.fetchCampaigns.error = {};
      state.thunks.fetchCampaigns.isError = false;
    });
    builder.addCase(fetchCampaigns.fulfilled, (state, action) => {
      const campaignsArray = action.payload;
      state.campaigns = campaignsArray;
      state.thunks.fetchCampaigns.isLoading = false;
      state.thunks.fetchCampaigns.isSuccess = true;
      state.thunks.fetchCampaigns.error = {};
      state.thunks.fetchCampaigns.isError = false;
    });
    builder.addCase(fetchCampaigns.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.fetchCampaigns.error = error;
      state.thunks.fetchCampaigns.isError = true;
      state.thunks.fetchCampaigns.isLoading = false;
    });
  },
});

export const {
  setCurrentTargetGroupParams,
  addNewEmailSequenceInCurrentTargetGroup,
  removeNewEmailSequenceInCurrentTargetGroup,
  setCampaignOwner,
  resetTargetGroupState,
  resetDeleteTargetGroupThunk,
  setCompanyCountFilter,
} = targetGroupSlice.actions;

// export const targetGroupReducer = persistReducer(
//   {
//     key: 'targetGroup',
//     storage,
//     whitelist: ['currentTargetGroup'],
//   },
//   targetGroupSlice.reducer,
// );

export const targetGroupReducer = targetGroupSlice.reducer;
