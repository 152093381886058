import { NavigationPaths, Routes } from '../../../../shared/domain/constants/routing.constants';
import { IPipelineNavigationStrategy, PipelineStageName } from './pipeline-navigation.types';

import CountCompaniesToResearch from '../../../../features/count-pipeline-navigation-indicators/components/count-companies-to-research.component';
import CountCompaniesToVet from '../../../../features/count-pipeline-navigation-indicators/components/count-companies-to-vet.component';
import CountCompaniesToReview from '../../../../features/count-pipeline-navigation-indicators/components/count-companies-to-review.component';

export const pipelineNavigationStrategy = (clientId: string): IPipelineNavigationStrategy => {
  return {
    [PipelineStageName.TARGET_DEFINITION]: {
      stageName: PipelineStageName.TARGET_DEFINITION,
      navigateTo: NavigationPaths.TARGET_DEFINITION(clientId),
      currentRoute: Routes.TARGET_DEFINITION,
      key: PipelineStageName.TARGET_DEFINITION,
    },
    [PipelineStageName.GTM_PLAN]: {
      stageName: PipelineStageName.GTM_PLAN,
      navigateTo: NavigationPaths.GTM_PLAN(clientId),
      currentRoute: Routes.GTM_PLAN,
      key: PipelineStageName.GTM_PLAN,
    },
    [PipelineStageName.TARGET_UPLOAD]: {
      stageName: PipelineStageName.TARGET_UPLOAD,
      navigateTo: NavigationPaths.TARGET_UPLOAD(clientId),
      currentRoute: Routes.TARGET_UPLOAD,
      key: PipelineStageName.TARGET_UPLOAD,
    },
    [PipelineStageName.TARGET_VETTING]: {
      stageName: PipelineStageName.TARGET_VETTING,
      navigateTo: NavigationPaths.TARGET_VETTING(clientId),
      currentRoute: Routes.TARGET_VETTING,
      key: PipelineStageName.TARGET_VETTING,
      indicator: CountCompaniesToVet,
    },
    [PipelineStageName.TARGET_RESEARCH]: {
      stageName: PipelineStageName.TARGET_RESEARCH,
      navigateTo: NavigationPaths.TARGET_RESEARCH(clientId),
      currentRoute: Routes.TARGET_RESEARCH,
      key: PipelineStageName.TARGET_RESEARCH,
      indicator: CountCompaniesToResearch,
    },
    [PipelineStageName.REVIEW_EMAILS]: {
      stageName: PipelineStageName.REVIEW_EMAILS,
      navigateTo: NavigationPaths.REVIEW_EMAILS(clientId),
      currentRoute: Routes.REVIEW_EMAILS,
      key: PipelineStageName.REVIEW_EMAILS,
      indicator: CountCompaniesToReview,
    },
  };
};
