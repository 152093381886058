import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const DontTickContactContainer = styled(Stack)(() => ({
  minWidth: '10%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  '> .dont-contact-checkbox': { padding: 0 },
}));
