/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { ProfileDataLineContainer, ProfileDataTitle, ProfileDataValue } from './company-profile-data-line.styles';
import { ICompanyProfileDataLineProps } from './company-profile-data-line.types';

import Link from '../../../../shared/components/link/link.component';

function CompanyProfileDataLine({ title, value, isUrl }: ICompanyProfileDataLineProps) {
  return (
    <ProfileDataLineContainer>
      <ProfileDataTitle>{title}</ProfileDataTitle>
      <ProfileDataValue>{isUrl ? <Link url={value} title={value} /> : value}</ProfileDataValue>
    </ProfileDataLineContainer>
  );
}

export default CompanyProfileDataLine;
