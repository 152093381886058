import { styled } from '@mui/material/styles';
import Container, { ContainerProps } from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const ProfileDataLineContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  height: 'auto',
  minHeight: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  marginBottom: '12px',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const ProfileDataTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: 600,
  color: theme.palette.common.white,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));

export const ProfileDataValue = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: 600,
  color: theme.palette.text.orange,
  whiteSpace: 'break-spaces',

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));
