/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import { AutocompleteContainer, AutocompletePaper, Input } from './autocomplete-with-multiple-choice.styles';
import { IAutocompleteWithMultipleChoicesProps } from './autocomplete-with-multiple-choice.types';

import BaseChip from '../../chip/base-chip/base-chip.component';
import ValidationErrorsHelper from '../../helper/validation-errors-helper/validation-errors-helper.component';

const DEFAULT_PLACEHOLDER = 'Type and select from list';
const DEFAULT_NO_OPTIONS_TEXT = 'No options';

function AutocompleteWithMultipleChoices<T extends { id: string; name: string }>({
  id,
  className,
  label,
  name,
  placeholder,
  selectedOptions,
  options,
  handleOptionsChange,
  handleOptionDelete,
  handleInputChange,
  handleOptionsFilter,
  noOptionsText,
  errors,
  disabled,
  isClickableLink,
}: IAutocompleteWithMultipleChoicesProps<T>) {
  const [isOpened, setIsOpened] = useState(false);
  const [isDropdownAboveInput, setIsDropdownAboveInput] = useState(false);

  const paperRef = useRef<HTMLElement>();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const dropdownBottom = paperRef.current?.getBoundingClientRect().bottom ?? 0;
    const inputRefBottom = inputRef.current?.getBoundingClientRect().bottom ?? 0;
    setIsDropdownAboveInput(inputRefBottom > dropdownBottom);
  });

  return (
    <AutocompleteContainer>
      <Autocomplete
        multiple
        sx={{ width: '100%' }}
        ref={inputRef}
        disablePortal
        disabled={disabled ?? false}
        className={className}
        options={options}
        getOptionLabel={(option) => option.name}
        value={selectedOptions ?? []}
        onChange={handleOptionsChange}
        onInputChange={handleInputChange}
        popupIcon={<UnfoldMoreIcon />}
        filterOptions={handleOptionsFilter ? handleOptionsFilter : (allOptions) => allOptions}
        noOptionsText={noOptionsText ? noOptionsText : DEFAULT_NO_OPTIONS_TEXT}
        onOpen={() => setIsOpened(true)}
        onClose={() => setIsOpened(false)}
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: 'flip',
                enabled: false,
              },
              {
                name: 'preventOverflow',
                enabled: false,
              },
            ],
          },
        }}
        PaperComponent={(props: any) => (
          <AutocompletePaper
            {...props}
            ref={paperRef}
            isDropdownAboveInput={isDropdownAboveInput}
            isOpened={isOpened}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <BaseChip
              label={option.name}
              {...getTagProps({ index })}
              onDeleteCallback={handleOptionDelete}
              disabled={disabled}
              isClickableLink={isClickableLink}
            />
          ))
        }
        renderInput={(params) => {
          return (
            <Input
              {...params}
              variant="outlined"
              id={id}
              label={label ? label : undefined}
              placeholder={placeholder ? placeholder : DEFAULT_PLACEHOLDER}
              isDropdownAboveInput={isDropdownAboveInput}
              isOpened={isOpened}
              hasError={errors?.[name]?.hasError ?? false}
            />
          );
        }}
      />
      {errors?.[name] && (
        <ValidationErrorsHelper errors={errors} name={name} id={id} className="autocomplete-validation-helper" />
      )}
    </AutocompleteContainer>
  );
}

export default AutocompleteWithMultipleChoices;
