/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  companyForReviewSelector,
  companyTargetGroupThunksSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import {
  getCompanyForReview,
  resetValidateLeadThunk,
  validateLead,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { FeedbackUiType } from '../../../shared/components/feedback-ui/feedback-ui.types';
import { ValidateLeadContainer } from './validate-lead.styles';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';

function ValidateLead() {
  const [isPromptedToValidate, setIsPromptedToValidate] = useState(false);
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const currentCompanyForReview = useAppSelector(companyForReviewSelector);
  const companyForReviewFormValues = currentCompanyForReview.item;
  const isMarkedAsValidated = companyForReviewFormValues.isValidated;

  const {
    isError: isValidateLeadError,
    error: validateLeadError,
    isLoading: isValidateLeadLoading,
  } = useAppSelector(companyTargetGroupThunksSelector).validateLead;

  async function handleClickValidated(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsPromptedToValidate(true);
  }

  const errorConfig = {
    isError: isValidateLeadError,
    uiType: FeedbackUiType.ALERT,
    message: validateLeadError.message,
    handleClose: () => {
      dispatch(resetValidateLeadThunk());
    },
  };

  async function handleAction() {
    await dispatch(validateLead({ companyTargetGroupId: companyForReviewFormValues.id }));
    await dispatch(getCompanyForReview({ clientId, companyTargetGroupId: companyForReviewFormValues.id }));
    setIsPromptedToValidate(false);
  }

  function handleClose() {
    setIsPromptedToValidate(false);
  }

  function handleCancel() {
    setIsPromptedToValidate(false);
  }

  const promptModalConfig = {
    isInfo: isPromptedToValidate,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Contact this company?',
    isActionLoading: isValidateLeadLoading,
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} errorConfig={errorConfig} isChildrenVisible>
      <ValidateLeadContainer>
        <TransparentRectButtonWithOrangeBorder
          title={isMarkedAsValidated ? 'Validated' : 'Contact Company'}
          handleClick={isMarkedAsValidated ? undefined : handleClickValidated}
          className={`validate-lead ${isMarkedAsValidated ? 'validated' : 'not-validated'}`}
          isLoading={isValidateLeadLoading}
        />
      </ValidateLeadContainer>
    </FeedbackUiDecorator>
  );
}

export default ValidateLead;
