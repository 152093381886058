import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const GtmPlanRightSectionFormContainer = styled(Container, {
  shouldForwardProp: (props) => props !== 'isTargetGroupChosen',
})<ContainerProps & { isTargetGroupChosen: boolean }>(({ isTargetGroupChosen, theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  paddingBottom: '10px',
  paddingTop: isTargetGroupChosen ? '20px' : 0,

  '> .target-definition-form-wrapper': {
    width: '100%',
    gap: '5px',
  },

  [theme.breakpoints.up('xs')]: {
    paddingBottom: '10px',
    paddingTop: isTargetGroupChosen ? '20px' : 0,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
