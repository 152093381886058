import React, { useEffect, useRef, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { updateCompanyTargetGroupStatus } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch } from '../../../app/store/utils/redux.hooks';
import { CompanyTargetGroupStatus } from '../../../entities/company-target-group/domain/company-target-group.types';
import { ISelectCompanyStatusProps } from './select-company-status.types';
import { SelectCompanyStatusContainer } from './select-company-status.styles';

import DropdownWithCheckboxesAndChips from '../../../shared/components/dropdown/dropdown-with-checkboxes-and-chips/dropdown-with-checkboxes-and-chips.component';
import StatusUncheckedIcon from '../../../shared/components/svg-icon/status-unchecked.component';
import StatusCheckedIcon from '../../../shared/components/svg-icon/status-checked.component';
import { useDebounce } from '../../../shared/hooks/use-debounce.hook';

function SelectCompanyStatus({ companyTargetGroupId, status: selectedStatuses }: ISelectCompanyStatusProps) {
  const dispatch = useAppDispatch();
  const [statuses, setStatuses] = useState(selectedStatuses);
  const [isInitial, setIsInitial] = useState(true);
  const debouncedStatuses = useDebounce(statuses, 750);

  const prevStatuses = useRef(debouncedStatuses);

  async function handleCompanyStatusesChange(event: SelectChangeEvent<any>) {
    const chosenCompanyStatuses = event.target.value as CompanyTargetGroupStatus[];
    setStatuses(chosenCompanyStatuses);
    setIsInitial(false);
  }

  async function handleCompanyStatusDelete(status: string) {
    const updatedSelectedCompanyStatuses = [...statuses].filter((value: string) => value !== status);
    setStatuses(updatedSelectedCompanyStatuses);
    setIsInitial(false);
  }

  // useEffect(() => {
  //   console.log({ isInitial });
  //   setStatuses(selectedStatuses);
  // }, [selectedStatuses]);

  useEffect(() => {
    const isNotTheSame = JSON.stringify(debouncedStatuses) !== JSON.stringify(prevStatuses);
    if (isNotTheSame && !isInitial) {
      dispatch(updateCompanyTargetGroupStatus({ companyTargetGroupId, status: statuses }));
      prevStatuses.current = debouncedStatuses;
    }
  }, [debouncedStatuses, dispatch]);

  return (
    <SelectCompanyStatusContainer>
      <DropdownWithCheckboxesAndChips
        selectedOptions={statuses}
        options={Array.from(Object.values(CompanyTargetGroupStatus))}
        handleOptionsChange={handleCompanyStatusesChange}
        handleChipDelete={handleCompanyStatusDelete}
        uncheckedIcon={<StatusUncheckedIcon />}
        checkedIcon={<StatusCheckedIcon />}
      />
    </SelectCompanyStatusContainer>
  );
}

export default React.memo(SelectCompanyStatus, (prevProps, nextProps) => {
  return (
    prevProps.status.length === nextProps.status.length &&
    prevProps.status.every((element, index) => element === nextProps.status[index])
  );
});
