import React from 'react';
import { useNavigate } from 'react-router-dom';

import { authFormValidationStrategy } from '../../domain/strategies/auth-form-validation.strategy';
import { checkIsEmptyObject } from '../../../../shared/helpers/base.helpers';
import { formDefaultAuthFormState, formInputsConfig } from '../../helpers/auth-form.helpers';
import { login } from '../../store/authentication.slice';
import { setUser } from '../../../../entities/user/store/user.slice';
import { useAppDispatch, useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { useForm } from '../../../../shared/hooks/use-form.hook';
import { validateObject } from '../../../../shared/libs/validator/validate-object.helpers';
import { AuthProcess } from '../../domain/types/authentication.types';
import { ButtonClickEvent } from '../../../../shared/domain/types/html-elements.types';

import { UserRole, UserRoles } from '../../../../entities/user/domain/user.types';

import AuthForm from '../auth-form/auth-form.component';
import { loginResultSelector } from '../../store/authentication.selectors';
import { NavigationPaths } from '../../../../shared/domain/constants/routing.constants';

function LoginWithEmail() {
  const authProcess = AuthProcess.LOGIN;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const defaultLoginFormState = formDefaultAuthFormState(authProcess);

  const { formValues, handleInputChange, validateForm, validationErrors } = useForm(
    defaultLoginFormState,
    validateObject,
    authFormValidationStrategy,
  );

  const inputsConfig = formInputsConfig<typeof formValues>(
    authProcess,
    formValues,
    validationErrors,
    handleInputChange,
  );

  const { isLoading } = useAppSelector(loginResultSelector);

  async function submitLoginForm(event: ButtonClickEvent) {
    event.preventDefault();
    try {
      const validationErrorsObject = validateForm(event, { ...formValues });
      const noValidationErrors = checkIsEmptyObject(validationErrorsObject as Object);
      if (noValidationErrors) {
        dispatch(login({ ...formValues }))
          .unwrap()
          .then((response) => {
            const { accessToken, ...user } = response?.item;
            const userRole = response?.item.role as UserRole;
            const roleInfoObject = response?.item?.roleInfo as UserRoles;
            const roleId = roleInfoObject?.id;
            dispatch(setUser(user));
            navigate(
              userRole === UserRole.CLIENT ? NavigationPaths.TARGET_DEFINITION(roleId) : NavigationPaths.CLIENTS,
            );
          });
      }
    } catch (error: any) {
      //   handleLoginError(error);
    }
  }

  return (
    <AuthForm
      submitForm={submitLoginForm}
      inputsConfig={inputsConfig}
      buttonTitle="Continue"
      isButtonLoading={isLoading}
    />
  );
}

export default LoginWithEmail;
