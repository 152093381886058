import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Fade } from '@mui/material';

import { IConfirmActionProps } from './confirm-action.types';
import {
  ConfirmActionContainer,
  ConfirmActionTitle,
  ConfirmActionContent,
  ConfirmActionButtons,
} from './confirm-action.styles';

import BaseButton from '../../../../button/base-button/base-button.component';
import TextButton from '../../../../button/text-button/text-button.component';

export default function ConfirmAction({
  title,
  message,
  cancelTitle,
  actionTitle,
  handleAction,
  handleCancel,
  handleClose,
  isActionLoading,
}: IConfirmActionProps) {
  return (
    <ConfirmActionContainer open onClose={handleClose} maxWidth="sm" fullWidth TransitionComponent={Fade}>
      <ConfirmActionTitle onClick={(e: any) => e.stopPropagation()}>
        <WarningAmberIcon />
        {title}
      </ConfirmActionTitle>
      <ConfirmActionContent onClick={(e: any) => e.stopPropagation()}>{message}</ConfirmActionContent>
      <ConfirmActionButtons onClick={(e: any) => e.stopPropagation()}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <TextButton title={cancelTitle as string} handleClick={handleCancel} className="cancel-button" />
          <BaseButton
            title={actionTitle as string}
            handleClick={handleAction}
            className="action-button"
            isLoading={isActionLoading}
          />
        </div>
      </ConfirmActionButtons>
    </ConfirmActionContainer>
  );
}
