import React from 'react';

import { AuthFormSectionContainer, AuthFormSectionHeader, LogoImage } from './auth-form-section.styles';
import { IAuthFormSectionProps } from './auth-form-section.types';

function AuthFormSection({ children, headerTitle }: IAuthFormSectionProps) {
  return (
    <AuthFormSectionContainer>
      <LogoImage src="/standing-logo.svg" />
      <AuthFormSectionHeader variant="h4">{headerTitle}</AuthFormSectionHeader>
      {children}
    </AuthFormSectionContainer>
  );
}

export default AuthFormSection;
