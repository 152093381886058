import React from 'react';

import { InputInfoHelper } from './validation-errors-helper.styles';
import { IValidationErrorsHelperProps } from './validation-errors-helper.types';

function ValidationErrorsHelper({ errors, name, id, className }: IValidationErrorsHelperProps) {
  return (
    <ul className={className}>
      {errors?.[name]?.errors?.map(
        (
          error:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | Iterable<React.ReactNode>
            | React.ReactPortal
            | null
            | undefined,
          i: React.Key | null | undefined,
        ) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <InputInfoHelper id={id}>{error}</InputInfoHelper>
            </li>
          );
        },
      )}
    </ul>
  );
}

export default ValidationErrorsHelper;
