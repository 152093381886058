import React from 'react';

import { relevantCompaniesNewsEventsSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import {
  INewsEventsError,
  ICompanyNewsEventsWithData,
} from '../../../entities/company-target-group/domain/company-news-events.types';

import FetchRelevantCompaniesNewsLayout from './fetch-relevant-companies-news-layout/fetch-relevant-companies-news-layout.component';
import NoRelevantCompaniesScreen from './no-relevant-companies-screen/no-relevant-companies-screen.component';
import RelevantCompaniesNewsWithoutData from './relevant-companies-news/relevant-companies-news-without-data.component';
import RelevantCompaniesNewsWithData from './relevant-companies-news/relevant-companies-news-with-data.component';

function FetchRelevantCompaniesNews() {
  const relevantCompaniesData = useAppSelector(relevantCompaniesNewsEventsSelector).data?.item;

  const relevantCompaniesNewsEvents = relevantCompaniesData?.relevantCompaniesNewsEvents || {};
  const relevantCompanies = relevantCompaniesData?.relevantCompanies || [];

  const finalContent: any = [];

  relevantCompanies.forEach((companyDomain: string) => {
    const isRelevantCompaniesNewsEventsError =
      relevantCompaniesNewsEvents && 'error' in relevantCompaniesNewsEvents?.[companyDomain];

    if (isRelevantCompaniesNewsEventsError) {
      const newsWithError = relevantCompaniesNewsEvents?.[companyDomain] as INewsEventsError;
      finalContent.push(
        <RelevantCompaniesNewsWithoutData companyDomain={companyDomain} message={newsWithError?.error.message} />,
      );
    } else if (relevantCompaniesNewsEvents && 'data' in relevantCompaniesNewsEvents?.[companyDomain]) {
      const data = relevantCompaniesNewsEvents?.[companyDomain] as ICompanyNewsEventsWithData;
      const isEmptyData = data?.data.length === 0;
      if (isEmptyData) {
        finalContent.push(
          <RelevantCompaniesNewsWithoutData
            companyDomain={companyDomain}
            message="No Events were found for this search criteria."
          />,
        );
      } else {
        finalContent.push(<RelevantCompaniesNewsWithData companyDomain={companyDomain} data={data} />);
      }
    }
  });

  return (
    <FetchRelevantCompaniesNewsLayout>
      {relevantCompanies.length === 0 ? <NoRelevantCompaniesScreen /> : finalContent}
    </FetchRelevantCompaniesNewsLayout>
  );
}

export default FetchRelevantCompaniesNews;
