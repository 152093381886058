import Dialog, { DialogProps } from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const ButtonContainer = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '4.5vw',
  height: '4.5vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .cross-modal-button': {},
}));

export const ModalContainer = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiDialog-container': {
    background: 'rgba(15, 27, 57, 0.60)',
    backdropFilter: 'blur(1px)',
  },

  '& .MuiPaper-root.MuiDialog-paper': {
    width: '58%',
    height: '67%',
    maxWidth: '58%',
    borderRadius: '16px',
    padding: '3.5%',
    position: 'relative',
    backgroundColor: theme.palette.background.midnightBlue,
  },
}));
