import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const SingleSectionScreenLayoutContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100vh',
  overflow: 'hidden',

  [theme.breakpoints.up('xs')]: {
    paddingBottom: '1.2%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const SectionContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.blueTranslucent,
  position: 'relative',
  fontSize: '0.8rem',
  fontWeight: 600,
  color: theme.palette.text.orange,

  [theme.breakpoints.up('xs')]: {
    padding: '2.5% 3.5% 22px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));
