/* eslint-disable no-restricted-syntax */
import React from 'react';

import { formatCellData } from './data-table-row.helpers';
import { IDataTableRowProps } from './data-table-row.types';
import { MuiTableRow } from './data-table-row.styles';

import Cell from '../table-cell/cell.component';
import SpecialCell from '../table-cell/special-cell.component';

function pickObjectKeys(object: { [key in string]: any }, keys: string[]) {
  const newObject: { [key in string]: any } = {};

  for (const key of keys) {
    newObject[key] = object?.[key];
  }

  return newObject;
}

function DataTableRow({
  row,
  rowKey,
  rowPropertyNames,
  specificTableCellFormatsConfig,
  optionalData = [],
}: IDataTableRowProps) {
  return (
    <MuiTableRow key={rowKey}>
      {rowPropertyNames.map((rowPropertyName, rowPropertyNameIndex) => {
        const uniqueId = `${rowPropertyName}${rowPropertyNameIndex}`;
        if (rowPropertyName in (specificTableCellFormatsConfig || {})) {
          const specialCellProps = {
            ...pickObjectKeys(row, optionalData),
            [rowPropertyName]: row[rowPropertyName],
            id: row.id,
          };
          return (
            <SpecialCell
              align="left"
              key={uniqueId}
              index={uniqueId}
              propsForChildren={{ specialCellProps }}
              className="specific-table-cell"
            >
              {specificTableCellFormatsConfig?.[rowPropertyName](specialCellProps)}
            </SpecialCell>
          );
        }
        return (
          <Cell
            align="left"
            rowValue={formatCellData(rowPropertyName, row)}
            index={rowPropertyNameIndex}
            key={uniqueId}
          />
        );
      })}
    </MuiTableRow>
  );
}

export default DataTableRow;
