import { BaseListServerResponse } from '../../../shared/domain/types/base.types';

export interface ITargetGroupEmail {
  subject: string;
  body: string;
}

export interface IApolloSearchOutcome {
  url: string;
  status: 'success' | 'fail';
  date: string;
  companiesParsed: number;
}

export interface ITargetGroup {
  id: string;
  clientId: string | undefined;
  name: string;
  description: string;
  emailSequences: ITargetGroupEmail[];
  headcount?: string;
  revenue: string;
  regions: string;
  keywords: string[];
  exampleCompanies: string;
  relevantPastDeals: string;
  relevantAcquirers: string;
  otherRelevantInformation: string;
  campaignOwner: { id: string; name: string };
  createdAt: string;
  updatedAt: string;
  countCompanies?: number;
  aiPrompt?: string;
  maxHeadcount?: number | null;
  minHeadcount?: number | null;
  locations?: string[];
  industryFilters?: string[];
  goodFitCompaniesExamples?: string[];
  badFitCompaniesExamples?: string[];
  apolloSearches?: string[];
  aiPromptOutcome?: any;
  apolloSearchOutcome?: IApolloSearchOutcome[];
  selectionJobToggle?: 'on' | 'off';
  weeklySelectionRate?: number;
  sourceFiles?: string[];
}

export interface ICreateTargetGroupBody extends Omit<ITargetGroup, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IUpdateTargetGroupBody extends Omit<ICreateTargetGroupBody, 'clientId'> {}

export interface IUpdateTargetGroupParams {
  targetGroupId: string;
  body: Partial<IUpdateTargetGroupBody>;
}

export interface ITargetGroupsListResponse extends BaseListServerResponse<ITargetGroup> {}

export interface ITargetGroupKPI {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  clientId?: string;
  clientName?: string;
  companyName?: string;
  targetGroupId?: string;
  targetGroupName?: string;
  week?: string;
  uploadBeforeProcessing?: number;
  uploadAfterProcessing?: number;
  vetted?: number;
  vettedAccepted?: number;
  researchedTotal?: number;
  repliedTotal?: number;
  researchedFit?: number;
  reviewedTotal?: number;
  reviewedFit?: number;
  researchedRecycledTotal?: number;
  researchedRecycledFit?: number;
  reviewedRecycledTotal?: number;
  reviewedRecycledFit?: number;
}

export interface ITargetGroupKPIsResponse extends BaseListServerResponse<ITargetGroupKPI> {}

export interface IClientsVisibilityKPIsResponse extends BaseListServerResponse<IClientVisibilityKPI> {}

export interface IClientVisibilityKPI {
  clientName?: string;
  inRecycling?: number;
  estimatedFitCompaniesLeft?: number;
  selectionWeeksLeft?: number;
  companiesToVet?: number;
  companiesToResearch?: number;
  companiesToReview?: number;
  recyclingScheduleForNext4Weeks?: string;
}

export interface ICurrentTargetGroupParams {
  id: string;
  name: string;
}

export interface IThunkStatuses {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: {};
}

export interface ITargetGroupThunks {
  getClientsVisibilityKPIs: IThunkStatuses;
  getTargetGroupsWeeklyKPIs: IThunkStatuses;
  getAllTargetGroupsByClient: IThunkStatuses;
  getTargetGroupById: IThunkStatuses;
  createTargetGroup: IThunkStatuses;
  updateTargetGroup: IThunkStatuses;
  deleteTargetGroup: IThunkStatuses;
  fetchCampaigns: IThunkStatuses;
  promptExampleCompanies: IThunkStatuses;
  enrichExampleCompanies: IThunkStatuses;
  runSeaches: IThunkStatuses;
  runClassification: IThunkStatuses;
  getForAllClientsTargetGroupsList: IThunkStatuses;
  deleteUploadedFile: IThunkStatuses;
}

export type TargetGroupState = {
  clientsVisibilityKPIs: IClientsVisibilityKPIsResponse;
  targetGroupsWeeklyKPIs: ITargetGroupKPIsResponse;
  allCLientsTGsList: ITargetGroupsListResponse;
  targetGroupsList: ITargetGroupsListResponse;
  currentTargetGroupParams: ICurrentTargetGroupParams;
  currentTargetGroup: ITargetGroup;
  campaigns: any[];
  classificationTriggeredForTGs: Set<string>;
  thunks: ITargetGroupThunks;
  companyCountFilter: string | undefined;
};

export enum TargetGroupFormField {
  TARGET_GROUP_NAME = 'Target Group Name',
  SHORT_DESCRIPTION = 'Short Description',
  SIZE_HEADCOUNT = 'Size (Headcount)', // remove it
  SIZE_REVENUE = 'Size (Revenue)',
  REGIONS = 'Regions', // remove it
  KEYWORDS = 'Keywords to look for',
  EXAMPLE_COMPANIES = 'Examples of good fit companies',
  BAD_EXAMPLE_COMPANIES = 'Example of bad fit companies',
  RELEVANT_PAST_DEALS = 'Relevant Past Deals',
  RELEVANT_ACQUIRERS = 'Relevant Acquirers you know',
  OTHER_RELEVANT_INFORMATION = 'Other relevant information',
  CAMPAIGN_OWNER = 'Campaign Owner',
  EMAIL_1 = 'Email 1',
  EMAIL_2 = 'Email 2',
  AI_PROMPT = 'AI Prompt',
  MIN_HEADCOUNT = 'Min headcount',
  MAX_HEADCOUNT = 'Max headcount',
  LOCATIONS = 'Locations',
  INDUSTRY_FILTERS = 'Industry Filters',
  APOLLO_SEARCHES = 'Apollo Searches',
}
