/* eslint-disable react-hooks/rules-of-hooks, no-restricted-syntax */
import React from 'react';
import { useParams } from 'react-router-dom';

import { checkIsEmptyObject } from '../../../shared/helpers/base.helpers';
import {
  companyForResearchSelector,
  companyTargetGroupThunksSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import {
  getCompanyForResearch,
  markCompanyAsResearched,
  markCompanyAsToResearch,
  setResearchFormValidationErrors,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import {
  generatePlaceholdersValidationStrategy,
  researchFormValidationStrategy,
} from '../domain/research-form-validation.strategy';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { validateObject } from '../../../shared/libs/validator/validate-object.helpers';
import { IMarkAsResearchedProps } from './mark-as-researched.types';
import { MarkAsResearchedContainer } from './mark-as-researched.styles';
import { UpdateCompanyForResearchBody } from '../../../entities/company-target-group/domain/company-target-group.types';
import { ValidationResultsObject } from '../../../shared/domain/types/validation.types';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import BaseAlerts from '../../../shared/components/alert/base-alerts/base-alerts.component';

function MarkAsResearched({ companyTargetGroupId, isMarkedAsResearched }: IMarkAsResearchedProps) {
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const currentCompanyForResearch = useAppSelector(companyForResearchSelector);
  const companyForResearchFormValues = currentCompanyForResearch.item;

  const {
    isLoading: isResearchedLoading,
    isError,
    error: researchError,
  } = useAppSelector(companyTargetGroupThunksSelector).markCompanyAsResearched;
  const { isLoading: isToResearchLoading } = useAppSelector(companyTargetGroupThunksSelector).markCompanyAsToResearch;

  function validateLeadsValues(leads: UpdateCompanyForResearchBody['leads'] = []) {
    const leadsValidationResult: { [key: string]: ValidationResultsObject } = {};

    for (const lead of leads) {
      leadsValidationResult[lead.id] = validateObject(lead, researchFormValidationStrategy);
    }

    return leadsValidationResult;
  }

  function validatePlaceholders(placeholders: Record<string, string>): ValidationResultsObject {
    const validationStrategy = generatePlaceholdersValidationStrategy(placeholders);
    const validationResult = validateObject(placeholders, validationStrategy);
    const reshapeInputName = ([key, value]: [string, any]) => [`placeholders.${key}`, value];
    return Object.fromEntries(Object.entries(validationResult).map(reshapeInputName));
  }

  function validateResearchFormValues(values: UpdateCompanyForResearchBody): ValidationResultsObject {
    const validationResult = validateObject(values, researchFormValidationStrategy);
    const placeholdersValidationResult = validatePlaceholders(values.placeholders);
    const leadsValidationResults = validateLeadsValues(values.leads);
    const result = { ...validationResult, ...placeholdersValidationResult, leads: leadsValidationResults };
    dispatch(setResearchFormValidationErrors(result));
    return result;
  }

  function hasPassedValidation({ leads, ...rest }: any): boolean {
    const noValidationErrors = checkIsEmptyObject(rest as Object);
    const noLeadsValidationErrors = Object.values(leads).every(checkIsEmptyObject);
    return noValidationErrors && noLeadsValidationErrors;
  }

  async function handleClickResearched() {
    try {
      const researchFormValues = new UpdateCompanyForResearchBody(companyForResearchFormValues);
      const validationErrorsObject = validateResearchFormValues(researchFormValues);
      const noValidationErrors = hasPassedValidation(validationErrorsObject);
      if (noValidationErrors) {
        await dispatch(markCompanyAsResearched({ companyTargetGroupId }));
        await dispatch(getCompanyForResearch({ clientId, companyTargetGroupId }));
      }
      // eslint-disable-next-line no-empty
    } catch (error: any) {}
  }

  async function handleClickUnresearch() {
    await dispatch(markCompanyAsToResearch({ companyTargetGroupId }));
    await dispatch(getCompanyForResearch({ clientId, companyTargetGroupId }));
  }

  return (
    <MarkAsResearchedContainer>
      <TransparentRectButtonWithOrangeBorder
        title={isMarkedAsResearched ? 'Researched' : 'Mark as researched'}
        handleClick={isMarkedAsResearched ? handleClickUnresearch : handleClickResearched}
        className={`marked-as-researched ${isMarkedAsResearched ? 'researched' : 'not-researched'}`}
        isLoading={isResearchedLoading || isToResearchLoading}
      />
      <BaseAlerts
        message={`Unable to research company: ${researchError?.message || 'internal server error'}`}
        isUiVisible={isError}
        type="error"
        delay={3000}
      />
    </MarkAsResearchedContainer>
  );
}

export default MarkAsResearched;
