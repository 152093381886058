import React, { useState } from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { useAppDispatch } from '../../../app/store/utils/redux.hooks';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
import { DeleteSequenceButton } from './delete-target-group.styles';
import { removeNewEmailSequenceInCurrentTargetGroup } from '../../../entities/target-group/store/target-group.slice';

import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';

function DeleteSequence({ index }: { index: number }) {
  const [isPromptedToDelete, setIsPromptedToDelete] = useState(false);

  const dispatch = useAppDispatch();

  function handleDeleteButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsPromptedToDelete(true);
  }

  async function handleAction() {
    dispatch(removeNewEmailSequenceInCurrentTargetGroup(index));
    setIsPromptedToDelete(false);
  }

  function handleClose() {
    setIsPromptedToDelete(false);
  }

  function handleCancel() {
    setIsPromptedToDelete(false);
  }

  const promptModalConfig = {
    isInfo: isPromptedToDelete,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Remove this sequence?',
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <DeleteSequenceButton onClick={handleDeleteButtonClick}>
        <DeleteOutlinedIcon />
      </DeleteSequenceButton>
    </FeedbackUiDecorator>
  );
}

export default DeleteSequence;
