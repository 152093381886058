import React, { Fragment } from 'react';

import {
  companyForReviewSelector,
  companyTargetGroupThunksSelector,
  currentCompanyForReviewParamsSelector,
} from '../../../../entities/company-target-group/store/company-target-group.selectors';
import { useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { ReviewEmailsRightSectionFormContainer } from './review-emails-main.styles';

import CompanyProfile from '../../../../features/company-profile/components/company-profile.component';
import FinalEmailReview from '../../../../features/final-email-review/components/final-email-review.component';
import NoCompanyChosenScreen from './no-company-chosen-screen/no-company-chosen-screen.component';
import RightSectionMain from '../../../../app/layouts/layout-components/right-section-main/right-section-main.component';

function ReviewEmailsMain() {
  const currentCompanyForReviewParams = useAppSelector(currentCompanyForReviewParamsSelector);
  const { id: currentCompanyTargetGroupId } = currentCompanyForReviewParams;
  const isCompanyForReviewChosen = Boolean(currentCompanyTargetGroupId);

  const companyForReview = useAppSelector(companyForReviewSelector);
  const { isFirstCompanyLoad } = companyForReview;

  const { isLoading: isCompanyForReviewLoading } = useAppSelector(companyTargetGroupThunksSelector).getCompanyForReview;

  return (
    <ReviewEmailsRightSectionFormContainer isCompanyChosen={isCompanyForReviewChosen}>
      <RightSectionMain
        isListEntityChosen={isCompanyForReviewChosen}
        isDataLoading={isCompanyForReviewLoading && isFirstCompanyLoad}
        content={
          <Fragment>
            <CompanyProfile />
            <FinalEmailReview />
          </Fragment>
        }
        noContent={<NoCompanyChosenScreen />}
      />
    </ReviewEmailsRightSectionFormContainer>
  );
}

export default ReviewEmailsMain;
