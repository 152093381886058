import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';

export const CreateTargetGroupWrapper = styled(Stack)<StackProps>(({ theme }) => ({
  width: '100%',
  height: '80px',
  backgroundColor: theme.palette.background.midnightBlue,
  position: 'absolute',
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 22px',
  borderBottomRightRadius: '16px',
  borderBottomLeftRadius: '16px',

  '> .create-target-group-button': {
    width: '100%',
  },

  [theme.breakpoints.up('xl')]: {
    height: '110px',
    padding: '0 36px',
    '> button': { height: '70px' },
  },
}));
