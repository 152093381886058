import React from 'react';
import { MuiTableCell } from '../table.styles';

function Cell({ rowValue, index }: any) {
  return (
    <MuiTableCell align="left" key={index}>
      {rowValue}
    </MuiTableCell>
  );
}

export default React.memo(Cell);
