import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';

export const PipelineNavigationWrapper = styled(Stack)<StackProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const PipelineNavigationContainer = styled(Stack)<StackProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  height: '65px',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  '> button': {
    margin: 0,
    width: '16%',
    maxWidth: '180px',
    minWidth: 'auto',
    '&:not(style)+:not(style)': { margin: 0 },

    [theme.breakpoints.up('xl')]: {
      minWidth: '200px',
      height: '70px',
    },
  },

  [theme.breakpoints.up('xl')]: {
    width: '80%',
    gap: '25px',
  },
}));
