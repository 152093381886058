import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const AlertsContainer = styled(Stack)<StackProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  position: 'fixed',
  top: '15px',
  right: '15px',
  zIndex: 100000,
}));
