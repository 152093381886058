import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';

export const RejectLeadContainer = styled(Stack)<StackProps>(({ theme }) => ({
  '> .reject-lead': {
    width: '128px',
    height: '40px',
    minWidth: '128px',

    '& .MuiCircularProgress-root': { color: theme.palette.background.orange },

    '&.rejected': {
      backgroundColor: theme.palette.background.orange,
      color: theme.palette.background.black,
    },
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
