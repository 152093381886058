import { styled } from '@mui/material/styles';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import Stack, { StackProps } from '@mui/material/Stack';

export const ReviewEmailEditorWrapper = styled(Stack)<StackProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const Label = styled(InputLabel)<InputLabelProps>(({ theme }) => ({
  width: '100%',
  color: theme.palette.common.white,
  fontSize: '0.8rem',

  '&.MuiFormLabel-root': { marginBottom: '15px', fontWeight: 600 },

  '&.Mui-focused': {
    color: theme.palette.common.white,
  },

  '&.Mui-error': {
    color: theme.palette.common.white,
  },

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.5rem',
  },
}));

export const ReviewEmailEditorContainer = styled(Stack)<StackProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const ReviewEmailEditorSubject = styled(Stack)<StackProps>(({ theme }) => ({
  width: '100%',
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 15px',
  backgroundColor: theme.palette.common.white,
  borderRadius: '10px 10px 0px 0px',
  borderBottom: '1px solid #8F939B',

  '> .email-editor-subject': {
    margin: 0,
    '& .MuiInputBase-root': { margin: 0 },
    input: {
      height: '100%',
      border: 'none',
      color: theme.palette.text.darkGrey,
      fontSize: '0.8rem',
      fontWeight: 600,
      lineHeight: 'normal',
      padding: 0,
      borderRadius: 0,

      [theme.breakpoints.up('xl')]: {
        fontSize: '1rem',
      },
    },
  },
}));

export const SubjectTitle = styled(Stack)<StackProps>(({ theme }) => ({
  fontSize: '0.8rem',
  color: 'rgba(37, 37, 37, 0.70)',
  marginRight: '10px',

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));

export const BodyContainer = styled(Stack)<StackProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.common.white,
  borderRadius: '0px 0px 10px 10px',

  '> div > .email-editor-body': {
    marginBottom: 0,
    '& .MuiInputBase-root': {
      border: 'none',
      alignItems: 'flex-start',
      minHeight: '185px',
      padding: 0,

      '& textarea': {
        padding: '10px 15px 18px',
        color: theme.palette.text.darkGrey,
        fontSize: '0.8rem',
        lineHeight: 'normal',

        [theme.breakpoints.up('xl')]: {
          fontSize: '1rem',
        },
      },
    },
  },
}));

export const HighlightedInputContainer = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.background.darkGrey,
  overflowX: 'auto',
  whiteSpace: 'pre',
  fontWeight: 600,
  '> div': {
    padding: 0,
  },
  '.public-DraftStyleDefault-block': {
    overflowX: 'auto',
    whiteSpace: 'pre',
    display: 'flex',
  },
  '.highlighted-placeholder': {
    fontWeight: 600,
    color: theme.palette.background.darkGrey,
    overflowX: 'auto',
    whiteSpace: 'pre',
  },
}));