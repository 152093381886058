import { userSelector } from '../../../entities/user/store/user.selectors';
import { isAuthedSelector } from '../../../features/authentication/store/authentication.selectors';
import { useAppSelector } from '../../store/utils/redux.hooks';
import { IProtectDecorator } from './protect.types';

export default function ProtectDecorator({ allowedRoles, children }: IProtectDecorator) {
  const { role } = useAppSelector(userSelector);
  const isAuthenticated = useAppSelector(isAuthedSelector);

  const hasRole = role && allowedRoles.includes(role);
  const isAllowed = isAuthenticated && hasRole;

  return isAllowed ? children : undefined;
}
