import React from 'react';
import { Outlet } from 'react-router-dom';

import { AuthenticationLayoutContainer, AuthenticationBackground } from './authentication-layout.styles';

function AuthenticationLayout() {
  return (
    <AuthenticationLayoutContainer className="authentication-layout-container">
      <AuthenticationBackground>
        <Outlet />
      </AuthenticationBackground>
    </AuthenticationLayoutContainer>
  );
}

export default AuthenticationLayout;
