import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const FetchClientsListContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: 'calc(100% - 85px)',
  overflow: 'auto',
  paddingBottom: '10px',

  '> .select-client': {
    paddingTop: 0,

    '> .client-card': {
      height: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0 15px',
      transition: '0.1s ease-in-out',

      '&:hover': {
        p: { color: theme.palette.text.orange },
      },

      '& .MuiCardContent-root': {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        '> p': {
          fontSize: '1rem',

          [theme.breakpoints.up('xl')]: {
            fontSize: '1.2rem',
          },
        },
      },
    },
  },

  [theme.breakpoints.up('xs')]: {
    paddingBottom: '10px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
    width: '60%',
  },
}));
