import React from 'react';
import { Stack } from '@mui/material';

import { EmailEditorValidationHelperProps } from './email-editor-validation-helper.types';
import { HelperHeader, HelperText } from './email-editor-validation-helper.styles';

function EmailEditorValidationHelper({ errors, name, id, header }: EmailEditorValidationHelperProps) {
  return (
    <Stack direction="row" id={id}>
      <HelperHeader>{header}:</HelperHeader>
      <HelperText>{errors?.[name]?.errors?.[0]}</HelperText>
    </Stack>
  );
}

export default EmailEditorValidationHelper;
