/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment, ReactNode, useEffect } from 'react';
import { useLocation, Navigate, useParams } from 'react-router-dom';

import { isAuthedSelector } from '../../features/authentication/store/authentication.selectors';
import { useAppDispatch, useAppSelector } from '../store/utils/redux.hooks';
import { currentClientSelector } from '../../entities/user/store/user.selectors';
import { getCurrentClient } from '../../entities/user/store/user.slice';

function RequireAuthRoute({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(isAuthedSelector);
  const { clientId } = useParams();
  const currentClient = useAppSelector(currentClientSelector);

  useEffect(() => {
    const isCurrentClientSet = currentClient && currentClient.id === clientId;
    if (clientId && !isCurrentClientSet) dispatch(getCurrentClient({ clientId }));
  }, [dispatch, clientId]);

  const location = useLocation();

  return isAuthenticated ? (
    <Fragment>{children}</Fragment>
  ) : (
    <Navigate to="/authentication/login" state={{ from: location }} replace />
  );
}

export default RequireAuthRoute;
