import React, { useState } from 'react';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { ShowPromptOutcomeContainer } from './show-prompt-outcome.styles';
import { currentTargetGroupSelector } from '../../../entities/target-group/store/target-group.selectors';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';

interface FitObject {
  [key: string]: string;
}

interface FitData {
  goodFit: FitObject;
  badFit: FitObject;
  goodFitScore: number;
  badFitScore: number;
  aiPrompt: string;
}

function formatFitData(data: FitData): string {
  const { goodFit, badFit, goodFitScore, badFitScore, aiPrompt } = data;

  const formatFitTable = (fitType: 'goodFit' | 'badFit', fitObject: FitObject): string => {
    const tableRows = Object.entries(fitObject)
      .map(([url, status]) => `<tr><td><a href="${url}" target="_blank">${url}</a></td><td>${status}</td></tr>`)
      .join('');

    return `
       <table class="fit-table">
        <thead>
          <tr>
            <th>URL</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;
  };

  return `
      <style>
      .fit-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
      }
      .fit-table th, .fit-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
      .fit-table th {
        background-color: #f2f2f2;
      }
    </style>
    <div>
      <h3>${aiPrompt}</h3>
      <br>
      <h3>Good Fit Score: ${goodFitScore}</h3>
      ${formatFitTable('goodFit', goodFit)}
      <br>
      <br>
      <h3>Bad Fit Score: ${badFitScore}</h3>
      ${formatFitTable('badFit', badFit)}
    </div>
  `;
}

function ShowPromptOutcome() {
  const [isOpen, setIsOpen] = useState(false);
  const { aiPromptOutcome } = useAppSelector(currentTargetGroupSelector);

  async function handleButtonClick() {
    setIsOpen(!isOpen);
  }

  if (!aiPromptOutcome) return null;

  const promptModalConfig = {
    isInfo: isOpen,
    title: 'Last Prompt Results',
    handleAction: handleButtonClick,
    handleClose: handleButtonClick,
    handleCancel: handleButtonClick,
    message: (<div dangerouslySetInnerHTML={{ __html: formatFitData(aiPromptOutcome) }} />) as any,
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <ShowPromptOutcomeContainer>
        <TransparentRectButtonWithOrangeBorder
          title="Outcome"
          handleClick={handleButtonClick}
          className={`marked-as-not-a-fit not-marked`}
        />
      </ShowPromptOutcomeContainer>
    </FeedbackUiDecorator>
  );
}

export default ShowPromptOutcome;
