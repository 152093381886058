/* eslint-disable jsx-a11y/anchor-is-valid */
import { ICompanyTargetGroupDataForReview } from '../../../entities/company-target-group/domain/company-target-group.types';
import Link from '../../../shared/components/link/link.component';
import ChangeCompanyTargetGroup from '../../change-company-target-group/components/change-company-target-group.component';
import IgnoreLead from '../../ignore-lead/components/ignore-lead.component';
import InputVettingComment from '../../input-vetting-comment/components/input-vetting-comment.component';
import {
  CompanyProfileDataCommentInputContainer,
  CompanyProfileDataTGSelectorContainer,
} from '../components/company-profile.styles';
import { CompanyProfileDataField } from '../components/company-profile.types';
import { composeCountriesString } from '../helpers/company-profile.helpers';

export const DONT_SHOW_INDICATOR = 'do not show';

const generateLeadsProfile = (leads: { id: string; value: string }[], clientId: string, ctgId: string) => {
  const leadsProfile: { [key: string]: any } = {};
  const hasMoreThanOneLeads = leads.length > 1;

  (leads || []).forEach((lead, index) => {
    const title = `Person ${index + 1}`;
    leadsProfile[title] = {
      title: (
        <>
          {title}
          {hasMoreThanOneLeads ? (
            <IgnoreLead leadId={lead.id} clientId={clientId} companyTargetGroupId={ctgId} />
          ) : null}
        </>
      ),
      value: lead.value,
      key: lead.id,
    };
  });

  return leadsProfile;
};

export const companyProfileStrategy = (
  companyData: ICompanyTargetGroupDataForReview,
  handleTargetGroupUpdate: (
    name: string,
    value: {
      name: string;
      id: string;
    },
  ) => void,
  clientId: string,
) => {
  return {
    [CompanyProfileDataField.LEAD_OWNER]: {
      title: CompanyProfileDataField.LEAD_OWNER,
      value: companyData.campaignOwner.name,
      key: `1_${CompanyProfileDataField.LEAD_OWNER}`,
    },
    [CompanyProfileDataField.COMPANY_NAME]: {
      title: CompanyProfileDataField.COMPANY_NAME,
      value: (
        <div>
          <Link key={'key'} url={companyData.website} title={companyData.companyName} />
          <a href={companyData.companyLinkedIn || '#'} target="_blank" rel="noreferrer">
            <img
              style={{
                marginLeft: '3px',
                width: '17px',
                height: '17px',
                backgroundColor: '#FFFFFF',
                borderRadius: '3px',
              }}
              src={`data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 30 30">
      <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"></path>
  </svg>`}
              alt="no content"
            />
          </a>
        </div>
      ),
      key: `12_${CompanyProfileDataField.COMPANY_NAME}`,
    },
    [CompanyProfileDataField.COMPANY_DESCRIPTION]: {
      title: CompanyProfileDataField.COMPANY_DESCRIPTION,
      value: companyData.shortDescription || DONT_SHOW_INDICATOR,
      key: `10_${CompanyProfileDataField.COMPANY_DESCRIPTION}`,
    },
    [CompanyProfileDataField.TARGET_GROUP]: {
      title: CompanyProfileDataField.TARGET_GROUP,
      value: (
        <CompanyProfileDataTGSelectorContainer>
          <ChangeCompanyTargetGroup
            className="update-target-group-on-review"
            label={''}
            name="targetGroup"
            values={{ targetGroup: { id: companyData.targetGroupId, name: companyData.targetGroupName } }}
            disabled={false}
            handleChange={handleTargetGroupUpdate}
            ifHasSequenceAfter={companyData.emailSendStatuses.length}
          />
        </CompanyProfileDataTGSelectorContainer>
      ),
      key: `9_${CompanyProfileDataField.TARGET_GROUP}`,
    },
    [CompanyProfileDataField.HEADCOUNT]: {
      title: CompanyProfileDataField.HEADCOUNT,
      value: companyData.headcount,
      key: `7_${CompanyProfileDataField.HEADCOUNT}`,
    },
    [CompanyProfileDataField.COUNTRIES]: {
      title: CompanyProfileDataField.COUNTRIES,
      value: composeCountriesString(companyData.countries),
      key: `8_${CompanyProfileDataField.COUNTRIES}`,
    },
    ...generateLeadsProfile(companyData.leads, clientId, companyData.id),
    [CompanyProfileDataField.ACQUIRER]: {
      title: CompanyProfileDataField.ACQUIRER,
      value: companyData.acquirer || DONT_SHOW_INDICATOR,
      key: `16_${CompanyProfileDataField.ACQUIRER}`,
    },
    [CompanyProfileDataField.BE_BACKER]: {
      title: CompanyProfileDataField.BE_BACKER,
      value: companyData.PEbacker || DONT_SHOW_INDICATOR,
      key: `17_${CompanyProfileDataField.BE_BACKER}`,
    },
    [CompanyProfileDataField.VC_BACKER]: {
      title: CompanyProfileDataField.VC_BACKER,
      value: companyData.VCbacker || DONT_SHOW_INDICATOR,
      key: `18_${CompanyProfileDataField.VC_BACKER}`,
    },
    [CompanyProfileDataField.ACQUIRED_COMPANIES]: {
      title: CompanyProfileDataField.ACQUIRED_COMPANIES,
      value: companyData.acquiredCompanies || DONT_SHOW_INDICATOR,
      key: `19_${CompanyProfileDataField.ACQUIRED_COMPANIES}`,
    },
    [CompanyProfileDataField.EBITDA]: {
      title: CompanyProfileDataField.EBITDA,
      value: companyData.EBITDA || DONT_SHOW_INDICATOR,
      key: `14_${CompanyProfileDataField.EBITDA}`,
    },
    [CompanyProfileDataField.REVENUE]: {
      title: CompanyProfileDataField.REVENUE,
      value: companyData.revenue || DONT_SHOW_INDICATOR,
      key: `15_${CompanyProfileDataField.REVENUE}`,
    },
    [CompanyProfileDataField.EBITDA_MARGIN]: {
      title: CompanyProfileDataField.EBITDA_MARGIN,
      value: companyData.EBITDAMargin || DONT_SHOW_INDICATOR,
      key: `13_${CompanyProfileDataField.EBITDA_MARGIN}`,
    },
    [CompanyProfileDataField.OTHER_INFO]: {
      title: CompanyProfileDataField.OTHER_INFO,
      value: companyData.otherInfo || DONT_SHOW_INDICATOR,
      key: `11_${CompanyProfileDataField.OTHER_INFO}`,
    },
    [CompanyProfileDataField.COMMENT]: {
      title: CompanyProfileDataField.COMMENT,
      value: (
        <CompanyProfileDataCommentInputContainer>
          <InputVettingComment companyTargetGroupId={companyData.id} comment={companyData.comment} />
        </CompanyProfileDataCommentInputContainer>
      ),
      key: `11_${CompanyProfileDataField.COMMENT}`,
    },
  };
};
