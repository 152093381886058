/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import Table from '../../../shared/components/table/table.component';

import { columnNameToColumnHeaderCellConfig, rowPropertyNames } from '../domain/kpi-weekly-table.constants';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { getTargetGroupsWeeklyKPIs } from '../../../entities/target-group/store/target-group.slice';
import { targetGroupsWeeklyKPIsSelector } from '../../../entities/target-group/store/target-group.selectors';
import { userStateSelector } from '../../../entities/user/store/user.selectors';

export const specificTableCellFormatsConfig = {};

function KPIWeeklyTable() {
  const dispatch = useAppDispatch();

  const { selectedClients } = useAppSelector(userStateSelector);

  const weeklyKPIs = useAppSelector(targetGroupsWeeklyKPIsSelector);

  useEffect(() => {
    const clientIds = selectedClients.map((e) => e.id);
    dispatch(getTargetGroupsWeeklyKPIs({ clientIds }));
  }, [selectedClients, dispatch]);

  return (
    <Stack
      direction="column"
      width="100%"
      height="100%"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      sx={{ background: 'white', borderRadius: '10px', marginTop: '8px' }}
    >
      <Table
        rowPropertyNames={rowPropertyNames()}
        rows={weeklyKPIs?.items ?? []}
        columnNameToColumnHeaderCellConfig={columnNameToColumnHeaderCellConfig()}
        specificTableCellFormatsConfig={specificTableCellFormatsConfig}
      />
    </Stack>
  );
}

export default KPIWeeklyTable;
