export const columnHeadersNames = [
  'Company Name',
  'Website',
  'Target Group',
  'Person',
  'LinkedIn',
  'Job Title',
  'Status',
];

export const rowPropertyNames = [
  'companyName',
  'website',
  'targetGroupName',
  'person',
  'linkedIn',
  'jobTitle',
  'status',
];


