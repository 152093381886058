/* eslint-disable prefer-destructuring */
/* eslint-disable react/require-default-props */
import React from 'react';
import { List, Stack } from '@mui/material';
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';

import { currentCompanySelector } from '../../../entities/company/store/company.selectors';
import { companyNewsEventsSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { formatDateToDDMMYYYY } from '../../../shared/helpers/base.helpers';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import { NewsListItem, NewsListItemIcon, NewsListItemText } from './fetch-company-news.styles';

import NoNewsFetchedScreen from './no-news-fetched-screen/no-news-fetched-screen.component';
import FetchCompanyNewsLayout from './fetch-company-news-layout/fetch-company-news-layout.component';
import { ICompanyNewsEventsWithData } from '../../../entities/company-target-group/domain/company-news-events.types';
import { IFetchCompanyNewsProps } from './fetch-company-news.types';

function FetchCompanyNews({ listPaddingTop = '20px', listWidth = '50%', hasCompanyName = true }: IFetchCompanyNewsProps = {}) {
  const companyNewsEvents = useAppSelector(companyNewsEventsSelector).data?.item?.companyNewsEvents;
  const { name } = useAppSelector(currentCompanySelector);
  const companyName = hasCompanyName ? name : '';

  const isCompanyNewsEventsError = companyNewsEvents && 'error' in companyNewsEvents;
  const isEmptyCompanyNews = companyNewsEvents && 'data' in companyNewsEvents && companyNewsEvents.data.length === 0;

  if (isCompanyNewsEventsError)
    return (
      <FetchCompanyNewsLayout companyName={companyName}>
        <NoNewsFetchedScreen message={companyNewsEvents?.error.message} />
      </FetchCompanyNewsLayout>
    );

  if (isEmptyCompanyNews || companyNewsEvents === null)
    return (
      <FetchCompanyNewsLayout companyName={companyName}>
        <NoNewsFetchedScreen message="No Events were found for this search criteria." />
      </FetchCompanyNewsLayout>
    );

  return (
    <FetchCompanyNewsLayout listWidth={listWidth} companyName={companyName}>
      <Stack>
        <List sx={{ paddingTop: listPaddingTop }}>
          {(companyNewsEvents as ICompanyNewsEventsWithData)?.data.map((item) => (
            <NewsListItem key={item.id}>
              <NewsListItemIcon>
                <FiberManualRecordIcon />
              </NewsListItemIcon>
              <NewsListItemText
                primary={
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <span className="article-name">{`${item.title}`}</span>
                    <span className="article-date">{` (${formatDateToDDMMYYYY(item.articlePublishedAt)})`}</span>
                  </a>
                }
              />
            </NewsListItem>
          ))}
        </List>
      </Stack>
    </FetchCompanyNewsLayout>
  );
}

export default FetchCompanyNews;
