import { ComponentType } from 'react';

export enum PipelineStageName {
  TARGET_DEFINITION = 'Target Definition',
  GTM_PLAN = 'GTM Plan',
  TARGET_UPLOAD = 'Target Upload',
  TARGET_VETTING = 'Target Vetting',
  TARGET_RESEARCH = 'Target Research',
  REVIEW_EMAILS = 'Review Emails',
}

export interface IStage {
  stageName: PipelineStageName;
  navigateTo: string;
  currentRoute: string;
  key: string;
  indicator?: ComponentType<any>;
}

export interface IPipelineNavigationStrategy {
  [key: string]: IStage;
}
