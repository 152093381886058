import { placeholderRegex } from './highlighted-textarea.constants';

export const findPlaceholders = (text: string) => {
  const placeholders = [];
  let match;
  let offset = 0;

  // eslint-disable-next-line no-cond-assign
  while ((match = placeholderRegex.exec(text)) !== null) {
    const originalStart = match.index;

    const placeholderText = match[1];
    const adjustedStart = originalStart - offset;
    const adjustedEnd = adjustedStart + placeholderText.length;

    placeholders.push({ start: adjustedStart, end: adjustedEnd, placeholderText });

    offset += match[0].length - placeholderText.length;
  }

  return placeholders;
};

export function replaceNewLinesWithBr(text: string)  {
  return text.replace(/\n/g, '<br>');
};