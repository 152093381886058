import { styled } from '@mui/material/styles';
import Container, { ContainerProps } from '@mui/material/Container';
import Stack, { StackProps } from '@mui/material/Stack';

export const FinalEmailReviewWrapper = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '50%',
  height: '100%',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const ReviewEmailsContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  maxHeight: '100%',
  overflow: 'auto',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const EmailSequencesContainer = styled(Stack)<StackProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '42px',
}));
