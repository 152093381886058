import Typography, { TypographyProps } from '@mui/material/Typography';
import Container, { ContainerProps } from '@mui/material/Container';
import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const RightSectionHeaderContainer = styled(Container, {
  shouldForwardProp: (props) => props !== 'isListEntityChosen' && props !== 'hasEntityName',
})<ContainerProps & { isListEntityChosen: boolean; hasEntityName: boolean }>(
  ({ isListEntityChosen, hasEntityName, theme }) => ({
    width: '100%',
    height: isListEntityChosen && hasEntityName ? '85px' : '42.5px',
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: isListEntityChosen ? '22px' : 0,

    [theme.breakpoints.up('xs')]: {
      paddingBottom: isListEntityChosen ? '22px' : 0,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  }),
);

export const SectionHeaderName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1.5rem',
  lineHeight: '34px',
  fontWeight: 600,
  color: theme.palette.common.white,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.75rem',
  },
}));

export const ListEntityName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 600,
  color: theme.palette.text.orange,
  margin: '12px 0 0',

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.25rem',
  },
}));

export const SectionHeaderControls = styled(Stack)<StackProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '14px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
