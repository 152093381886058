import { targetDefinitionFormStrategy } from '../domain/target-definition-form.strategy';
import { FormFieldValueType } from '../components/target-definition-form.types';
import { ITargetGroup } from '../../../entities/target-group/domain/target-group.types';
import { ITargetDefinitionFormInputProps } from '../components/target-definition-form-input/target-definition-form-input.types';
import { defaultCampaignOwner, defaultEmail } from '../../../entities/target-group/store/target-group.slice';

export function formCurrentTargetDefinitionFormState(targetGroupFormData: ITargetGroup) {
  const {
    aiPrompt,
    maxHeadcount,
    minHeadcount,
    locations,
    industryFilters,
    goodFitCompaniesExamples,
    badFitCompaniesExamples,
    apolloSearches,
  } = targetGroupFormData;
  const targetDefinitionFormState = {
    aiPrompt,
    maxHeadcount,
    minHeadcount,
    locations,
    industryFilters,
    goodFitCompaniesExamples,
    badFitCompaniesExamples,
    apolloSearches,
    name: targetGroupFormData.name ?? '',
    description: targetGroupFormData.description ?? '',
    headcount: targetGroupFormData.headcount ?? '',
    revenue: targetGroupFormData.revenue ?? '',
    regions: targetGroupFormData.regions ?? '',
    keywords: targetGroupFormData.keywords ?? [],
    exampleCompanies: targetGroupFormData.exampleCompanies ?? '',
    relevantPastDeals: targetGroupFormData.relevantPastDeals ?? '',
    relevantAcquirers: targetGroupFormData.relevantAcquirers ?? '',
    otherRelevantInformation: targetGroupFormData.otherRelevantInformation ?? '',
    campaignOwner: targetGroupFormData.campaignOwner ?? defaultCampaignOwner,
    emailSequences: targetGroupFormData.emailSequences ?? [defaultEmail, defaultEmail],
  };
  return targetDefinitionFormState;
}

export function formInputsConfig<T>(
  values: ITargetDefinitionFormInputProps<T>['values'],
  handleChange: ITargetDefinitionFormInputProps<T>['handleChange'],
  handleMultipleInputChange: (name: string, multipleValues: string[]) => void,
) {
  const targetDefinitionsFormFieldsEntries = Object.entries(targetDefinitionFormStrategy);
  const inputsConfigArray = targetDefinitionsFormFieldsEntries.map(([, fieldConfig]) => {
    const { inputValueType, ...restFieldConfig } = fieldConfig;
    const strategy = {
      [FormFieldValueType.SOLO]: { ...restFieldConfig, handleChange, values },
      [FormFieldValueType.MULTIPLE]: { ...restFieldConfig, handleChange: handleMultipleInputChange, values },
    };
    return strategy[inputValueType];
  });
  return inputsConfigArray;
}
