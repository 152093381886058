import {
  CompaniesForResearchFilterCriterion,
  CompaniesForResearchFilterCriterionName,
} from '../../../entities/company-target-group/domain/company-target-group.types';

export const dropdownOptions = [
  {
    id: CompaniesForResearchFilterCriterion.TO_RESEARCH,
    name: CompaniesForResearchFilterCriterionName.TO_RESEARCH,
    values: {
      id: CompaniesForResearchFilterCriterion.TO_RESEARCH,
      name: CompaniesForResearchFilterCriterionName.TO_RESEARCH,
      value: CompaniesForResearchFilterCriterion.TO_RESEARCH,
    },
  },
  {
    id: CompaniesForResearchFilterCriterion.RESEARCHED,
    name: CompaniesForResearchFilterCriterionName.RESEARCHED,
    values: {
      id: CompaniesForResearchFilterCriterion.RESEARCHED,
      name: CompaniesForResearchFilterCriterionName.RESEARCHED,
      value: CompaniesForResearchFilterCriterion.RESEARCHED,
    },
  },
  {
    id: CompaniesForResearchFilterCriterion.NOT_A_FIT,
    name: CompaniesForResearchFilterCriterionName.NOT_A_FIT,
    values: {
      id: CompaniesForResearchFilterCriterion.NOT_A_FIT,
      name: CompaniesForResearchFilterCriterionName.NOT_A_FIT,
      value: CompaniesForResearchFilterCriterion.NOT_A_FIT,
    },
  },
  {
    id: CompaniesForResearchFilterCriterion.EMAIL_VALIDATION,
    name: CompaniesForResearchFilterCriterionName.EMAIL_VALIDATION,
    values: {
      id: CompaniesForResearchFilterCriterion.EMAIL_VALIDATION,
      name: CompaniesForResearchFilterCriterionName.EMAIL_VALIDATION,
      value: CompaniesForResearchFilterCriterion.EMAIL_VALIDATION,
    },
  },
  {
    id: CompaniesForResearchFilterCriterion.INVALID_EMAIL,
    name: CompaniesForResearchFilterCriterionName.INVALID_EMAIL,
    values: {
      id: CompaniesForResearchFilterCriterion.INVALID_EMAIL,
      name: CompaniesForResearchFilterCriterionName.INVALID_EMAIL,
      value: CompaniesForResearchFilterCriterion.INVALID_EMAIL,
    },
  },
  {
    id: CompaniesForResearchFilterCriterion.RESEARCH_PAUSED,
    name: CompaniesForResearchFilterCriterionName.RESEARCH_PAUSED,
    values: {
      id: CompaniesForResearchFilterCriterion.RESEARCH_PAUSED,
      name: CompaniesForResearchFilterCriterionName.RESEARCH_PAUSED,
      value: CompaniesForResearchFilterCriterion.RESEARCH_PAUSED,
    },
  },
];
