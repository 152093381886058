import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { enableMapSet } from 'immer'; // eslint-disable-line import/no-extraneous-dependencies

import { persistor, store } from './app/store/store';
import { router } from './app/routing/router';
import { ErrorBoundaryDecorator } from './app/decorators/error-boundary/error-boundary.decorator';
import { MuiThemeDecorator } from './app/decorators/mui-theme/mui-theme.decorator';

import reportWebVitals from './app/reportWebVitals';
import CustomErrorForErrorBoundary from './shared/components/error/custom-error-for-error-boundary.component';
import './app/styles/global/css/globals.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <ErrorBoundaryDecorator
    FallbackComponent={CustomErrorForErrorBoundary}
    onError={(error, errorInfo) => {
      console.log({ error });
      console.log({ errorInfo });
    }}
  >
    <StyledEngineProvider injectFirst>
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <MuiThemeDecorator>
            <RouterProvider router={router} />
          </MuiThemeDecorator>
        </Provider>
      </PersistGate>
    </StyledEngineProvider>
  </ErrorBoundaryDecorator>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
enableMapSet();
