import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import { authenticationReducer } from '../../features/authentication/store/authentication.slice';
import { companyReducer } from '../../entities/company/store/company.slice';
import { companyTargetGroupReducer } from '../../entities/company-target-group/store/company-target-group.slice';
import { targetGroupReducer } from '../../entities/target-group/store/target-group.slice';
import { userReducer } from '../../entities/user/store/user.slice';
import { setUpInterceptor } from '../services/axios-interceptors.config';

const store = configureStore({
  reducer: {
    user: userReducer,
    authentication: authenticationReducer,
    targetGroup: targetGroupReducer,
    company: companyReducer,
    companyTargetGroup: companyTargetGroupReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
});

setUpInterceptor(store);

const persistor = persistStore(store);

export { store, persistor };
