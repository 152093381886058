import { ITargetGroup } from '../../../entities/target-group/domain/target-group.types';
import { ITargetGroupCardProps } from '../components/target-group-card/target-group-card.types';
import { PipelineStageName } from '../../../modules/navigation/components/pipeline-navigation/pipeline-navigation.types';

export function formTargetGroupsEntityData(
  targetGroupsEntityDataFromServer: ITargetGroup[],
  currentTargetGroupId: string,
): (ITargetGroupCardProps & { key: string })[] {
  return targetGroupsEntityDataFromServer?.map(({ id: targetGroupId, name: targetGroupName }) => {
    return {
      targetGroupName,
      targetGroupId,
      isActiveEntity: currentTargetGroupId === targetGroupId,
      key: `${targetGroupId}_${targetGroupName}`,
    };
  });
}

export function checkIsModificationAllowed(stageName: PipelineStageName) {
  const allowedForStages = [PipelineStageName.TARGET_DEFINITION];
  const isAllowed = allowedForStages.includes(stageName);
  return isAllowed;
}
