import { FeedbackUiType, LoaderSubtype, StatusFeedbackUiPropsUnion } from '../feedback-ui.types';

export const BaseLoaderPropsDefaults = {
  STATUS: (status: boolean) => status,
  TITLE: '',
  SIZE: 50,
  UI_SUBTYPE: LoaderSubtype.CONTAINER_LOADER,
  CLASSNAME: 'status-loader',
};

export const statusFeedbackUiPropsWithDefaults = {
  [FeedbackUiType.LOADER]: (props: StatusFeedbackUiPropsUnion) => {
    const propsWithDefaults = {
      status: props.status || BaseLoaderPropsDefaults.STATUS(false),
      title: props.title || BaseLoaderPropsDefaults.TITLE,
      size: props.size || BaseLoaderPropsDefaults.SIZE,
      uiSubtype: props.uiSubtype || BaseLoaderPropsDefaults.UI_SUBTYPE,
      className: props.className || BaseLoaderPropsDefaults.CLASSNAME,
    };
    return propsWithDefaults;
  },
};
