import React from 'react';

import { GtmPlanLeftSectionContainer } from './gtm-plan-left-section.styles';
import { PipelineStageName } from '../../navigation/components/pipeline-navigation/pipeline-navigation.types';

import FetchTargetGroupsList from '../../../features/fetch-target-groups-list/components/fetch-target-groups-list.component';

function GtmPlanLeftSection() {
  return (
    <GtmPlanLeftSectionContainer>
      <FetchTargetGroupsList stageName={PipelineStageName.GTM_PLAN} />
    </GtmPlanLeftSectionContainer>
  );
}

export default GtmPlanLeftSection;
