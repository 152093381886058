import Chip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const MuiChip = styled(Chip, {
  shouldForwardProp: (props) => props !== 'isDimmed',
})<ChipProps & { isDimmed: boolean }>(({ isDimmed, theme }) => ({
  backgroundColor: isDimmed ? theme.palette.background.lightPeachDimmed : theme.palette.background.lightPeach,
  color: isDimmed ? theme.palette.text.maroonDimmed : theme.palette.text.maroon,
  height: '16px',
  minWidth: '24px',
  width: 'auto',
  fontSize: '0.54rem',
  fontWeight: 600,
  borderRadius: '10px',
  padding: '1px 5px',

  '& .MuiChip-label': { padding: 0 },

  [theme.breakpoints.up('xl')]: {
    fontSize: '0.625rem',
  },
}));
