import { MutableRefObject } from 'react';
import { ThunkDispatch } from '@reduxjs/toolkit';

import {
  resetCompanyTargetGroupAnalyticsSorting,
  setCompanyTargetGroupAnalyticsSorting,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { CompanyTargetGroupsAnalyticsSortBy } from '../../../entities/company-target-group/domain/company-target-group.types';

function sortAnalyticsTableDataBy(
  sortByColumn: CompanyTargetGroupsAnalyticsSortBy,
  dispatch: ThunkDispatch<any, any, any>,
) {
  dispatch(setCompanyTargetGroupAnalyticsSorting(sortByColumn));
}

function toggleColumnSorting(
  isSorted: boolean,
  prevSortBy: MutableRefObject<CompanyTargetGroupsAnalyticsSortBy | undefined>,
  sortByColumn: CompanyTargetGroupsAnalyticsSortBy,
  dispatch: ThunkDispatch<any, any, any>,
) {
  if (isSorted) {
    dispatch(resetCompanyTargetGroupAnalyticsSorting());
    if (prevSortBy.current === sortByColumn) return;
  }
  sortAnalyticsTableDataBy(sortByColumn, dispatch);
}

function checkIsColumnCurrentlySorted(
  currentIsSorted: boolean,
  currentSortBy: CompanyTargetGroupsAnalyticsSortBy,
  columnSortBy: CompanyTargetGroupsAnalyticsSortBy,
) {
  return (currentIsSorted && currentSortBy) === columnSortBy;
}

export function formColumnNameToColumnHeaderCellConfig(
  isSorted: boolean,
  sortBy: CompanyTargetGroupsAnalyticsSortBy,
  prevSortBy: MutableRefObject<CompanyTargetGroupsAnalyticsSortBy | undefined>,
  dispatch: ThunkDispatch<any, any, any>,
) {
  const columnNameToColumnHeaderCellConfig = {
    'Company Name': {
      isSortable: true,
      isCurrentlySorted: checkIsColumnCurrentlySorted(
        isSorted,
        sortBy,
        CompanyTargetGroupsAnalyticsSortBy.COMPANY_NAME,
      ),
      handleClick: () => {
        toggleColumnSorting(isSorted, prevSortBy, CompanyTargetGroupsAnalyticsSortBy.COMPANY_NAME, dispatch);
      },
    },
    Website: {
      isSortable: false,
      isCurrentlySorted: false,
      handleClick: () => {},
    },
    'Target Group': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
    Person: {
      isSortable: true,
      isCurrentlySorted: checkIsColumnCurrentlySorted(isSorted, sortBy, CompanyTargetGroupsAnalyticsSortBy.PERSON),
      handleClick: () => {
        toggleColumnSorting(isSorted, prevSortBy, CompanyTargetGroupsAnalyticsSortBy.PERSON, dispatch);
      },
    },
    LinkedIn: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
    'Job Title': { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
    Status: { isSortable: false, isCurrentlySorted: false, handleClick: () => {} },
  };
  return columnNameToColumnHeaderCellConfig;
}
