import React, { PropsWithChildren, ReactNode } from 'react';
import { FormControl } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import { IBaseDropdownLightProps } from './base-dropdown-light.types';
import { Label, menuStyles, MuiSelect, MuiSelectItem } from './base-dropdown-light.styles';

function BaseDropdownLight<T extends { name: string; id: string; values: Partial<{ id: string; name: string }> }>({
  options,
  selectedOption,
  defaultOption,
  handleOptionChange,
  onMenuOpen,
  onMenuClose,
  label,
  className,
  isMenuOpen,
  disabled,
}: PropsWithChildren<IBaseDropdownLightProps<T>>) {
  return (
    <FormControl fullWidth className={className}>
      {label && <Label>{label}</Label>}
      <MuiSelect
        value={selectedOption}
        onChange={handleOptionChange}
        renderValue={(selected) => selected as ReactNode}
        className="custom-select"
        IconComponent={UnfoldMoreIcon}
        onOpen={onMenuOpen}
        onClose={onMenuClose}
        MenuProps={menuStyles}
        isMenuOpen={isMenuOpen ?? false}
        disabled={disabled ?? false}
        variant="outlined"
      >
        {defaultOption && (
          <MuiSelectItem
            isCurrentlySelectedItem={selectedOption === defaultOption.name}
            value={JSON.stringify(defaultOption)}
          >
            {defaultOption.name}
          </MuiSelectItem>
        )}
        {options.map((option: T) => {
          const { name, id, values } = option;
          const key = `${id}_${name}`;
          const isCurrentlySelectedItem = selectedOption === name;
          return (
            <MuiSelectItem key={key} value={JSON.stringify(values)} isCurrentlySelectedItem={isCurrentlySelectedItem}>
              {name}
            </MuiSelectItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
}

export default BaseDropdownLight;
