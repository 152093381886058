/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { useParams } from 'react-router-dom';

import {
  campaignsSelector,
  currentTargetGroupSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import {
  defaultCampaignOwner,
  fetchCampaigns,
  setCampaignOwner,
  updateTargetGroup,
} from '../../../entities/target-group/store/target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import BaseDropdownLight from '../../../shared/components/dropdown/base-dropdown-light/base-dropdown-light.component';

const DEFAULT_OPTION = defaultCampaignOwner.name;
const DROPDOWN_LABEL = 'Campaign owner';

function SelectCampaignOwner() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const campaignOwner = useAppSelector(currentTargetGroupSelector).campaignOwner || {};

  const targetGroupFormData = useAppSelector(currentTargetGroupSelector);
  const targetGroupId = targetGroupFormData.id;

  const campaigns = useAppSelector(campaignsSelector);

  const dropdownOptions = (campaigns || []).map((campaign) => {
    return { ...campaign, values: campaign };
  });

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  function handleOptionChange(event: SelectChangeEvent<unknown>) {
    const campaignOwnerObjectToParse = event.target.value as string;
    const { id, name } = JSON.parse(campaignOwnerObjectToParse);
    const body = { ...targetGroupFormData, campaignOwner: { id, name } };
    dispatch(setCampaignOwner(name));
    dispatch(updateTargetGroup({ targetGroupId, body }));
  }

  useEffect(() => {
    dispatch(fetchCampaigns(clientId));
  }, [clientId, dispatch]);

  return (
    <BaseDropdownLight
      selectedOption={campaignOwner?.name || DEFAULT_OPTION}
      options={Array.isArray(campaigns) ? dropdownOptions : []}
      handleOptionChange={handleOptionChange}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      label={DROPDOWN_LABEL}
      isMenuOpen={isMenuOpen}
    />
  );
}

export default SelectCampaignOwner;
