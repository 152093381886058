import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material';

export const LeadActionsContainer = styled('div')<TypographyProps>(({ theme }) => ({
  display: 'inline-flex',
  paddingLeft: '20px',
  gap: '20px',
  '> .form-button': {
    width: '150px',
    minWidth: '100px',
    height: '40px',

    '& .MuiCircularProgress-root': { color: theme.palette.background.orange },
  },
}));
