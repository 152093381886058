import {
  ValidationObject,
  ValidatorStrategy,
  ValidationResultsObject,
} from '../../domain/types/validation.types';
import { ValidationResultsArray } from './validator.types';

export function validateObject<Obj, Strategy>(
  object: ValidationObject<Obj>,
  strategy: ValidatorStrategy<Strategy>,
): ValidationResultsObject {
  const objectEntries = Object.entries(object);
  const validationResultsArray: ValidationResultsArray = objectEntries.map(([key, value]) => [
    key,
    strategy?.[key as keyof typeof strategy]?.(value),
  ]);
  const validationResultsWithErrorsArray = validationResultsArray.filter(
    ([, value]) => value?.hasError === true,
  );
  const validationResultsWithErrorsObject = Object.fromEntries(validationResultsWithErrorsArray);
  return validationResultsWithErrorsObject;
}
