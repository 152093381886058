import { MutableRefObject } from 'react';
import { ThunkDispatch } from '@reduxjs/toolkit';

import { resetNewsEventsSorting, setNewsEventsSorting } from '../../../entities/company/store/company.slice';
import { NewsEventsSortBy } from '../../../entities/company/domain/company.types';

function sortNewsEventsTableDataBy(sortByColumn: NewsEventsSortBy, dispatch: ThunkDispatch<any, any, any>) {
  dispatch(setNewsEventsSorting(sortByColumn));
}

function toggleColumnSorting(
  isSorted: boolean,
  prevSortBy: MutableRefObject<NewsEventsSortBy | undefined>,
  sortByColumn: NewsEventsSortBy,
  dispatch: ThunkDispatch<any, any, any>,
) {
  if (isSorted) {
    dispatch(resetNewsEventsSorting());
    if (prevSortBy.current === sortByColumn) return;
  }
  sortNewsEventsTableDataBy(sortByColumn, dispatch);
}

function checkIsColumnCurrentlySorted(
  currentIsSorted: boolean,
  currentSortBy: NewsEventsSortBy,
  columnSortBy: NewsEventsSortBy,
) {
  return (currentIsSorted && currentSortBy) === columnSortBy;
}

export function formColumnNameToColumnHeaderCellConfig(
  isSorted: boolean,
  sortBy: NewsEventsSortBy,
  prevSortBy: MutableRefObject<NewsEventsSortBy | undefined>,
  dispatch: ThunkDispatch<any, any, any>,
) {
  const columnNameToColumnHeaderCellConfig = {
    'Company Name': {
      isSortable: true,
      isCurrentlySorted: checkIsColumnCurrentlySorted(isSorted, sortBy, NewsEventsSortBy.CompanyName),
      handleClick: () => {
        toggleColumnSorting(isSorted, prevSortBy, NewsEventsSortBy.CompanyName, dispatch);
      },
    },
    'Article Title': {
      isSortable: false,
      isCurrentlySorted: false,
      handleClick: () => {},
    },
    Date: {
      isSortable: true,
      isCurrentlySorted: checkIsColumnCurrentlySorted(isSorted, sortBy, NewsEventsSortBy.Date),
      handleClick: () => {
        toggleColumnSorting(isSorted, prevSortBy, NewsEventsSortBy.Date, dispatch);
      },
    },
  };
  return columnNameToColumnHeaderCellConfig;
}
