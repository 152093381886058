import { styled } from '@mui/material/styles';

import InputWithLabelTooltip from '../../../../shared/components/input/input-with-label-tooltip/input-with-label-tooltip.component';

export const AuthBaseInput = styled(InputWithLabelTooltip)(({ theme }) => ({
  '&.auth-input': {
    '& input:focus': {
      border: theme.palette.border.focusedAuthInput,
    },
  },
}));
