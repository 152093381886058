import { ILoginState, ILogoutState } from '../domain/types/authentication.types';
import { RootState } from '../../../app/store/utils/redux.types';

export function isAuthedSelector(state: RootState): boolean {
  return state.authentication.isAuthenticated;
}

export function loginResultSelector(state: RootState): ILoginState {
  return state.authentication.login;
}

export function logoutResultSelector(state: RootState): ILogoutState {
  return state.authentication.logout;
}
