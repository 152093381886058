import React from 'react';

import { NoTargetGroupChosenScreenContainer, NoTargetGroupChosenMessage } from './no-target-group-chosen-screen.styles';

function NoTargetGroupChosenScreen() {
  return (
    <NoTargetGroupChosenScreenContainer>
      <NoTargetGroupChosenMessage>
        Create a new target group by clicking on the button "Add a new target group" or select an existing one on the
        left to modify it
      </NoTargetGroupChosenMessage>
    </NoTargetGroupChosenScreenContainer>
  );
}

export default NoTargetGroupChosenScreen;
