import React, { Fragment } from 'react';

import {
  currentTargetGroupParamsSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import { PipelineStageName } from '../../navigation/components/pipeline-navigation/pipeline-navigation.types';
import { GtmPlanRightSectionFormContainer } from './gtm-plan-right-section.styles';

import GtmPlanForm from '../../gtm-plan-form/components/gtm-plan-form.component';
import NoTargetGroupChosenScreen from './no-target-group-chosen-screen/no-target-group-chosen-screen.component';
import RightSectionHeader from '../../../app/layouts/layout-components/right-section-header/right-section-header.component';
import RightSectionMain from '../../../app/layouts/layout-components/right-section-main/right-section-main.component';
import Propagate from '../../../features/propagate/components/propagate.component';

const SECTION_HEADER = PipelineStageName.GTM_PLAN;

function GtmPlanRightSection() {
  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const isTargetGroupChosen = Boolean(targetGroupId);

  const { isLoading: isTargetGroupLoading } = useAppSelector(targetGroupThunksSelector).getTargetGroupById;

  return (
    <Fragment>
      <RightSectionHeader
        headerName={SECTION_HEADER}
        isListEntityChosen={isTargetGroupChosen}
        hasControls
        controls={<Propagate />}
      />
      <GtmPlanRightSectionFormContainer isTargetGroupChosen={isTargetGroupChosen}>
        <RightSectionMain
          isListEntityChosen={isTargetGroupChosen}
          isDataLoading={isTargetGroupLoading}
          content={<GtmPlanForm />}
          noContent={<NoTargetGroupChosenScreen />}
        />
      </GtmPlanRightSectionFormContainer>
    </Fragment>
  );
}

export default GtmPlanRightSection;
