import React, { PropsWithChildren } from 'react';
import ValidationErrorsHelper from '../../helper/validation-errors-helper/validation-errors-helper.component';
import { getValueFromObject } from '../../../helpers/base.helpers';
import HighlightedInput from './highlighted-input.component';
import { IBaseTextareaProps } from '../base-textarea/base-textarea.types';
import { MuiTextarea, TextareaContainer } from '../base-textarea/base-textarea.styles';

function HighlightedTextArea<T extends { [key: string]: any }>({
  id,
  className,
  label,
  name,
  handleChange,
  values,
  errors,
  maxRows,
  disabled,
}: PropsWithChildren<IBaseTextareaProps<T>>) {
  const value = getValueFromObject(values, name);

  return (
    <TextareaContainer>
      <MuiTextarea
        multiline
        id={id}
        label={label ?? null}
        name={name}
        className={className}
        maxRows={maxRows ?? undefined}
        hasError={errors?.[name]?.hasError ?? false}
        disabled={disabled ?? false}
        InputProps={{
          inputComponent: HighlightedInput as any,
          inputProps: { value, onInput: handleChange, name },
        }}
      />
      {errors?.[name] && (
        <ValidationErrorsHelper errors={errors} name={name} id={id} className="textarea-validation-helper" />
      )}
    </TextareaContainer>
  );
}

export default HighlightedTextArea;
