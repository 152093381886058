import Select, { SelectProps } from '@mui/material/Select';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

export const menuStyles = {
  sx: {
    '& .MuiPaper-root': {
      boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.25)',
      backgroundColor: '#FFFFFF',
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
      paddingTop: 0,
      '> ul': { padding: 0 },
    },
  },
};

export const MuiSelect = styled(Select)<SelectProps>(({ theme }) => ({
  width: '250px',
  height: '100%',
  color: theme.palette.background.orange,
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontWeight: 500,

  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },

  '& .MuiSelect-select': { padding: '4px 0' },

  '& .MuiSelect-icon': {
    fontSize: '1.1rem',
    fontWeight: 500,
    color: theme.palette.background.darkGrey,
  },

  [theme.breakpoints.down('lg')]: {
    width: '200px',
  },
}));

export const MuiSelectItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontWeight: 500,
  color: theme.palette.text.darkGrey,
  transition: theme.transitions.create(['color', 'background-color']),

  '&.Mui-selected': {
    backgroundColor: theme.palette.background.orangeDim,
    color: theme.palette.background.orange,
    transition: theme.transitions.create(['color', 'background-color']),
  },
}));
