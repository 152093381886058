import React, { PropsWithChildren, useState, useRef } from 'react';
import { EditorState, Editor, ContentBlock, CompositeDecorator, convertFromHTML, ContentState } from 'draft-js';
import { EditorContainer, HighlightedPlaceholder } from './highlighted-textarea.styles';
import { findPlaceholders, replaceNewLinesWithBr } from './highlighted-textarea.helpers';
import { IHighlightedInputProps, IPlaceholderMark } from './highlighted-textarea.types';

function getDecorator(placeholders: IPlaceholderMark[]) {
  const findSpanPlaceholder = (contentBlock: ContentBlock, callback: any) => {
    placeholders.forEach(({ start, end }) => {
      callback(start, end);
    });
  };

  return new CompositeDecorator([
    {
      strategy: findSpanPlaceholder,
      component: ({ children }) => (
        <HighlightedPlaceholder className="highlighted-placeholder">{children}</HighlightedPlaceholder>
      ),
    },
  ]);
}

function initializeEditorState(value: string) {
  const placeholders = findPlaceholders(value);
  const valueWithBr = replaceNewLinesWithBr(value);
  const blocksFromHTML = convertFromHTML(valueWithBr);
  const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  return EditorState.createWithContent(contentState, getDecorator(placeholders));
}

function HighlightedInput({ value, onInput, name, disabled }: PropsWithChildren<IHighlightedInputProps>) {
  const isModified = useRef(false);
  const initialState = useRef(initializeEditorState(value));
  const [editorState, setEditorState] = useState(initialState.current);

  const handleEditorChange = (newEditorState: EditorState) => {
    const currentContent = newEditorState.getCurrentContent();
    const newPlainText = currentContent.getPlainText();

    if (newPlainText !== editorState.getCurrentContent().getPlainText()) {
      if (!isModified.current) {
        isModified.current = true;
        const selection = newEditorState.getSelection();
        const newContentWithSelection = EditorState.createWithContent(currentContent);
        setEditorState(EditorState.acceptSelection(newContentWithSelection, selection));
      } else {
        setEditorState(newEditorState);
      }
      const syntheticEvent = {
        target: {
          name,
          value: newPlainText,
        },
      };

      onInput(syntheticEvent);
    } else {
      setEditorState(newEditorState);
    }
  };

  return (
    <EditorContainer>
      <Editor editorState={editorState} onChange={handleEditorChange} readOnly={disabled} />
    </EditorContainer>
  );
}

export default HighlightedInput;
