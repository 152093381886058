import React from 'react';

import { ISingleSectionScreenLayoutProps } from './single-sections-screen.types';
import { SectionContainer, SingleSectionScreenLayoutContainer } from './single-sections-screen.styles';

function SingleSectionScreenLayout({ children }: ISingleSectionScreenLayoutProps) {
  return (
    <SingleSectionScreenLayoutContainer>
      <SectionContainer>{children}</SectionContainer>
    </SingleSectionScreenLayoutContainer>
  );
}

export default SingleSectionScreenLayout;
