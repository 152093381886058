import React from 'react';
import { Stack } from '@mui/material';

import {
  AnalyticsAndReportingContainer,
  AnalyticsAndReportingHeader,
  AnalyticsAndReportingWrapper,
} from './analytics-and-reporting.styles';

import SelectTargetGroupWithDropdown from '../../features/select-target-group-with-dropdown/components/select-target-group-with-dropdown.component';
import AnalyticsIndicatorsDashboard from '../../modules/analytics-indicators-dashboard/components/analytics-indicators-dashboard.component';
import CompanyTargetGroupAnalyticsTable from '../../modules/company-target-group-analytics-table/components/company-target-group-analytics-table.component';
import DownloadFile from '../../features/download-file/components/download-file.component';

function AnalyticsAndReporting() {
  return (
    <AnalyticsAndReportingWrapper>
      <AnalyticsAndReportingContainer>
        <Stack
          direction="row"
          width="100%"
          minHeight="5%"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="30px"
        >
          <Stack width="90%" direction="row" justifyContent="flex-start" alignItems="center">
            <AnalyticsAndReportingHeader variant="h4">Analytics & Reporting</AnalyticsAndReportingHeader>
            <div style={{ width: '186px' }}>
              <SelectTargetGroupWithDropdown />
            </div>
          </Stack>
          <DownloadFile />
        </Stack>
        <AnalyticsIndicatorsDashboard />
        <CompanyTargetGroupAnalyticsTable />
      </AnalyticsAndReportingContainer>
    </AnalyticsAndReportingWrapper>
  );
}

export default AnalyticsAndReporting;
