import React from 'react';

import { useAppDispatch } from '../../../app/store/utils/redux.hooks';

import Checkbox from '../../../shared/components/checkbox/checkbox.component';
import StatusUncheckedIcon from '../../../shared/components/svg-icon/status-unchecked.component';
import StatusCheckedBlackIcon from '../../../shared/components/svg-icon/status-checked-black.component';
import { DontTickContactContainer } from '../../tick-dont-contact/components/tick-dont-contact.styles';
import { updateTargetGroup } from '../../../entities/target-group/store/target-group.slice';

function TickSelectionToggle({
  targetGroupId,
  selectionJobToggle,
}: {
  targetGroupId: string;
  selectionJobToggle: string;
}) {
  const isChecked = selectionJobToggle === 'on';

  const dispatch = useAppDispatch();

  function onCheckboxClick() {
    const toggle = isChecked ? 'off' : 'on';
    dispatch(updateTargetGroup({ targetGroupId, body: { selectionJobToggle: toggle } }));
  }

  return (
    <DontTickContactContainer>
      <Checkbox
        key={targetGroupId}
        isChecked={isChecked}
        className="dont-contact-checkbox"
        uncheckedIcon={<StatusUncheckedIcon />}
        checkedIcon={<StatusCheckedBlackIcon />}
        onCheckboxClick={onCheckboxClick}
      />
    </DontTickContactContainer>
  );
}

export default TickSelectionToggle;
