/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment } from 'react';

import { errorConfigDefaults, infoConfigDefaults, statusConfigDefaults } from './feedback-ui.constants';
import { formErrorMessagesArray } from '../../helpers/base.helpers';
import { IFeedbackUiDecoratorProps } from './feedback-ui.types';
import { InfoOrErrorFeedbackUiType, StatusFeedbackUiType } from '../../components/feedback-ui/feedback-ui.types';

import ErrorFeedbackUiKit from '../../components/feedback-ui/error/error-feedback-ui-kit.component';
import InfoFeedbackUiKit from '../../components/feedback-ui/info/info-feedback-ui-kit.component';
import StatusFeedbackUiKit from '../../components/feedback-ui/status/status-feedback-ui-kit.component';

function FeedbackUiDecorator({
  children,
  isChildrenVisible = false,
  statusConfig = statusConfigDefaults,
  errorConfig = errorConfigDefaults,
  infoConfig = infoConfigDefaults,
}: IFeedbackUiDecoratorProps) {
  const { isLoading, statusUiType, statusUiSubtype, statusTitle, size, className } = statusConfig;

  if ((isLoading && isChildrenVisible) || isLoading)
    return (
      <Fragment>
        <StatusFeedbackUiKit
          feedbackUiType={statusUiType as StatusFeedbackUiType}
          uiProps={{
            status: isLoading || false,
            uiSubtype: statusUiSubtype,
            title: statusTitle,
            size,
            className,
          }}
        />
        {isChildrenVisible && children}
      </Fragment>
    );

  if ((errorConfig.isError && isChildrenVisible) || errorConfig.isError)
    return (
      <Fragment>
        <ErrorFeedbackUiKit
          feedbackUiType={errorConfig.uiType as InfoOrErrorFeedbackUiType | undefined}
          uiProps={{
            isUiVisible: errorConfig.isError || false,
            uiContentType: errorConfig.uiContentType,
            title: errorConfig.title,
            message: formErrorMessagesArray(errorConfig.message), // array
            actionTitle: errorConfig.actionTitle,
            cancelTitle: errorConfig.cancelTitle,
            handleAction: errorConfig.handleAction,
            handleClose: errorConfig.handleClose,
            handleCancel: errorConfig.handleCancel,
          }}
        />
        {isChildrenVisible && children}
      </Fragment>
    );

  if ((infoConfig.isInfo && isChildrenVisible) || infoConfig.isInfo)
    return (
      <Fragment>
        <InfoFeedbackUiKit
          feedbackUiType={infoConfig.uiType as InfoOrErrorFeedbackUiType | undefined}
          uiProps={{
            isUiVisible: infoConfig.isInfo || false,
            uiContentType: infoConfig.uiContentType,
            title: infoConfig.title,
            message: infoConfig.message, // array
            actionTitle: infoConfig.actionTitle,
            cancelTitle: infoConfig.cancelTitle,
            handleAction: infoConfig.handleAction,
            handleClose: infoConfig.handleClose,
            handleCancel: infoConfig.handleCancel,
            isActionLoading: infoConfig.isActionLoading,
          }}
        />
        {isChildrenVisible && children}
      </Fragment>
    );

  return <Fragment>{children}</Fragment>;
}

export default FeedbackUiDecorator;
