import React from 'react';

import { NoCompanyChosenScreenContainer, NoCompanyChosenMessage } from './no-company-chosen-screen.styles';

function NoCompanyChosenScreen() {
  return (
    <NoCompanyChosenScreenContainer>
      <NoCompanyChosenMessage>Select a company on the left for final review</NoCompanyChosenMessage>
    </NoCompanyChosenScreenContainer>
  );
}

export default NoCompanyChosenScreen;
