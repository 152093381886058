import React from 'react';

import SingleSectionScreenLayout from '../../app/layouts/single-sections-screen/single-sections-screen.component';
import TargetVettingHeader from '../../modules/target-vetting-header/components/target-vetting-header.component';
import TargetVettingTable from '../../modules/target-vetting-table/components/target-vetting-table.component';

function TargetVetting() {
  return (
    <SingleSectionScreenLayout>
      <TargetVettingHeader />
      <TargetVettingTable />
    </SingleSectionScreenLayout>
  );
}

export default TargetVetting;
