import React from 'react';

import { NoRelevantCompaniesScreenContainer, NoRelevantCompaniesMessage } from './no-relevant-companies-screen.styles';

function NoRelevantCompaniesScreen() {
  return (
    <NoRelevantCompaniesScreenContainer>
      <NoRelevantCompaniesMessage>
        No relevant companies selected. You can configure the relevant companies tracking by clicking on the button
        "Track" above.
      </NoRelevantCompaniesMessage>
    </NoRelevantCompaniesScreenContainer>
  );
}

export default NoRelevantCompaniesScreen;
