import React, { Fragment } from 'react';

import {
  currentTargetGroupParamsSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import { PipelineStageName } from '../../navigation/components/pipeline-navigation/pipeline-navigation.types';
import {
  TargetUploadRightSectionContainer,
  TargetUploadRightSectionHeader,
} from './target-upload-right-section.styles';

import NoTargetGroupChosenScreen from './no-target-group-chosen-screen/no-target-group-chosen-screen.component';
import ImportCompanies from '../../../features/import-companies/components/import-companies.component';
import UploadedCompaniesTable from '../../uploaded-companies-table/components/uploaded-companies-table.component';
import RightSectionHeader from '../../../app/layouts/layout-components/right-section-header/right-section-header.component';
import RightSectionMain from '../../../app/layouts/layout-components/right-section-main/right-section-main.component';
import { UploadLeadsStrategies } from '../../../entities/company/domain/company.types';

const SECTION_HEADER = PipelineStageName.TARGET_UPLOAD;

function TargetUploadRightSection() {
  const { id: targetGroupId, name: targetGroupName } = useAppSelector(currentTargetGroupParamsSelector);
  const isTargetGroupChosen = Boolean(targetGroupId);

  const { isLoading: isTargetGroupLoading } = useAppSelector(targetGroupThunksSelector).getTargetGroupById;

  return (
    <Fragment>
      <TargetUploadRightSectionHeader isTargetGroupChosen={isTargetGroupChosen}>
        <RightSectionHeader
          isListEntityChosen={isTargetGroupChosen}
          headerName={SECTION_HEADER}
          listEntityName={targetGroupName}
          hasControls
          controls={
            <>
              <ImportCompanies title="Import on vetting" type="leads" strategy={UploadLeadsStrategies.onVetting} />
              <ImportCompanies title="Import on research" type="leads" strategy={UploadLeadsStrategies.onResearch} />
              <ImportCompanies title="Import on review" type="leads" strategy={UploadLeadsStrategies.onReview} />
            </>
          }
        />
      </TargetUploadRightSectionHeader>
      <TargetUploadRightSectionContainer isTargetGroupChosen={isTargetGroupChosen}>
        <RightSectionMain
          isListEntityChosen={isTargetGroupChosen}
          isDataLoading={isTargetGroupLoading}
          content={<UploadedCompaniesTable />}
          noContent={<NoTargetGroupChosenScreen />}
        />
      </TargetUploadRightSectionContainer>
    </Fragment>
  );
}

export default TargetUploadRightSection;
