import React, { useEffect, useRef } from 'react';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';

import {
  companiesForResearchSelector,
  companyForResearchSelector,
  companyTargetGroupThunksSelector,
  currentCompanyForResearchParamsSelector,
  currentResearchFilterCriterionSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import { formCompaniesForResearchEntityData } from '../helpers/fetch-companies-to-research-list.helper';
import {
  getCompaniesForResearchFilteredByCriterion,
  resetCompaniesForResearch,
  setCurrentCompanyForReviewParams,
  setMarkAsEmailValidation,
  setMarkAsInvalidEmail,
  setMarkAsNotAFit,
  setMarkAsResearched,
  setMarkAsToResearch,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { useInfiniteScroll } from '../../../shared/hooks/use-infinite-scroll.hook';
import { FetchCompaniesForResearchListContainer } from './fetch-companies-for-research-list.styles';

import CompanyToResearchCard from './company-for-research-card/company-for-research-card.component';
import SelectEntity from '../../select-entity/components/select-entity.component';
import { setCompanyCountFilter } from '../../../entities/target-group/store/target-group.slice';

function FetchCompaniesForResearchList() {
  const { clientId } = useParams();
  // const [isInit, setIsInit] = useState(true);

  const dispatch = useAppDispatch();

  const researchFilter = useAppSelector(currentResearchFilterCriterionSelector);
  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);

  const filterCriterion = researchFilter?.value;
  const prevResearchFilterCriterion = useRef(filterCriterion);
  const prevTargetGroupId = useRef(targetGroupId);

  const statuses = useAppSelector(companyTargetGroupThunksSelector).getCompaniesForResearchFilteredByCriterion;
  const { isLoading: areCompaniesForResearchLoading } = statuses;

  const companiesForResearch = useAppSelector(companiesForResearchSelector);
  const { id: currentCompanyTargetGroupId } = useAppSelector(currentCompanyForResearchParamsSelector);
  const companiesEntityData = formCompaniesForResearchEntityData(
    companiesForResearch?.items,
    currentCompanyTargetGroupId,
  );

  const currentCompanyForResearch = useAppSelector(companyForResearchSelector);
  const { isMarkedAsToResearch } = currentCompanyForResearch.markAsToResearch;
  const { isMarkedAsResearched } = currentCompanyForResearch.markAsResearched;
  const { isMarkedAsNotAFit } = currentCompanyForResearch.markAsNotAFit;
  const { isMarkedAsEmailValidation } = currentCompanyForResearch.markAsEmailValidation;
  const { isMarkedAsInvalidEmail } = currentCompanyForResearch.markAsInvalidEmail;

  const prevIsMarkedAsToResearch = useRef(isMarkedAsToResearch);
  const prevIsMarkedAsResearched = useRef(isMarkedAsResearched);
  const prevIsMarkedAsNotAFit = useRef(isMarkedAsNotAFit);
  const prevIsMarkedAsEmailValidation = useRef(isMarkedAsEmailValidation);
  const prevIsMarkedAsInvalidEmail = useRef(isMarkedAsInvalidEmail);

  const { observedElementRef, parentElementRef, skip, take, setSkip } = useInfiniteScroll<
    HTMLDivElement,
    HTMLDivElement
  >([
    filterCriterion,
    targetGroupId,
    isMarkedAsToResearch,
    isMarkedAsResearched,
    isMarkedAsNotAFit,
    isMarkedAsEmailValidation,
    isMarkedAsInvalidEmail,
  ]);

  useEffect(() => {
    dispatch(setCompanyCountFilter({ filter: filterCriterion }));
  }, [filterCriterion]);

  useEffect(() => {
    setSkip(-50);
    dispatch(resetCompaniesForResearch());
    prevResearchFilterCriterion.current = filterCriterion;
    prevTargetGroupId.current = targetGroupId;
  }, [filterCriterion, targetGroupId]);

  useEffect(() => {
    if (
      isMarkedAsToResearch ||
      isMarkedAsResearched ||
      isMarkedAsNotAFit ||
      isMarkedAsEmailValidation ||
      isMarkedAsInvalidEmail
    ) {
      // setSkip(-50);
      // dispatch(resetCompaniesForResearch());
      dispatch(setCurrentCompanyForReviewParams({ id: '', name: '' }));
      prevIsMarkedAsToResearch.current = isMarkedAsToResearch;
      prevIsMarkedAsResearched.current = isMarkedAsResearched;
      prevIsMarkedAsNotAFit.current = isMarkedAsNotAFit;
      prevIsMarkedAsEmailValidation.current = isMarkedAsEmailValidation;
      prevIsMarkedAsInvalidEmail.current = isMarkedAsInvalidEmail;
    }
  }, [
    isMarkedAsToResearch,
    isMarkedAsResearched,
    isMarkedAsNotAFit,
    isMarkedAsEmailValidation,
    isMarkedAsInvalidEmail,
  ]);

  useEffect(() => {
    if (skip >= 0)
      dispatch(getCompaniesForResearchFilteredByCriterion({ clientId, filterCriterion, targetGroupId, take, skip }));
    if (isMarkedAsToResearch) dispatch(setMarkAsToResearch({ isMarkedAsToResearch: false, message: '' }));
    if (isMarkedAsResearched) dispatch(setMarkAsResearched({ isMarkedAsResearched: false, message: '' }));
    if (isMarkedAsNotAFit) dispatch(setMarkAsNotAFit({ isMarkedAsNotAFit: false, message: '' }));
    if (isMarkedAsEmailValidation)
      dispatch(setMarkAsEmailValidation({ isMarkedAsEmailValidation: false, message: '' }));
    if (isMarkedAsInvalidEmail) dispatch(setMarkAsInvalidEmail({ isMarkedAsInvalidEmail: false, message: '' }));
  }, [
    clientId,
    skip,
    prevResearchFilterCriterion,
    prevTargetGroupId,
    prevIsMarkedAsToResearch,
    prevIsMarkedAsResearched,
    prevIsMarkedAsNotAFit,
    prevIsMarkedAsEmailValidation,
    prevIsMarkedAsInvalidEmail,
    dispatch,
  ]);

  return (
    <FetchCompaniesForResearchListContainer ref={parentElementRef}>
      <SelectEntity
        entityData={companiesEntityData ?? []}
        isLoading={areCompaniesForResearchLoading}
        className="select-company-for-research"
        entityCard={CompanyToResearchCard}
      />
      <Stack className="observed-element" ref={observedElementRef} height="1px" />
    </FetchCompaniesForResearchListContainer>
  );
}

export default FetchCompaniesForResearchList;
