import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  companiesToResearchCountSelector,
  companyForResearchSelector,
  companyTargetGroupThunksSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import { countCompaniesToResearch } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { useIsRouteMatched } from '../../../shared/hooks/use-is-route-matched.hook';
import { Routes } from '../../../shared/domain/constants/routing.constants';

import IndicatorChip from '../../../shared/components/chip/indicator-chip/indicator-chip.component';

function CountCompaniesToResearch() {
  const dispatch = useAppDispatch();

  const { isRouteMatched } = useIsRouteMatched();

  const { clientId } = useParams();

  const { isSuccess: areCompaniesVetted } = useAppSelector(companyTargetGroupThunksSelector).vetCompaniesForPeriod;

  const currentCompanyForResearch = useAppSelector(companyForResearchSelector);
  const { isMarkedAsToResearch } = currentCompanyForResearch.markAsToResearch;
  const { isMarkedAsResearched } = currentCompanyForResearch.markAsResearched;
  const { isMarkedAsNotAFit } = currentCompanyForResearch.markAsNotAFit;

  const count = useAppSelector(companiesToResearchCountSelector);

  const isDimmed = !isRouteMatched(Routes.TARGET_RESEARCH);

  useEffect(() => {
    dispatch(countCompaniesToResearch({ clientId }));
  }, []);

  useEffect(() => {
    if (areCompaniesVetted || isMarkedAsToResearch || isMarkedAsResearched || isMarkedAsNotAFit)
      dispatch(countCompaniesToResearch({ clientId }));
  }, [dispatch, areCompaniesVetted, isMarkedAsToResearch, isMarkedAsResearched, isMarkedAsNotAFit]);

  return <IndicatorChip label={count} isDimmed={isDimmed} />;
}

export default CountCompaniesToResearch;
