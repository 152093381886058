import React, { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

// import { getAllTargetGroupsByClient } from '../../../entities/target-group/store/target-group.slice';
import { targetGroupsListSelector } from '../../../entities/target-group/store/target-group.selectors';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import { ChangeCompanyTargetGroupContainer } from './change-company-target-group.styles';
import { IChangeCompanyTargetGroupProps } from './change-company-target-group.types';

import BaseDropdownLight from '../../../shared/components/dropdown/base-dropdown-light/base-dropdown-light.component';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';

function ChangeCompanyTargetGroup({
  className,
  label,
  name,
  values,
  disabled,
  handleChange,
  ifHasSequenceAfter,
}: IChangeCompanyTargetGroupProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPromptedToSelect, setIsPromptedToSelect] = useState(false);
  const [itemPromptedToSelect, setItemPromptedToSelect] = useState();

  async function handleAction() {
    if (itemPromptedToSelect) handleChange(name, itemPromptedToSelect);
    setIsPromptedToSelect(false);
    setItemPromptedToSelect(undefined);
  }

  function handleClose() {
    setIsPromptedToSelect(false);
    setItemPromptedToSelect(undefined);
  }

  function handleCancel() {
    setIsPromptedToSelect(false);
    setItemPromptedToSelect(undefined);
  }

  const promptModalConfig = {
    isInfo: isPromptedToSelect,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Are you sure you want to update Target Group? It will regenerate email sequences.',
  };

  let targetGroupsList = useAppSelector(targetGroupsListSelector)?.items || [];
  if (ifHasSequenceAfter)
    targetGroupsList = targetGroupsList.filter((tg) => tg.emailSequences.length >= ifHasSequenceAfter);

  const currentSelectedOption = values[name].name || '';
  const dropdownOptions = targetGroupsList.map((targetGroup) => {
    const targetGroupId = targetGroup.id;
    const targetGroupName = targetGroup.name;
    return { id: targetGroupId, name: targetGroupName, values: { id: targetGroupId, name: targetGroupName } };
  });

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  function handleOptionChange(event: SelectChangeEvent<unknown>) {
    event.stopPropagation();
    const chosenTargetGroup = event.target.value as string;
    const parsedTargetGroup = JSON.parse(chosenTargetGroup);
    setItemPromptedToSelect(parsedTargetGroup);
    setIsPromptedToSelect(true);
  }

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <ChangeCompanyTargetGroupContainer className={className}>
        <BaseDropdownLight
          selectedOption={currentSelectedOption}
          options={dropdownOptions || []}
          handleOptionChange={handleOptionChange}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          label={label}
          className="change-company-target-group-dropdown"
          isMenuOpen={isMenuOpen}
          disabled={disabled}
        />
      </ChangeCompanyTargetGroupContainer>
    </FeedbackUiDecorator>
  );
}

export default ChangeCompanyTargetGroup;
