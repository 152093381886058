export enum TargetResearchFormField {
  COMPANY_NAME = 'Company Name',
  WEBSITE = 'Website',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  EMAIL = 'Email',
  JOB_TITLE = 'Job Title',
  PERSON_LINKEDIN = 'Person LinkedIn',
  COMPANY_LINKEDIN = 'Company LinkedIn',
  HEADCOUNT = 'Headcount',
  TARGET_GROUP = 'Target Group',
  SHORT_DESCRIPTION = 'Short Description',
  COUNTRIES = 'Country(ies)',
  OTHER_INFO = 'Other info',
  ACQUIRED = 'Acquired',
  BACKED_BY_PE = 'Backed by PE',
  BACKED_BY_VC = 'Backed by VC',
  MADE_ACQUISITIONS = 'Made acquisition(s)',
  REVENUE = 'Revenue',
  EBITDA = 'EBITDA',
  ACQUIRER = 'Acquirer',
  PE_BACKER = 'PE Backer',
  VC_BACKER = 'VC Backer',
  ACQUIRED_COMPANIES = 'Acquired companies',
  COMMENT = 'Comment',
}

export enum FormFieldValueType {
  COMMON_FIELD = 'common',
  SPECIFIC_FIELD = 'specific',
}
