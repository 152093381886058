export const rowPropertyNames = () => [
  'companyName',
  'targetGroupName',
  'week',
  'processed',
  'classified',
  'remained',
  'weeksRemaining', // no idea
  'vetted',
  'vettedAccepted',
  'researchedTotal',
  'researchedFit',
  'researchedRecycledTotal',
  'researchedRecycledFit',
  'reviewedTotal',
  'reviewedFit',
  'reviewedRecycledTotal',
  'reviewedRecycledFit',
  'emailsSent',
  'repliedTotal',
];

const DEFAULT_CELL_CONFIG = { isSortable: false, isCurrentlySorted: false, handleClick: () => {} };

export const columnNameToColumnHeaderCellConfig = () => ({
  Client: DEFAULT_CELL_CONFIG,
  TargetGroup: DEFAULT_CELL_CONFIG,
  Week: DEFAULT_CELL_CONFIG,
  'Looked At': DEFAULT_CELL_CONFIG,
  Selected: DEFAULT_CELL_CONFIG,
  Remained: DEFAULT_CELL_CONFIG,
  'Weeks Remaining': DEFAULT_CELL_CONFIG, // no idea
  Vetted: DEFAULT_CELL_CONFIG,
  'Vetted Accepted': DEFAULT_CELL_CONFIG,
  Researched: DEFAULT_CELL_CONFIG,
  'Researched Fit': DEFAULT_CELL_CONFIG,
  'Researched Recycled': DEFAULT_CELL_CONFIG,
  'Researched Recycled Fit': DEFAULT_CELL_CONFIG,
  Reviewed: DEFAULT_CELL_CONFIG,
  'Reviewed Fit': DEFAULT_CELL_CONFIG,
  'Reviewed Recycled': DEFAULT_CELL_CONFIG,
  'Reviewed Recycled Fit': DEFAULT_CELL_CONFIG,
  'Emails Sent': DEFAULT_CELL_CONFIG,
  Replied: DEFAULT_CELL_CONFIG,
});
