import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';

export const CreateSequenceWrapper = styled(Stack)<StackProps>(({ theme }) => ({
  width: '25%',
  height: '65px',
  bottom: 0,
  left: 0,
  display: 'flex',
  marginTop: '1rem',
  borderBottomRightRadius: '16px',
  borderBottomLeftRadius: '16px',

  '> .create-target-group-button': {
    width: '100%',
  },

  [theme.breakpoints.up('xl')]: {
    height: '110px',
    // padding: '0 36px',
    '> button': { height: '70px' },
  },
}));
