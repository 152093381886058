import React from 'react';
import List from '@mui/material/List';
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';

import { formatDateToDDMMYYYY } from '../../../../shared/helpers/base.helpers';
import {
  Domain,
  DomainNewsContainer,
  MuiDivider,
  NewsListItem,
  NewsListItemIcon,
  NewsListItemText,
} from './relevant-companies-news.styles';
import { ICompanyNewsEventsWithData } from '../../../../entities/company-target-group/domain/company-news-events.types';

export interface IRelevantCompaniesNewsWithData {
  companyDomain: string;
  data: ICompanyNewsEventsWithData;
}

function RelevantCompaniesNewsWithData({ companyDomain, data }: IRelevantCompaniesNewsWithData) {
  return (
    <DomainNewsContainer>
      <Domain>{companyDomain}</Domain>
      <MuiDivider />
      <List>
        {data?.data.map((item) => (
          <NewsListItem key={item.id}>
            <NewsListItemIcon>
              <FiberManualRecordIcon />
            </NewsListItemIcon>
            <NewsListItemText
              primary={
                <a href={item.url} target="_blank" rel="noreferrer">
                  <span className="article-name">{`${item.title}`}</span>
                  <span className="article-date">{` (${formatDateToDDMMYYYY(item.articlePublishedAt)})`}</span>
                </a>
              }
            />
          </NewsListItem>
        ))}
      </List>
    </DomainNewsContainer>
  );
}

export default RelevantCompaniesNewsWithData;
