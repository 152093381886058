import { Stack } from '@mui/material';

import {
  FetchRelevantCompaniesNewsWrapper,
  FetchRelevantCompaniesNewsHeader,
  CompanyName,
  SectionHeaderName,
  FetchRelevantCompaniesNewsListContainer,
} from './fetch-relevant-companies-news-layout.styles';

import ConfigureRelevantCompaniesTracking from '../../../configure-relevant-companies-tracking/configure-relevant-companies-tracking.component';
import FeedbackUiDecorator from '../../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { relevantCompaniesNewsEventsSelector } from '../../../../entities/company-target-group/store/company-target-group.selectors';

function FetchRelevantCompaniesNewsLayout({ children }: any) {
  const { isLoading } = useAppSelector(relevantCompaniesNewsEventsSelector);

  return (
    <FetchRelevantCompaniesNewsWrapper>
      <FetchRelevantCompaniesNewsHeader>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center" position="relative">
          <CompanyName>Other Companies to Track</CompanyName>
          <ConfigureRelevantCompaniesTracking />
        </Stack>
        <SectionHeaderName>News Events</SectionHeaderName>
      </FetchRelevantCompaniesNewsHeader>
      <FetchRelevantCompaniesNewsListContainer>
        <FeedbackUiDecorator statusConfig={{ isLoading, size: 40 }}>{children}</FeedbackUiDecorator>
      </FetchRelevantCompaniesNewsListContainer>
    </FetchRelevantCompaniesNewsWrapper>
  );
}

export default FetchRelevantCompaniesNewsLayout;
