/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment } from 'react';

import { IRightSectionMain } from './right-section-main.types';

import FeedbackUiDecorator from '../../../../shared/decorators/feedback-ui/feedback-ui.decorator';

function RightSectionMain({ isListEntityChosen, isDataLoading, content, noContent }: IRightSectionMain) {
  return isListEntityChosen ? (
    <FeedbackUiDecorator
      statusConfig={{
        isLoading: isDataLoading,
        size: 40,
        className: 'right-section-main-loader',
      }}
    >
      {content}
    </FeedbackUiDecorator>
  ) : (
    <Fragment>{noContent}</Fragment>
  );
}

export default RightSectionMain;
