import React, { useState, useEffect } from 'react';

import { AcceptedFileContainer, FileName, FileProgress, SuccessLabel } from './accepted-file.styles';
import { IAcceptedFileProps } from './accepted-file.types';

function AcceptedFile({ fileName, successMessage, fileUploadPercent }: IAcceptedFileProps) {
  const [isSuccessLabelVisible, setIsSuccessLabelVisible] = useState(false);

  useEffect(() => {
    setIsSuccessLabelVisible(Boolean(successMessage));
  }, [successMessage]);

  return (
    <AcceptedFileContainer>
      <FileName>{fileName}</FileName>
      <SuccessLabel label={successMessage} isVisible={isSuccessLabelVisible} />
      <FileProgress variant="determinate" value={fileUploadPercent} />
    </AcceptedFileContainer>
  );
}

export default AcceptedFile;
