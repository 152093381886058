/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment, ReactNode } from 'react';

import SingleSectionScreenLayout from '../layouts/single-sections-screen-with-message/single-sections-screen-with-message.component';
import { PipelineStageName } from '../../modules/navigation/components/pipeline-navigation/pipeline-navigation.types';
import { useAppSelector } from '../store/utils/redux.hooks';
import { currentClientSelector } from '../../entities/user/store/user.selectors';

function RequirePipelineStageAccessRoute({
  children,
  stageName,
}: {
  children: ReactNode;
  stageName: PipelineStageName;
}) {
  const currentClient = useAppSelector(currentClientSelector);
  const skippedStages = currentClient?.pipelineConfig?.skipStages || [];
  const isAllowed = !skippedStages.includes(stageName);

  return isAllowed ? (
    <Fragment>{children}</Fragment>
  ) : (
    <SingleSectionScreenLayout message={`${stageName} stage is disabled for this Client!`} />
  );
}

export default RequirePipelineStageAccessRoute;
