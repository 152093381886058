import React, { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { useAppDispatch } from '../../../app/store/utils/redux.hooks';
import { IInputVettingCommentProps } from './input-vetting-comment.types';
import { InputVettingCommentContainer, VettingCommentBaseInput } from './input-vetting-comment.styles';

import { useDebounce } from '../../../shared/hooks/use-debounce.hook';
import { updateVettingComment } from '../../../entities/company-target-group/store/company-target-group.slice';

function InputVettingComment({ companyTargetGroupId, comment: initComment }: IInputVettingCommentProps) {
  const dispatch = useAppDispatch();
  const [isInit, setIsInit] = useState(true);
  const [comment, setComment] = useState(initComment ?? String());

  const debouncedComment = useDebounce(comment, 850);

  async function handleCompanyCommentChange(event: SelectChangeEvent<any>) {
    const chosenCompanyComment = event.target.value;
    setComment(chosenCompanyComment);
  }

  useEffect(() => {
    if (!isInit) dispatch(updateVettingComment({ companyTargetGroupId, comment: debouncedComment }));
    setIsInit(false);
  }, [debouncedComment, dispatch]);

  return (
    <InputVettingCommentContainer>
      <VettingCommentBaseInput
        className="vetting-comment-input"
        id={companyTargetGroupId}
        name={'comment'}
        handleChange={handleCompanyCommentChange}
        values={{ comment }}
      />
    </InputVettingCommentContainer>
  );
}

export default InputVettingComment;
