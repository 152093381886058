import React from 'react';

import SingleSectionScreenLayout from '../../app/layouts/single-sections-screen/single-sections-screen.component';
import KPISelectionTable from '../../modules/kpi-selection-table/components/kpi-selection-table.component';
import KPISelectionHeader from '../../modules/kpi-selection-header/components/kpi-selector-header.component';

function KPISelection() {
  const SECTION_HEADER = 'Selection Dashboard';

  return (
    <SingleSectionScreenLayout>
      <KPISelectionHeader header={SECTION_HEADER} />
      <KPISelectionTable />
    </SingleSectionScreenLayout>
  );
}

export default KPISelection;
