import React from 'react';

import LoginWithEmail from '../../../../features/authentication/components/login-with-email/login-with-email.component';
import AuthFormSection from '../auth-form-section/auth-form-section.component';

function LoginForm() {
  return (
    <AuthFormSection headerTitle="Sign in to your account">
      <LoginWithEmail />
    </AuthFormSection>
  );
}

export default LoginForm;
