import React, { useState } from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { useAppDispatch } from '../../../app/store/utils/redux.hooks';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
import { DeleteLeadButton } from './delete-lead.styles';

import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { deleteLead } from '../../../entities/company-target-group/store/company-target-group.slice';

function DeleteLead({ leadId }: { leadId: string }) {
  const [isPromptedToDelete, setIsPromptedToDelete] = useState(false);

  const dispatch = useAppDispatch();

  function handleDeleteButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsPromptedToDelete(true);
  }

  async function handleAction() {
    dispatch(deleteLead({ leadId }));
    setIsPromptedToDelete(false);
  }

  function handleClose() {
    setIsPromptedToDelete(false);
  }

  function handleCancel() {
    setIsPromptedToDelete(false);
  }

  const promptModalConfig = {
    isInfo: isPromptedToDelete,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Remove this Lead?',
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <DeleteLeadButton onClick={handleDeleteButtonClick}>
        <DeleteOutlinedIcon />
      </DeleteLeadButton>
    </FeedbackUiDecorator>
  );
}

export default DeleteLead;
