import React, { Fragment } from 'react';

import TargetResearchHeader from './target-research-header/target-research-header.component';
import TargetResearchMain from './target-research-main/target-research-main.component';

function TargetResearchRightSection() {
  return (
    <Fragment>
      <TargetResearchHeader />
      <TargetResearchMain />
    </Fragment>
  );
}

export default TargetResearchRightSection;
