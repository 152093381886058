/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from 'react';

import { MuiCheckbox } from './checkbox.styles';
import { ICheckboxProps } from './checkbox.types';

export default function Checkbox({
  isChecked,
  className,
  uncheckedIcon,
  checkedIcon,
  onCheckboxClick,
}: ICheckboxProps) {
  const [checked, setChecked] = React.useState(isChecked);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newChecked = event.target.checked;
    setChecked(newChecked);
    onCheckboxClick && onCheckboxClick();
  }

  React.useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <MuiCheckbox
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      className={className}
      icon={uncheckedIcon}
      checkedIcon={checkedIcon}
    />
  );
}
