import React, { PropsWithChildren } from 'react';

import { Form, FormHeader } from './base-form-wrapper.styles';
import { IBaseFormWrapperProps } from './base-form-wrapper.types';

function BaseFormWrapper({ formHeader, isHeaderShown, className, children }: PropsWithChildren<IBaseFormWrapperProps>) {
  return (
    <Form action="#" noValidate className={className}>
      {isHeaderShown ? <FormHeader variant="h4">{formHeader}</FormHeader> : null}
      {children}
    </Form>
  );
}

export default BaseFormWrapper;
