import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { companyTargetGroupThunksSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import {
  resetVetCompaniesForPeriodThunk,
  vetCompaniesForPeriod,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import { getAllTargetGroupsByClient } from '../../../entities/target-group/store/target-group.slice';

// const MODAL_MESSAGE_FOR_ALL = 'Vet all companies?';
const MODAL_MESSAGE_FOR_SPECIFIC = (tgName: string) => `Vet companies for ${tgName}?`;
const BUTTON_TITLE = 'Mark companies as vetted';

function VetCompaniesForPeriod() {
  const [isPromptedToVet, setIsPromptedToVet] = useState(false);

  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const { isLoading: isVetting } = useAppSelector(companyTargetGroupThunksSelector).vetCompaniesForPeriod;
  const companyTargetGroupThunks = useAppSelector(companyTargetGroupThunksSelector);
  const { isLoading: areCompaniesForVettingLoading } = companyTargetGroupThunks.getCompaniesForVetting;
  const isActionLoading = isVetting || areCompaniesForVettingLoading;

  // this part is outdated for now
  // const currentWeek = useAppSelector(currentWeekOfCompanyCreationSelector);
  // const { startDate, endDate } = currentWeek.values;
  // const selectedWeekIntervalName = currentWeek.name;
  // const areAllIntervalsSelected = selectedWeekIntervalName === defaultWeekInterval;

  const { id: targetGroupId, name: tgName } = useAppSelector(currentTargetGroupParamsSelector);

  function handleButtonClick() {
    setIsPromptedToVet(true);
  }

  async function handleAction() {
    await dispatch(vetCompaniesForPeriod({ clientId, targetGroupId }))
      .unwrap()
      .then(() => {
        dispatch(resetVetCompaniesForPeriodThunk());
        dispatch(getAllTargetGroupsByClient({ clientId, filter: 'vetting' }));
        setIsPromptedToVet(false);
      });
  }

  function handleClose() {
    setIsPromptedToVet(false);
  }

  function handleCancel() {
    setIsPromptedToVet(false);
  }

  const promptModalConfig = {
    isInfo: isPromptedToVet,
    handleAction,
    handleClose,
    handleCancel,
    message: MODAL_MESSAGE_FOR_SPECIFIC(tgName),
    isActionLoading,
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <TransparentRectButtonWithOrangeBorder
        title={BUTTON_TITLE}
        handleClick={handleButtonClick}
        className="mark-as-vetted-button"
        isLoading={false}
      />
    </FeedbackUiDecorator>
  );
}

export default VetCompaniesForPeriod;
