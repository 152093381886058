import { BaseAlertPropsDefaults } from '../../components/feedback-ui/error/error-feedback-ui-kit.constants';
import { BaseLoaderPropsDefaults } from '../../components/feedback-ui/status/status-feedback-ui-kit.constants';
import { FeedbackUiType } from '../../components/feedback-ui/feedback-ui.types';
import { BaseModalPropsDefaults } from '../../components/feedback-ui/info/info-feedback-ui-kit.constants';

export const statusConfigDefaults = {
  isLoading: BaseLoaderPropsDefaults.STATUS(false),
  statusUiType: FeedbackUiType.LOADER,
  statusUiSubtype: BaseLoaderPropsDefaults.UI_SUBTYPE,
  statusTitle: BaseLoaderPropsDefaults.TITLE,
  size: BaseLoaderPropsDefaults.SIZE,
};

export const errorConfigDefaults = {
  isError: BaseAlertPropsDefaults.IS_UI_VISIBLE,
  uiType: BaseAlertPropsDefaults.UI_TYPE,
  uiContentType: BaseAlertPropsDefaults.UI_CONTENT_TYPE,
  title: BaseAlertPropsDefaults.TITLE,
  message: BaseAlertPropsDefaults.MESSAGE,
  actionTitle: BaseAlertPropsDefaults.ACTION_TITLE,
  cancelTitle: BaseAlertPropsDefaults.CANCEL_TITLE,
  handleAction: BaseAlertPropsDefaults.HANDLE_ACTION,
  handleClose: BaseAlertPropsDefaults.HANDLE_CLOSE,
  handleCancel: BaseAlertPropsDefaults.HANDLE_CANCEL,
  isActionLoading: BaseAlertPropsDefaults.IS_ACTION_LOADING,
};

export const infoConfigDefaults = {
  isInfo: BaseModalPropsDefaults.IS_UI_VISIBLE,
  uiType: BaseModalPropsDefaults.UI_TYPE,
  uiContentType: BaseModalPropsDefaults.UI_CONTENT_TYPE,
  title: BaseModalPropsDefaults.TITLE,
  message: BaseModalPropsDefaults.MESSAGE,
  actionTitle: BaseModalPropsDefaults.ACTION_TITLE,
  cancelTitle: BaseModalPropsDefaults.CANCEL_TITLE,
  handleAction: BaseModalPropsDefaults.HANDLE_ACTION,
  handleClose: BaseModalPropsDefaults.HANDLE_CLOSE,
  handleCancel: BaseModalPropsDefaults.HANDLE_CANCEL,
  isActionLoading: BaseModalPropsDefaults.IS_ACTION_LOADING,
};
