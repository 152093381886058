import React, { PropsWithChildren, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';

import { menuStyles, MuiSelect, MuiSelectItem } from './dropdown-with-checkboxes-and-chips.styles';
import { IDropdownWithCheckboxesAndChipsProps } from './dropdown-with-checkboxes-and-chips.types';

import Checkbox from '../../checkbox/checkbox.component';
import StatusChip from '../../chip/status-chip/status-chip.component';

function DropdownWithCheckboxesAndChips({
  options,
  selectedOptions,
  handleOptionsChange,
  handleChipDelete,
  className,
  uncheckedIcon,
  checkedIcon,
}: PropsWithChildren<IDropdownWithCheckboxesAndChipsProps>) {
  return (
    <MuiSelect
      value={selectedOptions ?? []}
      onChange={handleOptionsChange}
      variant="standard"
      renderValue={(selected: string[] | any) => {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.8 }}>
            {selected.map((value: string) => (
              <StatusChip key={value} label={value} onDeleteCallback={handleChipDelete} className="dropdown-chip" />
            ))}
          </Box>
        ) as ReactNode;
      }}
      className={className}
      MenuProps={menuStyles}
      multiple
    >
      {options.map((option) => {
        return (
          <MuiSelectItem key={option} value={option}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <Checkbox
                isChecked={selectedOptions?.includes(option)}
                className="dropdown-checkbox"
                uncheckedIcon={uncheckedIcon}
                checkedIcon={checkedIcon}
              />
              {option}
            </Stack>
          </MuiSelectItem>
        );
      })}
    </MuiSelect>
  );
}

export default DropdownWithCheckboxesAndChips;
