/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';

import { ConfigWithComponent, IMapperWithVariableComponentsProps } from './mapper-with-variable-components.types';
import { ComponentsContainer, ComponentsRowContainer } from './mapper-with-variable-components.styles';
import { useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { userSelector } from '../../../../entities/user/store/user.selectors';
import ProtectDecorator from '../../../../app/decorators/protect/protect.decorator';
import { UserRole } from '../../../../entities/user/domain/user.types';

function MapperWithVariableComponents({
  config = [],
  componentsPerRow = 1,
}: Partial<IMapperWithVariableComponentsProps> = {}) {
  const allRoles = Object.values(UserRole) as UserRole[];
  const getAllowedRoles = (exclude?: UserRole[]) => (exclude ? allRoles.filter((e) => !exclude.includes(e)) : allRoles);
  const { role } = useAppSelector(userSelector);

  const getIsReadOnly = (forRoles?: UserRole[]) => role && forRoles && forRoles.includes(role);

  const rows: ConfigWithComponent[] = [];
  let currentRow: ConfigWithComponent = [];

  config.forEach((item, index) => {
    currentRow.push(item);
    if (currentRow.length === componentsPerRow || index === config.length - 1) {
      rows.push(currentRow);
      currentRow = [];
    }
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>
      {componentsPerRow === 1
        ? config.map(({ component: Component, isHiddenFor, isReadonlyFor, ...componentProps }) =>
            ProtectDecorator({
              allowedRoles: getAllowedRoles(isHiddenFor),
              children: <Component {...{ ...componentProps, disabled: getIsReadOnly(isReadonlyFor) }} />,
            }),
          )
        : rows.map((row, rowIndex) => (
            <ComponentsRowContainer key={rowIndex}>
              {row.map(({ component: Component, isHiddenFor, isReadonlyFor, ...componentProps }, colIndex) => (
                <ProtectDecorator allowedRoles={getAllowedRoles(isHiddenFor)}>
                  <ComponentsContainer
                    key={colIndex}
                    componentsPerRow={componentsPerRow}
                    isLastRow={rowIndex === rows.length - 1}
                  >
                    <Component {...{ ...componentProps, disabled: getIsReadOnly(isReadonlyFor) }} />
                  </ComponentsContainer>
                </ProtectDecorator>
              ))}
            </ComponentsRowContainer>
          ))}
    </Fragment>
  );
}

export default MapperWithVariableComponents;
