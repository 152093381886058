import React from 'react';

import TwoSectionsScreenLayout from '../../app/layouts/two-sections-screen/two-sections-screen-layout.component';
import GtmPlanLeftSection from '../../modules/gtm-plan-left-section/components/gtm-plan-left-section.component';
import GtmPlanRightSection from '../../modules/gtm-plan-right-section/components/gtm-plan-right-section.component';

function GtmPlan() {
  return (
    <TwoSectionsScreenLayout leftSectionComponent={GtmPlanLeftSection} rightSectionComponent={GtmPlanRightSection} />
  );
}

export default GtmPlan;
