import React, { useRef } from 'react';
import ClickAwayListener, { ClickAwayListenerProps } from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';

import { IBasePopperProps } from './base-popper.types';
import { PopperButton, PopperPaper } from './base-popper.styles';

export default function BasePopper({
  buttonTitle,
  placement,
  children,
  isOpen,
  handleButtonClick,
  handleClickAway,
  className,
}: IBasePopperProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);

  function handleInternalClickAway(event: MouseEvent | TouchEvent) {
    const targetNode = event.target as Node;
    if (anchorRef.current && anchorRef.current.contains(targetNode)) {
      // Click occurred inside the popper button, so ignore it
      return;
    }
    handleClickAway(event);
  }

  return (
    <div className={className}>
      <PopperButton ref={anchorRef} onClick={handleButtonClick} style={{ fontWeight: 600 }}>
        {buttonTitle}
      </PopperButton>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={placement || 'bottom-end'}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <PopperPaper>
              <ClickAwayListener onClickAway={handleInternalClickAway as ClickAwayListenerProps['onClickAway']}>
                <div>{children}</div>
              </ClickAwayListener>
            </PopperPaper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
