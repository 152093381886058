import React from 'react';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { CopyContainer } from './copy.styles';
import { RootState } from '../../../app/store/utils/redux.types';

function Copy<T>({
  selector,
  field,
  parseValue = (value: any) => String(value),
}: {
  selector: (state: RootState) => T;
  field: keyof T;
  parseValue?: (value: any) => string;
}) {
  const data = useAppSelector(selector);

  function stringifyValue(value: any): string {
    if (!value) return '';
    if (Array.isArray(value)) return value.map(parseValue).join(', ');
    return parseValue(value);
  }

  const value = stringifyValue(data?.[field]);

  if (!value) return null;

  async function handleButtonClick() {
    navigator.clipboard.writeText(String(value));
  }

  return (
    <CopyContainer>
      <TransparentRectButtonWithOrangeBorder
        title="Copy"
        handleClick={handleButtonClick}
        className={`marked-as-not-a-fit not-marked`}
      />
    </CopyContainer>
  );
}

export default Copy;
