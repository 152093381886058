import React from 'react';
import { useParams } from 'react-router-dom';

import { companyForReviewSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { DONT_SHOW_INDICATOR, companyProfileStrategy } from '../domain/company-profile.strategy';
import { CompanyProfileWrapper, CompanyProfileDataContainer } from './company-profile.styles';

import BaseMapper from '../../../shared/components/mapper/base-mapper/base-mapper.component';
import CompanyProfileDataLine from './company-profile-data-line/company-profile-data-line.component';
import FetchCompanyNews from '../../fetch-company-news/components/fetch-company-news.component';
import { changeTargetGroup } from '../../../entities/company-target-group/store/company-target-group.slice';

function CompanyProfile() {
  const companyForReview = useAppSelector(companyForReviewSelector).item;
  const dispatch = useAppDispatch();
  const { clientId } = useParams();

  function handleTargetGroupUpdate(name: string, value: { name: string; id: string }): void {
    const isSameValue = companyForReview && value && companyForReview.targetGroupId === value.id;
    if (!clientId || isSameValue) return;
    dispatch(changeTargetGroup({ clientId, companyTargetGroupId: companyForReview.id, targetGroupId: value.id }));
  }

  function formProfileDataArray() {
    const companyProfileDataArray = Object.values(
      companyProfileStrategy(companyForReview, handleTargetGroupUpdate, clientId ?? ''),
    );
    const filteredProfileDataArray = companyProfileDataArray.filter((item) => item.value !== DONT_SHOW_INDICATOR);
    return filteredProfileDataArray;
  }
  return (
    <CompanyProfileWrapper>
      <CompanyProfileDataContainer>
        <BaseMapper component={CompanyProfileDataLine} propsArray={formProfileDataArray()} />
        <FetchCompanyNews listPaddingTop={'0px'} listWidth="100%" hasCompanyName={false} />
      </CompanyProfileDataContainer>
    </CompanyProfileWrapper>
  );
}

export default CompanyProfile;
