import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Container, { ContainerProps } from '@mui/material/Container';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const ConfigureRelevantCompaniesWrapper = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  margin: 0,
  padding: '4%',
  display: 'flex',
  flexDirection: 'column',
  height: '350px',
  overflow: 'auto',

  [theme.breakpoints.up('sm')]: {
    padding: '4%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const AddRelevantCompanyContainer = styled(Stack)<StackProps>(({ theme }) => ({
  padding: '4%',
  paddingBottom: '20px',
  width: '100%',
  position: 'absolute',
  borderRadius: '10px',
  top: '0',
  left: '0',
  backgroundColor: theme.palette.common.white,
  zIndex: '1000',
  display: 'flex',
  flexDirection: 'row',

  '> .add-relevant-company-input': {
    marginRight: '10px',
    marginBottom: '0',

    '> div': { marginBottom: '0' },
  },

  '> .add-relevant-company-button': { width: '46px', height: '46px' },
}));

export const RelevantCompanyContainer = styled(Stack)<StackProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '10px',

  '> .delete-relevant-company-button': { width: '46px', height: '46px' },
}));

export const RelevantCompanyBox = styled(Box)<BoxProps>(() => ({
  backgroundColor: '#fff',
  color: '#01071A',
  border: '1.5px solid #E9EDF1',
  borderRadius: '10px',
  boxSizing: 'border-box',
  height: '46px',
  width: '100%',
  padding: '0 13px',
  paddingLeft: '4%',
  paddingRight: '4%',
  marginRight: '10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const RelevantCompanyDomain = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: 500,
  margin: 0,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));
