import React from 'react';
import { Stack } from '@mui/material';

import { ISelectEntityProps } from './select-entity.types';

import BaseMapper from '../../../shared/components/mapper/base-mapper/base-mapper.component';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';

function SelectEntity({ className, entityData, isLoading, entityCard: EntityCard }: ISelectEntityProps) {
  return (
    <Stack sx={{ width: '100%', padding: '10px 0 0' }} className={className}>
      <FeedbackUiDecorator statusConfig={{ isLoading: isLoading && entityData.length === 0, size: 30 }}>
        <BaseMapper component={EntityCard} propsArray={entityData} />
      </FeedbackUiDecorator>
    </Stack>
  );
}

export default SelectEntity;
