import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import { MuiTooltipProps, ITooltipStyleSchema } from './base-tooltip.types';

export const defaultTooltipStyle: ITooltipStyleSchema = {
  backgroundColor: '#e2e5ee',
  textColor: '#090909',
  border: 'none',
};

export const muiTooltipPopperStyles = {
  "&.MuiTooltip-popper[data-popper-placement*='right'] .MuiTooltip-tooltip": {
    marginLeft: '14px',
  },
  '&.MuiTooltip-popper[data-popper-placement*="right"] .MuiTooltip-arrow': {
    marginLeft: '-0.66em',
  },
};

export const muiTooltipStyles = {
  borderRadius: '10px',
  backgroundColor: '#ffffff',
  fontFamily: "'Inter', sans-serif",
  color: '#090909',
  width: '200px',
  minHeight: '46px',
  padding: '10px 15px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '>ul': {
    margin: 0,
    padding: '0 10px',
    li: {
      listStyleType: 'none',
      position: 'relative',
    },
    'li::before': {
      content: '"\u2B24"',
      position: 'absolute',
      left: '-0.8rem',
      top: '0.3rem',
      fontSize: '0.35rem',
      color: '#FC5130',
    },
  },
};

export const muiTooltipArrowStyles = {
  color: '#ffffff',
};

export const MuiTooltip = styled(Tooltip, {
  shouldForwardProp: (props) => props !== 'styleSchema',
})<MuiTooltipProps>(({ styleSchema }) => ({
  backgroundColor: styleSchema.backgroundColor || defaultTooltipStyle.backgroundColor,
  color: styleSchema.textColor || defaultTooltipStyle.textColor,
  border: styleSchema.border || defaultTooltipStyle.border,

  '& .MuiTooltip-arrow': {
    color: styleSchema.backgroundColor || defaultTooltipStyle.backgroundColor,
  },
}));
