/* eslint-disable func-names */
import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import {
  currentTargetGroupParamsSelector,
  currentTargetGroupSelector,
  targetGroupThunksSelector,
  targetGroupsListSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import { checkIsModificationAllowed, formTargetGroupsEntityData } from '../helpers/fetch-target-groups-list.helpers';
import { getAllTargetGroupsByClient } from '../../../entities/target-group/store/target-group.slice';
import { scrollToListBottom } from '../../../shared/helpers/base.helpers';
import { useGenericElementRef } from '../../../shared/hooks/use-generic-element-ref.hook';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { FetchTargetGroupsListContainer } from './fetch-target-groups-list.styles';
import { IFetchTargetGroupsListProps } from './fetch-target-groups-list.types';

import SelectEntity from '../../select-entity/components/select-entity.component';
import TargetGroupCard from './target-group-card/target-group-card.component';
import TargetGroupCardWithControls from './target-group-card-with-controls/target-group-card-with-controls.component';

function FetchTargetGroupsList({ stageName }: IFetchTargetGroupsListProps) {
  const { clientId } = useParams();

  const dispatch = useAppDispatch();

  const { id: selectedTargetGroupId, name: selectedTargetGroupName } = useAppSelector(currentTargetGroupParamsSelector);

  const { isLoading: isTargetGroupsListLoading } = useAppSelector(targetGroupThunksSelector).getAllTargetGroupsByClient;

  const { name: currentTargetGroupName } = useAppSelector(currentTargetGroupSelector);

  const targetGroupsList = useAppSelector(targetGroupsListSelector);

  const listRef = useGenericElementRef<HTMLDivElement>();

  const prevTargetGroupsNumInList = useRef(targetGroupsList?.items?.length);
  const currentTargetGroupsNumInList = targetGroupsList?.items?.length;

  const targetGroupsEntityData = formTargetGroupsEntityData(targetGroupsList?.items, selectedTargetGroupId);

  const getAndSetTargetGroupsByClient = useCallback(async () => {
    dispatch(getAllTargetGroupsByClient({ clientId }));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (currentTargetGroupsNumInList > prevTargetGroupsNumInList.current) scrollToListBottom(listRef);
    prevTargetGroupsNumInList.current = currentTargetGroupsNumInList;
  }, [currentTargetGroupsNumInList]);

  useEffect(() => {
    getAndSetTargetGroupsByClient();
  }, [getAndSetTargetGroupsByClient]);

  useEffect(() => {
    if (selectedTargetGroupName !== currentTargetGroupName) {
      dispatch(getAllTargetGroupsByClient({ clientId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTargetGroupName]);

  return (
    <FetchTargetGroupsListContainer className="fetch-target-groups-list-container" ref={listRef}>
      <SelectEntity
        entityData={targetGroupsEntityData ?? []}
        isLoading={isTargetGroupsListLoading}
        className="select-target-group"
        entityCard={checkIsModificationAllowed(stageName) ? TargetGroupCardWithControls : TargetGroupCard}
      />
    </FetchTargetGroupsListContainer>
  );
}

export default FetchTargetGroupsList;
