import React, { Fragment, useEffect } from 'react';

import { companyNewsEventsSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { currentCompanySelector } from '../../../entities/company/store/company.selectors';
import { getCompanyNews } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import {
  CompanyTrackingEventsSectionContainer,
  CompanyTrackingEventsSectionHeader,
  NewsEventsContainer,
  SectionHeaderName,
} from './company-tracking-news-section.styles';

import FetchCompanyNews from '../../../features/fetch-company-news/components/fetch-company-news.component';
import NoCompanyChosenScreen from './no-company-chosen-screen/no-company-chosen-screen.component';
import FetchRelevantCompaniesNews from '../../../features/fetch-relevant-companies-news/components/fetch-relevant-companies-news.component';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';

function CompanyTrackingNewsSection() {
  const dispatch = useAppDispatch();

  const { id: companyId } = useAppSelector(currentCompanySelector);
  const { isLoading: areAllNewsEventsLoading } = useAppSelector(companyNewsEventsSelector);

  useEffect(() => {
    if (companyId) dispatch(getCompanyNews({ companyId }));
  }, [dispatch, companyId]);

  return (
    <CompanyTrackingEventsSectionContainer>
      <CompanyTrackingEventsSectionHeader>
        <SectionHeaderName>Company Tracking</SectionHeaderName>
      </CompanyTrackingEventsSectionHeader>
      <FeedbackUiDecorator statusConfig={{ isLoading: areAllNewsEventsLoading, size: 40 }}>
        <NewsEventsContainer>
          {companyId ? (
            <Fragment>
              <FetchCompanyNews />
              <FetchRelevantCompaniesNews />
            </Fragment>
          ) : (
            <NoCompanyChosenScreen />
          )}
        </NewsEventsContainer>
      </FeedbackUiDecorator>
    </CompanyTrackingEventsSectionContainer>
  );
}

export default CompanyTrackingNewsSection;
