import React, { Fragment, SyntheticEvent } from 'react';
import { resetUploadCompaniesThunk } from '../../../entities/company/store/company.slice';
import { useAppDispatch } from '../../../app/store/utils/redux.hooks';
import { useModal } from '../../../shared/hooks/use-modal.hook';
import BaseButton from '../../../shared/components/button/base-button/base-button.component';
import FeatureModal from '../../../shared/components/modal/feature-modal/feature-modal.component';
import ImportCompaniesDropzone from './import-companies-dropzone/import-companies-dropzone.component';
import { ImportCompaniesProps } from './import-companies.types';

const IMPORT_BUTTON_TITLE = 'Import new leads';

function ImportCompanies({ title = IMPORT_BUTTON_TITLE, type = 'leads', strategy, onFinish }: ImportCompaniesProps) {
  const dispatch = useAppDispatch();

  const { isOpened, handleOpen, handleClose } = useModal();

  function handleUploadModalClose(event: SyntheticEvent) {
    handleClose(event);
    dispatch(resetUploadCompaniesThunk());
    if (onFinish) onFinish();
  }

  return (
    <Fragment>
      <BaseButton title={title} handleClick={handleOpen} className="import-companies-button" />
      <FeatureModal isOpened={isOpened} handleClose={handleUploadModalClose}>
        <ImportCompaniesDropzone type={type} strategy={strategy} />
      </FeatureModal>
    </Fragment>
  );
}

export default ImportCompanies;
