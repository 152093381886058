import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HighlightedPlaceholder = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'inline',
  whiteSpace: 'pre-line',
  wordBreak: 'break-all',
  backgroundColor: theme.palette.text.orange,
  fontSize: '0.8rem',
  lineHeight: 'normal',
  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));

export const EditorContainer = styled('div')<TypographyProps>(({ theme }) => ({
  width: '100%',
  padding: '10px 15px 18px',
  '.DraftEditor-root': {
    fontSize: '0.8rem',
    lineHeight: 'normal',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
    },
  },
}));
