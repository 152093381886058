import TwoSectionsScreenLayout from '../../app/layouts/two-sections-screen/two-sections-screen-layout.component';

import NewsEventsLeftSection from '../../modules/news-events-left-section/components/news-events-left-section.component';
import NewsEventsRightSection from '../../modules/news-events-right-section/components/news-events-right-section.component';

function NewsEvents() {
  return (
    <TwoSectionsScreenLayout
      leftSectionComponent={NewsEventsLeftSection}
      rightSectionComponent={NewsEventsRightSection}
    />
  );
}

export default NewsEvents;
