import { styled } from '@mui/material/styles';
import Container, { ContainerProps } from '@mui/material/Container';
import Paper, { PaperProps } from '@mui/material/Paper';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export const AutocompleteContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  '& .autocomplete-validation-helper': {
    margin: 0,
    paddingInlineStart: '20px',

    li: {
      p: {
        fontSize: '0.65rem',
        color: theme.palette.text.error,

        [theme.breakpoints.up('xl')]: {
          fontSize: '0.8rem',
        },
      },
    },
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const Input = styled(TextField, {
  shouldForwardProp: (props) => props !== 'isDropdownAboveInput' && props !== 'isOpened' && props !== 'hasError',
})<TextFieldProps & { isDropdownAboveInput: boolean; isOpened: boolean; hasError: boolean }>(
  ({ isDropdownAboveInput, isOpened, hasError, theme }) => ({
    marginBottom: hasError ? '4px' : '22px',

    '& .MuiInputLabel-root': {
      color: theme.palette.common.white,
      fontSize: '0.8rem',

      '&.Mui-focused': {
        color: theme.palette.common.white,
      },

      '&.Mui-error': {
        color: theme.palette.common.white,
      },

      [theme.breakpoints.up('xl')]: {
        fontSize: '1rem',
      },
    },

    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      backgroundColor: theme.palette.common.white,
      padding: '4px',
      color: theme.palette.text.primary,
      border: hasError ? `1.5px solid ${theme.palette.text.error}` : 'none',
      minHeight: '46px',
      gap: '4px',
      borderRadius:
        // eslint-disable-next-line no-nested-ternary
        isDropdownAboveInput && isOpened
          ? '0 0 10px 10px'
          : !isDropdownAboveInput && isOpened
          ? '10px 10px 0 0'
          : '10px',
      transition: 'border-radius 0.1s ease-in-out',

      '& .MuiAutocomplete-endAdornment': {
        right: '15px',
        '& .MuiAutocomplete-popupIndicator': {
          svg: { fontSize: '1.1rem', fontWeight: 500, color: theme.palette.background.orange },
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        top: 0,
      },
    },

    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: '0 1.3%',
      minWidth: '100px',

      [theme.breakpoints.down(1600)]: {
        padding: '0 1.8%',
      },
    },

    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.darkGrey,
      borderRadius: '10px',
      boxSizing: 'border-box',
      minWidth: '100px',
      height: '36px',
      fontSize: '0.8rem',
      fontWeight: 500,
      padding: '0 1% 0',

      [theme.breakpoints.up('xl')]: {
        fontSize: '1rem',
      },
    },

    '&.Mui-error': {
      color: '#ea4c89',
    },
  }),
);

export const AutocompletePaper = styled(Paper, {
  shouldForwardProp: (props) => props !== 'isDropdownAboveInput' && props !== 'isOpened',
})<PaperProps & { isDropdownAboveInput: boolean; isOpened: boolean }>(({ isDropdownAboveInput, isOpened, theme }) => ({
  boxShadow: 'none',
  border: 'none',
  outline: 'none',
  width: '100%',
  minHeight: '200px',
  height: '15vw',
  borderRadius:
    // eslint-disable-next-line no-nested-ternary
    isDropdownAboveInput && isOpened ? '10px 10px 0 0 ' : !isDropdownAboveInput && isOpened ? '0 0 10px 10px' : '10px',

  '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
    height: '35px',
    fontSize: '0.8rem',
    fontWeight: 600,
    textAlign: 'center',
    backgroundColor: theme.palette.common.white,
    color: '#152040',
    justifyContent: 'center',
    transition: theme.transitions.create(['color', 'background-color']),

    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },

    '&:hover': {
      backgroundColor: 'rgba(246, 174, 45, 0.1)',
      color: theme.palette.background.orange,
      transition: theme.transitions.create(['color', 'background-color']),
    },

    [theme.breakpoints.up('xl')]: {
      fontSize: '0.875rem',
    },
  },
}));
