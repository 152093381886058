import { BaseItemServerResponse, BaseServerResponse } from '../../../../shared/domain/types/base.types';
import { IAuthInputProps } from '../../components/auth-input/auth-input.types';
import { IUser } from '../../../../entities/user/domain/user.types';

export interface IUserAuthed extends IUser {}

export interface IUserPassword {
  password: string;
}

export interface IAuthToken {
  accessToken: string;
}

export enum AuthProcess {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  CONFIRM_EMAIL = 'confirmEmail',
  CHANGE_PASSWORD = 'changePassword',
}

export interface IAuthFormFieldConfig extends Omit<IAuthInputProps<any>, 'handleChange' | 'values' | 'errors'> {
  process: AuthProcess;
  defaultState: string;
  key: string;
}

export type AuthFieldsEntries = [string, IAuthFormFieldConfig];

export interface ILoginBody extends Pick<IUser, 'email'>, IUserPassword {}

export interface ILogoutBody extends Pick<IUser, 'id' | 'email'> {}

export interface IUserAuthedWithToken extends IUserAuthed, IAuthToken {}

export interface IUserAuthedWithTokenResponse extends BaseItemServerResponse<IUserAuthedWithToken> {}

export interface ILoginState {
  isLoading: boolean;
  data: IUserAuthed;
  isError: boolean;
  error: {};
}

export interface ILogoutState {
  isLoading: boolean;
  data: BaseServerResponse;
  isError: boolean;
  error: {};
}

export interface IAuthenticationState {
  accessToken: string;
  isAuthenticated: boolean;
  login: ILoginState;
  logout: ILogoutState;
}
