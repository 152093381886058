/* eslint-disable no-unneeded-ternary */
import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const TwoSectionsScreenLayoutContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100vh',
  overflow: 'hidden',

  [theme.breakpoints.up('xs')]: {
    paddingBottom: '1.2%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const LeftSectionContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '25%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.blueTranslucent,
  color: theme.palette.common.white,
  position: 'relative',

  [theme.breakpoints.up('xs')]: {
    padding: '1.2% 1.2% 0',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const RightSectionContainer = styled(Container, {
  shouldForwardProp: (props) => props !== 'customPadding',
})<ContainerProps & { customPadding: string | undefined }>(({ customPadding, theme }) => ({
  width: '75%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.blueTranslucent,
  color: theme.palette.common.white,
  position: 'relative',

  [theme.breakpoints.up('xs')]: {
    padding: customPadding ? customPadding : '2.5% 3.5% 22px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
