import React from 'react';
import Stack from '@mui/material/Stack';

import { SolidOrangeRectTabBaseButton, UpperLine, BottomLine } from './solid-orange-rect-tab-button.styles';
import { ISolidOrangeRectTabButtonProps } from './solid-orange-rect-tab-button.types';

function SolidOrangeRectTabButton({
  upperLine,
  bottomLine,
  handleClick,
  disabled,
  className,
  isLoading,
  isCurrentRoute,
  indicator: Indicator,
}: ISolidOrangeRectTabButtonProps) {
  const title = (
    <Stack direction="column" width="100%" alignItems="flex-start" justifyContent="center">
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <UpperLine>{upperLine}</UpperLine>
        {Indicator && <Indicator />}
      </Stack>
      <BottomLine>{bottomLine}</BottomLine>
    </Stack>
  );

  return (
    <SolidOrangeRectTabBaseButton
      handleClick={handleClick}
      disabled={disabled}
      className={className}
      isLoading={isLoading}
      isCurrentRoute={isCurrentRoute}
    >
      {title}
    </SolidOrangeRectTabBaseButton>
  );
}

export default SolidOrangeRectTabButton;
