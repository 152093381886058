import Typography, { TypographyProps } from '@mui/material/Typography';
import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const TargetUploadRightSectionHeader = styled(Container, {
  shouldForwardProp: (props) => props !== 'isTargetGroupChosen',
})<ContainerProps & { isTargetGroupChosen: boolean }>(({ isTargetGroupChosen, theme }) => ({
  '> div': {
    paddingBottom: isTargetGroupChosen ? '10px' : 0,
    '> div > .import-companies-button': {
      width: '162px',
      height: '40px',
      fontSize: '0.8rem',
      fontWeight: 600,

      [theme.breakpoints.up('xl')]: {
        fontSize: '1rem',
      },
    },
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const SectionHeaderName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1.5rem',
  lineHeight: '34px',
  fontWeight: 600,
  color: theme.palette.common.white,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.75rem',
  },
}));

export const TargetGroupName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 600,
  color: theme.palette.text.orange,
  margin: '12px 0',

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.25rem',
  },
}));

export const TargetUploadRightSectionContainer = styled(Container, {
  shouldForwardProp: (props) => props !== 'isTargetGroupChosen',
})<ContainerProps & { isTargetGroupChosen: boolean }>(({ isTargetGroupChosen, theme }) => ({
  width: '100%',
  height: isTargetGroupChosen ? 'calc(100% - 85px)' : '100%',
  paddingBottom: '10px',

  '> .target-upload-right-loader': { position: 'absolute', top: 0, left: 0 },

  [theme.breakpoints.up('xs')]: {
    paddingBottom: '10px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
