import { styled } from '@mui/material/styles';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';

export const menuStyles = {
  sx: {
    '& .MuiPaper-root': {
      backgroundColor: '#fff',
      borderRadius: '10px',
      width: '186px',
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      paddingTop: 0,
      boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.25)',

      '> ul > li': {
        height: '40px',
      },
    },
  },
};

export const Label = styled(InputLabel)<InputLabelProps>(({ theme }) => ({
  width: '100%',
  color: theme.palette.common.white,
  fontSize: '0.8rem',

  '&.Mui-focused': {
    color: theme.palette.common.white,
  },

  '&.Mui-error': {
    color: theme.palette.common.white,
  },

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));

export const MuiSelect = styled(Select, {
  shouldForwardProp: (props) => props !== 'isMenuOpen',
})<SelectProps & { isMenuOpen: boolean }>(({ isMenuOpen, theme }) => ({
  width: '100%',
  color: theme.palette.background.orange,
  fontSize: '0.8rem',
  fontWeight: 600,
  textAlign: 'center',
  borderRadius: '10px',
  height: '46px',
  marginBottom: '29px',
  borderBottomRightRadius: isMenuOpen ? '0' : '10px',
  borderBottomLeftRadius: isMenuOpen ? '0' : '10px',
  transition: 'border-radius 0.3s ease-in-out',

  '&.custom-select': { backgroundColor: '#fff', '> .MuiSelect-select': { padding: '15px 32px 15px 14px ' } },

  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },

  '& .MuiSelect-icon': {
    fontSize: '1.1rem',
    fontWeight: 500,
    color: theme.palette.background.orange,
    right: '15px',
  },

  [theme.breakpoints.up('xl')]: {
    fontSize: '0.875rem',
  },
}));

export const MuiSelectItem = styled(MenuItem, {
  shouldForwardProp: (props) => props !== 'isCurrentlySelectedItem',
})<MenuItemProps & { isCurrentlySelectedItem?: boolean }>(({ isCurrentlySelectedItem, theme }) => ({
  height: '35px',
  fontSize: '0.8rem',
  fontWeight: 600,
  textAlign: 'center',
  backgroundColor: isCurrentlySelectedItem ? 'rgba(15, 27, 57, 0.1)' : theme.palette.common.white,
  color: isCurrentlySelectedItem ? theme.palette.text.primary : '#152040',
  justifyContent: 'center',
  transition: theme.transitions.create(['color', 'background-color']),

  '&:focus': {
    backgroundColor: isCurrentlySelectedItem ? 'rgba(15, 27, 57, 0.1)' : theme.palette.common.white,
  },

  '&:hover': {
    backgroundColor: 'rgba(246, 174, 45, 0.1)',
    color: theme.palette.background.orange,
    transition: theme.transitions.create(['color', 'background-color']),
  },

  [theme.breakpoints.up('xl')]: {
    fontSize: '0.875rem',
  },
}));
