import React, { useCallback, useEffect, useRef } from 'react';
import Stack from '@mui/material/Stack';

import { checkIsEmptyObject } from '../../../shared/helpers/base.helpers';
import { currentTargetGroupSelector } from '../../../entities/target-group/store/target-group.selectors';
import { defaultEmail, updateTargetGroup } from '../../../entities/target-group/store/target-group.slice';
import { emailEditorValidationStrategy } from '../domain/email-editor-validation.strategy';
import { useForm } from '../../../shared/hooks/use-form.hook';
import { useDebounce } from '../../../shared/hooks/use-debounce.hook';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { validateObject } from '../../../shared/libs/validator/validate-object.helpers';
import {
  BodyContainer,
  EmailEditorContainer,
  EmailEditorSubject,
  EmailEditorWrapper,
  Label,
  SubjectTitle,
} from './email-editor.styles';
import { IEmailEditorProps } from './email-editor.types';
import { ValidationResultsObject } from '../../../shared/domain/types/validation.types';

import BaseInput from '../../../shared/components/input/base-input/base-input.component';
import BaseTextarea from '../../../shared/components/textarea/base-textarea/base-textarea.component';
import EmailEditorValidationHelper from './email-editor-validation-helper/email-editor-validation-helper.component';

const DEBOUNCE_DELAY = 500;

function EmailEditor({ indexInSequence, label }: IEmailEditorProps) {
  const isFirstRender = useRef(true);

  const errors = useRef<ValidationResultsObject>();

  const dispatch = useAppDispatch();

  const targetGroupFormData = useAppSelector(currentTargetGroupSelector);
  const targetGroupId = targetGroupFormData.id;
  const emailFromSequence = targetGroupFormData.emailSequences?.[indexInSequence] || defaultEmail;

  const {
    formValues: editorValues,
    resetFormState: resetEditorState,
    handleInputChange,
    validateValues: validateEmailEditor,
  } = useForm(emailFromSequence, validateObject, emailEditorValidationStrategy);

  const debouncedValues = useDebounce(editorValues, DEBOUNCE_DELAY);

  const validateAndUpdateTargetGroup = useCallback(() => {
    const validationErrorsObject = validateEmailEditor(debouncedValues);
    errors.current = validationErrorsObject;
    const noValidationErrors = checkIsEmptyObject(validationErrorsObject as Object);
    if (noValidationErrors) {
      const body = { ...targetGroupFormData };
      body.emailSequences = [...(body?.emailSequences || [defaultEmail, defaultEmail])];
      body.emailSequences[indexInSequence] = debouncedValues;
      dispatch(updateTargetGroup({ targetGroupId, body }));
    }
  }, [debouncedValues, dispatch, indexInSequence, targetGroupId]);

  useEffect(() => {
    isFirstRender.current = true;
    resetEditorState();
  }, [targetGroupId, targetGroupFormData.emailSequences.length]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    validateAndUpdateTargetGroup();
  }, [validateAndUpdateTargetGroup]);

  return (
    <EmailEditorWrapper>
      {label && <Label>{label}</Label>}
      <Stack direction="column" sx={{ marginBottom: '10px' }}>
        {errors.current?.subject && (
          <EmailEditorValidationHelper errors={errors.current} name="subject" id="subject" header="Subject error" />
        )}
        {errors.current?.body && (
          <EmailEditorValidationHelper errors={errors.current} name="body" id="body" header="Body error" />
        )}
      </Stack>
      <EmailEditorContainer>
        <EmailEditorSubject>
          <SubjectTitle>Subject</SubjectTitle>
          <BaseInput
            isLabelShown={false}
            id="subject"
            name="subject"
            values={editorValues}
            handleChange={handleInputChange}
            type="text"
            className="email-editor-subject"
          />
        </EmailEditorSubject>
        <BodyContainer>
          <BaseTextarea
            id="body"
            handleChange={handleInputChange}
            name="body"
            values={editorValues}
            className="email-editor-body"
          />
        </BodyContainer>
      </EmailEditorContainer>
    </EmailEditorWrapper>
  );
}

export default EmailEditor;
