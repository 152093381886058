import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const MuiChip = styled(Chip)<any>(({ theme }) => ({
  backgroundColor: theme.palette.background.orangeLight,
  color: theme.palette.text.darkGrey,
  height: '32px',
  fontSize: '0.8rem',
  fontWeight: 500,
  borderRadius: '10px',

  '& .MuiChip-deleteIcon': {
    fontSize: '0.8rem',
    color: theme.palette.text.darkGrey,

    '&:hover': { color: theme.palette.text.primary },
  },

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',

    '& .MuiChip-deleteIcon': {
      fontSize: '1rem',
    },
  },
}));
