/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import { Stack } from '@mui/material';

import { companiesFileSelector, uploadedCompaniesSelector } from '../../../entities/company/store/company.selectors';
import { columnNameToColumnHeaderCellConfig, rowPropertyNames } from '../domain/uploaded-companies-table.constants';
import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import {
  getCompaniesByTargetGroup,
  resetUploadedCompanies,
  setIsFileDataUploaded,
} from '../../../entities/company/store/company.slice';
import { useInfiniteScroll } from '../../../shared/hooks/use-infinite-scroll.hook';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import Link from '../../../shared/components/link/link.component';
import Table from '../../../shared/components/table/table.component';

export const specificTableCellFormatsConfig = {
  website: ({ id, website }: any) => <Link key={id} url={website} title={website} />,
  linkedIn: ({ id, linkedIn }: any) => <Link key={id} url={linkedIn} title={linkedIn} />,
};

function UploadedCompaniesTable() {
  const dispatch = useAppDispatch();

  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const companiesByTargetGroup = useAppSelector(uploadedCompaniesSelector);

  const { isFileDataUploaded } = useAppSelector(companiesFileSelector);

  const prevTargetGroupId = useRef(targetGroupId);

  const { observedElementRef, parentElementRef, skip, take, setSkip } = useInfiniteScroll<
    HTMLDivElement,
    HTMLTableSectionElement
  >([isFileDataUploaded]);

  useEffect(() => {
    setSkip(-50);
    prevTargetGroupId.current = targetGroupId;
  }, [targetGroupId]);

  useEffect(() => {
    if (isFileDataUploaded) {
      setSkip(-50);
      dispatch(setIsFileDataUploaded(false));
    }
  }, [isFileDataUploaded]);

  useEffect(() => {
    if (skip >= 0) dispatch(getCompaniesByTargetGroup({ targetGroupId, skip, take }));
  }, [prevTargetGroupId, skip, dispatch]);

  useEffect(() => {
    dispatch(resetUploadedCompanies());
  }, [dispatch, prevTargetGroupId, isFileDataUploaded]);

  return (
    <Stack
      direction="column"
      width="100%"
      height="100%"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      sx={{ background: 'white', borderRadius: '10px', marginTop: '8px' }}
    >
      <Table
        rowPropertyNames={rowPropertyNames}
        rows={companiesByTargetGroup?.items ?? []}
        scrollableRootRef={parentElementRef}
        observedElementRef={observedElementRef}
        columnNameToColumnHeaderCellConfig={columnNameToColumnHeaderCellConfig}
        specificTableCellFormatsConfig={specificTableCellFormatsConfig}
      />
    </Stack>
  );
}

export default UploadedCompaniesTable;
