import React, { Fragment } from 'react';

import {
  currentTargetGroupParamsSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import { PipelineStageName } from '../../navigation/components/pipeline-navigation/pipeline-navigation.types';
import { TargetDefinitionRightSectionFormContainer } from './target-definition-right-section.styles';

import NoTargetGroupChosenScreen from './no-target-group-chosen-screen/no-target-group-chosen-screen.component';
import TargetDefinitionForm from '../../target-definition-form/components/target-definition-form.component';
import RightSectionHeader from '../../../app/layouts/layout-components/right-section-header/right-section-header.component';
import RightSectionMain from '../../../app/layouts/layout-components/right-section-main/right-section-main.component';
import TestPrompt from '../../../features/test-prompt/components/test-prompt.component';
// import GenerateSearches from '../../../features/generate-searches/components/generate-searches.component';
import RunSearches from '../../../features/run-searches/components/run-searches.component';
import EnrichExamples from '../../../features/enrich-examples/components/enrich-examples.component';
import ToggleSelection from '../../../features/toggle-selection/components/toggle-selection.component';
import ProtectDecorator from '../../../app/decorators/protect/protect.decorator';
import { UserRole } from '../../../entities/user/domain/user.types';

const SECTION_HEADER = PipelineStageName.TARGET_DEFINITION;

function TargetDefinitionRightSection() {
  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const isTargetGroupChosen = Boolean(targetGroupId);

  const { isLoading: isTargetGroupLoading } = useAppSelector(targetGroupThunksSelector).getTargetGroupById;

  return (
    <Fragment>
      <RightSectionHeader
        headerName={SECTION_HEADER}
        isListEntityChosen={isTargetGroupChosen}
        hasControls
        controls={
          <ProtectDecorator allowedRoles={[UserRole.EMPLOYEE]}>
            <TestPrompt targetGroupId={targetGroupId} />
            <EnrichExamples targetGroupId={targetGroupId} />
            <RunSearches targetGroupId={targetGroupId} />
            <ToggleSelection targetGroupId={targetGroupId} />
          </ProtectDecorator>
        }
      />
      <TargetDefinitionRightSectionFormContainer isTargetGroupChosen={isTargetGroupChosen}>
        <RightSectionMain
          isListEntityChosen={isTargetGroupChosen}
          isDataLoading={isTargetGroupLoading}
          content={<TargetDefinitionForm />}
          noContent={<NoTargetGroupChosenScreen />}
        />
      </TargetDefinitionRightSectionFormContainer>
    </Fragment>
  );
}

export default TargetDefinitionRightSection;
