import React from 'react';

import { PipelineStageName } from '../../navigation/components/pipeline-navigation/pipeline-navigation.types';
import {
  TargetVettingRightSectionHeader,
  SectionHeaderName,
  TargetVettingHeaderControls,
} from './target-vetting-header.styles';

import VetCompaniesForPeriod from '../../../features/vet-companies-for-period/components/vet-companies-for-period.component';
import SelectTargetGroupWithDropdown from '../../../features/select-target-group-with-dropdown/components/select-target-group-with-dropdown.component';

const SECTION_HEADER = PipelineStageName.TARGET_VETTING;

function TargetVettingHeader() {
  return (
    <TargetVettingRightSectionHeader>
      <SectionHeaderName>{SECTION_HEADER}</SectionHeaderName>
      <TargetVettingHeaderControls>
        <VetCompaniesForPeriod />
        <div style={{ width: '20rem', marginBottom: '5px' }}>
          <SelectTargetGroupWithDropdown showCounts isVetting />
        </div>
      </TargetVettingHeaderControls>
    </TargetVettingRightSectionHeader>
  );
}

export default TargetVettingHeader;
