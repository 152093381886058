/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { useGenericElementRef } from './use-generic-element-ref.hook';

const defaultTake = 50;
export function useInfiniteScroll<ObservedElement extends Element, ParentElement extends Element>(
  dependencies: any[] = [],
) {
  const [skip, setSkip] = useState<number>(-defaultTake);
  const observedElementRef = useGenericElementRef<ObservedElement>();
  const parentElementRef = useGenericElementRef<ParentElement>();

  const handleObserver = (entries: any) => {
    const [entry] = entries;
    const { isIntersecting, intersectionRatio } = entry;
    if (isIntersecting || Math.floor(intersectionRatio) === 1) {
      setSkip((prev) => prev + defaultTake);
    }
  };

  useEffect(() => {
    const option = {
      root: parentElementRef.current,
      rootMargin: '0px 0px 100px 0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (observedElementRef.current) observer.observe(observedElementRef.current);

    return () => {
      if (observedElementRef.current) observer.unobserve(observedElementRef.current);
    };
  }, [...dependencies, observedElementRef, parentElementRef]);

  return { observedElementRef, parentElementRef, skip, take: defaultTake, setSkip };
}
