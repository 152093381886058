/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  companiesForVettingSelector,
  companyTargetGroupThunksSelector,
  // currentWeekOfCompanyCreationSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import {
  getCompaniesForVetting,
  resetCompaniesForVetting,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { columnNameToColumnHeaderCellConfig, rowPropertyNames } from '../domain/target-vetting-table.constants';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { useInfiniteScroll } from '../../../shared/hooks/use-infinite-scroll.hook';

import Link from '../../../shared/components/link/link.component';
import Table from '../../../shared/components/table/table.component';
import TickDontContact from '../../../features/tick-dont-contact/components/tick-dont-contact.component';
import InputVettingComment from '../../../features/input-vetting-comment/components/input-vetting-comment.component';
import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';

export const specificTableCellFormatsConfig = {
  comment: ({ id, comment }: any) => <InputVettingComment companyTargetGroupId={id} comment={comment} />,
  rejectionStatus: ({ id, rejectionStatus }: any) => (
    <TickDontContact companyTargetGroupId={id} rejectionStatus={rejectionStatus} />
  ),
  companyName: ({ id, website, companyName, companyLinkedIn }: any) => (
    <div>
      <Link key={id} url={website} title={companyName} />
      <a href={companyLinkedIn} target="_blank" rel="noreferrer">
        <img
          src={`data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 30 30">
      <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"></path>
  </svg>`}
          alt="no content"
        />
      </a>
    </div>
  ),
};

function TargetVettingTable() {
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  // anneeded anymore
  // const currentWeek = useAppSelector(currentWeekOfCompanyCreationSelector);
  // const { name: currentWeekName, values: currentWeekValue } = currentWeek;
  // const { startDate, endDate } = currentWeekValue;
  //

  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);

  const companiesForVetting = useAppSelector(companiesForVettingSelector);
  const { isSuccess: areCompaniesVetted } = useAppSelector(companyTargetGroupThunksSelector).vetCompaniesForPeriod;

  const { observedElementRef, parentElementRef, skip, take, setSkip } = useInfiniteScroll<
    HTMLDivElement,
    HTMLTableSectionElement
  >([targetGroupId, areCompaniesVetted]);

  const prevtargetGroupId = useRef(targetGroupId);
  const prevAreCompaniesVetted = useRef(areCompaniesVetted);

  useEffect(() => {
    setSkip(-50);
    prevtargetGroupId.current = targetGroupId;
    prevAreCompaniesVetted.current = areCompaniesVetted;
  }, [targetGroupId, areCompaniesVetted]);

  useEffect(() => {
    if (skip >= 0) dispatch(getCompaniesForVetting({ clientId, targetGroupId, take, skip }));
  }, [clientId, prevtargetGroupId, prevAreCompaniesVetted, skip, dispatch]);

  useEffect(() => {
    dispatch(resetCompaniesForVetting());
  }, [dispatch, targetGroupId, areCompaniesVetted]);

  return (
    <Stack
      direction="column"
      width="100%"
      height="100%"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      sx={{ background: 'white', borderRadius: '10px', marginTop: '8px' }}
    >
      <Table
        rowPropertyNames={rowPropertyNames(companiesForVetting.flags)}
        rows={companiesForVetting?.items ?? []}
        scrollableRootRef={parentElementRef}
        observedElementRef={observedElementRef}
        columnNameToColumnHeaderCellConfig={columnNameToColumnHeaderCellConfig(companiesForVetting.flags)}
        specificTableCellFormatsConfig={specificTableCellFormatsConfig}
        optionalData={['website', 'companyLinkedIn']}
      />
    </Stack>
  );
}

export default TargetVettingTable;
