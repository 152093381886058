import React from 'react';
import { useParams } from 'react-router-dom';

import { companyTargetGroupThunksSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import {
  getCompanyForResearch,
  markCompanyAsNotAFit,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { IMarkAsNotAFitProps } from './mark-as-not-a-fit.types';
import { MarkAsNotAFitContainer } from './mark-as-not-a-fit.styles';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';

function MarkAsNotAFit({ companyTargetGroupId, isMarkedAsNotAFit }: IMarkAsNotAFitProps) {
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const { isLoading } = useAppSelector(companyTargetGroupThunksSelector).markCompanyAsNotAFit;

  async function handleButtonClick() {
    await dispatch(markCompanyAsNotAFit({ companyTargetGroupId }));
    await dispatch(getCompanyForResearch({ clientId, companyTargetGroupId }));
  }

  return (
    <MarkAsNotAFitContainer>
      <TransparentRectButtonWithOrangeBorder
        title="Not a fit"
        handleClick={isMarkedAsNotAFit ? undefined : handleButtonClick}
        className={`marked-as-not-a-fit ${isMarkedAsNotAFit ? 'not-a-fit' : 'not-marked'}`}
        isLoading={isLoading}
      />
    </MarkAsNotAFitContainer>
  );
}

export default MarkAsNotAFit;
