export const rowPropertyNames = () => [
  'companyName',
  'inRecycling',
  'estimatedFitCompaniesLeft',
  'companiesToVet',
  'companiesToResearch',
  'companiesToReview',
  'recyclingScheduleForNext4Weeks',
];

const DEFAULT_CELL_CONFIG = { isSortable: false, isCurrentlySorted: false, handleClick: () => {} };

export const columnNameToColumnHeaderCellConfig = () => ({
  Client: DEFAULT_CELL_CONFIG,
  'In Recycling': DEFAULT_CELL_CONFIG,
  'Estimated Fit Companies Left': DEFAULT_CELL_CONFIG,
  'Companies To Vet': DEFAULT_CELL_CONFIG,
  'Companies To Research': DEFAULT_CELL_CONFIG,
  'Companies To Review': DEFAULT_CELL_CONFIG,
  'Recycling Schedule For Next 4 Weeks': DEFAULT_CELL_CONFIG,
});
