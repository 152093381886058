import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const TargetResearchRightSectionFormContainer = styled(Container, {
  shouldForwardProp: (props) => props !== 'isCompanyChosen',
})<ContainerProps & { isCompanyChosen: boolean }>(({ isCompanyChosen, theme }) => ({
  width: '100%',
  height: isCompanyChosen ? 'calc(100% - 85px)' : '100%',
  overflow: 'auto',
  paddingBottom: '10px',
  paddingTop: isCompanyChosen ? '20px' : 0,

  '> .target-research-form-wrapper': {
    width: '100%',
  },

  [theme.breakpoints.up('xs')]: {
    paddingBottom: '10px',
    paddingTop: isCompanyChosen ? '20px' : 0,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
