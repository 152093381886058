import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { Fade } from '@mui/material';
import { styled } from '@mui/material/styles';

import { InputIconProps, LabelProps, MuiInputProps, FormControlContainerProps } from './base-input.types';
import { defaultTooltipStyle } from '../../tooltip/base-tooltip.styles';
import { TooltipPlacement } from '../../tooltip/base-tooltip.types';
import { muiThemeOptions } from '../../../../app/styles/global/mui/mui-theme-options';

export const defaultBaseInputStyleSchema = {
  tooltipStyleSchema: {
    styleSchema: defaultTooltipStyle,
    transition: Fade,
    placement: TooltipPlacement.RIGHT,
  },
};

export const additionalBaseInputStyles = () => {
  return {
    '&::placeholder': {
      color: muiThemeOptions.palette.text.placeholder,
      opacity: 1,
      position: 'absolute',
      top: '13px',
      left: '18px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingLeft: 0,
    },
    '&[placeholder]': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    ':focus::-webkit-input-placeholder': { color: 'transparent' },
    ':focus::-moz-placeholder': { color: 'transparent' },
    ':focus::-ms-input-placeholder': { color: 'transparent' },
  };
};

// check this!!!!!
export const FormControlContainer = styled(FormControl)<FormControlContainerProps>(() => ({
  marginBottom: '22px',
  // width: '437px',
  width: '100%',

  '>ul': {
    margin: 0,
    padding: '0 15px',

    li: {
      listStyleType: 'none',
      position: 'relative',
    },

    'li::before': {
      content: '"\u2B24"',
      position: 'absolute',
      left: '-0.8rem',
      top: '0.3rem',
      fontSize: '0.35rem',
      color: '#ea4c89',
    },
  },
}));

export const Label = styled(InputLabel)<LabelProps>(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '0.8rem',

  '&.Mui-focused': {
    color: theme.palette.common.white,
  },

  '&.Mui-error': {
    color: theme.palette.common.white,
  },

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));

export const InputIcon = styled(InputAdornment)<InputIconProps>(({ theme }) => ({
  '&.MuiInputAdornment-positionEnd': {
    color: theme.palette.background.input.endAdornment,
    position: 'absolute',
    top: '23.5px',
    left: '89%',
    cursor: 'pointer',

    '> svg': { fontSize: '20px' },

    [theme.breakpoints.down(1600)]: {
      left: '87%',
    },
  },
}));

export const Input = styled(InputBase, {
  shouldForwardProp: (props) => props !== 'isPasswordField',
})<MuiInputProps>(({ theme, isPasswordField }) => ({
  marginBottom: '7px',

  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    border: theme.palette.border.input,
    borderRadius: '10px',
    boxSizing: 'border-box',
    height: '46px',
    fontSize: '0.8rem',
    fontWeight: 500,
    padding: '0 13px',
    paddingLeft: '4%',
    paddingRight: isPasswordField ? '9.3%' : '4%',

    [theme.breakpoints.down(1600)]: {
      paddingLeft: '5%',
      paddingRight: isPasswordField ? '11%' : '5%',
    },

    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
    },
  },

  '&.Mui-error': {
    color: '#ea4c89',
  },
}));
