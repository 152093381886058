import CardActions, { CardActionsProps } from '@mui/material/CardActions';
import CardContent, { CardContentProps } from '@mui/material/CardContent';
import Card, { CardProps } from '@mui/material/Card';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const EntityCardWithActionsWrapper = styled(Card)<CardProps>(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.blueTranslucent,
  borderRadius: '10px',
  minWidth: '0px',
  marginBottom: '10px',
  boxShadow: 'none',
  cursor: 'pointer',
  position: 'relative',

  '&:last-child': { marginBottom: 0 },

  [theme.breakpoints.up('xl')]: {
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
  },
}));

export const EntityCardWithActionsContent = styled(CardContent)<CardContentProps>(() => ({
  padding: '1.1rem',
  textAlign: 'center',

  '&:last-child': { paddingBottom: '1.1rem' },
}));

export const EntityCardWithActionsTitle = styled(Typography, {
  shouldForwardProp: (props) => props !== 'isActiveEntity',
})<TypographyProps & { isActiveEntity: boolean | undefined }>(({ isActiveEntity, theme }) => ({
  color: isActiveEntity ? theme.palette.text.orange : theme.palette.common.white,
  fontSize: '0.8rem',
  fontWeight: 600,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));

export const EntityCardActions = styled(CardActions)<CardActionsProps>(({ theme }) => ({
  position: 'absolute',
  top: '5px',
  right: '-3px',

  [theme.breakpoints.up('xl')]: {
    top: '10px',
    right: '6px',
  },
}));
