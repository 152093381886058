import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';

export const MuiTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.table.evenRow,
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
