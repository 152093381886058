import React, { SyntheticEvent } from 'react';

import { IIndicatorChipProps } from './indicator-chip.types';
import { MuiChip } from './indicator-chip.styles';

function IndicatorChip({ label, isDimmed, className }: IIndicatorChipProps) {
  return (
    <MuiChip
      label={label}
      onMouseDown={(event: SyntheticEvent) => event.stopPropagation()}
      isDimmed={isDimmed}
      className={className}
    />
  );
}

export default IndicatorChip;
