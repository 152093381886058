import React, { useCallback, useEffect, useRef } from 'react';

import { companyForReviewSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import {
  defaultEmail,
  updateEmailSequences,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { useForm } from '../../../shared/hooks/use-form.hook';
import { useDebounce } from '../../../shared/hooks/use-debounce.hook';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import {
  BodyContainer,
  ReviewEmailEditorContainer,
  ReviewEmailEditorSubject,
  ReviewEmailEditorWrapper,
  Label,
  SubjectTitle,
  HighlightedInputContainer,
} from './review-email-editor.styles';
import { IReviewEmailEditorProps } from './review-email-editor.types';

import {
  ICompanyTargetGroupDataForReview,
  ICompanyTargetGroupEmail,
} from '../../../entities/company-target-group/domain/company-target-group.types';
import HighlightedTextArea from '../../../shared/components/textarea/highlighted-textarea/highlighted-textarea.component';
import HighlightedInput from '../../../shared/components/textarea/highlighted-textarea/highlighted-input.component';

const DEBOUNCE_DELAY = 500;

function getEmail(
  companyForReview: ICompanyTargetGroupDataForReview,
  indexInSequence: number,
  historyEmail?: ICompanyTargetGroupEmail,
): ICompanyTargetGroupEmail {
  const emailFromSequence = companyForReview.emailSequences?.[indexInSequence];
  return historyEmail || emailFromSequence || defaultEmail;
}

function ReviewEmailEditor({ indexInSequence, label, emailStatus, historyEmail }: IReviewEmailEditorProps) {
  const isFirstRender = useRef(true);

  const dispatch = useAppDispatch();

  const companyForReview = useAppSelector(companyForReviewSelector).item;
  const companyTargetGroupId = companyForReview.id;
  const emailItem = getEmail(companyForReview, indexInSequence, historyEmail);
  const { isValidated } = companyForReview;
  const isSentOrInQueued = emailStatus !== null;
  const isDisabled = isValidated || isSentOrInQueued;

  const { formValues: editorValues, resetFormState: resetEditorState, handleInputChange } = useForm(emailItem);

  const debouncedValues = useDebounce(editorValues, DEBOUNCE_DELAY);

  const getAndUpdateEmailSequences = useCallback(() => {
    dispatch(updateEmailSequences({ companyTargetGroupId, body: { email: debouncedValues, index: indexInSequence } }));
  }, [debouncedValues, dispatch, indexInSequence, companyTargetGroupId]);

  useEffect(() => {
    isFirstRender.current = true;
    resetEditorState();
  }, [companyTargetGroupId, companyForReview]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getAndUpdateEmailSequences();
  }, [getAndUpdateEmailSequences]);

  return (
    <ReviewEmailEditorWrapper>
      {label && <Label>{`${label} ${emailStatus || ''}`}</Label>}
      <ReviewEmailEditorContainer>
        <ReviewEmailEditorSubject>
          <SubjectTitle>Subject</SubjectTitle>
          <HighlightedInputContainer>
            <HighlightedInput
              name="subject"
              value={editorValues.subject}
              onInput={handleInputChange}
              disabled={isDisabled}
            />
          </HighlightedInputContainer>
        </ReviewEmailEditorSubject>
        <BodyContainer>
          <HighlightedTextArea
            id="body"
            handleChange={handleInputChange}
            name="body"
            values={editorValues}
            className="email-editor-body"
            disabled={isDisabled}
          />
        </BodyContainer>
      </ReviewEmailEditorContainer>
    </ReviewEmailEditorWrapper>
  );
}

export default ReviewEmailEditor;
