import React from 'react';
import { Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import SolidOrangeRectNavButton from '../../../../shared/components/button/solid-orange-rect-nav-button/solid-orange-rect-nav-button.component';
import { NavigationPaths, Routes } from '../../../../shared/domain/constants/routing.constants';
import { useIsRouteMatched } from '../../../../shared/hooks/use-is-route-matched.hook';

function KpisNavigation() {
  const { isRouteMatched } = useIsRouteMatched();

  const navigate = useNavigate();

  const { clientId } = useParams();

  return (
    <Stack direction="row" spacing={{ xs: 0.8, sm: 1.5, md: 2.5 }} justifyContent="flex-end" alignItems="center">
      <SolidOrangeRectNavButton
        title="Selection"
        handleClick={() => navigate(NavigationPaths.SELECTION(clientId))}
        isCurrentRoute={isRouteMatched(Routes.SELECTION)}
      />
      <SolidOrangeRectNavButton
        title="Weekly KPIs"
        handleClick={() => navigate(NavigationPaths.WEEKLY_KPIS(clientId))}
        isCurrentRoute={isRouteMatched(Routes.WEEKLY_KPIS)}
      />
      <SolidOrangeRectNavButton
        title="Visibility KPIs"
        handleClick={() => navigate(NavigationPaths.VISIBILITY_KPIS(clientId))}
        isCurrentRoute={isRouteMatched(Routes.VISIBILITY_KPIS)}
      />
    </Stack>
  );
}

export default KpisNavigation;
