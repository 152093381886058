import React from 'react';
import { styled } from '@mui/material';

function StatusCheckedIcon() {
  const SvgIcon = styled('svg')(() => ({
    width: '20px',
    height: '20px',
  }));
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.28351 10.6734L5.62903 11.0032L5.9743 10.6731L12.2302 4.69191L12.3861 4.84116L5.62877 11.3079L2.92336 8.71887L3.07958 8.56937L5.28351 10.6734Z"
        stroke="#F6AE2D"
      />
      <rect x="0.75" y="0.75" width="13.8091" height="14.5" rx="3.25" stroke="#F6AE2D" strokeWidth="1.5" />
    </SvgIcon>
  );
}

export default StatusCheckedIcon;
