import React from 'react';
import Stack from '@mui/material/Stack';

import { IRightSectionHeaderProps } from './right-section-header.types';
import {
  RightSectionHeaderContainer,
  ListEntityName,
  SectionHeaderControls,
  SectionHeaderName,
} from './right-section-header.styles';

function RightSectionHeader({
  isListEntityChosen,
  listEntityName,
  headerName,
  hasControls,
  controls,
}: IRightSectionHeaderProps) {
  return (
    <RightSectionHeaderContainer
      isListEntityChosen={isListEntityChosen || false}
      hasEntityName={Boolean(listEntityName)}
    >
      <Stack
        direction="column"
        height={isListEntityChosen && Boolean(listEntityName) ? '85px' : '42.5px'}
        justifyContent="center"
      >
        <SectionHeaderName>{headerName}</SectionHeaderName>
        {isListEntityChosen && listEntityName ? <ListEntityName>{listEntityName}</ListEntityName> : null}
      </Stack>
      {isListEntityChosen && hasControls ? <SectionHeaderControls>{controls}</SectionHeaderControls> : null}
    </RightSectionHeaderContainer>
  );
}

export default RightSectionHeader;
