import { styled } from '@mui/material/styles';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const MuiTableCell = styled(TableCell)(({ theme }) => ({
  height: '33px',
  padding: '9px 17px',
  borderBottom: 'none',
  fontWeight: 500,
  color: theme.palette.text.darkGrey,

  '&.MuiTableCell-root.MuiTableCell-body.specific-table-cell': {
    padding: '0 13px',

    [theme.breakpoints.down('lg')]: {},
  },

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.orange,
    fontSize: '0.85rem',
    lineHeight: '1.1rem',
    fontWeight: 600,
    '> div > svg': {
      fontSize: '1.1rem',
      fontWeight: 500,
      color: '#252525',
    },

    [theme.breakpoints.up('xl')]: {
      fontSize: '0.875rem',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.8rem',
    lineHeight: '1rem',

    [theme.breakpoints.up('xl')]: {
      fontSize: '0.8125rem',
    },
  },
}));
