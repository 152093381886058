/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';

import {
  eventsCategoriesSelector,
  eventsFilterByCategories,
  newsEventsSelector,
  newsEventsSortingSelector,
} from '../../../entities/company/store/company.selectors';
import { rowPropertyNames } from '../domain/news-events-table.constants';
import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import { getNewsEventsByTargetGroup, resetNewsEvents } from '../../../entities/company/store/company.slice';
import { useInfiniteScroll } from '../../../shared/hooks/use-infinite-scroll.hook';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import Link from '../../../shared/components/link/link.component';
import Table from '../../../shared/components/table/table.component';
import { formatDateToDDMMYYYY } from '../../../shared/helpers/base.helpers';
import { formColumnNameToColumnHeaderCellConfig } from '../helpers/news-events.helpers';

export const specificTableCellFormatsConfig = {
  companyName: ({ eventId, companyWebsite, companyName }: any) => (
    <Link key={eventId} url={companyWebsite} title={companyName} />
  ),
  eventTitle: ({ eventId, eventTitle, eventUrl }: any) => <Link key={eventId} url={eventUrl} title={eventTitle} />,
  eventArticlePublishedAt: ({ eventArticlePublishedAt }: any) => formatDateToDDMMYYYY(eventArticlePublishedAt),
};

function NewsEventsTable() {
  const dispatch = useAppDispatch();
  const { clientId } = useParams();

  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const companiesByTargetGroup = useAppSelector(newsEventsSelector);
  const allEventsCategories = useAppSelector(eventsCategoriesSelector);
  const selectedEventsCategories = useAppSelector(eventsFilterByCategories);

  const { isSorted, sortBy } = useAppSelector(newsEventsSortingSelector);

  const prevSortBy = useRef(sortBy);
  const prevTargetGroupId = useRef(targetGroupId);
  const prevCategoriesFilter = useRef(selectedEventsCategories);

  const columnNameToColumnHeaderCellConfig = formColumnNameToColumnHeaderCellConfig(
    isSorted,
    sortBy!,
    prevSortBy,
    dispatch,
  );

  const { observedElementRef, parentElementRef, skip, take, setSkip } = useInfiniteScroll<
    HTMLDivElement,
    HTMLTableSectionElement
  >([sortBy, targetGroupId, selectedEventsCategories]);

  useEffect(() => {
    setSkip(-50);
    prevTargetGroupId.current = targetGroupId;
    prevSortBy.current = sortBy;
    prevCategoriesFilter.current = selectedEventsCategories;
  }, [sortBy, targetGroupId, selectedEventsCategories]);

  useEffect(() => {
    const hasSelected = selectedEventsCategories?.length;
    const categories = hasSelected ? selectedEventsCategories : allEventsCategories;
    if (skip >= 0) dispatch(getNewsEventsByTargetGroup({ targetGroupId, clientId, skip, take, sortBy, categories }));
  }, [prevTargetGroupId, skip, dispatch, prevSortBy, prevCategoriesFilter, clientId]);

  useEffect(() => {
    dispatch(resetNewsEvents());
  }, [dispatch, prevTargetGroupId, sortBy, selectedEventsCategories]);

  return (
    <Stack
      direction="column"
      width="100%"
      height="100%"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      sx={{ background: 'white', borderRadius: '10px', marginTop: '8px' }}
    >
      <Table
        rowPropertyNames={rowPropertyNames}
        rows={companiesByTargetGroup?.items ?? []}
        scrollableRootRef={parentElementRef}
        observedElementRef={observedElementRef}
        columnNameToColumnHeaderCellConfig={columnNameToColumnHeaderCellConfig}
        specificTableCellFormatsConfig={specificTableCellFormatsConfig}
        optionalData={['companyWebsite', 'eventId', 'eventTitle', 'eventUrl']}
      />
    </Stack>
  );
}

export default NewsEventsTable;
