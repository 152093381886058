import React from 'react';

import { TextBaseButton } from './text-button.styles';
import { ITextButtonProps } from './text-button.types';

function TextButton({ title, handleClick, disabled, className, isLoading, disableRipple }: ITextButtonProps) {
  return (
    <TextBaseButton
      title={title}
      handleClick={handleClick}
      disabled={disabled}
      className={className}
      isLoading={isLoading}
      disableRipple={disableRipple ?? false}
    />
  );
}

export default TextButton;
