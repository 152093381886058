import React, { PropsWithChildren } from 'react';

import { AuthBaseInput } from './auth-input.styles';
import { IAuthInputProps } from './auth-input.types';

function AuthInput<T extends { [key: string]: any }>({
  id,
  title,
  label,
  name,
  type,
  handleChange,
  values,
  errors,
  isPasswordField,
}: PropsWithChildren<IAuthInputProps<T>>) {
  return (
    <AuthBaseInput
      className="auth-input"
      id={id}
      title={title}
      label={label}
      name={name}
      type={type}
      handleChange={handleChange}
      values={values}
      errors={errors}
      isPasswordField={isPasswordField}
    />
  );
}

export default AuthInput;
