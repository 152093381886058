import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';

const ROW_Y_GAP = 5;

export const ComponentsRowContainer = styled(Stack)<StackProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: `${ROW_Y_GAP}%`,
  marginBottom: '20px',
  flexWrap: 'wrap',
}));

export const ComponentsContainer = styled(Stack, {
  shouldForwardProp: (props) => props !== 'componentsPerRow',
})<StackProps & { componentsPerRow: number; isLastRow: boolean }>(({ componentsPerRow, isLastRow }) => ({
  flex: isLastRow
    ? `0 0 calc((100% - ${(componentsPerRow - 1) * ROW_Y_GAP}%) / ${componentsPerRow})`
    : `1 0 calc((100% - ${(componentsPerRow - 1) * ROW_Y_GAP}%) / ${componentsPerRow})`,
}));
