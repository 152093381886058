import React, { SyntheticEvent, useState } from 'react';

import { eventsCategoriesSelector } from '../../../entities/company/store/company.selectors';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import { ISelectNewsCategoriesWithAutocompleteProps } from './select-events-categories-with-autocomplete.types';

import AutocompleteWithMultipleChoices from '../../../shared/components/input/autocomplete-with-multiple-choice/autocomplete-with-multiple-choice.component';
import { SelectEventsCategoriesWithDropdownContainer } from './select-events-categories-with-autocomplete.styles';
import { INewsEventsCategory } from '../../../entities/company/domain/company.types';

const DEFAULT_QUERY_STATE = '';

function SelectEventsCategoriesWithAutocomplete({
  // className,
  id,
  name,
  values,
  errors,
  disabled,
  handleChange,
}: ISelectNewsCategoriesWithAutocompleteProps) {
  const [query, setQuery] = useState<string>(DEFAULT_QUERY_STATE);

  const countries = useAppSelector(eventsCategoriesSelector);

  function handleOptionsChange(event: SyntheticEvent<Element, Event>, value: readonly INewsEventsCategory[]) {
    handleChange(value as INewsEventsCategory[]);
    setQuery(DEFAULT_QUERY_STATE);
  }

  function handleOptionDelete(optionToDelete: string) {
    const optionsAfterDelete = values.filter((option: INewsEventsCategory) => option.name !== optionToDelete);
    handleChange(optionsAfterDelete);
  }

  function handleInputChange(event: SyntheticEvent<Element, Event>, newInputValue: string) {
    setQuery(newInputValue);
  }

  function handleOptionsFilter(selectedOptionsArray: INewsEventsCategory[]) {
    return (allOptions: INewsEventsCategory[]) =>
      allOptions
        .filter((option) => !selectedOptionsArray.some((selectedOption) => option.name === selectedOption.name))
        .filter((e) => (query ? e.name.trim().toLowerCase().startsWith(query.trim().toLocaleLowerCase()) : true));
  }

  return (
    <SelectEventsCategoriesWithDropdownContainer>
      <AutocompleteWithMultipleChoices<INewsEventsCategory>
        id={id}
        className="some-class"
        name={name}
        placeholder={'Select categories to filter list'}
        selectedOptions={values}
        options={countries}
        handleOptionsChange={handleOptionsChange}
        handleOptionDelete={handleOptionDelete}
        handleInputChange={handleInputChange}
        handleOptionsFilter={handleOptionsFilter(values)}
        errors={errors}
        disabled={disabled}
      />
    </SelectEventsCategoriesWithDropdownContainer>
  );
}

export default SelectEventsCategoriesWithAutocomplete;
