import React, { Fragment } from 'react';

import { PipelineStageName } from '../../navigation/components/pipeline-navigation/pipeline-navigation.types';

import CreateTargetGroup from '../../../features/create-target-group/components/create-target-group.component';
import FetchTargetGroupsList from '../../../features/fetch-target-groups-list/components/fetch-target-groups-list.component';

function TargetDefinitionLeftSection() {
  return (
    <Fragment>
      <FetchTargetGroupsList stageName={PipelineStageName.TARGET_DEFINITION} />
      <CreateTargetGroup />
    </Fragment>
  );
}

export default TargetDefinitionLeftSection;
