import Container, { ContainerProps } from '@mui/material/Container';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const TextareaContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  '& .textarea-validation-helper': {
    margin: 0,
    paddingInlineStart: '20px',

    li: {
      p: {
        fontSize: '0.65rem',
        color: theme.palette.text.error,

        [theme.breakpoints.up('xl')]: {
          fontSize: '0.8rem',
        },
      },
    },
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const MuiTextarea = styled(TextField, {
  shouldForwardProp: (props) => props !== 'hasError',
})<TextFieldProps & { hasError: boolean }>(({ hasError, theme }) => ({
  marginBottom: hasError ? '4px' : '22px',
  width: '100%',
  '& .MuiInputLabel-root': {
    color: theme.palette.common.white,
    fontSize: '0.8rem',
    '&.Mui-focused': {
      color: theme.palette.common.white,
    },
    '&.Mui-error': {
      color: theme.palette.common.white,
    },
    '&.Mui-disabled': { color: theme.palette.common.white },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
    },
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    backgroundColor: theme.palette.common.white,
    padding: '4px',
    color: theme.palette.text.primary,
    border: hasError ? `1.5px solid ${theme.palette.text.error}` : 'none',
    borderRadius: '10px',
    minHeight: '46px',
    gap: '4px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      top: 0,
    },
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0 1.3%',
    minWidth: '100px',
    [theme.breakpoints.down(1600)]: {
      padding: '0 1.8%',
    },
  },
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.darkGrey,
    borderRadius: '10px',
    fontSize: '0.8rem',
    fontWeight: 500,
    padding: '0 2%',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
      padding: '0 1.5%',
    },
  },
  '&.Mui-error': {
    color: theme.palette.text.error,
  },
}));
