import React, { PropsWithChildren, ReactNode } from 'react';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import { IBaseDropdownProps } from './base-dropdown.types';
import { menuStyles, MuiSelect, MuiSelectItem } from './base-dropdown.styles';
import IndicatorChip from '../../chip/indicator-chip/indicator-chip.component';

function BaseDropdown<
  T extends { name: string; id: string; chip?: number | string; values: Partial<{ id: string; name: string }> },
>({
  options,
  selectedOptionName,
  defaultValue,
  handleOptionChange,
  onMenuOpen,
  onMenuClose,
  className,
  showChip,
  isDisabled = false,
}: PropsWithChildren<IBaseDropdownProps<T>>) {
  return (
    <MuiSelect
      value={selectedOptionName}
      onChange={handleOptionChange}
      renderValue={(selected) => selected as ReactNode}
      className={className}
      IconComponent={UnfoldMoreIcon}
      onOpen={onMenuOpen}
      onClose={onMenuClose}
      MenuProps={menuStyles}
      disabled={isDisabled}
      variant="standard"
    >
      {defaultValue && (
        <MuiSelectItem
          value={JSON.stringify(defaultValue)}
          isCurrentlySelectedItem={selectedOptionName === defaultValue.name}
        >
          {defaultValue.name}
        </MuiSelectItem>
      )}
      {options.map((option: T) => {
        const { name, id, values } = option;
        const key = `${id}_${name}`;
        const isCurrentlySelectedItem = selectedOptionName === name;
        return (
          <MuiSelectItem key={key} value={JSON.stringify(values)} isCurrentlySelectedItem={isCurrentlySelectedItem}>
            <div className="dropdown-items-container">
              {name}
              {option.chip && showChip ? (
                <IndicatorChip label={option.chip} isDimmed={false} className="dropdown-items-counter" />
              ) : null}
            </div>
          </MuiSelectItem>
        );
      })}
    </MuiSelect>
  );
}

export default BaseDropdown;
