import React, { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { useAppDispatch } from '../../../app/store/utils/redux.hooks';

import { useDebounce } from '../../../shared/hooks/use-debounce.hook';
import { IInputWeeklySelectionRateProps } from './input-weekly-selection-rate.types';
import { InputWeeklySelectionRateContainer, InputWeeklySelectionRateInput } from './input-weekly-selection-rate.styles';
import { updateTargetGroup } from '../../../entities/target-group/store/target-group.slice';

function InputWeeklySelectionRate({ targetGroupId, weeklySelectionRate: initValue }: IInputWeeklySelectionRateProps) {
  const dispatch = useAppDispatch();
  const [isInit, setIsInit] = useState(true);
  const [weeklySelectionRate, setWeeklySelectionRate] = useState(initValue ?? 0);

  const debouncedComment = useDebounce(weeklySelectionRate, 850);

  function isPositiveInteger(value: string | number): boolean {
    if (value === '') return false;
    const numberValue = typeof value === 'number' ? value : Number(value);
    return Number.isInteger(numberValue) && numberValue >= 0;
  }

  async function handleRateChange(event: SelectChangeEvent<any>) {
    const { value } = event.target;
    if (isPositiveInteger(value)) setWeeklySelectionRate(value);
    else setWeeklySelectionRate(0);
  }

  useEffect(() => {
    if (!isInit) dispatch(updateTargetGroup({ targetGroupId, body: { weeklySelectionRate } }));
    setIsInit(false);
  }, [debouncedComment, dispatch]);

  return (
    <InputWeeklySelectionRateContainer>
      <InputWeeklySelectionRateInput
        className="vetting-comment-input"
        id={targetGroupId}
        name={'weeklySelectionRate'}
        handleChange={handleRateChange}
        values={{ weeklySelectionRate }}
        type="number"
        inputProps={{
          min: 0,
          max: 999,
          step: '1',
          pattern: '[0-9]*',
        }}
      />
    </InputWeeklySelectionRateContainer>
  );
}

export default InputWeeklySelectionRate;
