import { styled } from '@mui/material/styles';

import BaseButton from '../base-button/base-button.component';

export const TextBaseButton = styled(BaseButton)(({ theme }) => ({
  transition: theme.transitions.create('background-color'),
  backgroundColor: 'transparent',
  color: theme.palette.background.blue,
  border: 'none',

  '&:hover': {
    backgroundColor: 'rgba(15, 27, 57, 0.1)',
    border: 'none',
    color: theme.palette.background.blue,
    transition: theme.transitions.create('background-color'),
  },
}));
