import { BaseListServerResponse } from '../../../shared/domain/types/base.types';

export enum UploadLeadsStrategies {
  onVetting = 'onVetting',
  onResearch = 'onReasearch',
  onReview = 'onReview',
}

export interface IUploadedCompany {
  id: string;
  name: string;
  website: string;
  linkedIn: string;
  headcount: number;
}

export interface IThunkStatuses {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  isStarted: boolean;
  isFinished: boolean;
}

export interface ICompanyThunks {
  uploadCompanies: IThunkStatuses;
  getCompaniesByTargetGroup: IThunkStatuses;
  getNewsEventsByTargetGroup: IThunkStatuses;
}

export interface ICurrentCompany {
  id: string;
  name: string;
}

export interface ICompaniesFile {
  progressPercent: number;
  message: string;
  isFileDataUploaded: boolean;
}

export interface IGetCompaniesByTargetGroupThunkParams {
  targetGroupId: string;
  take: number;
  skip: number;
}

export interface IUploadedCompaniesListResponse extends BaseListServerResponse<IUploadedCompany> {}

export interface ICompanyState {
  currentCompany: ICurrentCompany;
  uploadedCompanies: IUploadedCompaniesListResponse;
  newsEvents: INewsEventsListResponse;
  companiesFile: ICompaniesFile;
  thunks: ICompanyThunks;
  sorting: { isSorted: boolean; sortBy?: NewsEventsSortBy };
  newsEventsCategories: INewsEventsCategory[];
  newsEventsFilterByCategories: INewsEventsCategory[];
}

export enum NewsEventCategoryTypes {
  LeadershipChange = 'Leadership Change',
  FinancialEvent = 'Financial Event',
  Expansion = 'Expansion',
  NewOffering = 'New Offering',
  CostCutting = 'Cost Cutting',
  Partnerships = 'Partnerships',
  Awards = 'Awards',
  ContractWon = 'Contract Won',
  CorporateChallenges = 'Corporate Challenges',
  CompetitionNews = 'Competition News',
}

export interface INewsEventsCategory {
  id: string;
  name: NewsEventCategoryTypes;
  values: string[];
}

export enum NewsEventsSortBy {
  Date = 'eventArticlePublishedAt',
  CompanyName = 'companyName',
}

export interface IGetNewsEventsThunkParams {
  clientId?: string;
  targetGroupId: string;
  take: number;
  skip: number;
  sortBy?: NewsEventsSortBy;
  categories: INewsEventsCategory[];
}

export interface INewsEvent {
  companyId: string;
  companyName: string;
  companyWebsite: string;
  eventId: string;
  eventType: string;
  eventTitle: string;
  eventUrl: string;
  eventFoundAt: string;
  eventCategories: string;
  eventArticlePublishedAt: string;
  eventArticleTitle: string;
}

export interface INewsEventsListResponse extends BaseListServerResponse<INewsEvent> {}
