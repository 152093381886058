import React from 'react';

import { ITransparentSquareIconButtonProps } from './transparent-square-icon-button.types';
import { TransparentSquareIconBaseButton } from './transparent-square-icon-button.styles';

function TransparentSquareIconButton({
  handleClick,
  disabled,
  className,
  isCurrentRoute,
  tooltipConfig,
  children,
}: ITransparentSquareIconButtonProps) {
  return (
    <TransparentSquareIconBaseButton
      handleClick={handleClick}
      disabled={disabled}
      className={className}
      isCurrentRoute={isCurrentRoute}
      tooltipConfig={tooltipConfig}
    >
      {children}
    </TransparentSquareIconBaseButton>
  );
}

export default TransparentSquareIconButton;
