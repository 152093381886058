import React, { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { currentResearchFilterCriterionSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { dropdownOptions } from '../domain/select-research-filter-with-dropdown.constants';
import { setCurrentResearchFilterCriterion } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { SelectResearchFilterWithDropdownContainer } from './select-research-filter-with-dropdown.styles';

import BaseDropdown from '../../../shared/components/dropdown/base-dropdown/base-dropdown.component';

function SelectResearchFilterWithDropdown() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { name: currentResearchFilterName } = useAppSelector(currentResearchFilterCriterionSelector);

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  function handleOptionChange(event: SelectChangeEvent<unknown>) {
    const researchFilterCriterion = event.target.value as string;
    const researchFilterCriterionParsed = JSON.parse(researchFilterCriterion);
    dispatch(setCurrentResearchFilterCriterion(researchFilterCriterionParsed));
  }

  return (
    <SelectResearchFilterWithDropdownContainer isMenuOpen={isMenuOpen}>
      <BaseDropdown
        selectedOptionName={currentResearchFilterName}
        options={dropdownOptions || []}
        handleOptionChange={handleOptionChange}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
      />
    </SelectResearchFilterWithDropdownContainer>
  );
}

export default SelectResearchFilterWithDropdown;
