import React from 'react';

import { NoTargetGroupChosenScreenContainer, NoTargetGroupChosenMessage } from './no-target-group-chosen-screen.styles';

function NoTargetGroupChosenScreen() {
  return (
    <NoTargetGroupChosenScreenContainer>
      <NoTargetGroupChosenMessage>Select a target group on the left to load news events </NoTargetGroupChosenMessage>
    </NoTargetGroupChosenScreenContainer>
  );
}

export default NoTargetGroupChosenScreen;
