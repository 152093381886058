import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { pipelineNavigationStrategy } from './pipeline-navigation.strategy';
import { useIsRouteMatched } from '../../../../shared/hooks/use-is-route-matched.hook';
import { PipelineNavigationContainer, PipelineNavigationWrapper } from './pipeline-navigation.styles';

import BaseMapper from '../../../../shared/components/mapper/base-mapper/base-mapper.component';
import SolidOrangeRectTabButton from '../../../../shared/components/button/solid-orange-rect-tab-button/solid-orange-rect-tab-button.component';
import { useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { currentClientSelector, userSelector } from '../../../../entities/user/store/user.selectors';
import { getSkippedStages, isStageAllowed } from '../../helpers/pipeline-navigation.helpers';

function PipelineNavigation() {
  const { isRouteMatched } = useIsRouteMatched();

  const navigate = useNavigate();

  const { clientId } = useParams();
  const currentClient = useAppSelector(currentClientSelector);
  const { role } = useAppSelector(userSelector);

  function handleClick(navigationPath: string) {
    navigate(navigationPath);
  }

  function checkIsCurrentRoute(route: string) {
    return isRouteMatched(route);
  }

  function mapToPipelineNavigationButtonsConfig() {
    if (!currentClient || !role) return [];
    const skippedStages = getSkippedStages(currentClient, role);
    const pipelineNavigationEntries = Object.entries(pipelineNavigationStrategy(clientId as string));

    return pipelineNavigationEntries
      .filter(([stage]) => isStageAllowed(stage, skippedStages))
      .map(([, stageParams], i) => {
        const { stageName, navigateTo, currentRoute, key, indicator } = stageParams;
        return {
          upperLine: `stage ${i + 1}`,
          bottomLine: stageName,
          handleClick: () => handleClick(navigateTo),
          isCurrentRoute: checkIsCurrentRoute(currentRoute),
          key,
          indicator,
        };
      });
  }

  const pipelineNavigationButtonsConfigArray = mapToPipelineNavigationButtonsConfig();

  return (
    <PipelineNavigationWrapper>
      <PipelineNavigationContainer spacing={{ xs: 0.8, sm: 1.5, md: 2.5 }}>
        <BaseMapper component={SolidOrangeRectTabButton} propsArray={pipelineNavigationButtonsConfigArray} />
      </PipelineNavigationContainer>
    </PipelineNavigationWrapper>
  );
}

export default PipelineNavigation;
