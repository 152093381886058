import { styled } from '@mui/material/styles';
import BaseForm from '../../../../shared/components/form/base-form/base-form.component';

export const AuthBaseForm = styled(BaseForm)(() => ({
  '&.auth-form': {
    width: '100%',
    flex: 1,
    justifyContent: 'space-between',

    '>.MuiFormControl-root.auth-input:last-of-type': {
      marginBottom: '30px',
    },
  },
}));
