import React from 'react';

import { SolidOrangeRectNavBaseButton } from './solid-orange-rect-nav-button.styles';
import { ISolidOrangeRectNavButtonProps } from './solid-orange-rect-nav-button.types';

function SolidOrangeRectNavButton({
  title,
  handleClick,
  disabled,
  className,
  isLoading,
  isCurrentRoute,
}: ISolidOrangeRectNavButtonProps) {
  return (
    <SolidOrangeRectNavBaseButton
      title={title}
      handleClick={handleClick}
      disabled={disabled}
      className={className}
      isLoading={isLoading}
      isCurrentRoute={isCurrentRoute}
    />
  );
}

export default SolidOrangeRectNavButton;
