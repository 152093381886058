import React, { Fragment } from 'react';

import { errorFeedbackUiKit } from './error-feedback-ui-kit.strategy';
import { IErrorFeedbackUiKitProps } from './error-feedback-ui-kit.types';
import { FeedbackUiType } from '../feedback-ui.types';

function ErrorFeedbackUiKit({ feedbackUiType, uiProps }: IErrorFeedbackUiKitProps) {
  return <Fragment>{errorFeedbackUiKit[feedbackUiType || FeedbackUiType.MODAL](uiProps)}</Fragment>;
}

export default ErrorFeedbackUiKit;
