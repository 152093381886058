/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import Table from '../../../shared/components/table/table.component';

import { columnNameToColumnHeaderCellConfig, rowPropertyNames } from '../domain/kpi-selection-table.constants';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { getForAllClientsTargetGroupsList } from '../../../entities/target-group/store/target-group.slice';
import { targetGroupsForClientsListSelector } from '../../../entities/target-group/store/target-group.selectors';
import TickSelectionToggle from '../../../features/tick-selection-toggle/components/tick-selection-toggle.component';
import InputWeeklySelectionRate from '../../../features/input-weekly-selection-rate/components/input-weekly-selection-rate.component';
import RunClassificationForTG from '../../../features/run-classification-for-tg/components/run-classification-for-tg.component';
import { userStateSelector } from '../../../entities/user/store/user.selectors';

export const specificTableCellFormatsConfig = {
  weeklySelectionRate: ({ id, weeklySelectionRate }: any) => (
    <InputWeeklySelectionRate targetGroupId={id} weeklySelectionRate={weeklySelectionRate} />
  ),
  clientCompanyName: ({ client }: any) => client.companyName,
  runSelection: ({ id, name }: any) => <RunClassificationForTG targetGroupId={id} targetGroupName={name} />,
  selectionJobToggle: ({ id, selectionJobToggle }: any) => (
    <TickSelectionToggle targetGroupId={id} selectionJobToggle={selectionJobToggle} />
  ),
};

function KPISelectionTable() {
  const dispatch = useAppDispatch();

  const tgsForClients = useAppSelector(targetGroupsForClientsListSelector);
  const { selectedClients } = useAppSelector(userStateSelector);

  useEffect(() => {
    const clientIds = selectedClients.map((e) => e.id);
    dispatch(getForAllClientsTargetGroupsList({ clientIds }));
  }, [selectedClients, dispatch]);

  return (
    <Stack
      direction="column"
      width="100%"
      height="100%"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      sx={{ background: 'white', borderRadius: '10px', marginTop: '8px' }}
    >
      <Table
        rowPropertyNames={rowPropertyNames()}
        rows={tgsForClients?.items ?? []}
        columnNameToColumnHeaderCellConfig={columnNameToColumnHeaderCellConfig()}
        specificTableCellFormatsConfig={specificTableCellFormatsConfig}
        optionalData={['client', 'name']}
      />
    </Stack>
  );
}

export default KPISelectionTable;
