import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const IndicatorsContainer = styled(Stack)<StackProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '60%',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down(1250)]: {
    width: '100%',
  },
}));
