/* eslint-disable react/no-array-index-key */
import React from 'react';

import { AlertsContainer } from './base-alerts.styles';
import { IBaseAlertProps } from '../../feedback-ui/feedback-ui.types';

import BaseAlert from '../base-alert/base-alert.component';

function BaseAlerts({ isUiVisible, message, handleClose, type, delay }: IBaseAlertProps) {
  return (
    <AlertsContainer>
      {Array.isArray(message) ? (
        message?.map((oneMessage, index) => (
          <BaseAlert
            key={`${oneMessage}${index}`}
            isUiVisible={isUiVisible!}
            handleClose={handleClose}
            message={oneMessage}
            type={type}
            delay={delay}
          />
        ))
      ) : (
        <BaseAlert isUiVisible={isUiVisible!} handleClose={handleClose} message={message!} type={type} delay={delay} />
      )}
    </AlertsContainer>
  );
}

export default BaseAlerts;
