import React, { useEffect, useRef } from 'react';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';

import {
  companiesForReviewSelector,
  companyForReviewSelector,
  companyTargetGroupThunksSelector,
  currentCompanyForReviewParamsSelector,
  currentReviewFilterCriterionSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import { formCompaniesForReviewEntityData } from '../helpers/fetch-companies-to-review-list.helper';
import {
  getCompaniesForReviewFilteredByCriterion,
  resetCompaniesForReview,
  setMarkAsRejected,
  setMarkAsValidated,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { useInfiniteScroll } from '../../../shared/hooks/use-infinite-scroll.hook';
import { FetchCompaniesForReviewListContainer } from './fetch-companies-for-review-list.styles';
import { setCompanyCountFilter } from '../../../entities/target-group/store/target-group.slice';

import CompanyForReviewCard from './company-for-review-card/company-for-review-card.component';
import SelectEntity from '../../select-entity/components/select-entity.component';

function FetchCompaniesForReviewList() {
  const { clientId } = useParams();

  const dispatch = useAppDispatch();

  const reviewFilter = useAppSelector(currentReviewFilterCriterionSelector);
  const filterCriterion = reviewFilter?.value;
  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);

  const prevReviewFilterCriterion = useRef(filterCriterion);
  const prevTargetGroupId = useRef(targetGroupId);

  const statuses = useAppSelector(companyTargetGroupThunksSelector).getCompaniesForReviewFilteredByCriterion;
  const { isLoading: areCompaniesForReviewLoading } = statuses;

  const companiesForReview = useAppSelector(companiesForReviewSelector);
  const { id: currentCompanyTargetGroupId } = useAppSelector(currentCompanyForReviewParamsSelector);
  const companiesEntityData = formCompaniesForReviewEntityData(companiesForReview?.items, currentCompanyTargetGroupId);

  const currentCompanyForReview = useAppSelector(companyForReviewSelector);
  const { isMarkedAsValidated } = currentCompanyForReview.markAsValidated;
  const { isMarkedAsRejected } = currentCompanyForReview.markAsRejected;

  const prevIsMarkedAsValidated = useRef(isMarkedAsValidated);
  const prevIsMarkedAsRejected = useRef(isMarkedAsRejected);

  const { observedElementRef, parentElementRef, skip, take, setSkip } = useInfiniteScroll<
    HTMLDivElement,
    HTMLDivElement
  >([filterCriterion, targetGroupId, isMarkedAsValidated, isMarkedAsRejected]);

  useEffect(() => {
    dispatch(setCompanyCountFilter({ filter: filterCriterion }));
  }, [filterCriterion]);

  useEffect(() => {
    setSkip(-50);
    dispatch(resetCompaniesForReview());
    prevReviewFilterCriterion.current = filterCriterion;
    prevTargetGroupId.current = targetGroupId;
  }, [filterCriterion, targetGroupId]);

  useEffect(() => {
    if (isMarkedAsValidated || isMarkedAsRejected) {
      // setSkip(-50);
      // dispatch(resetCompaniesForReview());
      prevIsMarkedAsValidated.current = isMarkedAsValidated;
      prevIsMarkedAsRejected.current = isMarkedAsRejected;
    }
  }, [isMarkedAsValidated, isMarkedAsRejected]);

  useEffect(() => {
    if (skip >= 0)
      dispatch(getCompaniesForReviewFilteredByCriterion({ clientId, filterCriterion, targetGroupId, take, skip }));
    if (isMarkedAsValidated) dispatch(setMarkAsValidated({ isMarkedAsValidated: false, message: '' }));
    if (isMarkedAsRejected) dispatch(setMarkAsRejected({ isMarkedAsRejected: false, message: '' }));
  }, [
    clientId,
    skip,
    prevReviewFilterCriterion,
    prevTargetGroupId,
    prevIsMarkedAsValidated,
    prevIsMarkedAsRejected,
    dispatch,
  ]);

  return (
    <FetchCompaniesForReviewListContainer ref={parentElementRef}>
      <SelectEntity
        entityData={companiesEntityData ?? []}
        isLoading={areCompaniesForReviewLoading}
        className="select-company-for-review"
        entityCard={CompanyForReviewCard}
      />
      <Stack className="observed-element" ref={observedElementRef} height="1px" />
    </FetchCompaniesForReviewListContainer>
  );
}

export default FetchCompaniesForReviewList;
