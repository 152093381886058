import { styled } from '@mui/material/styles';

import { TransparentSquareIconBaseButtonProps } from './transparent-square-icon-button.types';

import BaseIconButton from '../base-icon-button/base-icon-button.component';

export const TransparentSquareIconBaseButton = styled(BaseIconButton, {
  shouldForwardProp: (props) => props !== 'isCurrentRoute',
})<TransparentSquareIconBaseButtonProps>(({ isCurrentRoute, theme }) => ({
  transition: theme.transitions.create('background-color'),
  width: '35px',
  height: '35px',
  borderRadius: '10px',
  border: theme.palette.border.orangeButton,
  backgroundColor: isCurrentRoute ? theme.palette.background.orange : 'transparent',

  '&.Mui-disabled': {
    border: theme.palette.border.orangeDisabledButton,
    backgroundColor: 'transparent',
    '> svg': {
      fill: theme.palette.background.orangeDisabled,
    },
  },

  '> svg': {
    width: '25px',
    height: '25px',
    fill: isCurrentRoute ? theme.palette.background.black : theme.palette.background.orange,
  },

  '&:hover': {
    transition: theme.transitions.create('background-color'),
    backgroundColor: isCurrentRoute ? theme.palette.background.orange : 'transparent',

    '> svg': {
      fill: isCurrentRoute ? theme.palette.background.black : theme.palette.background.orange,
    },
  },
}));
