import React, { PropsWithChildren } from 'react';

import { TargetDefinitionFormBaseInput, TargetDefinitionInput } from './target-definition-form-input.styles';
import { ITargetDefinitionFormInputProps } from './target-definition-form-input.types';

function TargetDefinitionFormInput<T extends { [key: string]: any }>({
  id,
  label,
  name,
  handleChange,
  values,
  type,
  disabled,
}: PropsWithChildren<ITargetDefinitionFormInputProps<T>>) {
  const isTextArea = !type || type === 'text';
  return isTextArea ? (
    <TargetDefinitionFormBaseInput
      className="target-definition-form-input"
      id={id}
      label={label}
      name={name}
      handleChange={handleChange}
      values={values}
      disabled={disabled}
    />
  ) : (
    <TargetDefinitionInput
      className="target-definition-form-input"
      id={id}
      label={label}
      name={name}
      handleChange={handleChange}
      values={values}
      type={type}
      isLabelShown
    />
  );
}

export default TargetDefinitionFormInput;
