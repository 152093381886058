import { styled } from '@mui/material/styles';
import Container, { ContainerProps } from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const CompanyNewsWrapper = styled(Container)<ContainerProps & { width?: string; }>(({ theme, width = '50%' }) => ({
  width,
  height: '100%',
  margin: 0,
  padding: '1.3% 2% 0 0',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    padding: '1.3% 2% 0 0',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const CompanyNewsHeader = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '90px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '25px',
  padding: '0 0 10px',

  [theme.breakpoints.up('xs')]: {
    padding: '0 0 10px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const CompanyName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  color: theme.palette.text.orange,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.125rem',
  },
}));

export const SectionHeaderName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1.3rem',
  fontWeight: 600,
  color: theme.palette.common.white,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.5rem',
  },
}));

export const CompanyNewsListContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  maxHeight: '100%',
  overflow: 'auto',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
