import React, { PropsWithChildren } from 'react';

import BaseMapper from '../../mapper/base-mapper/base-mapper.component';
import BaseFormWrapper from '../base-form-wrapper/base-form-wrapper.component';
import { InputsContainer } from './base-form.styles';
import { IBaseFormProps } from './base-form.types';

function BaseForm<T>({
  formHeader,
  submitForm,
  inputComponent: Input,
  inputsConfig,
  buttonComponent: Button,
  buttonTitle,
  isHeaderShown,
  className,
  isButtonLoading,
}: PropsWithChildren<IBaseFormProps<T>>) {
  return (
    <BaseFormWrapper formHeader={formHeader} className={className} isHeaderShown={isHeaderShown}>
      <InputsContainer>
        <BaseMapper component={Input} propsArray={inputsConfig} />
      </InputsContainer>
      <Button title={buttonTitle} handleClick={submitForm} isLoading={isButtonLoading} />
    </BaseFormWrapper>
  );
}

export default BaseForm;
