import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';

export const MarkAsResearchedContainer = styled(Stack)<StackProps>(({ theme }) => ({
  '> .marked-as-researched': {
    width: '190px',
    height: '40px',

    '& .MuiCircularProgress-root': { color: theme.palette.background.orange },

    '&.researched': {
      backgroundColor: theme.palette.background.orange,
      color: theme.palette.background.black,
      width: '130px',
      minWidth: '130px',

      '& .MuiCircularProgress-root': { color: theme.palette.background.black },
    },
  },

  [theme.breakpoints.up('xs')]: {
    padding: '20px 0',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
