import React, { SyntheticEvent, useDeferredValue, useEffect, useState } from 'react';

import { countriesSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { searchCountries } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { ICountry } from '../../../entities/company-target-group/domain/company-target-group.types';
import { ISelectCountriesWithAutocompleteProps } from './select-countries-with-autocomplete.types';

import AutocompleteWithMultipleChoices from '../../../shared/components/input/autocomplete-with-multiple-choice/autocomplete-with-multiple-choice.component';

const DEFAULT_QUERY_STATE = '';

function SelectCountriesWithAutocomplete({
  className,
  id,
  label,
  name,
  values,
  errors,
  disabled,
  handleChange,
}: ISelectCountriesWithAutocompleteProps) {
  const [query, setQuery] = useState<string>(DEFAULT_QUERY_STATE);
  const deferredQuery = useDeferredValue(query);

  const dispatch = useAppDispatch();

  const selectedCountries = values[name];
  const countries = useAppSelector(countriesSelector);

  function handleOptionsChange(event: SyntheticEvent<Element, Event>, value: readonly ICountry[]) {
    handleChange(name, value as ICountry[]);
    setQuery(DEFAULT_QUERY_STATE);
  }

  function handleOptionDelete(optionToDelete: string) {
    const optionsAfterDelete = selectedCountries.filter((option: ICountry) => option.name !== optionToDelete);
    handleChange(name, optionsAfterDelete);
  }

  function handleInputChange(event: SyntheticEvent<Element, Event>, newInputValue: string) {
    setQuery(newInputValue);
  }

  function handleOptionsFilter(selectedOptionsArray: ICountry[]) {
    return (allOptions: ICountry[]) =>
      allOptions.filter(
        (option) => !selectedOptionsArray.some((selectedOption) => option.name === selectedOption.name),
      );
  }

  useEffect(() => {
    dispatch(searchCountries({ search: deferredQuery }));
  }, [dispatch, deferredQuery]);

  return (
    <AutocompleteWithMultipleChoices<ICountry>
      id={id}
      className={className}
      label={label}
      name={name}
      selectedOptions={selectedCountries}
      options={countries}
      handleOptionsChange={handleOptionsChange}
      handleOptionDelete={handleOptionDelete}
      handleInputChange={handleInputChange}
      handleOptionsFilter={handleOptionsFilter(selectedCountries)}
      errors={errors}
      disabled={disabled}
    />
  );
}

export default SelectCountriesWithAutocomplete;
