import { check } from '../../../shared/libs/validator/check.factory';
import { ValidatorResult } from '../../../shared/domain/types/validation.types';

export const researchFormValidationStrategy: { [key: string]: (param: any) => ValidatorResult } = {
  companyName: (companyName: string) => check('companyName', companyName).isString().notEmpty().validate(),
  website: (website: string) => check('website', website).isString().notEmpty().validate(),
  firstName: (firstName: string) => check('firstName', firstName).isString().notEmpty().validate(),
  lastName: (lastName: string) => check('lastName', lastName).isString().notEmpty().validate(),
  email: (email: string) => check('email', email).isString().notEmpty().isEmail().validate(),
  jobTitle: (jobTitle: string) => check('jobTitle', jobTitle).isString().notEmpty().validate(),
  countries: (countries: string) => check('countries', countries).notEmpty().validate(),
};

export function generatePlaceholdersValidationStrategy<T extends Record<string, string>>(
  placeholders: T,
): Record<keyof T, (param: any) => ValidatorResult> {
  const generateStrategy = (key: keyof T) => [
    String(key),
    (value: string) => check(String(key), value).isString().notEmpty().validate(),
  ];
  return Object.fromEntries(Object.keys(placeholders).map(generateStrategy));
}
