import React from 'react';
import { Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import SolidOrangeRectNavButton from '../../../../shared/components/button/solid-orange-rect-nav-button/solid-orange-rect-nav-button.component';
import { NavigationPaths, Routes } from '../../../../shared/domain/constants/routing.constants';
import { useIsRouteMatched } from '../../../../shared/hooks/use-is-route-matched.hook';

function ReportingNavigation() {
  const { isRouteMatched } = useIsRouteMatched();

  const navigate = useNavigate();

  const { clientId } = useParams();

  return (
    <Stack direction="row" spacing={{ xs: 0.8, sm: 1.5, md: 2.5 }} justifyContent="flex-end" alignItems="center">
      <SolidOrangeRectNavButton
        title="Analytics & Reporting"
        handleClick={() => navigate(NavigationPaths.ANALYTICS_AND_REPORTING(clientId))}
        isCurrentRoute={isRouteMatched(Routes.ANALYTICS_AND_REPORTING)}
      />
      <SolidOrangeRectNavButton
        title="Market Tracking"
        handleClick={() => navigate(NavigationPaths.MARKET_TRACKING(clientId))}
        isCurrentRoute={isRouteMatched(Routes.MARKET_TRACKING)}
      />
      <SolidOrangeRectNavButton
        title="Company Tracking"
        handleClick={() => navigate(NavigationPaths.COMPANY_TRACKING(clientId))}
        isCurrentRoute={isRouteMatched(Routes.COMPANY_TRACKING)}
      />
    </Stack>
  );
}

export default ReportingNavigation;
