import React, { Fragment } from 'react';

import ReviewEmailsHeader from './review-emails-header/review-emails-header.component';
import ReviewEmailsMain from './review-emails-main/review-emails-main.component';

function ReviewEmailsRightSection() {
  return (
    <Fragment>
      <ReviewEmailsHeader />
      <ReviewEmailsMain />
    </Fragment>
  );
}

export default ReviewEmailsRightSection;
