import React from 'react';

import { MuiTableCell } from '../table.styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SpecialCell({ index, children, propsForChildren }: any) {
  return (
    <MuiTableCell align="left" key={index}>
      {children}
    </MuiTableCell>
  );
}

export default React.memo(SpecialCell, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.propsForChildren) === JSON.stringify(nextProps.propsForChildren);
});
