import React from 'react';
import { styled } from '@mui/material';

function StatusUncheckedIcon() {
  const SvgIcon = styled('svg')(() => ({
    width: '20px',
    height: '20px',
  }));
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="13.8091" height="14.5" rx="3.25" stroke="#252525" strokeWidth="1.5" />
    </SvgIcon>
  );
}

export default StatusUncheckedIcon;
