import React from 'react';

import ReviewEmailsLeftSection from '../../modules/review-emails-left-section/components/review-emails-left-section.component';
import ReviewEmailsRightSection from '../../modules/review-emails-right-section/components/review-emails-right-section.component';
import TwoSectionsScreenLayout from '../../app/layouts/two-sections-screen/two-sections-screen-layout.component';

function ReviewEmails() {
  return (
    <TwoSectionsScreenLayout
      leftSectionComponent={ReviewEmailsLeftSection}
      rightSectionComponent={ReviewEmailsRightSection}
      customPadding="2.5% 3.5% 0"
    />
  );
}

export default ReviewEmails;
