import React from 'react';

import { useAppDispatch } from '../../../app/store/utils/redux.hooks';
import { setCompanyTargetGroupRejectionStatus } from '../../../entities/company-target-group/store/company-target-group.slice';
import { RejectionStatus } from '../../../entities/company-target-group/domain/company-target-group.types';
import { DontTickContactContainer } from './tick-dont-contact.styles';
import { ITickDontContactProps } from './tick-dont-contact.types';

import Checkbox from '../../../shared/components/checkbox/checkbox.component';
import StatusUncheckedIcon from '../../../shared/components/svg-icon/status-unchecked.component';
import StatusCheckedBlackIcon from '../../../shared/components/svg-icon/status-checked-black.component';

function TickDontContact({ companyTargetGroupId, rejectionStatus }: ITickDontContactProps) {
  const isChecked = rejectionStatus === RejectionStatus.DONT_CONTACT;

  const dispatch = useAppDispatch();

  function onCheckboxClick() {
    const status = isChecked ? null : RejectionStatus.DONT_CONTACT;
    dispatch(setCompanyTargetGroupRejectionStatus({ companyTargetGroupId, rejectionStatus: status }));
  }

  return (
    <DontTickContactContainer>
      <Checkbox
        key={companyTargetGroupId}
        isChecked={isChecked}
        className="dont-contact-checkbox"
        uncheckedIcon={<StatusUncheckedIcon />}
        checkedIcon={<StatusCheckedBlackIcon />}
        onCheckboxClick={onCheckboxClick}
      />
    </DontTickContactContainer>
  );
}

export default TickDontContact;
