/* eslint-disable no-param-reassign */
import { BaseListServerResponse } from '../../../shared/domain/types/base.types';

function updateStateAndAppendToDataList<
  State extends { [key: string]: any },
  Payload extends BaseListServerResponse<Payload>,
>(state: State, stateProperty: string, payload: Payload) {
  state[stateProperty].data = {
    ...payload,
    items: [...(state[stateProperty].data.items || []), ...payload.items],
  };
}

function updateStateAndPrependToDataList<
  State extends { [key: string]: any },
  Payload extends BaseListServerResponse<Payload>,
>(state: State, stateProperty: string, payload: Payload) {
  state[stateProperty].data = {
    ...payload,
    items: [...payload.items, ...(state[stateProperty].data.items || [])],
  };
}

export function updateStateAndHandlePaginatedData<
  State extends { [key: string]: any },
  Payload extends BaseListServerResponse<any>,
>(state: State, stateProperty: string, payload: Payload, skip: number = 12) {
  const prevSkip = state[stateProperty].data.pagination.skip;
  const newSkip = payload.pagination.skip;
  if (newSkip <= skip) {
    if (prevSkip > newSkip) updateStateAndPrependToDataList(state, stateProperty, payload);
    else updateStateAndAppendToDataList(state, stateProperty, payload);
  } else updateStateAndAppendToDataList(state, stateProperty, payload);
}
