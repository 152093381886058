import { FormFieldValueType, TargetResearchFormField } from '../components/target-research-form.types';

import ChangeCompanyTargetGroup from '../../../features/change-company-target-group/components/change-company-target-group.component';
import SelectCountriesWithAutocomplete from '../../../features/select-countries-with-autocomplete/components/select-countries-with-autocomplete.component';
import TargetResearchFormInput from '../components/target-research-form-input/target-research-form-input.component';
import CheckboxSelectorInput from '../../../features/checkbox-selector-input/checkbox-selector-input.component';

export const targetResearchFormStrategy = {
  [TargetResearchFormField.COMPANY_NAME]: {
    id: `1_${TargetResearchFormField.COMPANY_NAME}`,
    label: `${TargetResearchFormField.COMPANY_NAME} *`,
    name: 'companyName',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `1_${TargetResearchFormField.COMPANY_NAME}`,
  },
  [TargetResearchFormField.WEBSITE]: {
    id: `2_${TargetResearchFormField.WEBSITE}`,
    label: `${TargetResearchFormField.WEBSITE} *`,
    name: 'website',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `2_${TargetResearchFormField.WEBSITE}`,
  },
  [TargetResearchFormField.ACQUIRED]: {
    id: `14_${TargetResearchFormField.ACQUIRED}`,
    label: `${TargetResearchFormField.ACQUIRED}`,
    name: 'acquired',
    inputValueType: FormFieldValueType.SPECIFIC_FIELD,
    component: CheckboxSelectorInput,
    key: `14_${TargetResearchFormField.ACQUIRED}`,
  },
  [TargetResearchFormField.ACQUIRER]: {
    id: `20_${TargetResearchFormField.ACQUIRER}`,
    label: TargetResearchFormField.ACQUIRER,
    name: 'acquirer',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `20_${TargetResearchFormField.ACQUIRER}`,
  },
  [TargetResearchFormField.BACKED_BY_PE]: {
    id: `15_${TargetResearchFormField.BACKED_BY_PE}`,
    label: `${TargetResearchFormField.BACKED_BY_PE}`,
    name: 'backedByPE',
    inputValueType: FormFieldValueType.SPECIFIC_FIELD,
    component: CheckboxSelectorInput,
    key: `15_${TargetResearchFormField.BACKED_BY_PE}`,
  },
  [TargetResearchFormField.PE_BACKER]: {
    id: `21_${TargetResearchFormField.PE_BACKER}`,
    label: TargetResearchFormField.PE_BACKER,
    name: 'PEbacker',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `21_${TargetResearchFormField.PE_BACKER}`,
  },
  [TargetResearchFormField.BACKED_BY_VC]: {
    id: `16_${TargetResearchFormField.BACKED_BY_VC}`,
    label: `${TargetResearchFormField.BACKED_BY_VC}`,
    name: 'backedByVC',
    inputValueType: FormFieldValueType.SPECIFIC_FIELD,
    component: CheckboxSelectorInput,
    key: `16_${TargetResearchFormField.BACKED_BY_VC}`,
  },
  [TargetResearchFormField.VC_BACKER]: {
    id: `22_${TargetResearchFormField.VC_BACKER}`,
    label: TargetResearchFormField.VC_BACKER,
    name: 'VCbacker',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `22_${TargetResearchFormField.VC_BACKER}`,
  },
  [TargetResearchFormField.MADE_ACQUISITIONS]: {
    id: `17_${TargetResearchFormField.MADE_ACQUISITIONS}`,
    label: `${TargetResearchFormField.MADE_ACQUISITIONS}`,
    name: 'madeAcquisitions',
    inputValueType: FormFieldValueType.SPECIFIC_FIELD,
    component: CheckboxSelectorInput,
    key: `17_${TargetResearchFormField.MADE_ACQUISITIONS}`,
  },
  [TargetResearchFormField.ACQUIRED_COMPANIES]: {
    id: `23_${TargetResearchFormField.ACQUIRED_COMPANIES}`,
    label: TargetResearchFormField.ACQUIRED_COMPANIES,
    name: 'acquiredCompanies',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `23_${TargetResearchFormField.ACQUIRED_COMPANIES}`,
  },
  [TargetResearchFormField.COMPANY_LINKEDIN]: {
    id: `8_${TargetResearchFormField.COMPANY_LINKEDIN}`,
    label: TargetResearchFormField.COMPANY_LINKEDIN,
    name: 'companyLinkedIn',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `8_${TargetResearchFormField.COMPANY_LINKEDIN}`,
  },
  [TargetResearchFormField.HEADCOUNT]: {
    id: `9_${TargetResearchFormField.HEADCOUNT}`,
    label: TargetResearchFormField.HEADCOUNT,
    name: 'headcount',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `9_${TargetResearchFormField.HEADCOUNT}`,
  },
  [TargetResearchFormField.TARGET_GROUP]: {
    id: `10_${TargetResearchFormField.TARGET_GROUP}`,
    label: TargetResearchFormField.TARGET_GROUP,
    name: 'targetGroup',
    inputValueType: FormFieldValueType.SPECIFIC_FIELD,
    component: ChangeCompanyTargetGroup,
    key: `10_${TargetResearchFormField.TARGET_GROUP}`,
  },
  [TargetResearchFormField.SHORT_DESCRIPTION]: {
    id: `11_${TargetResearchFormField.SHORT_DESCRIPTION}`,
    label: TargetResearchFormField.SHORT_DESCRIPTION,
    name: 'shortDescription',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `11_${TargetResearchFormField.SHORT_DESCRIPTION}`,
  },
  [TargetResearchFormField.COUNTRIES]: {
    id: `12_${TargetResearchFormField.COUNTRIES}`,
    label: `${TargetResearchFormField.COUNTRIES} *`,
    name: 'countries',
    inputValueType: FormFieldValueType.SPECIFIC_FIELD,
    component: SelectCountriesWithAutocomplete,
    key: `12_${TargetResearchFormField.COUNTRIES}`,
  },
  [TargetResearchFormField.REVENUE]: {
    id: `18_${TargetResearchFormField.REVENUE}`,
    label: TargetResearchFormField.REVENUE,
    name: 'revenue',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `18_${TargetResearchFormField.REVENUE}`,
  },
  [TargetResearchFormField.EBITDA]: {
    id: `19_${TargetResearchFormField.EBITDA}`,
    label: TargetResearchFormField.EBITDA,
    name: 'EBITDA',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `19_${TargetResearchFormField.EBITDA}`,
  },
  [TargetResearchFormField.OTHER_INFO]: {
    id: `13_${TargetResearchFormField.OTHER_INFO}`,
    label: TargetResearchFormField.OTHER_INFO,
    name: 'otherInfo',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `13_${TargetResearchFormField.OTHER_INFO}`,
  },
  [TargetResearchFormField.COMMENT]: {
    id: `133_${TargetResearchFormField.COMMENT}`,
    label: TargetResearchFormField.COMMENT,
    name: 'comment',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `133_${TargetResearchFormField.COMMENT}`,
  },
};

export const generatePlaceholdersFormStrategy = (placeholders: Record<string, string>) => {
  if (!placeholders) return {};
  return Object.fromEntries(
    Object.keys(placeholders)
      .sort()
      .map((key) => [
        key,
        {
          id: `id_placeholder_${key}`,
          label: key,
          name: `placeholders.${key}`,
          inputValueType: FormFieldValueType.COMMON_FIELD,
          component: TargetResearchFormInput,
          key: `key_placeholder_${key}`,
        },
      ]),
  );
};

export const leadFormStrategy = {
  [TargetResearchFormField.FIRST_NAME]: {
    id: `1_${Date.now()}_${TargetResearchFormField.FIRST_NAME}`,
    label: `${TargetResearchFormField.FIRST_NAME} *`,
    name: 'firstName',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `1_${Date.now()}_${TargetResearchFormField.FIRST_NAME}`,
  },
  [TargetResearchFormField.LAST_NAME]: {
    id: `2_${Date.now()}_${TargetResearchFormField.LAST_NAME}`,
    label: `${TargetResearchFormField.LAST_NAME} *`,
    name: 'lastName',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `2_${Date.now()}_${TargetResearchFormField.LAST_NAME}`,
  },
  [TargetResearchFormField.EMAIL]: {
    id: `3_${Date.now()}_${TargetResearchFormField.EMAIL}`,
    label: `${TargetResearchFormField.EMAIL} *`,
    name: 'email',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `3_${Date.now()}_${TargetResearchFormField.EMAIL}`,
  },
  [TargetResearchFormField.JOB_TITLE]: {
    id: `4_${Date.now()}_${TargetResearchFormField.JOB_TITLE}`,
    label: `${TargetResearchFormField.JOB_TITLE} *`,
    name: 'jobTitle',
    defaultState: [],
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `4_${Date.now()}_${TargetResearchFormField.JOB_TITLE}`,
  },
  [TargetResearchFormField.PERSON_LINKEDIN]: {
    id: `5_${Date.now()}_${TargetResearchFormField.PERSON_LINKEDIN}`,
    label: TargetResearchFormField.PERSON_LINKEDIN,
    name: 'linkedIn',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `5_${Date.now()}_${TargetResearchFormField.PERSON_LINKEDIN}`,
  },
};

export const generateLeadForm = () => ({
  [TargetResearchFormField.FIRST_NAME]: {
    id: `1_${Date.now()}_${TargetResearchFormField.FIRST_NAME}`,
    label: `${TargetResearchFormField.FIRST_NAME} *`,
    name: 'firstName',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `1_${Date.now()}_${TargetResearchFormField.FIRST_NAME}`,
  },
  [TargetResearchFormField.LAST_NAME]: {
    id: `2_${Date.now()}_${TargetResearchFormField.LAST_NAME}`,
    label: `${TargetResearchFormField.LAST_NAME} *`,
    name: 'lastName',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `2_${Date.now()}_${TargetResearchFormField.LAST_NAME}`,
  },
  [TargetResearchFormField.EMAIL]: {
    id: `3_${Date.now()}_${TargetResearchFormField.EMAIL}`,
    label: `${TargetResearchFormField.EMAIL} *`,
    name: 'email',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `3_${Date.now()}_${TargetResearchFormField.EMAIL}`,
  },
  [TargetResearchFormField.JOB_TITLE]: {
    id: `4_${Date.now()}_${TargetResearchFormField.JOB_TITLE}`,
    label: `${TargetResearchFormField.JOB_TITLE} *`,
    name: 'jobTitle',
    defaultState: [],
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `4_${Date.now()}_${TargetResearchFormField.JOB_TITLE}`,
  },
  [TargetResearchFormField.PERSON_LINKEDIN]: {
    id: `5_${Date.now()}_${TargetResearchFormField.PERSON_LINKEDIN}`,
    label: TargetResearchFormField.PERSON_LINKEDIN,
    name: 'personLinkedIn',
    inputValueType: FormFieldValueType.COMMON_FIELD,
    component: TargetResearchFormInput,
    key: `5_${Date.now()}_${TargetResearchFormField.PERSON_LINKEDIN}`,
  },
});
