import { FormFieldValueType } from '../components/target-definition-form.types';
import { TargetGroupFormField } from '../../../entities/target-group/domain/target-group.types';
import { currentTargetGroupSelector } from '../../../entities/target-group/store/target-group.selectors';

import TargetDefinitionFormInput from '../components/target-definition-form-input/target-definition-form-input.component';
import InputWithChips from '../../../shared/components/input/input-with-multiple-chips/input-with-multiple-chips.component';
import SelectCountriesWithAutocomplete from '../../../features/select-countries-with-autocomplete/components/select-countries-with-autocomplete.component';
import ShowPromptOutcome from '../../../features/show-prompt-outcome/components/show-prompt-outcome.component';
import Copy from '../../../features/copy/components/copy.component';
import { UserRole } from '../../../entities/user/domain/user.types';
import ShowApolloSearchOutcome from '../../../features/show-apollo-search-outcome/components/show-apollo-search-outcome.component';

export const targetDefinitionFormStrategy = {
  [TargetGroupFormField.TARGET_GROUP_NAME]: {
    id: `1_${TargetGroupFormField.TARGET_GROUP_NAME}`,
    label: TargetGroupFormField.TARGET_GROUP_NAME,
    name: 'name',
    type: 'text',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `1_${TargetGroupFormField.TARGET_GROUP_NAME}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.SHORT_DESCRIPTION]: {
    id: `2_${TargetGroupFormField.SHORT_DESCRIPTION}`,
    label: TargetGroupFormField.SHORT_DESCRIPTION,
    name: 'description',
    type: 'text',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `2_${TargetGroupFormField.SHORT_DESCRIPTION}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.AI_PROMPT]: {
    id: `22_${TargetGroupFormField.AI_PROMPT}`,
    label: (
      <div style={{ display: 'flex' }}>
        <p>{TargetGroupFormField.AI_PROMPT}</p>
        <ShowPromptOutcome />
      </div>
    ) as any,
    name: 'aiPrompt',
    type: 'text',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `22_${TargetGroupFormField.AI_PROMPT}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.MAX_HEADCOUNT]: {
    id: `33_${TargetGroupFormField.MAX_HEADCOUNT}`,
    label: TargetGroupFormField.MAX_HEADCOUNT,
    name: 'maxHeadcount',
    type: 'number',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `33_${TargetGroupFormField.MAX_HEADCOUNT}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.MIN_HEADCOUNT]: {
    id: `333_${TargetGroupFormField.MIN_HEADCOUNT}`,
    label: TargetGroupFormField.MIN_HEADCOUNT,
    name: 'minHeadcount',
    type: 'number',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `333_${TargetGroupFormField.MIN_HEADCOUNT}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.SIZE_REVENUE]: {
    id: `4_${TargetGroupFormField.SIZE_REVENUE}`,
    label: TargetGroupFormField.SIZE_REVENUE,
    name: 'revenue',
    type: 'text',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `4_${TargetGroupFormField.SIZE_REVENUE}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.LOCATIONS]: {
    id: `55_${TargetGroupFormField.LOCATIONS}`,
    label: (
      <div style={{ display: 'flex' }}>
        <p>{TargetGroupFormField.LOCATIONS}</p>
        <Copy
          selector={currentTargetGroupSelector}
          field={'locations'}
          parseValue={({ name }: { name: string }) => name}
        />
      </div>
    ) as any,
    name: 'locations',
    type: 'text',
    // defaultState: [],
    inputValueType: FormFieldValueType.MULTIPLE,
    component: SelectCountriesWithAutocomplete,
    key: `55_${TargetGroupFormField.LOCATIONS}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.KEYWORDS]: {
    id: `6_${TargetGroupFormField.KEYWORDS}`,
    label: (
      <div style={{ display: 'flex' }}>
        <p>{TargetGroupFormField.KEYWORDS}</p>
        <Copy selector={currentTargetGroupSelector} field={'keywords'} />
      </div>
    ) as any,
    name: 'keywords',
    type: 'text',
    defaultState: [],
    inputValueType: FormFieldValueType.MULTIPLE,
    component: InputWithChips,
    key: `6_${TargetGroupFormField.KEYWORDS}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.INDUSTRY_FILTERS]: {
    id: `66_${TargetGroupFormField.INDUSTRY_FILTERS}`,
    label: (
      <div style={{ display: 'flex' }}>
        <p>{TargetGroupFormField.INDUSTRY_FILTERS}</p>
        <Copy selector={currentTargetGroupSelector} field={'industryFilters'} />
      </div>
    ) as any,
    name: 'industryFilters',
    type: 'text',
    defaultState: [],
    inputValueType: FormFieldValueType.MULTIPLE,
    component: InputWithChips,
    key: `66_${TargetGroupFormField.INDUSTRY_FILTERS}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.EXAMPLE_COMPANIES]: {
    id: `7_${TargetGroupFormField.EXAMPLE_COMPANIES}`,
    label: TargetGroupFormField.EXAMPLE_COMPANIES,
    name: 'goodFitCompaniesExamples',
    type: 'text',
    defaultState: [],
    isClickableLink: true,
    inputValueType: FormFieldValueType.MULTIPLE,
    component: InputWithChips,
    key: `7_${TargetGroupFormField.EXAMPLE_COMPANIES}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.BAD_EXAMPLE_COMPANIES]: {
    id: `66_${TargetGroupFormField.BAD_EXAMPLE_COMPANIES}`,
    label: TargetGroupFormField.BAD_EXAMPLE_COMPANIES,
    name: 'badFitCompaniesExamples',
    type: 'text',
    defaultState: [],
    isClickableLink: true,
    inputValueType: FormFieldValueType.MULTIPLE,
    component: InputWithChips,
    key: `66_${TargetGroupFormField.BAD_EXAMPLE_COMPANIES}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.APOLLO_SEARCHES]: {
    id: `666_${TargetGroupFormField.APOLLO_SEARCHES}`,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p>{TargetGroupFormField.APOLLO_SEARCHES}</p>
        <Copy selector={currentTargetGroupSelector} field={'apolloSearches'} />
        <ShowApolloSearchOutcome />
      </div>
    ) as any,
    name: 'apolloSearches',
    type: 'text',
    defaultState: [],
    isClickableLink: true,
    inputValueType: FormFieldValueType.MULTIPLE,
    component: InputWithChips,
    key: `666_${TargetGroupFormField.APOLLO_SEARCHES}`,
    isHiddenFor: [UserRole.CLIENT],
  },
  [TargetGroupFormField.OTHER_RELEVANT_INFORMATION]: {
    id: `10_${TargetGroupFormField.OTHER_RELEVANT_INFORMATION}`,
    label: TargetGroupFormField.OTHER_RELEVANT_INFORMATION,
    name: 'otherRelevantInformation',
    type: 'text',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `10_${TargetGroupFormField.OTHER_RELEVANT_INFORMATION}`,
    isReadonlyFor: [UserRole.CLIENT],
  },
};
