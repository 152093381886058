import React, { PropsWithChildren, useState } from 'react';

import BaseTooltip from '../../tooltip/base-tooltip.component';
import PasswordVisibilitySwitcher from './password-visibility-switcher/password-visibility-switcher.component';
import ValidationErrorsHelper from '../../helper/validation-errors-helper/validation-errors-helper.component';
import {
  Input,
  Label,
  FormControlContainer,
  InputIcon,
  additionalBaseInputStyles,
  defaultBaseInputStyleSchema,
} from './base-input.styles';
import { InputInfoUiType, IBaseInputProps } from './base-input.types';

function BaseInput<T extends { [key: string]: any }>({
  id,
  className,
  title,
  label,
  name,
  type,
  handleChange,
  values,
  errors,
  styleSchema,
  isLabelShown,
  isPlaceholderShown,
  infoUiType,
  isPasswordField,
  autoFocus,
  disabled,
  inputProps = {},
}: PropsWithChildren<IBaseInputProps<T>> & { inputProps?: any }): JSX.Element {
  const [passwordInputType, setPasswordInputType] = useState('password');

  const { tooltipStyleSchema } = styleSchema || defaultBaseInputStyleSchema; // do i need this?

  const endAdornmentIcon = (
    <InputIcon position="end">
      <PasswordVisibilitySwitcher setPasswordInputType={setPasswordInputType} />
    </InputIcon>
  );

  return (
    <FormControlContainer error={errors?.[name]?.hasError} className={className}>
      {isLabelShown ? (
        <Label shrink htmlFor={id}>
          {label ?? ''}
        </Label>
      ) : null}
      <BaseTooltip
        arrow
        title={<ValidationErrorsHelper errors={errors} name={name} id={id} />}
        placement={tooltipStyleSchema?.placement || defaultBaseInputStyleSchema.tooltipStyleSchema.placement}
        open={infoUiType === InputInfoUiType.TOOLTIP ? errors?.[name]?.hasError : false}
        styleSchema={tooltipStyleSchema?.styleSchema || defaultBaseInputStyleSchema.tooltipStyleSchema.styleSchema}
        transitionComponent={
          tooltipStyleSchema?.transition || defaultBaseInputStyleSchema.tooltipStyleSchema.transition
        }
      >
        <Input
          id={id}
          aria-describedby={id}
          type={isPasswordField ? passwordInputType : type}
          name={name}
          placeholder={isPlaceholderShown ? title : undefined}
          value={values[name]}
          onChange={handleChange}
          isPasswordField={isPasswordField || false}
          endAdornment={isPasswordField ? endAdornmentIcon : null}
          inputProps={{
            sx: isPlaceholderShown ? additionalBaseInputStyles() : null,
            ...inputProps,
          }}
          required
          autoFocus={autoFocus || false}
          disabled={disabled || false}
        />
      </BaseTooltip>
      {infoUiType === InputInfoUiType.FORM_HELPER ? (
        <ValidationErrorsHelper errors={errors} name={name} id={id} />
      ) : null}
    </FormControlContainer>
  );
}

export default BaseInput;
