import React from 'react';
import { IUploadedFileCardProps } from './uploaded-file-card.types';
import EntityCardWithActions from '../../../../shared/components/card/entity-card-with-actions/entity-card-with-actions.component';
import DeleteUploadedFile from '../delete-uploaded-file/delete-uploaded-file.component';

function UploadedFileCard({ targetGroupId, fileName }: IUploadedFileCardProps) {
  return (
    <EntityCardWithActions title={fileName}>
      <DeleteUploadedFile targetGroupId={targetGroupId} fileName={fileName} />
    </EntityCardWithActions>
  );
}

export default React.memo(UploadedFileCard);
