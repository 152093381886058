import React, { Fragment } from 'react';

import FetchCompaniesForResearchList from '../../../features/fetch-companies-for-research-list/components/fetch-companies-for-research-list.component';
import SelectResearchFilterWithDropdown from '../../../features/select-research-filter-with-dropdown/components/select-research-filter-with-dropdown.component';
import SelectTargetGroupWithDropdown from '../../../features/select-target-group-with-dropdown/components/select-target-group-with-dropdown.component';

function TargetResearchLeftSection() {
  return (
    <Fragment>
      <div style={{ width: '100%', marginBottom: '5px' }}>
        <SelectResearchFilterWithDropdown />
        <SelectTargetGroupWithDropdown showCounts />
      </div>
      <hr style={{ width: '80%', color: '#01071A', border: 'none', borderTop: '1.5px solid #01071A' }} />
      <FetchCompaniesForResearchList />
    </Fragment>
  );
}

export default TargetResearchLeftSection;
