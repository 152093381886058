import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const DeleteSequenceButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  '> svg': {
    width: '1.1rem',
    height: '1.1rem',
    color: theme.palette.text.orange,

    [theme.breakpoints.up('xl')]: {
      width: '1.1rem',
      height: '1rem',
    },
  },
}));
