import React from 'react';
import { Fade, Portal } from '@mui/material';

import { IFeatureModalProps } from './feature-modal.types';
import { ButtonContainer, ModalContainer } from './feature-modal.styles';
import BaseIconButton from '../../button/base-icon-button/base-icon-button.component';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function FeatureModal({ isOpened, children, className, handleClose, ...props }: IFeatureModalProps) {
  return isOpened ? (
    <Portal>
      <ModalContainer
        open
        maxWidth="sm"
        fullWidth
        TransitionComponent={Fade}
        className={className}
        onClose={handleClose}
      >
        <ButtonContainer>
          <BaseIconButton handleClick={handleClose} className="cross-modal-button" disableRipple>
            <img src="/cross-icon.svg" alt="" />
          </BaseIconButton>
        </ButtonContainer>
        {children}
      </ModalContainer>
    </Portal>
  ) : null;
}

export default FeatureModal;
