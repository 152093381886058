import React, { SyntheticEvent, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import AutocompleteWithMultipleChoices from '../../../shared/components/input/autocomplete-with-multiple-choice/autocomplete-with-multiple-choice.component';

import { IClientOption, ISelectClientsWithAutocompleteProps } from './select-clients-with-autocomplete.types';
import { SelectClientsWithDropdownContainer } from './select-clients-with-autocomplete.styles';
import { getAllClients, pushSelectedClientsToFilter } from '../../../entities/user/store/user.slice';
import { clientsSelector, userStateSelector } from '../../../entities/user/store/user.selectors';
import { IGetAllClientsItemDto } from '../../../entities/user/domain/user.types';

const DEFAULT_QUERY_STATE = '';

function SelectClientsWithAutocomplete({
  id,
  name,
  errors,
  disabled,
}: Omit<ISelectClientsWithAutocompleteProps, 'handleChange'>) {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState<string>(DEFAULT_QUERY_STATE);
  console.log(query);

  const values = useAppSelector(userStateSelector).selectedClients;

  const clients = useAppSelector(clientsSelector) ?? [];
  const options = clients.map((e: IGetAllClientsItemDto) => ({ ...e, name: e.companyName ?? e.id }));

  useEffect(() => {
    dispatch(getAllClients());
  }, [dispatch]);

  function handleChange(value: any): void {
    dispatch(pushSelectedClientsToFilter(value));
  }

  function handleOptionsChange(event: SyntheticEvent<Element, Event>, value: readonly IClientOption[]) {
    handleChange(value as IClientOption[]);
    setQuery(DEFAULT_QUERY_STATE);
  }

  function handleOptionDelete(optionToDelete: string) {
    const optionsAfterDelete = values.filter((option: IClientOption) => option.name !== optionToDelete);
    handleChange(optionsAfterDelete);
  }

  function handleInputChange(event: SyntheticEvent<Element, Event>, newInputValue: string) {
    setQuery(newInputValue);
  }

  function handleOptionsFilter(selectedOptionsArray: IClientOption[]) {
    return (allOptions: IClientOption[]) =>
      allOptions.filter(
        (option) => !selectedOptionsArray.some((selectedOption) => option.name === selectedOption.name),
      );
  }

  console.log({ options });
  return (
    <SelectClientsWithDropdownContainer>
      <AutocompleteWithMultipleChoices<IClientOption>
        id={id}
        className="some-class"
        name={name}
        placeholder={'Select clients to filter list'}
        selectedOptions={values}
        options={options as any}
        handleOptionsChange={handleOptionsChange}
        handleOptionDelete={handleOptionDelete}
        handleInputChange={handleInputChange}
        handleOptionsFilter={handleOptionsFilter(values)}
        errors={errors}
        disabled={disabled}
      />
    </SelectClientsWithDropdownContainer>
  );
}

export default SelectClientsWithAutocomplete;
