/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { companyThunksSelector, companiesFileSelector } from '../../../../entities/company/store/company.selectors';
import { currentTargetGroupParamsSelector } from '../../../../entities/target-group/store/target-group.selectors';
import {
  setCompaniesUploadPercent,
  setCompaniesFileMessage,
  uploadCompanies,
  resetUploadCompaniesThunk,
  setIsFileDataUploaded,
} from '../../../../entities/company/store/company.slice';
import { useAppDispatch, useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { FileRejectionSource } from './rejected-file/rejected-file.types';
import {
  FileInput,
  ImportCompaniesDropzoneContainer,
  UploadFormats,
  UploadIcon,
  UploadText,
} from './import-companies-dropzone.styles';

import AcceptedFile from './accepted-file/accepted-file.component';
import TextButton from '../../../../shared/components/button/text-button/text-button.component';
import RejectedFile from './rejected-file/rejected-file.component';
import { UploadLeadsStrategies } from '../../../../entities/company/domain/company.types';

const acceptedFileFormats = {
  'text/csv': [
    '.csv',
    'application/csv',
    'text/x-csv',
    'application/x-csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
  ],
};

function ImportCompaniesDropzone({ type, strategy }: { type: 'leads' | 'raw'; strategy?: UploadLeadsStrategies }) {
  const [acceptedFile, setAcceptedFile] = useState<File | undefined>();
  const [rejectedFile, setRejectedFile] = useState<FileRejection | undefined>();
  const [isImportStarted, setIsImportStarted] = useState(false);

  const { clientId } = useParams();

  const dispatch = useAppDispatch();

  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const { progressPercent, message: successMessage } = useAppSelector(companiesFileSelector);

  const {
    isError: isUploadFailed,
    error: uploadCompaniesError,
    isSuccess: isUploadSucceed,
    isFinished: isUploadFinished,
  } = useAppSelector(companyThunksSelector).uploadCompanies;

  function uploadFiles(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    dispatch(uploadCompanies({ clientId, targetGroupId, formData, type, strategy }));
  }

  function resetFileStatusesBeforeUpload() {
    setAcceptedFile(undefined);
    setRejectedFile(undefined);
    dispatch(resetUploadCompaniesThunk());
    setIsImportStarted(true);
  }

  function handleOnDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    resetFileStatusesBeforeUpload();
    if (fileRejections.length > 0) {
      setIsImportStarted(false);
      setRejectedFile(fileRejections[0]);
    } else {
      setAcceptedFile(acceptedFiles[0]);
      uploadFiles(acceptedFiles[0]);
    }
  }

  const dropzoneConfig = {
    multiple: false,
    accept: acceptedFileFormats,
    noClick: true,
    noKeyboard: true,
    onDrop: handleOnDrop,
  };
  const { getRootProps, getInputProps, open, isDragAccept, isFocused, isDragReject } = useDropzone(dropzoneConfig);

  useEffect(() => {
    if (isUploadFinished) {
      setIsImportStarted(false);
      dispatch(setIsFileDataUploaded(true));
    }
  }, [isUploadFinished]);

  useEffect(() => {
    dispatch(setCompaniesUploadPercent(0));
    dispatch(setCompaniesFileMessage(''));
  }, [acceptedFile]);

  return (
    <Fragment>
      <ImportCompaniesDropzoneContainer {...getRootProps({ isDragAccept, isFocused, isDragReject })}>
        <Stack direction="column" justifyContent="center" alignItems="center" width="100%" height="100%">
          <FileInput {...getInputProps()} />
          <UploadIcon src="/upload-icon.svg" alt="upload-icon" />
          <Stack direction="row" justifyContent="center" alignItems="center">
            <UploadText>Drag & drop files or</UploadText>
            <TextButton title="Browse" className="browse-file-button" handleClick={open} disableRipple />
          </Stack>
          <UploadFormats>Supported formats: CSV</UploadFormats>
        </Stack>
      </ImportCompaniesDropzoneContainer>
      {isImportStarted ? (
        <AcceptedFile
          fileName={acceptedFile ? acceptedFile?.name : ''}
          successMessage={successMessage}
          fileUploadPercent={progressPercent}
        />
      ) : isUploadSucceed ? (
        <AcceptedFile
          fileName={acceptedFile ? acceptedFile?.name : ''}
          successMessage={successMessage}
          fileUploadPercent={progressPercent}
        />
      ) : rejectedFile ? (
        <RejectedFile
          fileName={rejectedFile?.file?.name}
          error={rejectedFile?.errors[0]}
          errorSource={FileRejectionSource.REACT_DROPZONE}
        />
      ) : isUploadFailed ? (
        <RejectedFile
          fileName={acceptedFile ? acceptedFile?.name : ''}
          error={uploadCompaniesError?.message}
          errorSource={FileRejectionSource.SERVER}
        />
      ) : null}
    </Fragment>
  );
}

export default ImportCompaniesDropzone;
