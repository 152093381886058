import { ClientsCompanyByTargetGroup } from '../../../entities/company-target-group/domain/company-target-group.types';
import { IEntityCardProps } from '../../../shared/components/card/entity-card/entity-card.types';

export function formCompaniesEntityData(
  companiesEntityDataFromServer: ClientsCompanyByTargetGroup[],
  currentCompanyId: string,
  handleCompanyEntityClick: (companyId: string, companyName: string) => void,
): Partial<IEntityCardProps>[] {
  return companiesEntityDataFromServer?.map(({ companyId, companyName }) => {
    return {
      title: companyName,
      handleClick: handleCompanyEntityClick(companyId, companyName),
      isActiveEntity: currentCompanyId === companyId,
      key: `${companyId}_${companyName}`,
    };
  });
}
