import { SyntheticEvent, useState } from 'react';

export function useModal() {
  const [isOpened, setIsOpened] = useState(false);

  function handleOpen() {
    setIsOpened(true);
  }

  function handleClose(event: SyntheticEvent) {
    event.stopPropagation();
    setIsOpened(false);
  }

  return { isOpened, setIsOpened, handleOpen, handleClose };
}
