import { ReactNode } from 'react';

import {
  BaseLoaderPropsUnion,
  IBaseButtonLoaderProps,
  IBaseContainerLoaderProps,
  IBaseWindowLoaderProps,
  LoaderSubtype,
} from '../../feedback-ui/feedback-ui.types';

import BaseButtonLoader from '../base-button-loader/base-button-loader.component';
import BaseContainerLoader from '../base-container-loader/base-container-loader.component';
import BaseWindowLoader from '../base-window-loader/base-window-loader.component';

export const loaderSubtypeStrategy: {
  [Property in LoaderSubtype]: (props: BaseLoaderPropsUnion) => ReactNode;
} = {
  [LoaderSubtype.BUTTON_LOADER]: (props: BaseLoaderPropsUnion) => (
    <BaseButtonLoader {...(props as IBaseButtonLoaderProps)} />
  ),
  [LoaderSubtype.WINDOW_LOADER]: (props: BaseLoaderPropsUnion) => (
    <BaseWindowLoader {...(props as IBaseWindowLoaderProps)} />
  ),
  [LoaderSubtype.CONTAINER_LOADER]: (props: BaseLoaderPropsUnion) => (
    <BaseContainerLoader {...(props as IBaseContainerLoaderProps)} />
  ),
};
