import React from 'react';

import {
  getTargetGroupById,
  setCurrentTargetGroupParams,
} from '../../../../entities/target-group/store/target-group.slice';
import { useAppDispatch } from '../../../../app/store/utils/redux.hooks';
import { ITargetGroupCardProps } from './target-group-card.types';

import EntityCard from '../../../../shared/components/card/entity-card/entity-card.component';

function TargetGroupCard({ isActiveEntity, targetGroupId, targetGroupName }: ITargetGroupCardProps) {
  const dispatch = useAppDispatch();

  function handleTargetGroupEntityClick() {
    dispatch(setCurrentTargetGroupParams({ id: targetGroupId, name: targetGroupName }));
    dispatch(getTargetGroupById(targetGroupId));
  }

  return (
    <EntityCard title={targetGroupName} handleClick={handleTargetGroupEntityClick} isActiveEntity={isActiveEntity} />
  );
}

export default React.memo(TargetGroupCard);
