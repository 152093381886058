import React, { Fragment } from 'react';

import {
  companyForReviewSelector,
  currentCompanyForReviewParamsSelector,
} from '../../../../entities/company-target-group/store/company-target-group.selectors';
import { useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { PipelineStageName } from '../../../navigation/components/pipeline-navigation/pipeline-navigation.types';

import RejectLead from '../../../../features/reject-lead/components/reject-lead.component';
import ValidateLead from '../../../../features/validate-lead/components/validate-lead.component';
import RightSectionHeader from '../../../../app/layouts/layout-components/right-section-header/right-section-header.component';
import MarkAsPaused from '../../../../features/mark-as-paused/components/mark-as-paused.component';
import { PipelineStage } from '../../../../entities/company-target-group/domain/company-target-group.types';

const SECTION_HEADER = PipelineStageName.REVIEW_EMAILS;

function ReviewEmailsHeader() {
  const currentCompanyForReview = useAppSelector(currentCompanyForReviewParamsSelector);
  const { id: currentCompanyTargetGroupId } = currentCompanyForReview;
  const isCompanyForReviewChosen = Boolean(currentCompanyTargetGroupId);

  const { item } = useAppSelector(companyForReviewSelector);

  return (
    <RightSectionHeader
      headerName={SECTION_HEADER}
      isListEntityChosen={isCompanyForReviewChosen}
      hasControls
      controls={
        <Fragment>
          {item.isValidated || item.isRejected ? null : (
            <MarkAsPaused
              companyTargetGroupId={currentCompanyTargetGroupId}
              isMarkedAsPaused={item.pipelineStage === PipelineStage.REVIEW_PAUSED}
              onStage="review"
            />
          )}
          {item.isValidated ? null : <RejectLead />}
          <ValidateLead />
        </Fragment>
      }
    />
  );
}

export default ReviewEmailsHeader;
