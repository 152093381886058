import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';

import { EmailSequencesContainer } from './gtm-plan-form.styles';
import { updateTargetGroup } from '../../../entities/target-group/store/target-group.slice';

import EmailEditor from '../../target-group-email-editor/components/email-editor.component';
import SelectCampaignOwner from '../../../features/select-campaign-owner/components/select-campaign-owner.component';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { currentTargetGroupSelector } from '../../../entities/target-group/store/target-group.selectors';
import { ITargetGroupEmail } from '../../../entities/target-group/domain/target-group.types';
import DeleteSequence from '../../../features/delete-sequence/components/delete-seaquence.component';
import CreateSequence from '../../../features/create-sequence/components/create-sequence.component';

function EmailSequences({ items }: { items: ITargetGroupEmail[] }) {
  const result = [];
  let sequenceNumber = 0;

  for (let i = 0; i < items.length; i += 2) {
    if (!items[i]) break;
    sequenceNumber += 1;
    result.push(
      <EmailSequencesContainer>
        <div style={{ display: 'flex', width: '100%' }}>
          <h3>{`Sequence ${sequenceNumber}`}</h3>
          <DeleteSequence index={i} />
          {/* {sequenceNumber > 1 && sequenceNumber <= 3 ? <DeleteSequence index={i} /> : ''} */}
        </div>
        <EmailEditor indexInSequence={i} label="Email 1" />
        <EmailEditor indexInSequence={i + 1} label="Email 2" />
      </EmailSequencesContainer>,
    );
  }

  return result;
}

function GtmPlanForm() {
  const [isInitial, setIsInitial] = useState(true);
  const targetGroupFormData = useAppSelector(currentTargetGroupSelector);
  const emailFromSequences = targetGroupFormData.emailSequences;
  const targetGroupId = targetGroupFormData.id;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isInitial)
      dispatch(updateTargetGroup({ targetGroupId, body: { emailSequences: targetGroupFormData.emailSequences } }));
    setIsInitial(false);
  }, [dispatch, targetGroupFormData.emailSequences.length]);

  return (
    <Stack direction="column" width="100%">
      <SelectCampaignOwner />
      <EmailSequences items={emailFromSequences} />
      <div style={{ display: 'flex', width: '100%', paddingTop: '20px' }}>
        <CreateSequence />
        {/* {emailFromSequences.length < 6 ? <CreateSequence /> : ''} */}
      </div>
    </Stack>
  );
}

export default GtmPlanForm;
