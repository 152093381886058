import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const HelperHeader = styled(Typography)(({ theme }) => ({
  fontSize: '0.65rem',
  color: theme.palette.text.error,
  marginRight: '10px',

  [theme.breakpoints.up('xl')]: {
    fontSize: '0.8rem',
  },
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  fontSize: '0.65rem',
  color: theme.palette.common.white,

  [theme.breakpoints.up('xl')]: {
    fontSize: '0.8rem',
  },
}));
