import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

export const ConfirmActionContainer = styled(Dialog)<DialogProps>(() => ({
  '& .MuiPaper-root.MuiDialog-paper': { borderRadius: '10px', padding: '10px' },
}));

export const ConfirmActionTitle = styled(DialogTitle)<DialogTitleProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: theme.palette.text.primary,

  '> svg': {
    width: '1.25rem',
    height: '1.25rem',
    color: theme.palette.background.orange,
    marginRight: '12px',
  },
}));

export const ConfirmActionContent = styled(DialogContent)<DialogContentProps>(({ theme }) => ({
  fontSize: '0.8rem',
  color: theme.palette.text.primary,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
    lineHeight: '1.625rem',
  },
}));

export const ConfirmActionButtons = styled(DialogActions)<DialogActionsProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  alignItems: 'stretch',
  width: '100%',
  padding: 10,

  '> div > .action-button': {
    height: '40px',
    minWidth: '60px',
    width: 'auto',
  },

  '> div > .cancel-button': {
    height: '40px',
    minWidth: '60px',
    width: 'auto',
    padding: '6px 16px',
    color: theme.palette.text.orange,

    '&:hover': { backgroundColor: 'rgba(246, 174, 45, 0.1)' },
  },
}));
