import Button, { ButtonProps } from '@mui/material/Button';
import Paper, { PaperProps } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export type PopperButtonProps = ButtonProps & {};

export type PopperPaperProps = PaperProps & {};

export const PopperButton = styled(Button)<PopperButtonProps>(() => ({
  width: '120px',
  height: '40px',
}));

export const PopperPaper = styled(Paper)<PopperPaperProps>(() => ({
  width: '33.2vw',
  borderRadius: '10px',
  zIndex: '10000',
  position: 'relative',
  marginTop: '7px',
}));
