import React from 'react';

import { ISingleSectionScreenLayoutProps } from './single-sections-screen-with-message.types';
import { SectionContainer, SingleSectionScreenLayoutContainer } from './single-sections-screen-with-message.styles';

function SingleSectionScreenLayout({ message }: ISingleSectionScreenLayoutProps) {
  return (
    <SingleSectionScreenLayoutContainer>
      <SectionContainer>{message}</SectionContainer>
    </SingleSectionScreenLayoutContainer>
  );
}

export default SingleSectionScreenLayout;
