import React, { PropsWithChildren } from 'react';

import { IBaseTextareaProps } from './base-textarea.types';
import { MuiTextarea, TextareaContainer } from './base-textarea.styles';

import ValidationErrorsHelper from '../../helper/validation-errors-helper/validation-errors-helper.component';
import { getValueFromObject } from '../../../helpers/base.helpers';

function BaseTextarea<T extends { [key: string]: any }>({
  id,
  className,
  label,
  name,
  handleChange,
  values,
  errors,
  maxRows,
  disabled,
}: PropsWithChildren<IBaseTextareaProps<T>>) {
  return (
    <TextareaContainer>
      <MuiTextarea
        multiline
        id={id}
        label={label ?? null}
        name={name}
        value={getValueFromObject(values, name)}
        onChange={handleChange}
        className={className}
        maxRows={maxRows ?? undefined}
        hasError={errors?.[name]?.hasError ?? false}
        disabled={disabled ?? false}
      />
      {errors?.[name] && (
        <ValidationErrorsHelper errors={errors} name={name} id={id} className="textarea-validation-helper" />
      )}
    </TextareaContainer>
  );
}

export default BaseTextarea;
