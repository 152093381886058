/* eslint-disable no-param-reassign */
import { AnyAction, createAsyncThunk, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosProgressEvent } from 'axios';

import axios from '../../../app/services/axios.service';
import {
  ICompanyState,
  ICurrentCompany,
  IGetCompaniesByTargetGroupThunkParams,
  IGetNewsEventsThunkParams,
  INewsEventsCategory,
  INewsEventsListResponse,
  IUploadedCompaniesListResponse,
  NewsEventCategoryTypes,
  NewsEventsSortBy,
  UploadLeadsStrategies,
} from '../domain/company.types';
import { updateStateAndHandlePaginatedData } from '../helpers/news-events.helpers';

const NEWS_EVENTS_CATEGORIES: INewsEventsCategory[] = [
  { id: '1', name: NewsEventCategoryTypes.LeadershipChange, values: ['hires', 'promotes', 'leaves', 'retires'] },
  {
    id: '2',
    name: NewsEventCategoryTypes.Expansion,
    values: [
      'expands_offices_to',
      'expands_offices_in',
      'expands_facilities',
      'opens_new_location',
      'increases_headcount_by',
    ],
  },
  {
    id: '3',
    name: NewsEventCategoryTypes.FinancialEvent,
    values: [
      'acquires',
      'merges_with',
      'sells_assets_to',
      'receives_financing',
      'invests_into',
      'invests_into_assets',
      'goes_public',
    ],
  },
  {
    id: '4',
    name: NewsEventCategoryTypes.NewOffering,
    values: ['launches', 'integrates_with', 'is_developing'],
  },
  {
    id: '5',
    name: NewsEventCategoryTypes.CostCutting,
    values: ['closes_offices', 'decreases_headcount_by'],
  },
  {
    id: '6',
    name: NewsEventCategoryTypes.Partnerships,
    values: ['partners_with'],
  },
  {
    id: '7',
    name: NewsEventCategoryTypes.Awards,
    values: ['receives_award', 'recognized_as'],
  },
  {
    id: '8',
    name: NewsEventCategoryTypes.ContractWon,
    values: ['signs_new_client'],
  },
  {
    id: '9',
    name: NewsEventCategoryTypes.CorporateChallenges,
    values: ['files_suit_against', 'has_issues_with'],
  },
  {
    id: '10',
    name: NewsEventCategoryTypes.CompetitionNews,
    values: ['identified_as_competitor_of'],
  },
];

const defaultThunkStatus = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: {},
  isStarted: false,
  isFinished: false,
};

export const defaultCompanyState = {
  currentCompany: { id: '', name: '' },
  uploadedCompanies: {} as IUploadedCompaniesListResponse,
  newsEvents: {} as INewsEventsListResponse,
  sorting: { isSorted: true, sortBy: NewsEventsSortBy.Date },
  companiesFile: { progressPercent: 0, message: '', isFileDataUploaded: false },
  newsEventsCategories: NEWS_EVENTS_CATEGORIES,
  newsEventsFilterByCategories: [],
  thunks: {
    uploadCompanies: defaultThunkStatus,
    getCompaniesByTargetGroup: defaultThunkStatus,
    getNewsEventsByTargetGroup: defaultThunkStatus,
  },
};

export const companyState: ICompanyState = defaultCompanyState;

function handleUploadProgress(dispatch: ThunkDispatch<unknown, unknown, AnyAction>) {
  return function (progressEvent: AxiosProgressEvent) {
    const totalBytes = progressEvent.total as number;
    const loadedBytes = progressEvent.loaded;
    const percent = Math.round((loadedBytes * 100) / totalBytes);
    if (percent <= 100) dispatch({ type: 'company/setCompaniesUploadPercent', payload: percent });
  };
}

export const uploadCompanies = createAsyncThunk(
  'company/uploadCompanies',
  async (
    {
      clientId,
      targetGroupId,
      formData,
      type,
      strategy,
    }: {
      type: 'leads' | 'raw';
      clientId: string | undefined;
      targetGroupId: string;
      formData: FormData;
      strategy?: UploadLeadsStrategies;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const endpoint = `/company-target-group/upload-companies/${clientId}/${targetGroupId}/${type}${
        type === 'leads' ? `?strategy=${strategy}` : ''
      }`;
      const { data } = await axios.post(endpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: handleUploadProgress(dispatch),
      });
      return data.message;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCompaniesByTargetGroup = createAsyncThunk(
  'company/getCompaniesByTargetGroup',
  async ({ targetGroupId, take, skip }: IGetCompaniesByTargetGroupThunkParams, { rejectWithValue }) => {
    try {
      const endpoint = `/company/${targetGroupId}?take=${take ?? 50}&skip=${skip ?? 0}`;
      const { data } = await axios.get(endpoint);
      return data as IUploadedCompaniesListResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

function extractCategoriesValues(categories: INewsEventsCategory[]): string[] {
  const result: string[] = [];
  if (!categories) return [];
  const addLabelValuesToResult = ({ values }: INewsEventsCategory) => result.push(...values);
  categories.forEach(addLabelValuesToResult);
  return [...new Set(result)];
}

export const getNewsEventsByTargetGroup = createAsyncThunk(
  'company/getNewsEventsByTargetGroup',
  async (
    { targetGroupId, take, skip, sortBy, categories, clientId }: IGetNewsEventsThunkParams,
    { rejectWithValue },
  ) => {
    try {
      const categoriesValues = extractCategoriesValues(categories);
      const sortParam = sortBy ? `&sortBy=${sortBy}` : '';
      const categoriesParam = categoriesValues?.length ? `&categories=${JSON.stringify(categoriesValues)}` : '';
      const endpoint = `/company-target-group/event/${clientId}/${targetGroupId}?take=${take ?? 50}&skip=${
        skip ?? 0
      }${sortParam}${categoriesParam}`;
      const { data } = await axios.get(endpoint);
      return data as INewsEventsListResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

const companySlice = createSlice({
  name: 'company',
  initialState: companyState,
  reducers: {
    pushSelectedNewsEventsCategoryToFilter: (state, action: PayloadAction<INewsEventsCategory[]>) => {
      const categories = action.payload;
      state.newsEventsFilterByCategories = categories;
    },
    setNewsEventsSorting: (state, action: PayloadAction<NewsEventsSortBy>) => {
      const sortBy = action.payload;
      state.sorting.isSorted = true;
      state.sorting.sortBy = sortBy;
    },
    resetNewsEventsSorting: (state) => {
      state.sorting.isSorted = false;
      state.sorting.sortBy = undefined;
    },
    setCurrentCompany: (state, action: PayloadAction<ICurrentCompany>) => {
      const currentCompany = action.payload;
      state.currentCompany = currentCompany;
    },
    resetCurrentCompany: (state) => {
      state.currentCompany = { ...companyState }.currentCompany;
    },
    setCompaniesUploadPercent: (state, action: PayloadAction<number>) => {
      const percent = action.payload;
      state.companiesFile.progressPercent = percent;
    },
    setCompaniesFileMessage: (state, action: PayloadAction<string>) => {
      const message = action.payload;
      state.companiesFile.message = message;
    },
    setIsFileDataUploaded: (state, action: PayloadAction<boolean>) => {
      const isFileUploaded = action.payload;
      state.companiesFile.isFileDataUploaded = isFileUploaded;
    },
    // setUploadedCompaniesPagination: (state, action: PayloadAction<{ skip?: number; take?: number }>) => {
    //   const params = action.payload;
    //   if (params?.skip) state.uploadedCompanies.pagination.skip = params?.skip;
    //   if (params?.take) state.uploadedCompanies.pagination.take = params?.take;
    // },
    resetUploadedCompanies: (state) => {
      state.uploadedCompanies = { items: [], pagination: { take: 50, skip: 0 } };
    },
    resetNewsEvents: (state) => {
      state.newsEvents = { items: [], pagination: { take: 50, skip: 0 } };
    },
    resetUploadCompaniesThunk: (state) => {
      state.thunks.uploadCompanies = defaultThunkStatus;
    },
    resetCompanyState: (state) => {
      state.currentCompany = defaultCompanyState.currentCompany;
      state.uploadedCompanies = defaultCompanyState.uploadedCompanies;
      state.companiesFile = defaultCompanyState.companiesFile;
      state.thunks = defaultCompanyState.thunks;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadCompanies.pending, (state) => {
      state.thunks.uploadCompanies.isLoading = true;
      state.thunks.uploadCompanies.isSuccess = false;
      state.thunks.uploadCompanies.error = {};
      state.thunks.uploadCompanies.isError = false;
      state.thunks.uploadCompanies.isStarted = true;
    });
    builder.addCase(uploadCompanies.fulfilled, (state, action) => {
      const message = action.payload;
      state.companiesFile.message = message;
      state.thunks.uploadCompanies.isLoading = false;
      state.thunks.uploadCompanies.isSuccess = true;
      state.thunks.uploadCompanies.error = {};
      state.thunks.uploadCompanies.isError = false;
      state.thunks.uploadCompanies.isStarted = false;
      state.thunks.uploadCompanies.isFinished = true;
    });
    builder.addCase(uploadCompanies.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.uploadCompanies.error = error;
      state.thunks.uploadCompanies.isError = true;
      state.thunks.uploadCompanies.isLoading = false;
      state.thunks.uploadCompanies.isStarted = false;
      state.thunks.uploadCompanies.isFinished = true;
    });

    builder.addCase(getCompaniesByTargetGroup.pending, (state) => {
      state.thunks.getCompaniesByTargetGroup.isLoading = true;
      state.thunks.getCompaniesByTargetGroup.isSuccess = false;
      state.thunks.getCompaniesByTargetGroup.error = {};
      state.thunks.getCompaniesByTargetGroup.isError = false;
      state.thunks.getCompaniesByTargetGroup.isStarted = true;
    });
    builder.addCase(
      getCompaniesByTargetGroup.fulfilled,
      (state, action: PayloadAction<IUploadedCompaniesListResponse>) => {
        const data = action.payload;
        state.uploadedCompanies = {
          ...data,
          items: [...(state.uploadedCompanies.items || []), ...data.items],
        };
        state.thunks.getCompaniesByTargetGroup.isLoading = false;
        state.thunks.getCompaniesByTargetGroup.isSuccess = true;
        state.thunks.getCompaniesByTargetGroup.error = {};
        state.thunks.getCompaniesByTargetGroup.isError = false;
        state.thunks.getCompaniesByTargetGroup.isStarted = false;
        state.thunks.getCompaniesByTargetGroup.isFinished = true;
      },
    );
    builder.addCase(getCompaniesByTargetGroup.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getCompaniesByTargetGroup.error = error;
      state.thunks.getCompaniesByTargetGroup.isError = true;
      state.thunks.getCompaniesByTargetGroup.isLoading = false;
      state.thunks.getCompaniesByTargetGroup.isStarted = false;
      state.thunks.getCompaniesByTargetGroup.isFinished = true;
    });

    builder.addCase(getNewsEventsByTargetGroup.pending, (state) => {
      state.thunks.getNewsEventsByTargetGroup.isLoading = true;
      state.thunks.getNewsEventsByTargetGroup.isSuccess = false;
      state.thunks.getNewsEventsByTargetGroup.error = {};
      state.thunks.getNewsEventsByTargetGroup.isError = false;
      state.thunks.getNewsEventsByTargetGroup.isStarted = true;
    });
    builder.addCase(getNewsEventsByTargetGroup.fulfilled, (state, action: PayloadAction<INewsEventsListResponse>) => {
      const data = action.payload;
      updateStateAndHandlePaginatedData(state, 'newsEvents', data);
      // state.newsEvents = {
      //   ...data,
      //   items: [...(state.newsEvents.items || []), ...data.items],
      // };
      state.thunks.getNewsEventsByTargetGroup.isLoading = false;
      state.thunks.getNewsEventsByTargetGroup.isSuccess = true;
      state.thunks.getNewsEventsByTargetGroup.error = {};
      state.thunks.getNewsEventsByTargetGroup.isError = false;
      state.thunks.getNewsEventsByTargetGroup.isStarted = false;
      state.thunks.getNewsEventsByTargetGroup.isFinished = true;
    });
    builder.addCase(getNewsEventsByTargetGroup.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getNewsEventsByTargetGroup.error = error;
      state.thunks.getNewsEventsByTargetGroup.isError = true;
      state.thunks.getNewsEventsByTargetGroup.isLoading = false;
      state.thunks.getNewsEventsByTargetGroup.isStarted = false;
      state.thunks.getNewsEventsByTargetGroup.isFinished = true;
    });
  },
});

export const {
  pushSelectedNewsEventsCategoryToFilter,
  setCurrentCompany,
  setNewsEventsSorting,
  resetNewsEventsSorting,
  resetCurrentCompany,
  setCompaniesUploadPercent,
  setCompaniesFileMessage,
  setIsFileDataUploaded,
  resetUploadedCompanies,
  resetUploadCompaniesThunk,
  resetNewsEvents,
  resetCompanyState,
  // setUploadedCompaniesPagination,
} = companySlice.actions;

export const companyReducer = companySlice.reducer;
