import React from 'react';

import { Domain, DomainNewsContainer, MuiDivider } from './relevant-companies-news.styles';

import NoNewsFetchedScreen from '../no-news-fetched-screen/no-news-fetched-screen.component';

export interface IRelevantCompaniesNewsWithoutData {
  companyDomain: string;
  message: string;
}

function RelevantCompaniesNewsWithoutData({ companyDomain, message }: IRelevantCompaniesNewsWithoutData) {
  return (
    <DomainNewsContainer>
      <Domain>{companyDomain}</Domain>
      <MuiDivider />
      <NoNewsFetchedScreen message={message} />
    </DomainNewsContainer>
  );
}

export default RelevantCompaniesNewsWithoutData;
