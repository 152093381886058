import {
  currentCompanyForResearchParamsSelector,
  companyTargetGroupThunksSelector,
  companyForResearchSelector,
} from '../../../../entities/company-target-group/store/company-target-group.selectors';
import { useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { TargetResearchRightSectionFormContainer } from './target-research-main.styles';

import NoCompanyChosenScreen from './no-company-chosen-screen/no-company-chosen-screen.component';
import TargetResearchForm from '../../../target-research-form/components/target-research-form.component';
import RightSectionMain from '../../../../app/layouts/layout-components/right-section-main/right-section-main.component';

function TargetResearchMain() {
  const currentCompanyForResearchParams = useAppSelector(currentCompanyForResearchParamsSelector);
  const { id: currentCompanyTargetGroupId } = currentCompanyForResearchParams;
  const isCompanyForResearchChosen = Boolean(currentCompanyTargetGroupId);

  const companyForResearch = useAppSelector(companyForResearchSelector);
  const { isFirstCompanyLoad } = companyForResearch;

  const { isLoading: isCompanyForResearchLoading } = useAppSelector(
    companyTargetGroupThunksSelector,
  ).getCompanyForResearch;

  return (
    <TargetResearchRightSectionFormContainer isCompanyChosen={isCompanyForResearchChosen}>
      <RightSectionMain
        isListEntityChosen={isCompanyForResearchChosen}
        isDataLoading={isCompanyForResearchLoading && isFirstCompanyLoad}
        content={<TargetResearchForm key={companyForResearch.item.targetGroupName ?? '1'} />}
        noContent={<NoCompanyChosenScreen />}
      />
    </TargetResearchRightSectionFormContainer>
  );
}

export default TargetResearchMain;
