import { ComponentType } from 'react';
import { ITargetDefinitionFormInputProps } from './target-definition-form-input/target-definition-form-input.types';

export enum FormFieldValueType {
  SOLO = 'solo',
  MULTIPLE = 'multiple',
}

export interface ITargetDefinitionFormFieldConfig
  extends Omit<ITargetDefinitionFormInputProps<any>, 'handleChange' | 'values' | 'errors'> {
  inputValueType: FormFieldValueType;
  component: ComponentType<any>;
}

export type TargetDefinitionFieldsEntries = [string, ITargetDefinitionFormFieldConfig];
