/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';

import { IBaseMapperProps } from './base-mapper.types';

function BaseMapper<T extends { key: string }>({ component: Component, propsArray }: IBaseMapperProps<T>) {
  return (
    <Fragment>
      {propsArray.map((propsObject) => (
        <Component {...propsObject} />
      ))}
    </Fragment>
  );
}

export default BaseMapper;
