import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const FetchCompaniesForReviewListContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: 'calc(100% - 40px)',
  overflow: 'auto',
  paddingBottom: '10px',

  '> .select-company-for-review': {
    paddingTop: 0,
  },

  [theme.breakpoints.up('xs')]: {
    paddingBottom: '10px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
