import React, { useState } from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FeedbackUiDecorator from '../../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { useAppSelector, useAppDispatch } from '../../../../app/store/utils/redux.hooks';
import { targetGroupThunksSelector } from '../../../../entities/target-group/store/target-group.selectors';
import { ButtonClickEvent } from '../../../../shared/domain/types/html-elements.types';
import { IDeleteUploadedFileProps } from './delete-uploaded-file.types';
import { DeleteUploadedFileButton } from './delete-uploaded-file.styles';
import { deleteUploadedFile } from '../../../../entities/target-group/store/target-group.slice';

function DeleteUploadedFile({ targetGroupId, fileName }: IDeleteUploadedFileProps) {
  const [isPromptedToDelete, setIsPromptedToDelete] = useState(false);

  const { isLoading: isUploadedFileDeliting } = useAppSelector(targetGroupThunksSelector).deleteUploadedFile;

  const dispatch = useAppDispatch();

  function handleDeleteButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsPromptedToDelete(true);
  }

  async function handleAction() {
    dispatch(deleteUploadedFile({ targetGroupId, sourceUrl: fileName }));
    setIsPromptedToDelete(false);
  }

  function handleClose() {
    setIsPromptedToDelete(false);
  }

  function handleCancel() {
    setIsPromptedToDelete(false);
  }

  const promptModalConfig = {
    isInfo: isPromptedToDelete,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Remove this file?',
    isActionLoading: isUploadedFileDeliting,
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <DeleteUploadedFileButton onClick={handleDeleteButtonClick}>
        <DeleteOutlinedIcon />
      </DeleteUploadedFileButton>
    </FeedbackUiDecorator>
  );
}

export default DeleteUploadedFile;
