import {
  ErrorAlertContentType,
  ErrorModalContentType,
  FeedbackUiType,
  InfoOrErrorFeedbackUiPropsUnion,
} from '../feedback-ui.types';

export const BaseModalPropsDefaults = {
  IS_UI_VISIBLE: false,
  UI_TYPE: FeedbackUiType.MODAL,
  UI_CONTENT_TYPE: ErrorModalContentType?.SOME_ERROR_HAPPENED,
  TITLE: 'Network error',
  MESSAGE: 'Request failed',
  ACTION_TITLE: 'Retry',
  CANCEL_TITLE: 'Cancel',
  HANDLE_CLOSE: () => console.log('close'),
  HANDLE_ACTION: () => console.log('action'),
  HANDLE_CANCEL: () => console.log('cancel'),
  IS_ACTION_LOADING: false,
};

export const BaseAlertPropsDefaults = {
  IS_UI_VISIBLE: false,
  UI_TYPE: FeedbackUiType.ALERT,
  UI_CONTENT_TYPE: ErrorAlertContentType.SOME_ERROR_HAPPENED,
  TITLE: 'Network error',
  MESSAGE: 'Request failed',
  ACTION_TITLE: 'Retry',
  CANCEL_TITLE: 'Cancel',
  HANDLE_CLOSE: () => console.log('close'),
  HANDLE_ACTION: () => console.log('action'),
  HANDLE_CANCEL: () => console.log('cancel'),
  IS_ACTION_LOADING: false,
};

export const errorFeedbackUiPropsWithDefaults = {
  [FeedbackUiType.MODAL]: (props: InfoOrErrorFeedbackUiPropsUnion) => {
    const propsWithDefaults = {
      isUiVisible: props.isUiVisible || BaseModalPropsDefaults.IS_UI_VISIBLE,
      uiContentType: props.uiContentType || BaseModalPropsDefaults.UI_CONTENT_TYPE,
      title: props.title || BaseModalPropsDefaults.TITLE,
      message: props.message || BaseModalPropsDefaults.MESSAGE,
      actionTitle: props.actionTitle || BaseModalPropsDefaults.ACTION_TITLE,
      cancelTitle: props.cancelTitle || BaseModalPropsDefaults.CANCEL_TITLE,
      handleClose: props.handleClose || BaseModalPropsDefaults.HANDLE_CLOSE,
      handleAction: props.handleAction || BaseModalPropsDefaults.HANDLE_ACTION,
      handleCancel: props.handleCancel || BaseModalPropsDefaults.HANDLE_CANCEL,
      isActionLoading: props.isActionLoading || BaseModalPropsDefaults.IS_ACTION_LOADING,
    };
    return propsWithDefaults;
  },
  [FeedbackUiType.ALERT]: (props: InfoOrErrorFeedbackUiPropsUnion) => {
    const propsWithDefaults = {
      isUiVisible: props.isUiVisible || BaseAlertPropsDefaults.IS_UI_VISIBLE,
      uiContentType: props.uiContentType || BaseAlertPropsDefaults.UI_CONTENT_TYPE,
      title: props.title || BaseAlertPropsDefaults.TITLE,
      message: props.message || BaseAlertPropsDefaults.MESSAGE,
      actionTitle: props.actionTitle || BaseAlertPropsDefaults.ACTION_TITLE,
      cancelTitle: props.cancelTitle || BaseAlertPropsDefaults.CANCEL_TITLE,
      handleClose: props.handleClose || BaseAlertPropsDefaults.HANDLE_CLOSE,
      handleAction: props.handleAction || BaseAlertPropsDefaults.HANDLE_ACTION,
      handleCancel: props.handleCancel || BaseAlertPropsDefaults.HANDLE_CANCEL,
      isActionLoading: props.isActionLoading || BaseAlertPropsDefaults.IS_ACTION_LOADING,
    };
    return propsWithDefaults;
  },
};
