import {
  IClientsVisibilityKPIsResponse,
  ICurrentTargetGroupParams,
  ITargetGroup,
  ITargetGroupKPIsResponse,
  ITargetGroupThunks,
  ITargetGroupsListResponse,
  TargetGroupState,
} from '../domain/target-group.types';
import { RootState } from '../../../app/store/utils/redux.types';

export function getCompanyCountFilter(state: RootState): string | undefined {
  return state.targetGroup.companyCountFilter;
}

export function targetGroupStateSelector(state: RootState): TargetGroupState {
  return state.targetGroup;
}

export function targetGroupThunksSelector(state: RootState): ITargetGroupThunks {
  return state.targetGroup.thunks;
}

export function targetGroupsForClientsListSelector(state: RootState): ITargetGroupsListResponse {
  return state.targetGroup.allCLientsTGsList;
}

export function targetGroupsWeeklyKPIsSelector(state: RootState): ITargetGroupKPIsResponse {
  return state.targetGroup.targetGroupsWeeklyKPIs;
}

export function clientsVisibilityKPIsSelector(state: RootState): IClientsVisibilityKPIsResponse {
  return state.targetGroup.clientsVisibilityKPIs;
}

export function targetGroupsListSelector(state: RootState): ITargetGroupsListResponse {
  return state.targetGroup.targetGroupsList;
}

export function currentTargetGroupParamsSelector(state: RootState): ICurrentTargetGroupParams {
  return state.targetGroup.currentTargetGroupParams;
}

export function currentTargetGroupSelector(state: RootState): ITargetGroup {
  return state.targetGroup.currentTargetGroup;
}

export function campaignsSelector(state: RootState): any[] {
  return state.targetGroup.campaigns;
}
