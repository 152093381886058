import React, { useState } from 'react';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { ShowPromptOutcomeContainer } from './show-apollo-search-outcome.styles';
import { currentTargetGroupSelector } from '../../../entities/target-group/store/target-group.selectors';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { IApolloSearchOutcome } from '../../../entities/target-group/domain/target-group.types';
import { ISearchCompanyParams, parseApolloSearchParams } from '../helpers/show-apollo-search-outcome.helpers';
import { capitalizeFirstLetter } from '../../../shared/helpers/base.helpers';

function formatFitData(data: IApolloSearchOutcome[]): string {
  const formatFitTable = (): string => {
    return data
      .map(({ url, status, companiesParsed, date }) => {
        const searchParams = parseApolloSearchParams(url);

        return `
        <div>
          <p class="search-url"><strong>URL: </strong> <a href="${url}">${url}</a></p>
          <p><strong>Parameters:</strong></p>
          <ul>
          ${Object.keys(searchParams)
            .map((key) => {
              return `<li class="parameters-list"><strong>${key}</strong>: ${
                searchParams[key as keyof ISearchCompanyParams]
              }</li>`;
            })
            .join('')}
          </ul>
          <table class="fit-table">
            <thead>
              <tr>
                <th>Status</th>
                <th>Companies #</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${capitalizeFirstLetter(status)}</td>
                <td>${companiesParsed}</td>
                <td>${new Date(date).toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      `;
      })
      .join('');
  };

  return `
    <style>
      .fit-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .fit-table th, .fit-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
      .fit-table th {
        background-color: #f2f2f2;
      }
      .fit-table tr:nth-child(even) {
        background-color: #f9f9f9;
      }
      .search-url {
        white-space: pre;
        display: flex; 
        margin-bottom: 10px;
      }
      .search-url + p {
        margin-top: 5px;
        margin-bottom: 0px;
      }
      .search-url + p + ul {
        margin-top: 5px;
      }
      .search-url a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        text-decoration: underline;
      }
      .parameters-list {
        word-break: break-all;
      }
    </style>
    <div>
      ${formatFitTable()}
      <br><br>
    </div>
  `;
}

function ShowApolloSearchOutcome() {
  const [isOpen, setIsOpen] = useState(false);
  const { apolloSearchOutcome } = useAppSelector(currentTargetGroupSelector);

  async function handleButtonClick() {
    setIsOpen(!isOpen);
  }

  if (!apolloSearchOutcome) return null;

  const promptModalConfig = {
    isInfo: isOpen,
    title: 'Apollo Search Results',
    handleAction: handleButtonClick,
    handleClose: handleButtonClick,
    handleCancel: handleButtonClick,
    message: (<div dangerouslySetInnerHTML={{ __html: formatFitData(apolloSearchOutcome) }} />) as any,
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <ShowPromptOutcomeContainer>
        <TransparentRectButtonWithOrangeBorder
          title="Outcome"
          handleClick={handleButtonClick}
          className="marked-as-not-a-fit not-marked"
        />
      </ShowPromptOutcomeContainer>
    </FeedbackUiDecorator>
  );
}

export default ShowApolloSearchOutcome;
