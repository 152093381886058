/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  companyForReviewSelector,
  companyTargetGroupThunksSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import {
  getCompanyForReview,
  rejectLead,
} from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { RejectLeadContainer } from './reject-lead.styles';

import TransparentRectButtonWithOrangeBorder from '../../../shared/components/button/transparent-rect-button-with-orange-border/transparent-rect-button-with-orange-border.component';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';

function RejectLead() {
  const [isPromptedToReject, setIsPromptedToReject] = useState(false);
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const currentCompanyForReview = useAppSelector(companyForReviewSelector);
  const companyForReviewFormValues = currentCompanyForReview.item;
  const isMarkedAsRejected = companyForReviewFormValues.isRejected;

  const { isLoading: isRejectLeadLoading } = useAppSelector(companyTargetGroupThunksSelector).rejectLead;

  async function handleButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsPromptedToReject(true);
  }

  async function handleAction() {
    await dispatch(rejectLead({ companyTargetGroupId: companyForReviewFormValues.id }));
    await dispatch(getCompanyForReview({ clientId, companyTargetGroupId: companyForReviewFormValues.id }));
    setIsPromptedToReject(false);
  }

  function handleClose() {
    setIsPromptedToReject(false);
  }

  function handleCancel() {
    setIsPromptedToReject(false);
  }

  const promptModalConfig = {
    isInfo: isPromptedToReject,
    handleAction,
    handleClose,
    handleCancel,
    message: 'Reject this company?',
    isActionLoading: isRejectLeadLoading,
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <RejectLeadContainer>
        <TransparentRectButtonWithOrangeBorder
          title={isMarkedAsRejected ? 'Rejected' : 'Reject Company'}
          handleClick={isMarkedAsRejected ? undefined : handleButtonClick}
          className={`reject-lead ${isMarkedAsRejected ? 'rejected' : 'not-rejected'}`}
          isLoading={isRejectLeadLoading}
        />
      </RejectLeadContainer>
    </FeedbackUiDecorator>
  );
}

export default RejectLead;
