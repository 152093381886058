/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import AnalyticsIndicator from './analytics-indicator/analytics-indicator.component';
import { getCompanyTargetGroupsAnalyticsKpiSet } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';

import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import {
  areCompanyTargetGroupsStatusesUpdatedSelector,
  companyTargetGroupsAnalyticsKpiSetSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import { IndicatorsContainer } from './analytics-indicators-dashboard.styles';

function AnalyticsIndicatorsDashboard() {
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);

  const { data: analyticsKpiSet } = useAppSelector(companyTargetGroupsAnalyticsKpiSetSelector);

  const isStatusUpdated = useAppSelector(areCompanyTargetGroupsStatusesUpdatedSelector);

  useEffect(() => {
    dispatch(getCompanyTargetGroupsAnalyticsKpiSet({ clientId, targetGroupId }));
  }, [dispatch, clientId, targetGroupId]);

  useEffect(() => {
    if (isStatusUpdated) dispatch(getCompanyTargetGroupsAnalyticsKpiSet({ clientId, targetGroupId }));
  }, [dispatch, isStatusUpdated]);

  return (
    <Stack direction="row" width="100%" minHeight="15%" justifyContent="center" alignItems="center" marginBottom="20px">
      <IndicatorsContainer>
        {analyticsKpiSet.map(({ title, value }, index) => (
          <Stack width="25%" key={index}>
            <AnalyticsIndicator title={title} value={value} />
          </Stack>
        ))}
      </IndicatorsContainer>
    </Stack>
  );
}

export default AnalyticsIndicatorsDashboard;
