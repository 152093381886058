import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type FormHeaderProps = TypographyProps & {};

export const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '390px',
}));

export const FormHeader = styled(Typography)<FormHeaderProps>(() => ({
  marginBottom: '35px',
}));
