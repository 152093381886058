import React, { useEffect, useRef } from 'react';
import {
  currentTargetGroupSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import { formCurrentTargetDefinitionFormState, formInputsConfig } from '../helpers/target-definition-form.helpers';
import { useForm } from '../../../shared/hooks/use-form.hook';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { getTargetGroupById, updateTargetGroup } from '../../../entities/target-group/store/target-group.slice';
import { useDebounce } from '../../../shared/hooks/use-debounce.hook';
import { ITargetGroup } from '../../../entities/target-group/domain/target-group.types';
import BaseFormWrapper from '../../../shared/components/form/base-form-wrapper/base-form-wrapper.component';
import MapperWithVariableComponents from '../../../shared/components/mapper/mapper-with-variable-components/mapper-with-variable-components.component';
import ImportCompanies from '../../../features/import-companies/components/import-companies.component';
import ProtectDecorator from '../../../app/decorators/protect/protect.decorator';
import { UserRole } from '../../../entities/user/domain/user.types';
import UploadedFilesList from '../../../features/display-uploaded-files/components/uploaded-files-list/uploaded-files-list.component';

const DEBOUNCE_DELAY = 500;

function TargetDefinitionForm() {
  const isFirstRender = useRef(true);

  const dispatch = useAppDispatch();
  const enrichCallStatus = useAppSelector(targetGroupThunksSelector).enrichExampleCompanies;

  const targetGroupFormData = useAppSelector(currentTargetGroupSelector);
  const targetGroupId = targetGroupFormData.id;
  const fileNames = targetGroupFormData.sourceFiles ?? [];

  const currentTargetDefinitionFormState = formCurrentTargetDefinitionFormState(targetGroupFormData as ITargetGroup);

  const useFormResult = useForm(currentTargetDefinitionFormState);
  const { formValues, handleInputChange, handleCustomInputChange, resetFormState } = useFormResult;

  const inputsSetConfig = formInputsConfig<typeof formValues>(formValues, handleInputChange, handleCustomInputChange);

  const debouncedValues = useDebounce(formValues, DEBOUNCE_DELAY);

  useEffect(() => {
    if (enrichCallStatus.isSuccess) resetFormState();
  }, [enrichCallStatus]);

  useEffect(() => {
    isFirstRender.current = true;
    resetFormState();
  }, [targetGroupId]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const body = {
      ...debouncedValues,
      maxHeadcount: (debouncedValues.maxHeadcount as any) === '' ? null : debouncedValues.maxHeadcount,
      minHeadcount: (debouncedValues.minHeadcount as any) === '' ? null : debouncedValues.minHeadcount,
      emailSequences: targetGroupFormData.emailSequences,
      campaignOwner: targetGroupFormData.campaignOwner,
    };
    dispatch(updateTargetGroup({ targetGroupId, body }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues, dispatch]);

  return (
    <>
      <BaseFormWrapper isHeaderShown={false} className="target-definition-form-wrapper">
        <MapperWithVariableComponents config={inputsSetConfig} />
      </BaseFormWrapper>
      <ProtectDecorator allowedRoles={[UserRole.EMPLOYEE]}>
        <ImportCompanies
          type="raw"
          title="Upload Companies"
          onFinish={() => dispatch(getTargetGroupById(targetGroupId))}
        />
        <UploadedFilesList targetGroupId={targetGroupId} fileNames={fileNames} />
      </ProtectDecorator>
    </>
  );
}

export default TargetDefinitionForm;
