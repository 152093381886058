import React from 'react';

import { EntityCardWrapper, EntityCardContent, EntityCardTitle } from './entity-card.styles';
import { IEntityCardProps } from './entity-card.types';

function EntityCard({ className, title, handleClick, isActiveEntity, buttonComponent: ButtonItem }: IEntityCardProps) {
  return (
    <EntityCardWrapper className={className}>
      <EntityCardContent onClick={handleClick}>
        <EntityCardTitle isActiveEntity={isActiveEntity}>{title}</EntityCardTitle>
      </EntityCardContent>
      {ButtonItem}
    </EntityCardWrapper>
  );
}

export default EntityCard;
