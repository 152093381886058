import React, { Fragment } from 'react';

import {
  currentTargetGroupParamsSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { NewsEventsRightSectionContainer, NewsEventsRightSectionHeader } from './news-events-right-section.styles';
import { pushSelectedNewsEventsCategoryToFilter } from '../../../entities/company/store/company.slice';
import { eventsFilterByCategories } from '../../../entities/company/store/company.selectors';

import NoTargetGroupChosenScreen from './no-target-group-chosen-screen/no-target-group-chosen-screen.component';
import NewsEventsTable from '../../news-events-table/components/news-events-table.component';
import RightSectionHeader from '../../../app/layouts/layout-components/right-section-header/right-section-header.component';
import RightSectionMain from '../../../app/layouts/layout-components/right-section-main/right-section-main.component';
import SelectEventsCategoriesWithAutocomplete from '../../../features/select-events-categories-with-autocomplete/components/select-events-categories-with-autocomplete.component';

const SECTION_HEADER = 'Market Tracking';

function NewsEventsRightSection() {
  const { id: targetGroupId, name: targetGroupName } = useAppSelector(currentTargetGroupParamsSelector);
  const isTargetGroupChosen = Boolean(targetGroupId);

  const { isLoading: isTargetGroupLoading } = useAppSelector(targetGroupThunksSelector).getTargetGroupById;
  const selectedValues = useAppSelector(eventsFilterByCategories);

  const dispatch = useAppDispatch();

  return (
    <Fragment>
      <NewsEventsRightSectionHeader isTargetGroupChosen={isTargetGroupChosen}>
        <RightSectionHeader
          isListEntityChosen={isTargetGroupChosen}
          headerName={SECTION_HEADER}
          listEntityName={targetGroupName}
          hasControls
          controls={
            <SelectEventsCategoriesWithAutocomplete
              id={'13'}
              label={'Categories'}
              name={'Categories'}
              values={selectedValues}
              disabled={false}
              handleChange={function (value: any): void {
                dispatch(pushSelectedNewsEventsCategoryToFilter(value));
              }}
            />
          }
        />
      </NewsEventsRightSectionHeader>
      <NewsEventsRightSectionContainer isTargetGroupChosen={isTargetGroupChosen}>
        <RightSectionMain
          isListEntityChosen={isTargetGroupChosen}
          isDataLoading={isTargetGroupLoading}
          content={<NewsEventsTable />}
          noContent={<NoTargetGroupChosenScreen />}
        />
      </NewsEventsRightSectionContainer>
    </Fragment>
  );
}

export default NewsEventsRightSection;
