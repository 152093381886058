import React from 'react';
import { Stack } from '@mui/material';

import { CompanyTrackingWrapper } from './company-tracking.styles';

import CompanyTrackingSelectSection from '../../modules/company-tracking-select-section/components/company-tracking-select-section.components';
import CompanyTrackingNewsSection from '../../modules/company-tracking-news-section/components/company-tracking-news-section.component';

function CompanyTracking() {
  return (
    <CompanyTrackingWrapper>
      <Stack direction="row" height="100%" gap="1.2%">
        <CompanyTrackingSelectSection />
        <CompanyTrackingNewsSection />
      </Stack>
    </CompanyTrackingWrapper>
  );
}

export default CompanyTracking;
