import React, { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { currentReviewFilterCriterionSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { dropdownOptions } from '../domain/select-review-filter-with-dropdown.constants';
import { setCurrentReviewFilterCriterion } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { SelectReviewFilterWithDropdownContainer } from './select-review-filter-with-dropdown.styles';

import BaseDropdown from '../../../shared/components/dropdown/base-dropdown/base-dropdown.component';

function SelectReviewFilterWithDropdown() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { name: currentReviewFilterName } = useAppSelector(currentReviewFilterCriterionSelector);

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  function handleOptionChange(event: SelectChangeEvent<unknown>) {
    const reviewFilterCriterion = event.target.value as string;
    const reviewFilterCriterionParsed = JSON.parse(reviewFilterCriterion);
    dispatch(setCurrentReviewFilterCriterion(reviewFilterCriterionParsed));
  }

  return (
    <SelectReviewFilterWithDropdownContainer isMenuOpen={isMenuOpen}>
      <BaseDropdown
        selectedOptionName={currentReviewFilterName}
        options={dropdownOptions || []}
        handleOptionChange={handleOptionChange}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
      />
    </SelectReviewFilterWithDropdownContainer>
  );
}

export default SelectReviewFilterWithDropdown;
