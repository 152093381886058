export const Routes = {
  MAIN: '/',
  LOGIN: '/authentication/login',
  CLIENTS: '/dashboard/clients',
  PIPELINE: '/dashboard/pipeline',
  TARGET_DEFINITION: '/dashboard/pipeline/target-definition',
  GTM_PLAN: '/dashboard/pipeline/gtm-plan',
  TARGET_UPLOAD: '/dashboard/pipeline/target-upload',
  TARGET_VETTING: '/dashboard/pipeline/target-vetting',
  TARGET_RESEARCH: '/dashboard/pipeline/target-research',
  REVIEW_EMAILS: '/dashboard/pipeline/review-emails',
  REPORTING: '/dashboard/reporting',
  ANALYTICS_AND_REPORTING: '/dashboard/reporting/analytics-and-reporting',
  COMPANY_TRACKING: '/dashboard/reporting/company-tracking',
  MARKET_TRACKING: '/dashboard/reporting/market-tracking',
  SELECTION: '/dashboard/kpis/selection',
  WEEKLY_KPIS: '/dashboard/kpis/weekly',
  VISIBILITY_KPIS: '/dashboard/kpis/visibility',
};

export const NavigationPaths = {
  LOGIN: '/authentication/login',
  CLIENTS: '/dashboard/clients',
  PIPELINES_LIST: '/dashboard/pipeline',
  PIPELINE: (clientId: string | undefined) => `/dashboard/pipeline/${clientId}`,
  TARGET_DEFINITION: (clientId: string | undefined) => `/dashboard/pipeline/${clientId}/target-definition`,
  GTM_PLAN: (clientId: string | undefined) => `/dashboard/pipeline/${clientId}/gtm-plan`,
  TARGET_UPLOAD: (clientId: string | undefined) => `/dashboard/pipeline/${clientId}/target-upload`,
  TARGET_VETTING: (clientId: string | undefined) => `/dashboard/pipeline/${clientId}/target-vetting`,
  TARGET_RESEARCH: (clientId: string | undefined) => `/dashboard/pipeline/${clientId}/target-research`,
  REVIEW_EMAILS: (clientId: string | undefined) => `/dashboard/pipeline/${clientId}/review-emails`,
  ANALYTICS_AND_REPORTING: (clientId: string | undefined) => `/dashboard/reporting/${clientId}/analytics-and-reporting`,
  MARKET_TRACKING: (clientId: string | undefined) => `/dashboard/reporting/${clientId}/market-tracking`,
  COMPANY_TRACKING: (clientId: string | undefined) => `/dashboard/reporting/${clientId}/company-tracking`,
  SELECTION: (clientId: string | undefined) => `/dashboard/kpis/${clientId}/selection`,
  WEEKLY_KPIS: (clientId: string | undefined) => `/dashboard/kpis/${clientId}/weekly`,
  VISIBILITY_KPIS: (clientId: string | undefined) => `/dashboard/kpis/${clientId}/visibility`,
};
