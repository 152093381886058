import { styled } from '@mui/material/styles';

import { TransparentRectBaseButtonWithOrangeBorderProps } from './transparent-rect-button-with-orange-border.types';

import BaseButton from '../base-button/base-button.component';

export const TransparentRectBaseButtonWithOrangeBorder = styled(BaseButton)<TransparentRectBaseButtonWithOrangeBorderProps>(
  ({ theme }) => ({
    transition: theme.transitions.create('background-color'),
    minWidth: '180px',
    height: '50px',
    padding: '0 18px',
    border: theme.palette.border.orangeButton,
    color: theme.palette.text.orange,
    fontSize: '0.8rem',
    fontWeight: 600,
    backgroundColor: 'transparent',

    '&:hover': {
      transition: theme.transitions.create('background-color'),
      backgroundColor: theme.palette.background.orange,
      color: theme.palette.text.primary,
    },

    '&:disabled': {
      border: theme.palette.border.orangeDisabledButton,
      color: theme.palette.background.orangeDisabled,
    },

    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
    },
  }),
);
