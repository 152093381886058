/* eslint-disable max-classes-per-file */
import { BaseItemServerResponse, BaseListServerResponse } from '../../../shared/domain/types/base.types';
import { ValidationResultsObject } from '../../../shared/domain/types/validation.types';
import { ICompanyNewsEvents, ICompanyNewsEventsBase, IRelevantCompaniesNewsEvents } from './company-news-events.types';

export enum CompanyTargetGroupStatus {
  ACTIVE = 'Active',
  KEEP_IN_TOUCH = 'Keep in touch',
  MET_WITH = 'Met with',
  POSITIVE_RESPONSE = 'Positive response',
  NEGATIVE_RESPONSE = 'Negative response',
}

export enum PipelineStage {
  VETTED = 'vetted',
  RESEARCHED = 'researched',
  REVIEWED = 'reviewed',
  REVIEW_PAUSED = 'reviewPaused',
  RESEARCH_PAUSED = 'researchPaused',
}

export enum RejectionStatus {
  DONT_CONTACT = "don't contact",
  NOT_A_FIT = 'not a fit',
  NOT_VALIDATED = 'not validated',
}

export enum CompaniesForResearchFilterCriterion {
  TO_RESEARCH = 'to research',
  RESEARCHED = 'researched',
  NOT_A_FIT = 'not a fit',
  EMAIL_VALIDATION = 'email validation',
  INVALID_EMAIL = 'invalid email',
  RESEARCH_PAUSED = 'researchPaused',
}

export enum CompaniesForResearchFilterCriterionName {
  TO_RESEARCH = 'To research',
  RESEARCHED = 'Researched',
  NOT_A_FIT = 'Not a fit',
  EMAIL_VALIDATION = 'Email Validation',
  INVALID_EMAIL = 'Invalid Email',
  RESEARCH_PAUSED = 'Paused',
}

export enum CompaniesForReviewFilterCriterion {
  TO_VALIDATE = 'to validate',
  VALIDATED = 'validated',
  REJECTED = 'rejected',
  REVIEW_PAUSED = 'reviewPaused',
}

export enum CompaniesForReviewFilterCriterionName {
  TO_VALIDATE = 'To validate',
  VALIDATED = 'Validated',
  REJECTED = 'Rejected',
  REVIEW_PAUSED = 'Paused',
}

export interface ICompanyTargetGroup {
  id: string;
  targetGroupId: string;
  companyId: string;
  description: string;
  emailSequences: any;
  status: CompanyTargetGroupStatus[];
  isValidated: false;
  relevantCompanies: string[];
  relevantCompaniesNewsEvents: any;
  pipelineStage: PipelineStage | null;
  rejectionStatus: RejectionStatus | null;
  createdAt: string;
  updatedAt: string;
}

export interface ICountry {
  id: string;
  country: string;
  latitude: string;
  longitude: string;
  name: string;
}

export class AnalyticsCompanyTargetGroup {
  constructor(itemFromServer: any) {
    this.id = itemFromServer?.id;
    this.status = itemFromServer?.status;
    this.targetGroupName = itemFromServer?.targetGroup?.name;
    this.targetGroupId = itemFromServer?.targetGroup?.id;
    this.companyName = itemFromServer?.company?.name;
    this.website = itemFromServer?.company?.website;
    this.firstName = itemFromServer?.company?.leads?.[0]?.firstName;
    this.lastName = itemFromServer?.company?.leads?.[0]?.lastName;
    this.linkedIn = itemFromServer?.company?.leads?.[0]?.linkedIn;
    this.jobTitle = itemFromServer?.company?.leads?.[0]?.jobTitle;
  }

  id: string;

  status: CompanyTargetGroupStatus[];

  targetGroupName: string;

  targetGroupId: string;

  companyName: string;

  website: string;

  firstName: string;

  lastName: string;

  linkedIn: string;

  jobTitle: string;
}

export class ClientsCompanyByTargetGroup {
  constructor(itemFromServer: any) {
    this.id = itemFromServer?.id;
    this.status = itemFromServer?.status;
    this.targetGroupName = itemFromServer?.targetGroup?.name;
    this.targetGroupId = itemFromServer?.targetGroup?.id;
    this.companyId = itemFromServer?.company?.id;
    this.companyName = itemFromServer?.company?.name;
  }

  id: string;

  status: CompanyTargetGroupStatus[];

  targetGroupName: string;

  targetGroupId: string;

  companyId: string;

  companyName: string;
}

export interface IGetCompanyTargetGroupsForAnalyticsThunkParams {
  clientId: string | undefined;
  targetGroupId: string;
  take: number;
  skip: number;
  sortBy?: string;
}

export interface IGetCompanyTargetGroupsAnalyticsKpiSetThunkParams {
  clientId: string | undefined;
  targetGroupId: string;
}

export interface IDownloadCompanyTargetGroupsAnalyticsThunkParams {
  clientId: string | undefined;
  targetGroupId: string;
}

export interface IGetClientCompaniesListByTargetGroupIdThunkParams {
  clientId: string | undefined;
  targetGroupId: string;
  take: number;
  skip: number;
}

export interface IUpdateStatusBody extends Pick<AnalyticsCompanyTargetGroup, 'status'> {
  companyTargetGroupId: string;
}

export interface ICompanyTargetGroupsForAnalyticsListResponse
  extends BaseListServerResponse<AnalyticsCompanyTargetGroup> {}

export interface IClientsCompaniesByTargetGroupListResponse
  extends BaseListServerResponse<ClientsCompanyByTargetGroup> {}

export enum CompanyTargetGroupsAnalyticsSortBy {
  COMPANY_NAME = 'Company name',
  PERSON = 'Person',
}

export interface ICompanyTargetGroupSorting<T> {
  isSorted: boolean;
  sortBy?: T | undefined;
}

export interface ICompanyTargetGroupsForAnalytics {
  isLoading: boolean;
  data: ICompanyTargetGroupsForAnalyticsListResponse;
  isError: boolean;
  error: {};
  sorting: ICompanyTargetGroupSorting<CompanyTargetGroupsAnalyticsSortBy>;
}

export interface IClientsCompaniesByTargetGroup {
  isLoading: boolean;
  data: IClientsCompaniesByTargetGroupListResponse;
  isError: boolean;
  error: {};
}

export interface CompanyTargetGroupsAnalyticsKpi {
  title: string;
  value: number;
}

export interface ICompanyTargetGroupsAnalyticsKpiSet {
  isLoading: boolean;
  data: CompanyTargetGroupsAnalyticsKpi[];
  isError: boolean;
  error: {};
}

export interface IGetCompanyNewsEventsThunkParams {
  companyId: string;
}

export interface ICompanyNewsEventsItemResponse extends BaseItemServerResponse<ICompanyNewsEvents> {}

export interface IRelevantCompaniesNewsEventsItemResponse
  extends BaseItemServerResponse<IRelevantCompaniesNewsEvents> {}

export interface IBaseCompaniesNewsEventsItemResponse extends BaseItemServerResponse<ICompanyNewsEventsBase> {}

export interface IRelevantCompaniesDataItemResponse
  extends BaseItemServerResponse<
    Pick<IRelevantCompaniesNewsEvents, 'relevantCompanies' | 'relevantCompaniesNewsEvents'>
  > {}

export interface IUpdateRelevantCompaniesThunkParams {
  companyTargetGroupId: string;
  companyDomains: string[];
}

export interface ICompanyNewsEventsState {
  isLoading: boolean;
  data: ICompanyNewsEventsItemResponse;
  isError: boolean;
  error: {};
}

export interface IRelevantCompaniesNewsEventsState {
  isLoading: boolean;
  data: IRelevantCompaniesNewsEventsItemResponse;
  isError: boolean;
  error: {};
}

export interface IWeeklyIntervalOfCompaniesCreation {
  startDate: string;
  endDate: string;
}

export interface IWeeklyIntervalsOfCompaniesCreationListResponse
  extends BaseListServerResponse<IWeeklyIntervalOfCompaniesCreation> {}

export interface IGetWeeklyIntervalsOfCompaniesCreationThunkParams {
  clientId: string | undefined;
  take: number;
  skip: number;
}

export interface ICompanyForVetting {
  id: string;
  targetGroupId: string;
  targetGroupName: string;
  rejectionStatus: RejectionStatus | null;
  createdAt: string;
  companyId: string;
  companyName: string;
  website: string;
  companyLinkedIn: string;
}

export interface ICompaniesForVettingListResponse extends BaseListServerResponse<ICompanyForVetting> {
  flags?: {
    hasEBITDA?: boolean;
    hasVCBacker?: boolean;
    hasBEBacker?: boolean;
    hasAquirer?: boolean;
    hasAcquisitions?: boolean;
  };
}

export interface IGetCompaniesForVettingThunkParams {
  clientId: string | undefined;
  // startDate?: string;
  // endDate?: string;
  targetGroupId?: string;
  take: number;
  skip: number;
}

export interface IVetCompaniesForPeriodThunkParams {
  clientId: string | undefined;
  targetGroupId?: string;
  // startDate?: string;
  // endDate?: string;
}

export interface ICurrentWeekOfCompaniesCreation {
  id: string;
  name: string;
  values: IWeeklyIntervalOfCompaniesCreation & { id: string; name: string };
}

export interface ISetRejectionStatusBody extends Pick<ICompanyTargetGroup, 'rejectionStatus'> {
  companyTargetGroupId: string;
}

export interface ICurrentCompanyForResearchParams {
  id: string;
  name: string;
}

export interface IGetCompaniesForResearchThunkParams {
  clientId: string | undefined;
  filterCriterion?: CompaniesForResearchFilterCriterion;
  targetGroupId?: string;
  take: number;
  skip: number;
}

export interface ICompanyForResearch {
  id: string;
  pipelineStage: PipelineStage | null;
  rejectionStatus: RejectionStatus | null;
  createdAt: string;
  companyId: string;
  companyName: string;
}

export interface ICompaniesForResearchListResponse extends BaseListServerResponse<ICompanyForResearch> {}

export interface ICurrentResearchFilterCriterion {
  id: string;
  name: string;
  value: CompaniesForResearchFilterCriterion;
}

export interface IMarkCompanyAsToResearchThunkParams {
  companyTargetGroupId: string;
}

export interface IMarkCompanyAsResearchedThunkParams {
  companyTargetGroupId: string;
}

export interface IMarkCompanyAsNotAFitThunkParams {
  companyTargetGroupId: string;
}

export interface ILead {
  id: string;
  companyId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  linkedIn?: string;
  jobTitle?: string;
  emailValidationMeta: any;
}

export interface ICompanyTargetGroupDataForResearch {
  id: string;
  rejectionStatus: RejectionStatus | null;
  pipelineStage: PipelineStage | null;
  targetGroupId: string;
  comment: string;
  targetGroupName: string;
  companyId: string;
  companyName: string;
  website: string;
  companyLinkedIn: string;
  headcount: number;
  leadId: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  leads: ILead[];
  email: string;
  personLinkedIn: string;
  shortDescription: string;
  otherInfo: string;
  countries: any[];
  isResearched: boolean;
  isNotAFit: boolean;
  acquired?: CheckboxValues;
  backedByPE?: CheckboxValues;
  backedByVC?: CheckboxValues;
  madeAcquisitions?: CheckboxValues;
  revenue?: string;
  EBITDA?: string;
  acquirer?: string;
  PEbacker?: string;
  VCbacker?: string;
  acquiredCompanies?: string;
  emailValidationMeta?: IEmailValidationMeta;
  isWaitingForEmailValidation?: boolean;
  placeholders: Record<string, string>;
}

export interface IEmailValidationMeta {
  email: string;

  result: 'valid' | 'invalid';

  isCompleted: boolean;

  source?: 'Neverbounce' | 'Scrubby.io';

  sentToScrubbyAt?: Date;
}

export interface ICompanyTargetGroupEmail {
  subject: string;
  body: string;
  leadId?: string;
}

export interface ICompanyTargetGroupDataForReview {
  id: string;
  rejectionStatus: RejectionStatus | null;
  comment?: string;
  pipelineStage: PipelineStage | null;
  targetGroupId: string;
  targetGroupName: string;
  companyId: string;
  companyName: string;
  website: string;
  companyLinkedIn: string;
  headcount: number;
  leadId: string;
  leads: any[];
  allLeads: any[];
  shortDescription: string;
  otherInfo: string;
  countries: any[];
  emailSendStatuses: string[];
  emailSequences: ICompanyTargetGroupEmail[];
  campaignOwner: { id: string; name: string };
  isValidated: boolean;
  isRejected: boolean;
  companyNewsEvents?: any;
  EBITDA?: string;
  revenue?: string;
  EBITDAMargin?: string;
  acquirer?: string;
  PEbacker?: string;
  VCbacker?: string;
  acquiredCompanies?: string;
}

export interface IMarkAsToResearch {
  isMarkedAsToResearch: boolean;
  message: string;
}

export interface IMarkAsResearched {
  isMarkedAsResearched: boolean;
  message: string;
}

export interface IMarkAsNotAFit {
  isMarkedAsNotAFit: boolean;
  message: string;
}

export interface IMarkAsEmailValidation {
  isMarkedAsEmailValidation: boolean;
  message: string;
}

export interface IMarkAsInvalidEmail {
  isMarkedAsInvalidEmail: boolean;
  message: string;
}

export interface ICompanyForResearchState {
  item: ICompanyTargetGroupDataForResearch;
  markAsToResearch: IMarkAsToResearch;
  markAsResearched: IMarkAsResearched;
  markAsNotAFit: IMarkAsNotAFit;
  markAsEmailValidation: IMarkAsEmailValidation;
  markAsInvalidEmail: IMarkAsInvalidEmail;
  markAsPaused?: IMarkAsPaused;
  formValidationErrors: ValidationResultsObject | undefined;
  isFirstCompanyLoad: boolean;
}

export enum CheckboxValues {
  Yes = 'Yes',
  No = 'No',
}

export class UpdateCompanyForResearchBody {
  constructor(data: ICompanyTargetGroupDataForResearch) {
    this.companyName = data.companyName;
    this.website = data.website;

    this.comment = data.comment;

    this.leads = data.leads;

    this.companyLinkedIn = data.companyLinkedIn;
    this.headcount = data.headcount;
    this.targetGroup = {
      id: data.targetGroupId,
      name: data.targetGroupName,
    };
    this.shortDescription = data.shortDescription;
    this.countries = data.countries;
    this.otherInfo = data.otherInfo;
    this.acquired = data.acquired;
    this.backedByPE = data.backedByPE;
    this.backedByVC = data.backedByVC;
    this.madeAcquisitions = data.madeAcquisitions;
    this.revenue = data.revenue;
    this.EBITDA = data.EBITDA;
    this.acquirer = data.acquirer;
    this.PEbacker = data.PEbacker;
    this.VCbacker = data.VCbacker;
    this.acquiredCompanies = data.acquiredCompanies;
    this.placeholders = data.placeholders;
  }

  comment: string;

  leads?: any[];

  public companyName: string;

  public website: string;

  public companyLinkedIn: string;

  public headcount: number;

  public shortDescription: string;

  public otherInfo: string;

  public countries: ICountry[];

  acquired?: CheckboxValues;

  backedByPE?: CheckboxValues;

  backedByVC?: CheckboxValues;

  madeAcquisitions?: CheckboxValues;

  acquirer?: string;

  PEbacker?: string;

  VCbacker?: string;

  acquiredCompanies?: string;

  revenue?: string;

  EBITDA?: string;

  placeholders: Record<string, string> = {};

  public targetGroup: { name: string; id: string };
}

export interface IUpdateCompanyForResearchParams {
  companyTargetGroupId: string;
  companyId: string;
  leadId: string;
  body: UpdateCompanyForResearchBody;
}

export interface IFindLeadEmailParams {
  companyId: string;
  leadId: string;
}

export interface IGetCompaniesForReviewThunkParams {
  clientId: string | undefined;
  filterCriterion?: CompaniesForReviewFilterCriterion;
  targetGroupId: string;
  take: number;
  skip: number;
}

export interface ICompanyForReview {
  id: string;
  pipelineStage: PipelineStage | null;
  rejectionStatus: RejectionStatus | null;
  createdAt: string;
  companyId: string;
  companyName: string;
}

export interface ICompaniesForReviewListResponse extends BaseListServerResponse<ICompanyForReview> {}

export interface ICurrentReviewFilterCriterion {
  id: string;
  name: string;
  value: CompaniesForReviewFilterCriterion;
}

export interface ICurrentCompanyForReviewParams {
  id: string;
  name: string;
}

export interface IMarkAsValidated {
  isMarkedAsValidated: boolean;
  message: string;
}

export interface IMarkAsRejected {
  isMarkedAsRejected: boolean;
  message: string;
}

export interface IMarkAsPaused {
  isMarkedAsPaused: boolean;
  onStage: 'review' | 'research';
  message: string;
}

export interface ICompanyForReviewState {
  item: ICompanyTargetGroupDataForReview;
  markAsValidated: IMarkAsValidated;
  markAsRejected: IMarkAsRejected;
  markAsPaused?: IMarkAsPaused;
  isFirstCompanyLoad: boolean;
}

export interface IUpdateEmailSequencesParams {
  companyTargetGroupId: string;
  body: { email: ICompanyTargetGroupEmail; index: number };
}

export interface IUpdateEmailSequencesItemResponse extends BaseItemServerResponse<ICompanyTargetGroupEmail[]> {}

export interface IThunkStatuses {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  isStarted: boolean;
  isFinished: boolean;
}

export interface ICompanyTargetGroupThunks {
  getWeeklyIntervalsOfCompaniesCreation: IThunkStatuses;
  getCompaniesForVetting: IThunkStatuses;
  setCompanyTargetGroupRejectionStatus: IThunkStatuses;
  vetCompaniesForPeriod: IThunkStatuses;
  countCompaniesToVet: IThunkStatuses;
  getCompaniesForResearchFilteredByCriterion: IThunkStatuses;
  getCompanyForResearch: IThunkStatuses;
  markCompanyAsToResearch: IThunkStatuses;
  markCompanyAsResearched: IThunkStatuses;
  markCompanyAsNotAFit: IThunkStatuses;
  countCompaniesToResearch: IThunkStatuses;
  countCompaniesToReview: IThunkStatuses;
  updateCompanyForResearch: IThunkStatuses;
  findLeadEmail: IThunkStatuses[];
  searchCountries: IThunkStatuses;
  getCompaniesForReviewFilteredByCriterion: IThunkStatuses;
  getCompanyForReview: IThunkStatuses;
  updateEmailSequences: IThunkStatuses;
  validateLead: IThunkStatuses;
  rejectLead: IThunkStatuses;
  updateRelevantCompanies?: IThunkStatuses;
  getCompanyNews?: IThunkStatuses;
  getClientCompaniesListByTargetGroupId?: IThunkStatuses;
  downloadCompanyTargetGroupsAnalytics?: IThunkStatuses;
  getCompanyTargetGroupsAnalyticsKpiSet?: IThunkStatuses;
  updateCompanyTargetGroupStatus?: IThunkStatuses;
  getCompanyTargetGroupsForAnalytics?: IThunkStatuses;
  propagate: IThunkStatuses;
  createNewLead: IThunkStatuses;
  deleteLead: IThunkStatuses;
  selectLeadForSequence: IThunkStatuses;
  changeTargetGroup: IThunkStatuses;
  ignoreLead: IThunkStatuses;
  updateVettingComment: IThunkStatuses;
  markCompanyAsPaused: IThunkStatuses;
}

export interface ICompanyTargetGroupState {
  companyTargetGroupsForAnalytics: ICompanyTargetGroupsForAnalytics;
  companyTargetGroupsAnalyticsKpiSet: ICompanyTargetGroupsAnalyticsKpiSet;
  clientsCompaniesByTargetGroup: IClientsCompaniesByTargetGroup;
  companyNewsEvents: ICompanyNewsEventsState;
  relevantCompaniesNewsEvents: IRelevantCompaniesNewsEventsState;
  areCompanyTargetGroupsStatusesUpdated: boolean;
  weeklyIntervalsOfCompaniesCreation: IWeeklyIntervalsOfCompaniesCreationListResponse;
  currentWeekOfCompanyCreation: ICurrentWeekOfCompaniesCreation;
  companiesForVetting: ICompaniesForVettingListResponse;
  companiesToVetCount: number;
  companiesToReviewCount?: number;
  currentCompanyForResearchParams: ICurrentCompanyForResearchParams;
  companiesForResearch: ICompaniesForResearchListResponse;
  companiesToResearchCount: number;
  currentResearchFilterCriterion: ICurrentResearchFilterCriterion;
  companyForResearch: ICompanyForResearchState;
  countries: ICountry[];
  companiesForReview: ICompaniesForReviewListResponse;
  currentReviewFilterCriterion: ICurrentReviewFilterCriterion;
  currentCompanyForReviewParams: ICurrentCompanyForReviewParams;
  companyForReview: ICompanyForReviewState;
  thunks: ICompanyTargetGroupThunks;
}

export interface ICompanyTargetGroupResponse extends BaseItemServerResponse<ICompanyTargetGroup> {}
