/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';

import { IConfirmActionProps } from './modal-content/confirm-action/confirm-action.types';
import { ModalContentType, ErrorModalContentType, InfoModalContentType } from '../../feedback-ui/feedback-ui.types';

import ConfirmAction from './modal-content/confirm-action/confirm-action.component';

export const modalContentStrategy: { [Property in ModalContentType]: (props: any) => ReactNode } = {
  [ErrorModalContentType.SOME_ERROR_HAPPENED]: (props: any) => null,
  [InfoModalContentType.CONFIRM_ACTION]: (props: IConfirmActionProps) => {
    return <ConfirmAction {...props} />;
  },
};
