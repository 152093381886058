import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { SelectSequenceLeadWithDropdownContainer } from './select-sequence-lead-with-dropdown.styles';

import BaseDropdown from '../../../shared/components/dropdown/base-dropdown/base-dropdown.component';
import { companyForReviewSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import { selectLeadForSequence } from '../../../entities/company-target-group/store/company-target-group.slice';

const getCurrentSequenceLead = (
  currentLeadId: string,
  leadsToContact: any[],
  allLeads: any[],
  isContacted: boolean,
): { id: string; value: string } => {
  const defaultSelection = leadsToContact[0] || { id: '', value: 'No leads available' };
  if (isContacted) return allLeads.find(({ id }) => id === currentLeadId) || defaultSelection;
  return leadsToContact.find(({ id }) => id === currentLeadId) || defaultSelection;
};

const createSelectOption = ({ id, value }: { id: string; value: string }) => ({
  id,
  name: value,
  values: {
    id,
    name: value,
    value,
  },
});

function SelectSequenceLeadWithDropdown({
  sequenceIndex,
  contactedLeadsIds,
  currentLeadId,
  isContacted = false,
}: {
  sequenceIndex: number;
  contactedLeadsIds: string[];
  currentLeadId: string;
  isContacted: boolean;
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const ctg = useAppSelector(companyForReviewSelector).item;

  const { emailSequences, leads, id: companyTargetGroupId, isValidated, allLeads } = ctg;

  const leadsToContact = leads.filter(({ id }) => !contactedLeadsIds.includes(id));

  const currentLead = getCurrentSequenceLead(currentLeadId, leadsToContact, allLeads, isContacted);

  const isDisabled = isValidated || isContacted;

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  function handleOptionChange(event: SelectChangeEvent<unknown>) {
    const reviewFilterCriterion = event.target.value as string;
    const { id: leadId } = JSON.parse(reviewFilterCriterion);
    if (clientId && !isDisabled)
      dispatch(selectLeadForSequence({ companyTargetGroupId, clientId, sequenceIndex, leadId }));
  }

  useEffect(() => {
    const isCurrentLeadHasBeenIgnored =
      emailSequences[sequenceIndex]?.leadId && emailSequences[sequenceIndex]?.leadId !== currentLead.id;

    if (clientId && isCurrentLeadHasBeenIgnored && !isDisabled)
      dispatch(selectLeadForSequence({ companyTargetGroupId, clientId, sequenceIndex, leadId: currentLead.id }));
  }, [ctg]);

  return (
    <SelectSequenceLeadWithDropdownContainer isMenuOpen={isMenuOpen}>
      <BaseDropdown
        selectedOptionName={createSelectOption(currentLead)?.name ?? 'no lead'}
        options={leadsToContact.map(createSelectOption) || []}
        handleOptionChange={handleOptionChange}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        isDisabled={isDisabled}
      />
    </SelectSequenceLeadWithDropdownContainer>
  );
}

export default SelectSequenceLeadWithDropdown;
