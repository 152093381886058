import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  companiesToVetCountSelector,
  companyTargetGroupThunksSelector,
} from '../../../entities/company-target-group/store/company-target-group.selectors';
import { countCompaniesToVet } from '../../../entities/company-target-group/store/company-target-group.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { useIsRouteMatched } from '../../../shared/hooks/use-is-route-matched.hook';
import { Routes } from '../../../shared/domain/constants/routing.constants';

import IndicatorChip from '../../../shared/components/chip/indicator-chip/indicator-chip.component';
import { companiesFileSelector } from '../../../entities/company/store/company.selectors';

function CountCompaniesToVet() {
  const dispatch = useAppDispatch();

  const { isRouteMatched } = useIsRouteMatched();

  const { clientId } = useParams();

  const count = useAppSelector(companiesToVetCountSelector);
  const { isSuccess: areCompaniesVetted } = useAppSelector(companyTargetGroupThunksSelector).vetCompaniesForPeriod;
  const { isFileDataUploaded } = useAppSelector(companiesFileSelector);

  const isDimmed = !isRouteMatched(Routes.TARGET_VETTING);

  useEffect(() => {
    dispatch(countCompaniesToVet({ clientId }));
  }, []);

  useEffect(() => {
    if (areCompaniesVetted || isFileDataUploaded) dispatch(countCompaniesToVet({ clientId }));
  }, [dispatch, areCompaniesVetted, isFileDataUploaded]);

  return <IndicatorChip label={count} isDimmed={isDimmed} />;
}

export default CountCompaniesToVet;
