/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { logout, setAuthentication } from '../../features/authentication/store/authentication.slice';
import { resetUser } from '../../entities/user/store/user.slice';
import { AuthenticationEndpoint } from '../../features/authentication/api/authentication-endpoint.constants';

import axios from './axios.service';

export const setUpInterceptor = (store: any) => {
  axios.interceptors.request.use((config: any) => {
    const { accessToken } = store.getState().authentication;
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });

  axios.interceptors.response.use(
    (config) => config,
    async (error) => {
      const failedRequest = error.config;
      const isAuthError = error.response.status === 401;
      const notRetryFailedRequest = error.config && !error.config._isRetry;
      if (isAuthError && notRetryFailedRequest) {
        failedRequest._isRetry = true;
        try {
          const response = await axios.post(AuthenticationEndpoint.REFRESH);
          if (response.data) {
            store.dispatch(setAuthentication(response?.data?.item?.accessToken));
            return axios.request(failedRequest);
          }
        } catch (e) {
          store.dispatch(logout());
          store.dispatch(resetUser());
        }
      }
      throw error;
    },
  );
};
