import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';

export const ShowPromptOutcomeContainer = styled(Stack)<StackProps>(({ theme }) => ({
  '> .marked-as-not-a-fit': {
    width: '50px',
    minWidth: '80px',
    height: '20px',

    '& .MuiCircularProgress-root': { color: theme.palette.background.orange },
  },

  [theme.breakpoints.up('xs')]: {
    padding: '0.8rem 5px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
