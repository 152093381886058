import { FileRejectionSource } from './rejected-file.types';

export const ReactDropzoneRejection: { [key: string]: string } = {
  'file-invalid-type': 'File type must be .csv',
};

export const ServerRejection: { [key: string]: string } = {
  INCORRECT_COLUMNS: 'Incorrect column names provided',
};

export const FileRejectionStrategy: { [key: string]: (param: string) => string } = {
  [FileRejectionSource.REACT_DROPZONE]: (errorCode: string) => ReactDropzoneRejection[errorCode],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [FileRejectionSource.SERVER]: (error: string) => error || ServerRejection.INCORRECT_COLUMNS,
};
